import { useMutation } from '@apollo/client';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import { UserType } from '../../../__generated__/graphql';
import { USER_DELETE_MUTATION } from '../../../api/mutations/users';
import { USER_TYPE } from '../../../constants';
import { clearAuthCookies } from '../../../helpers/auth';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Warning } from '../../../layout/modals';
import { setSuccessAlert } from '../../../redux/alertSlice';

interface Props {
  user: UserType,
}

function DeleteAccount(props: Props) {
  const { user } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    deleteMutation,
    {
      data: deleteMutationData,
    }] = useMutation(USER_DELETE_MUTATION);

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  function requireConfirmation() {
    toggleConfirmationModal();
  }

  const handleDelete = () => {
    deleteMutation({
      variables: {
        user: user.id,
      },
    });
  };

  useEffect(() => {
    if (deleteMutationData) {
      clearAuthCookies();
      dispatch(setSuccessAlert(
        [t('Your Account was permanently deleted.')],
      ));
    }
  }, [deleteMutationData, dispatch, t]);

  if (deleteMutationData) {
    return <Navigate to="/authentication/login/" />;
  }
  return (
    <div className="px-2 sm:px-0 mt-5 md:mt-0">
      {!user.isOnboarded && (
        <div className="shadow sm:overflow-hidden mb-4">
          <div className="rounded sm:rounded-md bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium ">{t('Onboarding is not finished')}</h3>
              <p className="mt-1 text-sm ">
                {t('Please finish the onboarding process in order to get full settings panel access.')}
              </p>
            </div>
          </div>
          <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
            <Link
              to="/onboarding/"
              className="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              {t('Finish Onboarding')}
            </Link>
          </div>
        </div>
      )}
      <div className="shadow sm:overflow-hidden">
        {user.userType === USER_TYPE.MANAGER && (
          <div className="rounded sm:rounded-md bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium ">{t('Delete Account')}</h3>
              <p className="mt-1 text-sm">{t('In order to delete your account please contact us via email:')}</p>
              <a className="text-sm" href="mailto:support@sprungbrett-handwerk.de">support@sprungbrett-handwerk.de</a>
            </div>
          </div>
        )}
        {user.userType === USER_TYPE.APPLICANT && (
          <>
            <div className="rounded sm:rounded-md bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium ">{t('Delete Account')}</h3>
                <p className="mt-1 text-sm ">
                  {t('By clicking the button below you will permanently delete your account.')}
                </p>
              </div>
            </div>
            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              <button
                onClick={requireConfirmation}
                className="bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {t('Delete')}
              </button>
            </div>
          </>
        )}
      </div>
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleDelete}
        title={t('Delete Account')}
      />
    </div>
  );
}

export default DeleteAccount;
