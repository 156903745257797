import {
  zIndexTweakedSelectStyles,
} from '../../helpers/utils';
import Select from './Select';

interface Props {
  value: SelectOption | null,
  onChange: (value: SelectOption) => void,
  isDisabled: boolean,
}

const options = [
  { label: '+ 5 km', value: 5 },
  { label: '+ 10 km', value: 10 },
  { label: '+ 20 km', value: 20 },
  { label: '+ 30 km', value: 30 },
  { label: '+ 50 km', value: 50 },
  { label: '+ 100 km', value: 100 },
  { label: '+ 150 km', value: 150 },
  { label: '+ 200 km', value: 200 },
];

function AreaRadiusSelect(props: Props) {
  const { onChange, isDisabled, value } = props;
  return (
    <Select
      value={value && value}
      onChange={onChange}
      options={options}
      isClearable
      isDisabled={isDisabled}
      // eslint-disable-next-line no-undef
      menuPortalTarget={document.body}
      styles={zIndexTweakedSelectStyles}
    />
  );
}

export default AreaRadiusSelect;
