/* eslint-disable @typescript-eslint/no-explicit-any */
//@ts-ignore
import Files from 'react-butterfiles';
import { useTranslation } from 'react-i18next';
import { FILE_TYPE } from '../../../constants';
import { getFilesErrorLabel } from '../../../helpers/utils';
import FilesList from './FilesList';

interface Props {
  label: string,
  accept: string[],
  handleSuccess: (inputFiles: any) => void,
  handleError: (e: string) => void,
  hasValue?: boolean,
  isRequired?: boolean,
  uploadType: string,
  handleClear: () => void,
  hasError: boolean,
  items: any,
}

const maxFileSize = '10Mb';
const multipleFilesMaxSize = '100Mb';

function DropZoneUpload(props: Props) {
  const { t } = useTranslation();
  const {
    accept, handleSuccess, handleError, label, uploadType,
    isRequired, handleClear, hasError, items,
  } = props;

  const handleInnerSuccess = (inputFiles: any) => {
    handleSuccess(inputFiles);
    handleError('');
  };
  return (
    <>
      <Files
        multiple={uploadType === FILE_TYPE.CERTIFICATE}
        maxSize={maxFileSize}
        multipleMaxSize={multipleFilesMaxSize}
        convertToBase64
        accept={accept}
        onSuccess={handleInnerSuccess}
        onError={(e: any) => {
          const [firstError] = e;
          handleError(getFilesErrorLabel(t, firstError.type, maxFileSize, multipleFilesMaxSize));
        }}
      >
        {({ browseFiles, getDropZoneProps }: any) => (
          <div>
            <div className="relative py-1 pr-10 leading-normal">
              <label className={`${isRequired && 'font-bold'}`}>
                {label}
                {isRequired && <span className="text-red-600"> *</span>}
              </label>
            </div>
            <div
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...getDropZoneProps({
                className: 'rounded-lg mt-1 col-span-6 sm:col-span-3 bg-darkerGray flex items-center justify-center h-screen',
                style: {
                  height: 200,
                  border: `${hasError ? '2px #f26857 dashed' : '2px lightgray dashed'}`,
                },
              })}
              onClick={browseFiles}
              role="button"
              tabIndex="0"
            >
              <div className="px-4 text-lg ">
                {uploadType === FILE_TYPE.CV && (
                  <span>{t('Please drop your CV here or click this area to select')}</span>
                )}
                {uploadType === FILE_TYPE.CERTIFICATE && (
                  <span>{t('Please drop your Certificates here or click this area to select')}</span>
                )}
              </div>
            </div>
          </div>
        )}
      </Files>
      <FilesList
        items={items}
        handleClear={handleClear}
      />
    </>
  );
}

export default DropZoneUpload;
