import { useQuery } from '@apollo/client';
import { ChatAltIcon } from '@heroicons/react/outline';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from '../../../__generated__/graphql';
import { ARCHIVED_MESSAGES_QUERY } from '../../../api/queries/chats';
import { IMAGE_SIZE } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { Pagination, UserImage, TimeAgo } from '../../../layout';

interface Props {
  currentUserType: string,
  threadId: string,
  hideArchive: () => void,
}

function ArchiveChatSection(props: Props) {
  const { currentUserType, threadId, hideArchive } = props;
  const [page, setPage] = useState(1);
  const [hasMultiplePages, setHasMultiplePages] = useState(false);
  const { t } = useTranslation();
  const chatContainer = useRef(null);

  const { data: messagesData, fetchMore } = useQuery(
    ARCHIVED_MESSAGES_QUERY,
    {
      variables: {
        page: 1,
        thread: threadId,
      },
    },
  );

  const scrollToBottom = () => {
    if (chatContainer.current) {
      //@ts-ignore
      const scroll = chatContainer.current.scrollHeight + chatContainer.current.clientHeight;
      //@ts-ignore
      chatContainer.current.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    // Keep scroll at bottom initially
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (messagesData
      && (messagesData.archivedMessages.hasNext || messagesData.archivedMessages.hasPrev)) {
      setHasMultiplePages(true);
    }
  }, [messagesData]);

  useEffect(() => {
    fetchMore({
      variables: {
        page,
        thread: threadId,
      },
    });
  }, [fetchMore, page, threadId]);

  if (!messagesData) return '';

  return (
    <div
      className="pr-4 relative"
    >
      <div className="h-[65vh] md:h-[80vh]">
        <div className="absolute top-0 bottom-20 right-0 left-0">
          <div className="col-start-1 col-end-13 flex justify-center">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border-2 border-darkerGray shadow-sm text-sm leading-4 font-medium rounded-md  bg-white hover:bg-lightGray"
              onClick={() => hideArchive()}
            >
              {t('Return to Chat')}
              <ChatAltIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div
          id="styled-scroll"
          ref={chatContainer}
          className={classNames(hasMultiplePages ? 'bottom-20' : 'bottom-0', 'h-[53vh] md:h-[68vh] overflow-y-auto flex flex-col absolute top-10  right-0 left-0')}
        >
          <div className="grid grid-cols-12 gap-y-2">
            {messagesData && messagesData.archivedMessages.objects.length
              ? messagesData.archivedMessages.objects.map((item: MessageType) => (
                <div
                  key={item.id}
                  className={classNames(item.sender === currentUserType
                    ? 'col-start-2 md:col-start-6 col-end-13' : 'col-start-1 col-end-12 md:col-end-8', 'p-3 rounded-lg')}
                >
                  <div className={classNames(item.sender === currentUserType
                    ? 'justify-start flex-row-reverse' : 'flex-row', 'flex items-center')}
                  >
                    <UserImage
                      className="h-10 w-10 rounded-full"
                      profile={item[item?.sender?.toLowerCase() as keyof typeof item].profile}
                      imageSize={IMAGE_SIZE.S32}
                    />
                    <div className={classNames(item.sender === currentUserType
                      ? 'mr-3 bg-blue-50' : 'ml-3 bg-white', 'relative text-sm py-2 px-4 shadow rounded-xl')}
                    >
                      <div>{item.body}</div>
                      <div className="text-xs text-right text-secondaryGray">
                        <TimeAgo date={item.createdAt} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : (
                <div className="h-screen max-h-60vh col-start-2 col-end-13">
                  <div className="h-96 max-h-full p-6 flex items-center justify-center rounded font-medium text-lg ">
                    <span>{t('There are no messages in this conversation just yet.')}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
        {hasMultiplePages && (
          <div className="absolute bottom-0 left-0 right-0">
            <div className="h-14 mx-4">
              <Pagination
                page={messagesData.archivedMessages.page}
                hasNext={messagesData.archivedMessages.hasNext}
                hasPrev={messagesData.archivedMessages.hasPrev}
                elidedPageRange={messagesData.archivedMessages.elidedPageRange}
                onPageChange={setPage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArchiveChatSection;
