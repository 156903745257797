import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApplicantLikeType } from '../../../../__generated__/graphql';
import { APPLICANT_LIKES_QUERY } from '../../../../api/queries/activities';
import ActivityCard from './ActivityCard';

function ActivitiesList() {
  const { t } = useTranslation();
  // The first page is enough. If user wants more then we can go to dedicated page.
  const { data } = useQuery(APPLICANT_LIKES_QUERY, { variables: { page: 1 } });

  if (!data) {
    return '';
  }

  return (
    <>
      <div className="pt-6 pb-2">
        <h2 className="text-sm font-semibold">{t('Applicants Activity')}</h2>
      </div>
      <div>
        <ul className="divide-y divide-lightGray">
          {data.applicantLikes.objects.map((item: ApplicantLikeType) => (
            <li key={item.id} className="py-2">
              <ActivityCard
                item={item}
              />
            </li>
          ))}
        </ul>
        <div className="py-2 text-sm border-t border-lightGray">
          <Link to="/manager/activity/likes/" className="text-primary font-semibold hover:text-primary">
            {t('View all activity')}
          </Link>
        </div>
      </div>
    </>
  );
}

export default ActivitiesList;
