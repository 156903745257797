import L from 'leaflet';
import {
  MapContainer, TileLayer, GeoJSON, Marker,
} from 'react-leaflet';
import '../../assets/css/plugins/leaflet.css';
import { ApplicantPreferredLocationType } from '../../__generated__/graphql';
import icon from '../../assets/images/leaflet/marker-icon-2x-blue.png';

//@ts-ignore
const markerIcon = new L.icon({
  iconUrl: icon,
  iconSize: [30, 45],
});

const getZoom = (calculatedLength: number) => {
  if (calculatedLength > 30) {
    return 10;
  }
  if (calculatedLength > 20) {
    return 11;
  }
  if (calculatedLength > 7) {
    return 12;
  }
  return 13;
};

interface Props {
  calculatedLength: number,
  location: ApplicantPreferredLocationType,
}

function LocationMap(props: Props) {
  const { location, calculatedLength } = props;
  return (
    <MapContainer
      center={[location.city.latitude, location.city.longitude]}
      zoom={getZoom(calculatedLength)}
      scrollWheelZoom={false}
      style={{ height: '35vh', zIndex: 5 }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {!location.city.geojson || location.city.geojson.type === 'Point' ? (
        <Marker
          position={[location.city.latitude, location.city.longitude]}
          icon={markerIcon}
          opacity={0.7}
        />
      ) : (
        <GeoJSON
          data={location.city.geojson}
          style={{
            color: '#054787', // Outline color
          }}
        />
      )}
    </MapContainer>
  );
}

export default LocationMap;
