import { useQuery } from '@apollo/client';
import { CURRENT_APPLICANT_QUERY } from '../../api/queries/applicants';
import { ApplicantForm } from '../../components/shared';
import { SettingsLayout } from '../../layout/layouts/dashboard';

function Applicant() {
  const { data: applicantData } = useQuery(CURRENT_APPLICANT_QUERY);

  return (
    <SettingsLayout>
      {applicantData && (
        <ApplicantForm
          currentApplicant={applicantData.currentApplicant}
        />
      )}
    </SettingsLayout>
  );
}

export default Applicant;
