import { useApolloClient } from '@apollo/client';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApplicantLikeType, ApplicantOfferViewType, ApplicantType, CompanyType, DetailsApplicantType, OfferType, SimplifiedOfferType } from '../../../../__generated__/graphql';
import { PUBLIC_APPLICANT_QUERY } from '../../../../api/queries/applicants';
import { TimeAgo } from '../../../../layout';

interface TitleType {
  applicant: ApplicantType | DetailsApplicantType,
  company?: CompanyType,
  createdAt: Date | DateTime,
  id: string,
  offer: SimplifiedOfferType | OfferType,
  target?: string,
  updatedAt?: DateTime,
}

interface Props {
  item: ApplicantLikeType | ApplicantOfferViewType,
}

function ActivityCard(props: Props) {
  const { item } = props;
  const { t } = useTranslation();
  const client = useApolloClient();

  const getTitle = (data: TitleType) => {
    if (data.offer) {
      return data.offer.fullTitle;
    } if (data.company) {
      return data.company.name;
    }
    return '';
  };

  return (
    <div className="flex space-x-3">
      <div className="flex-1 space-y-1">
        <Link
          to={`/applicant/${item?.applicant?.id}/`}
          onMouseEnter={() => client.query({
            query: PUBLIC_APPLICANT_QUERY,
            variables: { applicant: item?.applicant?.id },
          })}
        >
          <p className="text-xs"><TimeAgo date={item.createdAt} /></p>
          <div className="flex items-center">
            <h3 className="text-sm font-semibold">{item?.applicant?.fullTitle}</h3>
          </div>
          <p className="text-sm ">
            {t('Liked')}
            {' '}
            <span>{getTitle(item as TitleType)}</span>
          </p>
        </Link>
      </div>
    </div>
  );
}

export default ActivityCard;
