import { gql } from '@apollo/client';

export const NOTIFICATION_SETTINGS_QUERY = gql`
  query notificationSettings{
    notificationSettings{
      id
      notificationChannels
    }
  }
`;
