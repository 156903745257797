/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';

function PlaceholderCard() {
  const { t } = useTranslation();
  return (
    <div className="grid gap-4">
      <div className="text-2xl font-extrabold tracking-tight ">{t('Like, match and get to know each other')}</div>
      <div className="text-base tracking-tight ">{t('In two simple steps you can quickly benefit from Sprungbrett Handwerk.')}</div>

      <ol className="list-inside list-decimal text-base flex flex-col gap-1">
        <li>
          <span className="font-extrabold">{t('Like job offer or company')}</span>
          {' - '}
          <span>{t('Now like a job offer or a company in the "Dashboard" or under "Job offers". The company will be notified immediately and can also like your profile.')}</span>
        </li>
        <li>
          <span className="font-extrabold">{t('Match')}</span>
          {' - '}
          <span>{t('You will be notified when your profile receives a "Like" from a company. If a job offer or a company is interesting for you, you return the Like and you can chat with each other here.')}</span>
        </li>
      </ol>

      <p className="text-base">
        <span className="font-extrabold">{t('New job ads?')}</span>
        <br />
        <span>{t('As soon as a company you have liked posts a new job, it will be displayed here immediately.')}</span>
      </p>

      <p className="text-base">
        <span>{t('Tip')}</span>
        {': '}
        <span className="font-extrabold">{t('Your profile')}</span>
        {' - '}
        <span>{t('You should have created a meaningful profile of yourself. This increases your chances significantly.')}</span>
      </p>
    </div>
  );
}

export default PlaceholderCard;
