import { useQuery } from '@apollo/client';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { USER_TYPE } from '../../../constants';
import DashboardLayout from '../DashboardLayout';

interface Props {
  children: ReactNode,
}

function ApplicantDashboardLayout({ children }: Props) {
  const { data, error } = useQuery(CURRENT_USER_QUERY);
  const user = data ? data.currentUser : null;

  if (user && user.userType === USER_TYPE.MANAGER) {
    return <Navigate to="/404/" />;
  }

  return (
    <DashboardLayout>
      {error && (<p>{error.message}</p>)}
      {user && user.userType === USER_TYPE.APPLICANT && (
        children
      )}
    </DashboardLayout>
  );
}

export default ApplicantDashboardLayout;
