import { gql } from '@apollo/client';

export const NOTIFICATION_SETTINGS_MUTATION = gql`
mutation updateNotificationSettings(
  $id: ID!
  $notificationChannels: String!,
  $user: ID!
  ){
  notificationSettings(
    input:
    {
      id: $id,
      notificationChannels: $notificationChannels,
      user: $user,
    })
    { response{
      id
      notificationChannels
    }
    errors{
      field
      messages
    }
  }
}
`;
