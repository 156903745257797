import { useQuery } from '@apollo/client';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { CURRENT_COMPANY_IS_ACTIVE_QUERY } from '../../../api/queries/companies';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { USER_TYPE } from '../../../constants';
import DashboardLayout from '../DashboardLayout';

interface Props {
  children: ReactNode,
}

function ManagerDashboardLayout({ children }: Props) {
  const { data: userData, error: userError } = useQuery(CURRENT_USER_QUERY);
  const { data: companyData, error: companyError } = useQuery(CURRENT_COMPANY_IS_ACTIVE_QUERY);
  const user = userData ? userData.currentUser : null;
  const company = companyData ? companyData.currentCompany : null;

  if (user && user.userType === USER_TYPE.APPLICANT) {
    return <Navigate to="/404/" />;
  }

  if (company && !company.isActive) {
    return <Navigate to="/inactive/company/" />;
  }

  return (
    <DashboardLayout>
      {userError && (<p>{userError.message}</p>)}
      {companyError && (<p>{companyError.message}</p>)}
      {user && user.userType === USER_TYPE.MANAGER && (
        children
      )}
    </DashboardLayout>
  );
}

export default ManagerDashboardLayout;
