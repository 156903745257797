import { gql } from '@apollo/client';

export const COMPANY_MUTATION = gql`
  mutation createOrUpdateCompany(
    $id: ID,
    $branche: String,
    $companySize: String!,
    $description: String,
    $email: String!,
    $highlights: String,
    $logoImage: String,
    $invitationCode: UUID!,
    $isActive: Boolean,
    $name: String!,
    $owner: ID!,
    $phone: String!,
    $profileImage: String,
    $website: String,
    ){
      company(input:{
      id: $id
      branche: $branche
      companySize: $companySize
      description: $description
      email: $email
      highlights: $highlights
      logoImage: $logoImage
      invitationCode: $invitationCode
      isActive: $isActive
      name: $name
      owner: $owner
      phone: $phone
      profileImage: $profileImage
      website: $website
    }){
      response{
        id
        name
        branche
        companySize
        description
        email
        highlights
        logoImageSrcset
        invitationCode
        isActive
        phone
        profileImageSrcset
        website
        owner{
          id
        }
        benefits{
          id
          benefitType
          description
        }
        address{
          id
          street
          areaCode{
            id
          }
          city{
            id
            name
          }
          country{
            id
            name
          }
        }
        managers{
          id
          ownedCompanies{
            id
          }
          profile{
            fullName
            user{
              email
            }
          }
        }
        pendingInvitations{
          id
          manager{
            id
            profile{
            fullName
            user{
              email
            }
          }
        }
      }
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const LEAVE_COMPANY_MUTATION = gql`
  mutation leaveCompany(
    $company: ID!,
    ){
      leaveCompany(input:{
      company: $company
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;

export const REMOVE_MANAGER_MUTATION = gql`
  mutation removeManager(
    $company: ID!,
    $manager: ID!,
    ){
      removeManager(input:{
      company: $company
      manager: $manager
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;

export const CHANGE_COMPANY_OWNER_MUTATION = gql`
  mutation changeCompanyOwner(
    $company: ID!,
    $newOwner: ID!,
    ){
      changeCompanyOwner(input:{
      company: $company
      newOwner: $newOwner
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;

export const SWITCH_COMPANY_MUTATION = gql`
  mutation switchCompany(
    $company: ID!,
    ){
      switchCompany(input:{
      company: $company
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;

export const COMPLETE_COMPANY_MUTATION = gql`
  mutation completeCompany(
    $branche: String,
    $companySize: String!,
    $description: String,
    $email: String!,
    $highlights: String,
    $logoImage: String,
    $invitationCode: UUID!,
    $name: String!,
    $owner: ID!,
    $phone: String!,
    $profileImage: String,
    $website: String,
    $areaCode: ID!,
    $city: ID!,
    $country: ID!,
    $street: String!,
    ){
      completeCompany(input:{
      branche: $branche
      companySize: $companySize
      description: $description
      email: $email
      highlights: $highlights
      logoImage: $logoImage
      invitationCode: $invitationCode
      name: $name
      owner: $owner
      phone: $phone
      profileImage: $profileImage
      website: $website
      areaCode: $areaCode
      city: $city
      country: $country
      street: $street
    }){
      response{
        id
        name
        branche
        companySize
        description
        email
        highlights
        logoImageSrcset
        invitationCode
        isActive
        phone
        profileImageSrcset
        website
        owner{
          id
        }
        benefits{
          id
          benefitType
          description
        }
        address{
          id
          street
          areaCode{
            id
          }
          city{
            id
            name
          }
          country{
            id
            name
          }
        }
        managers{
          id
          ownedCompanies{
            id
          }
          profile{
            fullName
            user{
              email
            }
          }
        }
        pendingInvitations{
          id
          manager{
            id
            profile{
            fullName
            user{
              email
            }
          }
        }
      }
      }
      errors{
        field
        messages
      }
    }
  }
`;
