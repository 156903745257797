import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { USER_TYPE } from '../constants';
import { clearAuthCookies } from '../helpers/auth';

function Dashboard() {
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY);
  const user = data ? data.currentUser : null;
  if (!loading && error) {
    clearAuthCookies();
    return <Navigate to="/authentication/login/" />;
  }
  if (user && !user.isOnboarded) {
    return <Navigate to="/onboarding/" />;
  }
  if (user && user.userType === USER_TYPE.MANAGER) {
    return <Navigate to="/manager/" />;
  }
  if (user && user.userType === USER_TYPE.APPLICANT) {
    return <Navigate to="/applicant/" />;
  }
  return '';
}

export default Dashboard;
