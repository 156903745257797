import { ProfileType } from '../__generated__/graphql';
import defaultUserImage from '../assets/images/default-user.png';

function getUserImage(profile: ProfileType, imageSize: string) {
  if (profile && profile.imageSrcset) {
    return profile.imageSrcset[imageSize];
  }
  return defaultUserImage;
}

interface Props {
  profile: ProfileType,
  className: string,
  imageSize: string,
}

function UserImage(props: Props) {
  const { profile, className, imageSize } = props;
  const image = getUserImage(profile, imageSize);

  return (
    <img
      className={className}
      src={image}
      alt=""
    />
  );
}

export default UserImage;
