/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import Cropper from 'cropperjs';
import * as React from 'react';
import 'cropperjs/dist/cropper.min.css';

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.aspectRatio = props.aspectRatio;
    this.imageRef = null;
    this.cropper = null;
  }

  componentDidMount() {
    this.cropper = new Cropper(this.imageRef, {
      ...this.props,
      aspectRatio: this.aspectRatio,
    });
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    this.cropper && this.cropper.destroy();
  }

  render() {
    return this.props.children({
      cropper: this.cropper,
      getImgProps: (props) => ({
        ...props,
        ref: (ref) => (this.imageRef = ref),
      }),
      getDataURL: () => {
        if (this.cropper) {
          return this.cropper.getCroppedCanvas().toDataURL();
        }
        return null;
      },
    });
  }
}

export default ImageCropper;
