import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UserType } from '../../../__generated__/graphql';
import { USER_PASSWORD_MUTATION } from '../../../api/mutations/users';
import { clearAuthCookies } from '../../../helpers/auth';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Input } from '../../../layout/fields';
import { Warning } from '../../../layout/modals';
import { setSuccessAlert } from '../../../redux/alertSlice';

interface Props {
  user: UserType,
}

function ChangePassword(props: Props) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { user } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [
    executeMutation,
    {
      data: executeMutationData,
      loading: executeMutationLoading,
    }] = useMutation(USER_PASSWORD_MUTATION);

  const schema = yup.object({
    password1: yup.string().required(t('Password is Required')),
    password2: yup.string()
      // eslint-disable-next-line func-names
      .test('passwords-match', 'Passwords must match', function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.password1 === value;
      }),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        user: user.id,
        password: values.password1,
      };
      executeMutation({ variables });
    },
  });

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  function requireConfirmation(e: React.FormEvent) {
    e.preventDefault();
    if (!executeMutationLoading) {
      toggleConfirmationModal();
    }
  }



  useEffect(() => {
    if (executeMutationData
      && executeMutationData.passwordUser
      && executeMutationData.passwordUser.response) {
      clearAuthCookies();
      dispatch(setSuccessAlert(
        [t('Your Authentication Credentials have been changed. Please login again.')],
      ));
      return navigate('/authentication/login');
    }
  }, [executeMutationData, dispatch, t, navigate]);

  useEffect(() => {
    if ((touched.password1 && formikErrors.password1) || (touched.password2 && formikErrors.password2)) {
      setShowConfirmationModal(false);
    }
  }, [touched, formikErrors]);

  return (
    <div className="px-2 sm:px-0 mt-5 md:mt-0">
      <div className="shadow sm:overflow-hidden">
        <form onSubmit={requireConfirmation}>
          <div className="rounded sm:rounded-md bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium ">{t('Change Password')}</h3>
              <p className="mt-1 text-sm  mb-2">
                {t('Here you can change your account Password.')}
                {' '}
                {t('You will be forced to login again on success.')}
              </p>

              <div className="space-y-1">
                <Input
                  id="password1"
                  name="password1"
                  type="password"
                  autoComplete="password"
                  //@ts-ignore
                  errorMessage={touched.password1 && formikErrors.password1}
                  label={t('New Password')}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="space-y-1">
                <Input
                  id="password2"
                  name="password2"
                  type="password"
                  required
                  autoComplete="password"
                  //@ts-ignore
                  errorMessage={touched.password2 && formikErrors.password2}
                  label={t('Confirm Password')}
                  onChange={handleChange}
                />
              </div>

            </div>
          </div>
          <div className="px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              disabled={executeMutationLoading ? true : false}
              className="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleSubmit}
        title={t('Change Password')}
      />
    </div>
  );
}

export default ChangePassword;
