import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { APPLICANT_MUTATION } from '../../api/mutations/applicants';
import { CURRENT_APPLICANT_QUERY } from '../../api/queries/applicants';
import { CURRENT_NOTIFICATIONS_QUERY } from '../../api/queries/notifications';
import { INLINE_SKILLS_QUERY } from '../../api/queries/skills';
import {
  APPLICANT_ONBOARDING_STATUS_QUERY,
  CURRENT_USER_QUERY,
} from '../../api/queries/users';
import {
  DRIVER_LICENSE_TYPE,
  FILE_TYPE,
  TARGET_FLEID,
  LANGUAGE,
  JOB_TITLE,
  JOB_QUALIFICATION,
} from '../../constants';
import {
  getInlineOptionsFromEnumDict,
  updateSelectStylesOnError,
  getInlineOptionsFromQuery,
  convertInlineOptionsToString,
  getLabeledDriverLicenceOptions,
  getLabeledJobTitleOptions,
  classNames,
  getFormattedDate,
  fixStr,
} from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/reduxHooks';
import {
  Input, DateInput, Switch, Select,
} from '../../layout/fields';
import DropZoneUpload from '../../layout/fields/upload/DropZoneUpload';
import { ErrorsList, AlertErrorsList } from '../../layout/forms';
import LoadingIndicator from '../../layout/LoadingIndicator';
import { setSuccessAlert } from '../../redux/alertSlice';
import { ShowProfileButton } from './buttons';

const driverLicenceOptions = getInlineOptionsFromEnumDict(DRIVER_LICENSE_TYPE, false);

const labeledDriverLicenceOptions = getLabeledDriverLicenceOptions();

const languageOptions = getInlineOptionsFromEnumDict(LANGUAGE);

const titleOptions = getInlineOptionsFromEnumDict(JOB_TITLE, false);

const labeledJobTitleOptions = getLabeledJobTitleOptions();

const qualificationOptions = getInlineOptionsFromEnumDict(JOB_QUALIFICATION, false);

function ApplicantForm(props) {
  const { currentApplicant, userId } = props;
  const [skillsOptions, setSkillsOptions] = useState([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const translatedLanguageOptions = languageOptions.map(
    (item) => ({ value: item.value, label: t(item.label) }),
  );

  const { data: skillsData } = useQuery(INLINE_SKILLS_QUERY);
  const [
    applicantMutation,
    {
      data,
      loading,
    },
  ] = useMutation(APPLICANT_MUTATION, {
    refetchQueries: [
      { query: APPLICANT_ONBOARDING_STATUS_QUERY },
      { query: CURRENT_USER_QUERY },
      { query: CURRENT_NOTIFICATIONS_QUERY }],
    update: (cache, { data: { applicant } }) => {
      if (currentApplicant && applicant.response) {
        cache.writeQuery({
          query: CURRENT_APPLICANT_QUERY,
          data: { currentApplicant: applicant.response },
        });
      }
    },
  });

  const schema = yup.object({
    birthdate: yup.date()
      .required(t('Requried'))
      .typeError(t('Invalid Date'))
      .min(new Date(`${new Date().getFullYear() - 100}-01-01`), t('Invalid Date'))
      .max(new Date(), t('Invalid Date')),
    certificates: yup.array(),
    CV: yup.object().nullable(),
    driverLicence: yup.array(),
    goals: yup.string(),
    isOpenForTravelling: yup.bool().required(t('Requried')),
    languages: yup.array().required().min(1, t('Requried')),
    miscellaneous: yup.string(),
    salary: yup.number()
      .integer(t('Salary must be an integer.'))
      .required(t('Required')),
    skills: yup.array(),
    title: yup.string().required(t('Requried')),
    qualification: yup.string().required(t('Requried')),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      birthdate: currentApplicant ? currentApplicant.birthdate : `${new Date().getFullYear() - 25}-01-01`,
      certificates: [],
      CV: undefined,
      driverLicence: [],
      goals: '',
      isOpenForTravelling: false,
      languages: [translatedLanguageOptions.find((item) => item.value === 'de')],
      miscellaneous: '',
      salary: '',
      skills: [],
      title: '',
      qualification: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        birthdate: getFormattedDate(values.birthdate, 'YYYY-MM-DD'),
        driverLicence: convertInlineOptionsToString(values.driverLicence, TARGET_FLEID.VALUE),
        languages: convertInlineOptionsToString(values.languages, TARGET_FLEID.VALUE),
        salary: parseFloat(values.salary),
        skills: values.skills.map((item) => item.label),
        user: userId || currentApplicant.user.id,
      };
      let files = [];
      if (values.CV) {
        files = [
          ...files,
          {
            id: values.CV.id,
            name: values.CV.name,
            file: values.CV.file,
            fileType: FILE_TYPE.CV,
          }];
      }
      if (values.certificates.length) {
        files = [...files, ...values.certificates.map(
          (item) => (
            {
              id: item.id,
              name: item.name,
              file: item.file,
              fileType: FILE_TYPE.CERTIFICATE,
            }
          ),
        )];
      }
      variables.files = files;
      delete variables.CV;
      delete variables.certificates;
      applicantMutation(
        {
          variables,
        },
      );
    },
  });

  const handleCvSelection = (inputFiles) => {
    const { src } = inputFiles[0];
    setFieldValue('CV', {
      innerId: uuidv4(),
      name: src.file.name,
      file: src.base64,
    });
  };

  const handleCertsSelection = (inputFiles) => {
    const certs = inputFiles.map((item) => (
      {
        innerId: uuidv4(),
        name: item.src.file.name,
        file: item.src.base64,
      }
    ));
    setFieldValue('certificates', [...formikValues.certificates, ...certs]);
  };

  const handleCvClear = () => {
    setFieldValue('cv', null);
  };

  const handleCertsClear = (itemId) => {
    setFieldValue('certificates', formikValues.certificates.filter((item) => item.innerId !== itemId));
  };

  const convertFormikErrorsToList = () => Object.entries(formikErrors).map((pair) => `${t(fixStr(pair[0]))} ${pair[1].toLowerCase()}`);

  useEffect(() => {
    if (skillsData) {
      setSkillsOptions(getInlineOptionsFromQuery(skillsData, 'name'));
    }
  }, [skillsData, setFieldValue]);

  useEffect(() => {
    if (currentApplicant) {
      const currentDriverLicence = currentApplicant.driverLicence.map(
        (item) => driverLicenceOptions.find((licence) => licence.value === item),
      );
      const currentLanguages = currentApplicant.languages.map(
        (item) => translatedLanguageOptions.find((language) => language.value === item),
      );
      const currentSkills = currentApplicant.skills.map(
        (item) => skillsOptions.find((skill) => skill.value === item.id),
      ).filter((item) => item !== undefined);
      const currentValues = { ...currentApplicant };
      if (currentApplicant.cv) {
        currentValues.CV = {
          ...currentApplicant.cv,
          innerId: currentApplicant.cv.id,
        };
      }
      currentValues.salary = currentApplicant.salaryDetails.exactValue;
      currentValues.driverLicence = currentDriverLicence;
      currentValues.languages = currentLanguages;
      currentValues.skills = currentSkills;
      if (currentApplicant.certificates.length) {
        currentValues.certificates = currentApplicant.certificates.map((item) => {
          const currentItem = { ...item };
          currentItem.innerId = item.id;
          return currentItem;
        });
      }
      setValues(currentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplicant, skillsOptions, setValues, setFieldValue]);

  useEffect(() => {
    if (currentApplicant && data && data.applicant && data.applicant.response) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [currentApplicant, data, dispatch, t]);
  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-6">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden rounded sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="col-span-6 sm:col-span-3">
                <DropZoneUpload
                  label={t('Your CV')}
                  items={formikValues.CV ? [formikValues.CV] : []}
                  hasError={!!formikErrors.CV}
                  accept={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                  handleSuccess={handleCvSelection}
                  handleError={(e) => setErrors({ CV: e })}
                  handleClear={handleCvClear}
                  uploadType={FILE_TYPE.CV}
                />
              </div>
              {formikErrors.CV && (
                <div className="col-span-6 sm:col-span-6 bg-white mt-1">
                  <ErrorsList
                    hideIcon
                    errors={[formikErrors.CV]}
                  />
                </div>
              )}
              <div className="mt-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Title')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={titleOptions.find((item) => item.value === formikValues.title)}
                    className="mt-1"
                    options={labeledJobTitleOptions}
                    onChange={(e) => setFieldValue('title', e.value)}
                    styles={formikErrors.title && updateSelectStylesOnError}
                    hasError={!!formikErrors.title}
                    errorMessage={formikErrors.title}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Qualification')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={qualificationOptions.find(
                      (item) => item.value === formikValues.qualification,
                    )}
                    className="mt-1"
                    options={qualificationOptions}
                    onChange={(e) => setFieldValue('qualification', e.value)}
                    styles={formikErrors.qualification && updateSelectStylesOnError}
                    hasError={!!formikErrors.qualification}
                    errorMessage={formikErrors.qualification}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="salary"
                    name="salary"
                    type="number"
                    value={formikValues.salary}
                    errorMessage={formikErrors.salary}
                    label={t('Salary Expectations')}
                    required
                    hasSuffix
                    suffixValue="EUR"
                    className="no-arrows"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm  font-bold"
                  >
                    {t('Birthdate')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <DateInput
                    className="mt-1"
                    value={formikValues.birthdate}
                    errorMessage={formikErrors.birthdate}
                    handleChange={(e) => setFieldValue('birthdate', e)}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Languages')}
                    {` (${t('multiple selection possible')})`}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={formikValues.languages}
                    isMulti
                    className="mt-1"
                    options={translatedLanguageOptions}
                    onChange={(e) => setFieldValue('languages', e)}
                    errorMessage={formikErrors.languages}
                    styles={formikErrors.languages && updateSelectStylesOnError}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Skills')}
                    {` (${t('multiple selection possible')})`}
                  </label>
                  <Select
                    isMulti
                    isClearable
                    value={formikValues.skills}
                    className="mt-1"
                    onMultiChange={(e) => (e ? setFieldValue('skills', e) : setFieldValue('skills', []))}
                    options={skillsOptions}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Driver Licence')}
                    {` (${t('multiple selection possible')})`}
                  </label>
                  <Select
                    value={formikValues.driverLicence}
                    isMulti
                    className="mt-1"
                    options={labeledDriverLicenceOptions}
                    onMultiChange={(e) => setFieldValue('driverLicence', e)}
                    styles={formikErrors.driverLicence && updateSelectStylesOnError}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <DropZoneUpload
                    label={t('Certificates')}
                    isRequired={false}
                    items={formikValues.certificates}
                    hasError={!!formikErrors.certificates}
                    accept={[
                      'application/pdf',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                    handleSuccess={handleCertsSelection}
                    handleError={(e) => setErrors({ certificates: e })}
                    handleClear={handleCertsClear}
                    uploadType={FILE_TYPE.CERTIFICATE}
                  />
                </div>
                {!!formikErrors.certificates && (
                  <div className="col-span-6 sm:col-span-6 bg-white mt-1">
                    <ErrorsList
                      errors={[formikErrors.certificates]}
                    />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Goals')}
                  </label>
                  <textarea
                    id="goals"
                    name="goals"
                    rows={4}
                    value={formikValues.goals}
                    className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Miscellaneous')}
                  </label>
                  <textarea
                    id="miscellaneous"
                    name="miscellaneous"
                    rows={4}
                    value={formikValues.miscellaneous}
                    className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <Switch
                    value={formikValues.isOpenForTravelling}
                    onChange={(e) => setFieldValue('isOpenForTravelling', e)}
                    label={t('I am open to travel/assembly')}
                  />
                </div>
              </div>
              {!!Object.entries(formikErrors).length && (
                <div className="col-span-6 sm:col-span-6 bg-white mt-4">
                  <AlertErrorsList
                    errors={convertFormikErrorsToList(formikErrors)}
                  />
                </div>
              )}
            </div>
            <div className={classNames(currentApplicant ? 'grid-cols-2' : 'grid-cols-1', 'grid')}>
              {currentApplicant && (
                <div className="px-4 py-3 bg-lightGray text-left sm:px-6 flex flex-shrink">
                  <ShowProfileButton link={`/applicant/${currentApplicant.id}`} />
                </div>
              )}
              <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
                {loading
                  ? (
                    <LoadingIndicator className="flex justify-end py-4 mr-2" />

                  )
                  : (
                    <button
                      diasbled={loading.toString()}
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    >
                      {currentApplicant ? t('Save') : t('Next Step')}
                    </button>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

ApplicantForm.propTypes = {
  currentApplicant: PropTypes.objectOf(PropTypes.any),
  userId: PropTypes.string,
};

export default ApplicantForm;
