import { MessageType } from '../../../__generated__/graphql';
import { IMAGE_SIZE } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { UserImage, TimeAgo } from '../../../layout';
import MessageOptions from './MessageOptions';

interface Props {
  item: MessageType,
  isSender: boolean,
  threadId: string,
}

function MessageCard(props: Props) {
  const { item, isSender, threadId } = props;
  return (
    <div
      className={classNames(isSender
        ? 'justify-start flex-row-reverse' : 'flex-row', 'flex items-center')}
    >
      <UserImage
        className="h-10 w-10 rounded-full"
        profile={item[item?.sender?.toLowerCase() as keyof typeof item].profile}
        imageSize={IMAGE_SIZE.S32}
      />
      <div className={classNames(isSender
        ? 'mr-3 bg-blue-50' : 'ml-3 bg-white', 'relative text-sm py-2 px-4 shadow rounded-xl')}
      >
        <div>{item.body}</div>
        <div className="text-xs text-right text-secondaryGray">
          <TimeAgo date={item.createdAt} />
        </div>
      </div>
      {isSender && (
        <MessageOptions
          messageId={item.id}
          isSender={isSender}
          threadId={threadId}
        />
      )}
    </div>
  );
}

export default MessageCard;
