import { gql } from '@apollo/client';

export const SELECT_PACKAGE_PRICE_GRID_ITEM_MUTATION = gql`
mutation selectPackagePriceGridItem(
  $company: ID!,
  $item: ID!,
  ){
  selectPackagePriceGridItem(
      input:{
        company: $company,
        item: $item,
        }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;

export const FINALIZE_PAYPAL_PAYMENT_MUTATION = gql`
mutation finalizePaypalPayment(
  $company: ID!,
  $orderId: String!,
  ){
  finalizePaypalPayment(
      input:{
        company: $company,
        orderId: $orderId,
        }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;

export const FINALIZE_SEPA_PAYMENT_MUTATION = gql`
mutation finalizeSepaPayment(
  $company: ID!,
  $iban: String!,
  ){
  finalizeSepaPayment(
      input:{
        company: $company,
        iban: $iban,
        }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;
