interface Props {
  id: string,
  name: string,
  type: string,
  autoComplete?: string,
  required: boolean,
  label: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  errorMessage?: string,
  hasSuffix?: boolean,
  suffixValue?: string,
  disabled?: boolean,
  value?: string | number,
  placeholder?: string,
  className?: string,
}

function Input(props: Props) {
  const {
    id, name, type, autoComplete, required, label, onChange, errorMessage,
    hasSuffix, suffixValue, value, disabled, placeholder, className,
  } = props;
  const hasError = !!errorMessage || false;
  let inputClasses = hasError
    ? 'appearance-none block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
    : 'appearance-none block w-full px-3 py-2 border-2 border-darkerGray rounded-md shadow-sm placeholder-secondaryGray focus:outline-none focus:ring-primary focus:border-primary sm:text-sm';
  inputClasses = disabled ? 'bg-darkerGray  w-full px-3 py-2 border-2 border-darkerGray rounded-md shadow-sm sm:text-sm' : inputClasses;
  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          aria-invalid={hasError}
          aria-describedby={id}
          className={className ? `${inputClasses} ${className}` : inputClasses}
          disabled={disabled || undefined}
          placeholder={placeholder || ''}
        />
        {hasSuffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className=" sm:text-sm" id="price-currency">
              {suffixValue}
            </span>
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 text-sm text-red-600" id={id}>
          {errorMessage}
        </p>
      )}
    </>
  );

  if (label) {
    return (
      <>
        <label
          htmlFor={name}
          className={`block text-sm  ${required && 'font-bold'}`}
        >
          {label}
          {required && <span className="text-red-600"> *</span>}
        </label>
        {input}
      </>
    );
  }
  return input;
}

export default Input;
