import { gql } from '@apollo/client';

export const APPLICANT_OFFER_VIEW_MUTATION = gql`
mutation applicantOfferView(
  $offer: ID!
  ){
    applicantOfferView(
      input:{
        offer: $offer
      }
    )
  { response{
    id
  }
    errors{
      field
      messages
    }
  }
}
`;
