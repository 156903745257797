import { useQuery, useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useState } from 'react';
import { PackagePriceGridItemType } from '../../../../../__generated__/graphql';
import { SELECT_PACKAGE_PRICE_GRID_ITEM_MUTATION } from '../../../../../api/mutations/ecommerce';
import { AVAILABLE_PACKAGE_PRICE_GRID_ITEMS_QUERY } from '../../../../../api/queries/ecommerce';
import { PAYMENT_CYCLE } from '../../../../../constants';
import PackageCard from './PackageCard';
import PaymentCycle from './PaymentCycle';

interface Props {
  companyId: string,
  refetchQueries: { query: DocumentNode }[],
}

export default function CompanyPackages(props: Props) {
  const { companyId, refetchQueries } = props;
  const [paymentCycle, setPaymentCycle] = useState(PAYMENT_CYCLE.ANNUAL);
  const {
    data: companyPackagesData,
    loading: companyPackagesLoading,
  } = useQuery(
    AVAILABLE_PACKAGE_PRICE_GRID_ITEMS_QUERY,
    { variables: { company: companyId, paymentCycle } },
  );
  
  const {
    data: monthlyCompanyPackagesData,
    loading: monthlyCompanyPackagesLoading,
  } = useQuery(
    AVAILABLE_PACKAGE_PRICE_GRID_ITEMS_QUERY,
    { variables: { company: companyId, paymentCycle: PAYMENT_CYCLE.MONTHLY } },
  );

  const [
    selectPackageMutation,
  ] = useMutation(SELECT_PACKAGE_PRICE_GRID_ITEM_MUTATION, {
    refetchQueries,
  });

  const handlePackageSelect = (itemId: string) => {
    if (!companyPackagesLoading && !monthlyCompanyPackagesLoading) {
      selectPackageMutation(
        {
          variables: {
            company: companyId,
            item: itemId,
          },
        },
      );
    }
  };

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="flex flex-col gap-4 rounded-md px-4 py-5 bg-white sm:p-6">
        <div className="px-4 py-5 sm:p-6 sm:align-center sm:flex sm:flex-col bg-gradient-to-r from-yellow-100 to-green-100 sm:rounded-lg border">
          <h2 className="text-lg font-medium leading-6">
            Kostenfrei in 2024.
          </h2>
          <p className="mt-4 text-lg">
          2024 ist für alle Unternehmen vollständig kostenfrei. Sollte Dir Sprungbrett Handwerk gefallen und Du Dein Unternehmen <span className='font-semibold'>nicht bis zum 31.12.2024</span> abmeldest, berechnen wir für Deine gewählte.
Unternehmensgröße die folgenden Firmenpakete, gemäß unserer <a href="https://sprungbrett-handwerk.de/start.html" target="_blank" rel="noopener noreferrer" className='font-medium text-primary underline'>Preisstruktur</a>.
          </p>
        </div>
        <PaymentCycle
          paymentCycle={paymentCycle}
          setPaymentCycle={setPaymentCycle}
        />
        <div className="gap-4 mt-4 space-y-4 sm:mt-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none">
          {companyPackagesData && companyPackagesData.availablePackagePriceGridItems.map(
            (item: PackagePriceGridItemType) => (
              <PackageCard
                key={item.id}
                item={item}
                monthlyItem={monthlyCompanyPackagesData && monthlyCompanyPackagesData.availablePackagePriceGridItems.find(
                  (innerItem: PackagePriceGridItemType) => 
                    innerItem.companySize === item.companySize, 
                )}
                handlePackageSelect={handlePackageSelect}
              />
            ),
          )}
          
        </div>
        <span className='text-sm font-medium text-gray-500'>*Alle Preise verstehen sich zzgl. der aktuell geltenden gesetzlichen Umsatzsteuer</span>
      </div>
    </div>
  );
}
