import { useQuery } from '@apollo/client';
import { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Footer } from '..';
import { CURRENT_USER_QUERY } from '../../api/queries/users';
import { clearAuthCookies } from '../../helpers/auth';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { discardAlert } from '../../redux/alertSlice';
import Alert from '../Alert';
import DashboardNavbar from '../navbars/DashboardNavbar';

interface Props {
  children: ReactNode,
}

export default function DashboardLayout({ children }: Props) {
  // checks that we can do API call to backend, means
  // JWT token is in place and it is valid.
  const { data, error } = useQuery(CURRENT_USER_QUERY);
  const alertsState = useAppSelector((state) => state.alert);
  const dispatch = useAppDispatch();
  const location = useLocation();
  if (error) {
    clearAuthCookies();
    return <Navigate to="/authentication/login/" />;
  }
  if (data && data.currentUser) {
    const user = data.currentUser;

    if (!user.hasConfirmedEmail && !location.pathname.startsWith('/authentication/email-confirmation/')) {
      return <Navigate to="/authentication/email-confirmation/" />;
    }

    if (!user.isOnboarded) {
      const allowedPages = [
        '/onboarding/',
        '/settings/account/',
      ];
      if (user.hasConfirmedEmail && !(allowedPages.includes(location.pathname))) {
        return <Navigate to="/onboarding/" />;
      }
    }
    return (
      <div className="bg-darkerGray text-darkestGray">
        <div className="flex flex-wrap min-h-screen">
          <div className="w-full">
            <DashboardNavbar
              user={user}
            />
            <div className="pt-5">
              {alertsState.alert
                && (
                  <Alert
                    key={alertsState.alert.id}
                    alertType={alertsState.alert.alertType}
                    isOpen={alertsState.alert.show}
                    messageList={alertsState.alert.messages}
                    toggle={() => { dispatch(discardAlert()); }}
                  />
                )}
              <main>
                <div
                  className="max-w-7xl mx-auto sm:px-6 lg:px-8"
                >
                  {children}
                </div>
              </main>
            </div>
          </div>
          <div className="self-end w-screen">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
  return '';
}
