import { useTranslation } from 'react-i18next';
import { LimitedApplicantType, OfferType } from '../../../__generated__/graphql';
import { PreviewCard as OfferPreviewCard } from '../../applicant/offers';
import { PreviewCard as ApplicantPreviewCard } from '../../manager/applicants';

interface Props {
  items: OfferType[] | LimitedApplicantType[],
  onLoadMore: (offset: number) => void,
  currentItem: OfferType | LimitedApplicantType | null,
  handlePreviewClick: ((item: OfferType) => void) | ((item: LimitedApplicantType) => void),
  cardIsOffer?: boolean,
  cardIsApplicant?: boolean,
}

function MatchingPreviewCardList(props: Props) {
  const {
    items, onLoadMore, currentItem, handlePreviewClick, cardIsOffer, cardIsApplicant,
  } = props;
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = ({ currentTarget }: any, funOnLoadMore: (offset: number) => void) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight
      >= currentTarget.scrollHeight
    ) {
      funOnLoadMore(items.length);
    }
  };

  if (!items.length) {
    return <div className="text-center py-8 text-lg">{t('No records found')}</div>;
  }
  return (
    <div
      id="styled-scroll"
      className="max-h-[100vh] overflow-y-auto pr-2 grid gap-4"
      onScroll={(e) => handleScroll(e, onLoadMore)}
    >
      {cardIsOffer && (
        (items as OfferType[]).map((item) => (
          <OfferPreviewCard
            key={item.id}
            item={item}
            isCurrent={currentItem?.id === item.id}
            handlePreviewClick={handlePreviewClick as ((item: OfferType) => void)}
          />
        )))}

      {cardIsApplicant && (
        (items as LimitedApplicantType[]).map((item) => (
          <ApplicantPreviewCard
            key={item.id}
            item={item}
            isCurrent={currentItem?.id === item.id}
            handlePreviewClick={handlePreviewClick as ((item: LimitedApplicantType) => void)}
          />
        )))}
    </div>
  );
}

export default MatchingPreviewCardList;
