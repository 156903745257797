import { gql } from '@apollo/client';

export const APPLICANT_OFFER_LIKE_MUTATION = gql`
mutation applicantOfferLike(
  $offer: ID!
  ){
    applicantOfferLike(
      input:{
        offer: $offer
      }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;

export const APPLICANT_COMPANY_LIKE_MUTATION = gql`
mutation applicantCompanyLike(
  $company: ID!
  ){
    applicantCompanyLike(
      input:{
        company: $company
      }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;

export const COMPANY_APPLICANT_LIKE_MUTATION = gql`
mutation companyApplicantLike(
  $applicant: ID!
  ){
    companyApplicantLike(
      input:{
        applicant: $applicant
      }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`;
