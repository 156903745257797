import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { ExternalRedirect } from './components/shared';
import Applicant from './pages/Applicant';
import ApplicantDashboard from './pages/applicant/ApplicantDashboard';
import { Chat as ApplicantChat } from './pages/applicant/chat';
import { Offers } from './pages/applicant/offers';
import {
  Login, RegisterApplicant, RegisterManager, ResetPassword, EmailConfirmation,
  EmailConfirmationTokenProcessor, ResetPasswordConfirmation,
} from './pages/auth';
import Company from './pages/Company';
import Dashboard from './pages/Dashboard';
import DeactivatedCompany from './pages/DeactivatedCompany';
import {
  Error400, Error401, Error403, Error404, Error500, Error503,
} from './pages/errorPages';
import { ApplicantsActivity } from './pages/manager/activity';
import { Applicants } from './pages/manager/applicants';
import { Chat as ManagerChat } from './pages/manager/chat';
import { CreateAdditionalCompany } from './pages/manager/company';
import ManagerDashboard from './pages/manager/ManagerDashboard';
import { CreateOffer, UpdateOffer } from './pages/manager/offer';
import Offer from './pages/Offer';
import Onboarding from './pages/Onboarding';
import {
  Account, Profile, PreferredLocation, Companies,
  Company as SettingsCompany, Applicant as SettingsApplicant,
} from './pages/settings';

function App() {
  return (
    <Router>
      <Routes>
        <Route  path="/" element={<Dashboard />} />
        <Route  path="/onboarding/" element={<Onboarding />} />

        <Route  path="/manager/" element={<ManagerDashboard />} />
        <Route  path="/manager/applicants/" element={<Applicants />} />
        <Route  path="/manager/matches/" element={<ManagerChat />} />
        <Route  path="/manager/activity/:tab/" element={<ApplicantsActivity />} />
        <Route  path="/manager/activity/:tab/:offer/" element={<ApplicantsActivity />} />
        <Route  path="/manager/company/create/" element={<CreateAdditionalCompany />} />
        <Route  path="/manager/offer/create/" element={<CreateOffer />} />
        <Route  path="/manager/offer/:id/update/" element={<UpdateOffer />} />

        <Route  path="/applicant/" element={<ApplicantDashboard />} />
        <Route  path="/applicant/matches/" element={<ApplicantChat />} />
        <Route  path="/applicant/offers/" element={<Offers />} />

        <Route  path="/company/:id/" element={<Company />} />
        <Route  path="/offer/:id/" element={<Offer />} />
        <Route  path="/applicant/:id/" element={<Applicant />} />

        <Route  path="/settings/account/" element={<Account />} />
        <Route  path="/settings/profile/" element={<Profile />} />
        <Route  path="/settings/companies/" element={<Companies />} />
        <Route  path="/settings/companies/:id/:tab/" element={<SettingsCompany />} />
        <Route  path="/settings/applicant/" element={<SettingsApplicant />} />
        <Route  path="/settings/preferred-location/" element={<PreferredLocation />} />

        <Route  path="/400/" element={<Error400 />} />
        <Route  path="/401/" element={<Error401 />} />
        <Route  path="/403/" element={<Error403 />} />
        <Route  path="/404/" element={<Error404 />} />
        <Route  path="/500/" element={<Error500 />} />
        <Route  path="/503/" element={<Error503 />} />

        <Route  path="/inactive/company/" element={<DeactivatedCompany />} />

        <Route  path="/authentication/login/" element={<Login />} />
        <Route  path="/authentication/register/applicant/" element={<RegisterApplicant />} />
        <Route  path="/authentication/register/manager/" element={<RegisterManager />} />
        <Route  path="/authentication/reset/" element={<ResetPassword />} />
        <Route  path="/authentication/reset/confirmation/:uid/:token" element={<ResetPasswordConfirmation />} />
        <Route  path="/authentication/email-confirmation/" element={<EmailConfirmation />} />
        <Route  path="/authentication/email-confirmation/:token" element={<EmailConfirmationTokenProcessor />} />

        <Route path="/privacy-policy/" element={<ExternalRedirect to="https://sprungbrett-handwerk.de/datenschutz.html" />} />
        <Route path="/terms-of-service/" element={<ExternalRedirect to="https://sprungbrett-handwerk.de/allgemeine-geschaeftsbedingungen.html" />} />

        <Route path="/settings" element={<Navigate replace to="/settings/account/" />} />
        <Route path="/authentication" element={<Navigate replace to="/authentication/login/" />} />
        <Route path="*" element={<Navigate replace to="/404/" />} />
      </Routes>
    </Router>
  );
}

export default App;
