/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { ArchiveIcon } from '@heroicons/react/outline';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageType } from '../../../__generated__/graphql';
import { READ_THREAD_MUTATION } from '../../../api/mutations/chats';
import {
  MESSAGES_QUERY,
  MANAGER_THREADS_QUERY,
  APPLICANT_THREADS_QUERY,
  UNVIEWED_MESSAGES_QUANTITY_QUERY,
} from '../../../api/queries/chats';
import { CHAT_POLLING_INTERVAL } from '../../../config';
import { USER_TYPE } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { LoadingIndicator } from '../../../layout';
import ChatInput from './ChatInput';
import MessageCard from './MessageCard';

interface Props {
  currentUserType: string,
  threadId: string,
  openArchive: () => void,
}

function ChatSection(props: Props) {
  const {
    currentUserType, threadId, openArchive,
  } = props;
  const { t } = useTranslation();
  const chatContainer = useRef(null);

  const { data: messagesData, loading: messagesLoading } = useQuery(
    MESSAGES_QUERY,
    {
      variables: {
        thread: threadId,
      },
      pollInterval: CHAT_POLLING_INTERVAL,
    },
  );

  let threadRefetchQueries: { query: DocumentNode }[] = [];
  let inputRefetchQueries: ({ query: DocumentNode, variables: { thread: string } } | { query: DocumentNode })[] = [];

  switch (currentUserType) {
    case USER_TYPE.MANAGER:
      threadRefetchQueries = [
        { query: MANAGER_THREADS_QUERY },
        { query: UNVIEWED_MESSAGES_QUANTITY_QUERY },
      ];
      inputRefetchQueries = [{
        query: MESSAGES_QUERY,
        variables: {
          thread: threadId,
        },
      },
      { query: MANAGER_THREADS_QUERY }];
      break;

    case USER_TYPE.APPLICANT:
      threadRefetchQueries = [
        { query: APPLICANT_THREADS_QUERY },
        { query: UNVIEWED_MESSAGES_QUANTITY_QUERY },
      ];
      inputRefetchQueries = [{
        query: MESSAGES_QUERY,
        variables: {
          thread: threadId,
        },
      },
      { query: APPLICANT_THREADS_QUERY }];
      break;

    default:
      break;
  }

  const [
    threadMutation,
  ] = useMutation(READ_THREAD_MUTATION, {
    refetchQueries: threadRefetchQueries,
  });

  const scrollToBottom = () => {
    if (chatContainer.current) {
      //@ts-ignore
      const scroll = chatContainer.current.scrollHeight + chatContainer.current.clientHeight;
      //@ts-ignore
      chatContainer.current.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    threadMutation({ variables: { thread: threadId } });
  }, [messagesData, threadId, threadMutation]);

  useEffect(() => {
    // Keep scroll at bottom on messages update
    scrollToBottom();
  }, [messagesData]);

  useEffect(() => {
    // Keep scroll at bottom initially
    scrollToBottom();
  }, []);

  const items: MessageType[] = messagesData ? messagesData.messages.objects : [];
  const archive = messagesData && messagesData.messages.hasArchive;

  return (messagesLoading && (!messagesData)
    ? (<LoadingIndicator className="pr-2 h-[65vh] md:h-[80vh] flex items-center justify-center" />)
    : (
      <div
        className="pr-2 relative"
      >
        <div className="h-[65vh] md:h-[80vh]">
          {
            items.length
              ? (
                <div
                  ref={chatContainer}
                  id="styled-scroll"
                  className="h-[58vh] md:h-[73vh] overflow-y-auto flex flex-col absolute top-0 bottom-20 right-0 left-0"
                >
                  <div className="grid grid-cols-12 gap-y-2">
                    {archive && (
                      <div className="col-start-1 col-end-13 flex justify-center">
                        <button
                          type="button"
                          className="inline-flex items-center px-3 py-2 border-2 border-darkerGray shadow-sm text-sm leading-4 font-medium rounded-md  bg-white hover:bg-lightGray"
                          onClick={() => openArchive()}
                        >
                          {t('Search for older messages in Archive')}
                          <ArchiveIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    )}
                    {items.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(item.sender === currentUserType
                          ? 'col-start-2 md:col-start-6 col-end-13' : 'col-start-1 col-end-12 md:col-end-8', 'p-3 rounded-lg')}
                      >
                        <MessageCard
                          item={item}
                          threadId={threadId}
                          isSender={item.sender === currentUserType}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )
              : (
                <div className="h-full px-6 pb-12 flex items-center justify-center rounded font-medium text-lg">
                  <span>{t('There are no messages in this conversation just yet.')}</span>
                </div>
              )
          }
        </div>
        <div className="absolute bottom-0 left-0 right-0">
          <ChatInput
            threadId={threadId}
            senderType={currentUserType}
            refetchQueries={inputRefetchQueries}
          />
        </div>
      </div>
    )
  );
}

export default ChatSection;
