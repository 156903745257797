import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import DebounceLink from 'apollo-link-debounce';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';
import { REFRESH_JWT_MUTATION } from './api/mutations/users';
import App from './app';
import favicon from './assets/images/favicon.ico';
import { API_URL, DEFAULT_DEBOUNCE_TIMEOUT, SENTRY_SDN } from './config';
import { SIGNATURE_EXPIRED_ERROR, REFRESH_TOKEN_EXPIRED_ERROR } from './constants';
import { setAuthCookies, clearAuthCookies } from './helpers/auth';
import { getUniqueObjects } from './helpers/utils';
import { setErrorAlert } from './redux/alertSlice';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './assets/css/index.css';


import './i18n';

let isRefreshing = false;
let pendingRequests: [] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: () => void) => callback());
  pendingRequests = [];
};

if (SENTRY_SDN) {
  Sentry.init({
    dsn: SENTRY_SDN,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const httpLink = createUploadLink({ uri: API_URL });

const errorLink = onError(
  // @ts-ignore
  ({
    graphQLErrors, operation, forward,
  }) => {
    if (graphQLErrors) {
      let forward$;

      for (const err of graphQLErrors) {
        switch (err.message) {
          case SIGNATURE_EXPIRED_ERROR:

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                client
                  .mutate({
                    mutation: REFRESH_JWT_MUTATION,
                    variables: { refreshToken: Cookies.get('jwt_refresh') as string },
                  })
                  .then(({ data }) => {
                    if (data && data.refreshToken) {
                      setAuthCookies(data.refreshToken.token, data.refreshToken.refreshToken);
                      return true;
                    }
                  })

                  // eslint-disable-next-line @typescript-eslint/no-loop-func
                  .catch((error) => {
                    pendingRequests = [];
                    // Clear cookies and forward to login screen.
                    if (error.message === REFRESH_TOKEN_EXPIRED_ERROR) {
                      // eslint-disable-next-line no-use-before-define
                      clearAuthCookies();
                      window.location.href = '/authentication/login';
                    }
                    return false;
                  })
                  // eslint-disable-next-line @typescript-eslint/no-loop-func
                  .finally(() => {
                    isRefreshing = false;
                    resolvePendingRequests();
                  }),
              );
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                new Promise((resolve) => {
                  // @ts-ignore
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));

          default:
            store.dispatch(setErrorAlert([err.message]));
        }
        return null;
      }
    }
  },
);

const customErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const responseData = response.data;
    if (responseData) {
      const key = Object.keys(responseData)[0];
      const innerData = responseData[key];
      if (innerData && innerData.errors && innerData.errors.length) {
        const errList: string[] = [];
        innerData.errors.forEach((error: any) => {
          error.messages.forEach((message: string) => {
            errList.push(`${message}`);
          });
        });
        store.dispatch(setErrorAlert(errList));
      }
    }
    return response;
  });
});

const authLink = setContext(async (_, { headers }) => {
  const token = Cookies.get('jwt_token');

  if (!isRefreshing && token) {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${token}`,
      },
    };
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get('jwt_token');
  if (!token && !window.location.toString().includes('authentication')) {
    window.location.href = '/authentication/login';
  }

  return forward(operation);
});

// Requests get debounced together if they share the same debounceKey.
// Requests without a debounce key are passed to the next link unchanged.
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT);

const client = new ApolloClient({
  link: from([authMiddleware, errorLink, customErrorLink, authLink, debounceLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          currentUser: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          currentCompany: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          currentManager: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          currentApplicant: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          messages: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          possibleMatches: {
            keyArgs: false,
            merge(_existing, incoming) {
              return incoming;
            },
          },
          recommendedOffers: {
            keyArgs: false,
            merge(_existing, incoming) {
              return incoming;
            },
          },
          companyOffers: {
            keyArgs: false,
            merge(_existing, incoming) {
              return incoming;
            },
          },
          dashboardOffers: {
            keyArgs: false,
            merge(_existing, incoming) {
              return incoming;
            },
          },
          archivedMessages: {
            keyArgs: false,
            merge(_existing, incoming) {
              return incoming;
            },
          },
          offers: {
            keyArgs: false,
            merge(existing, incoming) {
              let merged = {};
              if (existing) {
                merged = {
                  ...incoming,
                  objects: getUniqueObjects([...existing.objects, ...incoming.objects]),
                };
              } else {
                merged = incoming;
              }
              return merged;
            },
          },
          applicants: {
            keyArgs: false,
            merge(existing, incoming) {
              let merged = {};
              if (existing) {
                merged = {
                  ...incoming,
                  objects: getUniqueObjects([...existing.objects, ...incoming.objects]),
                };
              } else {
                merged = incoming;
              }
              return merged;
            },
          },
        },
      },
    },
    addTypename: false,
  }),
});

// @ts-ignore
const root = createRoot(document.getElementById('root'));
root.render(
  // @ts-ignore
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Favicon url={favicon} />
      <App />
    </ApolloProvider>
  </Provider>,
);
serviceWorker.unregister();

