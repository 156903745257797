import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useState, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../../../layout/fields';

interface Props {
  modalOpen: boolean,
  setModalOpen: (value: boolean) => void,
  ibanError: string,
  handleIBANInput: (value: string) => void,
}

export default function IBANModal(props: Props) {
  const {
    modalOpen, setModalOpen, ibanError, handleIBANInput,
  } = props;
  const [ibanInput, setIbanInput] = useState('');
  const { t } = useTranslation();
  const refDiv = useRef(null);

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[110]" onClose={setModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cc] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6" ref={refDiv}>
                <div>
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-secondaryGray hover:text-darkestGray focus:outline-none"
                      onClick={() => setModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {t('Please enter your IBAN and we will send you the SEPA direct debit mandate by e-mail')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <Input
                        id="iban"
                        name="iban"
                        type="text"
                        value={ibanInput}
                        errorMessage={ibanInput && ibanError}
                        label="IBAN"
                        required
                        onChange={(e) => setIbanInput(e.target.value)}
                      />
                    </div>
                    <div className='mt-2'>
                      {t('2024 is completely free and you will not be charged anything. Only from 2025 will we calculate the annual usage fee according to our price structure for your company size.')}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:text-sm"
                    onClick={() => handleIBANInput(ibanInput)}
                  >
                    {t('Apply')}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
