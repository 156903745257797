import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { APPLICANT_OFFER_VIEW_MUTATION } from '../../api/mutations/views';

interface Props {
  offerId: string,
}

function ApplicantOfferViewTrigger(props: Props) {
  const { offerId } = props;

  const [
    viewMutation,
  ] = useMutation(APPLICANT_OFFER_VIEW_MUTATION, {
  });

  useEffect(() => {
    viewMutation({ variables: { offer: offerId } });
  }, [offerId, viewMutation]);
  return '';
}

export default ApplicantOfferViewTrigger;
