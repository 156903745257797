import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';


function CompanySizeTooltip() {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tippy
    visible={visible} onClickOutside={hide}
    interactive
    content={
      <div>
        Wähle Deine Unternehmensgröße - Anhand Deiner Größenordnung wird die zukünftige Jahresgebühr (ab 2025) berechnet. Mehr dazu findest du unter <a href="https://sprungbrett-handwerk.de/start.html" target="_blank" rel="noopener noreferrer" className='font-semibold underline'>Link</a>.
      </div>
    }
    placement="top"
  >
    <ExclamationCircleIcon
      onClick={visible ? hide : show}
      className='cursor-pointer w-5 h-5'
    />
  </Tippy>
  );
}

export default CompanySizeTooltip;