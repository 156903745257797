import { gql } from '@apollo/client';

export const COMPANY_ADDRESS_MUTATION = gql`
  mutation companyAddress(
    $id: ID,
    $areaCode: ID!,
    $city: ID!,
    $country: ID!,
    $street: String!,
    $company: ID!,
    ){
      companyAddress(input:{
      id: $id
      areaCode: $areaCode
      city: $city
      country: $country
      street: $street
      company: $company
    }){
      response{
        id
        street
        areaCode{
          id
        }
        city{
          id
          name
        }
        country{
          id
          name
        }
      }
      errors{
        field
        messages
      }
    }
  }
`;
