import { useTranslation } from 'react-i18next';

function EmptyNotification() {
  const { t } = useTranslation();

  return (
    <div className="mt-1">
      <p className="text-sm font-semibold ">{t('Notifications')}</p>
      <p className="mt-1 text-sm ">{t('No activities have taken place so far')}</p>
    </div>
  );
}

export default EmptyNotification;
