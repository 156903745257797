import { useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../../__generated__/graphql';
import { USER_EMAIL_MUTATION } from '../../../api/mutations/users';
import { clearAuthCookies } from '../../../helpers/auth';
import { isEmpty } from '../../../helpers/utils';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Input } from '../../../layout/fields';
import { Warning } from '../../../layout/modals';
import { setSuccessAlert } from '../../../redux/alertSlice';

interface Props {
  user: UserType,
}

function ChangeEmail(props: Props) {
  const { user } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    executeMutation,
    {
      data: executeMutationData,
      loading: executeMutationLoading,
    }] = useMutation(USER_EMAIL_MUTATION);

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  function requireConfirmation() {
    if ((!isEmpty(email) || !executeMutationLoading)) {
      toggleConfirmationModal();
    }
  }

  const handleExecute = () => {
    executeMutation({
      variables: {
        user: user.id,
        email,
      },
    });
  };

  useEffect(() => {
    if (executeMutationData
      && executeMutationData.emailUser
      && executeMutationData.emailUser.response) {
      clearAuthCookies();
      dispatch(setSuccessAlert(
        [t('Please confirm your email by following the link in the confirmation letter.')],
      ));
      return navigate('/authentication/login');
    }
  }, [executeMutationData, dispatch, t, navigate]);

  return (
    <div className="px-2 sm:px-0 mt-5 md:mt-0">
      <div className="shadow sm:overflow-hidden">
        <div className="rounded sm:rounded-md bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium ">{t('Change Email')}</h3>
            <p className="mt-1 text-sm ">
              {t('Here you can change your contact Email.')}
              {' '}
              {t('You will be forced to login again on success.')}
            </p>
            <div className="mt-2">
              <Input
                id="email"
                name="email"
                type="email"
                label={t('New Email')}
                required
                placeholder={user.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
          <button
            onClick={requireConfirmation}
            disabled={isEmpty(email) || executeMutationLoading}
            className="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {t('Save')}
          </button>
        </div>
      </div>
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleExecute}
        title={t('Change Email')}
      />
    </div>
  );
}

export default ChangeEmail;
