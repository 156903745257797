import {
  LocationMarkerIcon, CurrencyEuroIcon,
} from '@heroicons/react/solid';
import { LimitedApplicantType } from '../../../__generated__/graphql';
import { JOB_TITLE, JOB_QUALIFICATION } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { LikeApplicantIconButton } from '../likes';

interface Props {
  item: LimitedApplicantType,
  isCurrent: boolean,
  handlePreviewClick: ((item: LimitedApplicantType) => void),
}

function PreviewCard(props: Props) {
  const { item, isCurrent, handlePreviewClick } = props;

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(isCurrent ? 'border-4 border-primary shadow-xl' : 'border-t-lightGray border-l-lightGray shadow-md', 'border hover:shadow-xl px-2 py-4')}
      //@ts-ignore
      onClick={() => handlePreviewClick(item)}
    >
      <div className="grid grid-cols-8">
        <div className="col-span-7 break-words">
          <div className="font-semibold">
            {JOB_TITLE[item.title as keyof typeof JOB_TITLE]}
          </div>
          <div className="text-sm ">
            {JOB_QUALIFICATION[item.qualification]}
          </div>
        </div>
        <div className="col-span-1 flex items-center">
          <div>
            <LikeApplicantIconButton applicantId={item.id} className="h-6 w-6" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 text-secondaryGray text-xs">
        {item.__typename === 'LimitedApplicantType' && (
          <div className="flex gap-1 items-center">
            <LocationMarkerIcon className="h-5 w-5" />
            {item?.preferredLocation?.city.name}
          </div>
        )}
        <div className="col-span-2 flex items-center gap-1">
          <CurrencyEuroIcon className="h-5 w-5" />
          {`${item?.salaryDetails?.exactValue} ${item?.salaryDetails?.currencyCode}`}
        </div>
      </div>
    </div>
  );
}

export default PreviewCard;
