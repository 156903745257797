import { useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MESSAGE_MUTATION } from '../../../api/mutations/chats';

interface Props {
  senderType: string,
  threadId: string,
  refetchQueries: ({ query: DocumentNode, variables: { thread: string } } | { query: DocumentNode })[],
}

function ChatInput(props: Props) {
  const {
    senderType, threadId, refetchQueries,
  } = props;
  const [body, setBody] = useState('');
  const { t } = useTranslation();

  const messageInput = useRef<HTMLInputElement>(null);

  const [
    messageMutation,
    {
      loading: messageLoading,
    }] = useMutation(MESSAGE_MUTATION, {
    refetchQueries,
  });

  const handleSend = useCallback(() => {
    if (body && !messageLoading) {
      messageMutation({
        variables: {
          body,
          sender: senderType,
          thread: threadId,
        },
      });
      setBody('');
      // autofocus input on message send
      if (messageInput && messageInput.current) messageInput.current.focus();
    }
  }, [body, messageLoading, messageMutation, senderType, threadId]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSend();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSend]);

  useEffect(() => {
    // initial autofocus input
    if (messageInput && messageInput.current) messageInput.current.focus();
  }, []);

  return (
    <div className="relative flex py-2 pl-1">
      <input
        type="text"
        ref={messageInput}
        placeholder={t('Write Something')}
        className="w-full focus:outline-none focus:placeholder-secondaryGray  placeholder-secondaryGray pl-4 pr-16 bg-lightGray rounded-full py-3"
        value={body}
        onChange={(e) => (e ? setBody(e.target.value) : setBody(''))}
      />
      <div className="absolute right-0 items-center inset-y-0 flex">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-full h-14 w-14 transition duration-200 ease-in-out text-white bg-primary hover:bg-primary focus:outline-none"
          onClick={() => handleSend()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-7 w-7 transform rotate-90">
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default ChatInput;
