import { useState } from 'react';
import { CityType } from '../../__generated__/graphql';
import { INLINE_CITIES_QUERY } from '../../api/queries/shared';
import { AsyncSelect } from '../../layout/fields';

export function getInlineOptions(cities: CityType[]) {
  return cities.map(
    (item) => (
      {
        label: item.postcodesLabel ? `${item.name} (${item.postcodesLabel})` : item.name,
        value: item.id,
      }
    ),
  );
}

interface Props {
  value: SelectOption | null,
  onChange: (e: SelectOption) => void,
  isClearable?: boolean,
  isDisabled?: boolean,
  skipSmall: boolean,
  placeholder?: string,
}

function InfiniteCitiesSelect(props: Props) {
  const {
    value, onChange, isClearable, skipSmall, placeholder, isDisabled,
  } = props;
  const [cityName, setCityName] = useState('');

  const handleCityInputChange = (newValue: string) => {
    setCityName(newValue);
    return newValue;
  };

  return (
    <AsyncSelect
      getInlineOptions={getInlineOptions}
      value={value}
      query={INLINE_CITIES_QUERY}
      queryName="INLINE_CITIES_QUERY"
      variables={{ name: cityName, skipSmall }}
      onChange={onChange}
      isClearable={isClearable ? true : false}
      isDisabled={isDisabled}
      handleInputChange={handleCityInputChange}
      placeholder={placeholder}
    />
  );
}

export default InfiniteCitiesSelect;
