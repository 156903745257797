import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { APPLICANT_PREFERRED_LOCATION_MUTATION } from '../../api/mutations/applicantPreferredLocations';
import { MINIFIED_CURRENT_APPLICANT_QUERY } from '../../api/queries/applicants';
import { INLINE_COUNTRIES_QUERY } from '../../api/queries/shared';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Select } from '../../layout/fields';
import { setSuccessAlert } from '../../redux/alertSlice';
import { InfiniteCitiesSelect } from '../shared';

function PreferredLocationForm(props) {
  const {
    refetchQueries, currentLocation,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: applicantData } = useQuery(MINIFIED_CURRENT_APPLICANT_QUERY);

  const { data: countriesData } = useQuery(INLINE_COUNTRIES_QUERY);

  const [
    locationMutation,
    {
      data,
      loading,
    }] = useMutation(APPLICANT_PREFERRED_LOCATION_MUTATION, {
    refetchQueries,
  });

  const schema = yup.object({
    city: yup.object().required(t('Requried')),
  });

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      city: null,
      country: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        city: values.city.id,
        country: countriesData && countriesData.countries.find((item) => item.name === 'Germany').id,
        applicant: applicantData ? applicantData.currentApplicant.id : undefined,
      };
      locationMutation(
        {
          variables,
        },
      );
    },
  });

  const handleCityChange = (e) => {
    setFieldValue('city', { id: e.value, name: e.label });
  };

  useEffect(() => {
    if (currentLocation) {
      setValues(currentLocation);
    }
  }, [currentLocation, setValues]);

  useEffect(() => {
    if (data && data.applicantPreferredLocation.response && currentLocation) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [data, currentLocation, dispatch, t]);

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden rounded sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Country')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={{ label: 'Germany', value: null }}
                    isDisabled
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('City')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <InfiniteCitiesSelect
                    value={
                      formikValues.city
                      && { label: formikValues.city.name, value: formikValues.city.id }
                    }
                    skipSmall
                    onChange={handleCityChange}
                  />
                  {formikErrors.city && (
                  <p className="mt-2 text-sm text-red-600">
                    {formikErrors.city}
                  </p>
                  )}
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              <button
                diasbled={loading.toString()}
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {currentLocation ? t('Save') : t('Done')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

PreferredLocationForm.propTypes = {
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
  currentLocation: PropTypes.objectOf(PropTypes.any),
};

export default PreferredLocationForm;
