import { useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import type { FormikValues } from 'formik';
import { Fragment, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SimplifiedCompanyType } from '../../../../__generated__/graphql';
import { SWITCH_COMPANY_MUTATION } from '../../../../api/mutations/companies';
import { zIndexTweakedSelectStyles } from '../../../../helpers/utils';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { Select } from '../../../../layout/fields';
import { setSuccessAlert } from '../../../../redux/alertSlice';

interface Props {
  viewModal: boolean,
  closeModal: () => void,
  refetchQueries: ({ query: DocumentNode } | { query: DocumentNode, variables: { page: number, filterBy: string, orderBy: string } })[],
  companiesList: SimplifiedCompanyType[],
}

export default function SwitchCompanyModal(props: Props) {
  const {
    viewModal, closeModal, refetchQueries, companiesList,
  } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [
    switchMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(SWITCH_COMPANY_MUTATION, {
    refetchQueries,
  });

  const schema = yup.object({
    company: yup.object().required(t('Requried')),
  });

  const {
    handleSubmit,
    setFieldValue,
    values: formikValues,
  } = useFormik({
    initialValues: {
      company: null,
    } as FormikValues,
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      switchMutation(
        {
          variables: {
            company: values.company.value,
          },
        },
      );
      resetForm();
    },
  });

  useEffect(() => {
    if (mutationData && mutationData.switchCompany.response) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
      closeModal();
    }
  }, [mutationData, dispatch, closeModal, t]);

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={viewModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={viewModal}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-lightGray bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium ">
                    {t('Switch Company')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="py-2">
                      <Select
                        value={formikValues.company}
                        options={companiesList.map(
                          (item) => ({ value: item.id, label: item.name }),
                        )}
                        onChange={(e) => setFieldValue('company', e)}
                        // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        styles={zIndexTweakedSelectStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lightGray px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={mutationLoading}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleSubmit()}
                >
                  {t('Confirm')}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border-2 border-darkerGray shadow-sm px-4 py-2 bg-white text-base font-medium  hover:bg-lightGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                  ref={cancelButtonRef}
                >
                  {t('Cancel')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
