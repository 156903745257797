import { gql } from '@apollo/client';

export const MINIFIED_CURRENT_COMPANY_QUERY = gql`
query minifiedCurrentCompany{
  currentCompany{
    id
  }
}
`;
export const CURRENT_COMPANY_DASHBOARD_QUERY = gql`
  query currentCompanyDashboard{
    currentCompany{
      id
      name
      logoImageSrcset
      activeOffersQuantity
      closedOffersQuantity
    }
  }
`;

export const CURRENT_COMPANY_ACTIVITY_TABS_QUERY = gql`
query currentCompanyActivityTabs{
  currentCompany{
    id
    totalOffersLikesQuantity
    totalApplicantOfferViewsQuantity
    offers{
      id
      fullTitle
    }
  }
}
`;

export const CURRENT_COMPANY_IS_ACTIVE_QUERY = gql`
query currentCompanyIsActive{
  currentCompany{
    id
    isActive
  }
}
`;

export const PUBLIC_COMPANY_QUERY = gql`
  query publicCompany($company: ID!) {
    publicCompany(company: $company,){
      id
      branche
      name
      companySize
      description
      highlights
      logoImageSrcset
      profileImageSrcset
      email
      phone
      website
      address{
        id
        fullAddress
        coordinates
        city{
          id
          name
        }
      }
      benefits{
        id
        benefitType
        description
      }
    }
  }
`;

export const COMPANY_QUERY = gql`
  query company($company: ID!) {
    company(company: $company,){
      id
      name
      branche
      companySize
      description
      email
      highlights
      logoImageSrcset
      invitationCode
      isActive
      phone
      profileImageSrcset
      website
      owner{
        id
      }
      benefits{
        id
        benefitType
        description
      }
      address{
        id
        street
        areaCode{
          id
        }
        city{
          id
          name
        }
        country{
          id
          name
        }
      }
      managers{
        id
        ownedCompanies{
          id
        }
        profile{
          fullName
          user{
            email
          }
        }
      }
      pendingInvitations{
        id
        manager{
          id
          profile{
          fullName
          user{
            email
          }
        }
      }
     }
    }
  }
`;

export const POSSIBLE_MATCHES_QUERY = gql`
  query possibleMatches(
    $page: Int
    ) {
    possibleMatches(
      page: $page
      ){
      page
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        name
        logoImageSrcset
        profileImageSrcset
        description
        likedAt
      }
    }
  }
`;
