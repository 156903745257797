import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error400() {
  const { t } = useTranslation();
  const label = `${t('Error')}: ${t('Bad Request')}.`;
  return (
    <ErrorPageTempate
      label={label}
      code={400}
    />
  );
}

export default Error400;
