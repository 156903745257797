import { useTranslation } from 'react-i18next';
import { PackagePriceGridItemType } from '../../../../../__generated__/graphql';
import { PAYMENT_CYCLE, COMPANY_SIZE } from '../../../../../constants';
import { getCurrencySymbol } from '../../../../../helpers/utils';

interface Props {
  item: PackagePriceGridItemType,
  monthlyItem?: PackagePriceGridItemType
  handlePackageSelect: (itemId: string) => void,
}

export default function PackageCard(props: Props) {
  const { item, monthlyItem, handlePackageSelect } = props;
  const { t } = useTranslation();
  
  return (
    <div key={item.id} className="divide-y divide-gray-200 rounded-lg border border-gray-200 hover:shadow-xl">
      <div className="p-6 shadow-lg">
        <h2 className="text-lg font-medium leading-6">
          {t('Package')}
          {' '}
          &quot;
          {COMPANY_SIZE[item.companySize]}
          &quot;
        </h2>
        <p className="mt-4 text-sm">
          {t('Membership for 1 year with unlimited access')}
        </p>
        <div className="mt-4">
          <span className="text-2xl font-medium text-darkestGray tracking-tight">
            {monthlyItem && (
              monthlyItem.value.replace('.', ',')
            )}
            {getCurrencySymbol(item.package.currencyCode)}
          </span>
          {' '}
          <span className="font-medium text-gray-700 tracking-tight">
            /Monat
          </span>
          {item.paymentCycle === PAYMENT_CYCLE.ANNUAL && (
            <div className="text-sm font-medium text-gray-500">
              bei jährlicher Zahlweise*
            </div>
          )}
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => handlePackageSelect(item.id)}
          className="cursor-pointer mt-8 block w-full rounded-md border border-transparent bg-primary py-2 text-center text-sm font-semibold text-white"
        >
          {t('Buy package now')}
        </div>
      </div>
    </div>
  );
}
