import { DocumentDownloadIcon } from '@heroicons/react/outline';
import { FileType } from '../../__generated__/graphql';

interface Props {
  item: FileType,
}

function File(props: Props) {
  const { item } = props;

  return (
    <a
      key={item.id}
      href={item.file}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer flex px-3 relative bg-lightGray border border-transparent rounded-md py-2 items-center justify-center font-medium text-primary hover:bg-darkerGray"
    >
      <div>
        <DocumentDownloadIcon className="-ml-1 mr-2 h-6 w-6" aria-hidden="true" />
      </div>
      <div className="flex flex-1 truncate">
        {item.name}
      </div>
    </a>
  );
}

export default File;
