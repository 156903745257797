import { useEffect } from 'react';

interface Props {
  to: string,
}

function ExternalRedirect({ to }: Props) {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
}

export default ExternalRedirect;
