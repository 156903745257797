import { useQuery, useMutation } from '@apollo/client';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BellIcon, MenuIcon, XIcon, ChatAltIcon,
} from '@heroicons/react/outline';
import { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NotificationType, ProfileType, UserType } from '../../__generated__/graphql';
import { REVOKE_REFRESH_TOKEN_MUTATION } from '../../api/mutations/users';
import { UNVIEWED_MESSAGES_QUANTITY_QUERY } from '../../api/queries/chats';
import { CURRENT_NOTIFICATIONS_QUERY } from '../../api/queries/notifications';
import logo from '../../assets/images/logo.svg';
import { NotificationsSlideOver } from '../../components/shared/notifications';
import { CHAT_POLLING_INTERVAL } from '../../config';
import { USER_TYPE, IMAGE_SIZE } from '../../constants';
import { clearAuthCookies, getRefreshToken } from '../../helpers/auth';
import { classNames } from '../../helpers/utils';
import UserImage from '../UserImage';

function getNotificationsNumber(notificationsList: NotificationType[]) {
  if (!notificationsList.length) return '';
  const number = notificationsList.length;
  if (number < 100) return number;
  return '99+';
}

interface NavigationType {
  name: string,
  href: string,
}

function getNavigationPages(user: UserType) {
  if (!user.hasConfirmedEmail) {
    return [{
      name: 'Email Confirmation',
      href: '/email-confirmation/',
    }];
  }
  if (!user.isOnboarded) {
    return [{
      name: 'Onboarding',
      href: '/onboarding/',
    }];
  }
  let navigation: NavigationType[] = [];
  if (user.userType === USER_TYPE.MANAGER) {
    navigation = [
      { name: 'Dashboard', href: '/manager/' },
      { name: 'Applicants', href: '/manager/applicants/' },
      { name: 'Matched', href: '/manager/matches/' },
    ];
  } else if (user.userType === USER_TYPE.APPLICANT) {
    navigation = [
      { name: 'Dashboard', href: '/applicant/' },
      { name: 'Offers', href: '/applicant/offers/' },
      { name: 'Matched', href: '/applicant/matches/' },
    ];
  }

  return navigation;
}
function UserNavigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [revokeRefreshToken,
    { data: revokeRefreshTokenData },
  ] = useMutation(REVOKE_REFRESH_TOKEN_MUTATION);

  const handleLogout = () => {
    revokeRefreshToken({ variables: { refreshToken: getRefreshToken() } });
  };

  useEffect(() => {
    if (revokeRefreshTokenData) {
      clearAuthCookies();
      navigate('/authentication/login/');
    }
  }, [navigate, revokeRefreshTokenData]);

  return (
    <>
      <Link
        className="block px-4 py-2 text-base font-medium  hover:bg-darkerGray"
        to="/settings/account/"
      >
        {t('Settings')}
      </Link>
      <div
        onClick={() => {
          handleLogout();
        }}
        role="button"
        tabIndex={0}
        className="block px-4 py-2 text-base font-medium  hover:bg-darkerGray"
      >
        {t('Log out')}
      </div>

    </>
  );
}

interface Props {
  user: UserType,
}

function DashboardNavbar(props: Props) {
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const { user } = props;
  const location = useLocation();
  const navigation = getNavigationPages(user);

  const {
    data: notificationsData,
  } = useQuery(CURRENT_NOTIFICATIONS_QUERY);

  const {
    data: unviewedMessagesQuantityData,
  } = useQuery(UNVIEWED_MESSAGES_QUANTITY_QUERY, {
    pollInterval: CHAT_POLLING_INTERVAL,
    skip: user.isOperational === false,
  });

  return (
    <Disclosure as="nav" className="bg-white border-b border-lightGray">
      {({ open: openNavbar, close: closeNavbar }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="flex-shrink-0 flex items-center">
                    <img
                      className="block h-8 w-auto"
                      src={logo}
                      alt="Sprungbrett Handwerk Logo"
                    />
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? 'border-primary '
                          : 'border-transparent  hover:border-lightGray hover:',
                        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                      )}
                      aria-current={item.href === location.pathname ? 'page' : undefined}
                    >
                      {t(item.name)}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <Link
                  className="relative inline-block h-8 w-8 mr-1"
                  to={`/${user.userType.toLowerCase()}/matches/`}
                >
                  <div className="bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                    <ChatAltIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                    {unviewedMessagesQuantityData
                      && !!unviewedMessagesQuantityData.unviewedMessagesQuantity
                      && (
                        <span className="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full">
                          {unviewedMessagesQuantityData.unviewedMessagesQuantity}
                        </span>
                      )
                    }
                  </div>
                </Link>
                <span className="relative inline-block h-8 w-8">
                  <button
                    onClick={() => setShowNotifications(!showNotifications)}
                    //@ts-ignore
                    className={classNames(showNotifications && 'outline-none ring-2 ring-offset-2 ring-primary', 'bg-white p-1 rounded-full')}
                  >
                    <BellIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                    {notificationsData && !!notificationsData.currentNotifications.length && (
                      <span className="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full">
                        {getNotificationsNumber(notificationsData.currentNotifications)}
                      </span>
                    )}
                  </button>
                </span>
                {/* Profile dropdown */}
                <Menu as="div" className="z-10 ml-5 relative">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button
                          //@ts-ignore
                          className={classNames(open && 'outline-none ring-2 ring-offset-2 ring-primary', 'max-w-xs bg-white flex items-center text-sm rounded-full')}>
                          <UserImage
                            className="h-8 w-8 rounded-full"
                            profile={user.profile as ProfileType}
                            imageSize={IMAGE_SIZE.S32}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <UserNavigation />
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md  hover:bg-darkerGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  {openNavbar ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === location.pathname
                      ? 'bg-blue-50 border-primary text-primary'
                      : 'border-transparent  hover:bg-lightGray hover:border-lightGray hover:',
                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                  )}
                  aria-current={item.href === location.pathname ? 'page' : undefined}
                >
                  {t(item.name)}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-lightGray">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <UserImage
                    className="h-10 w-10 rounded-full"
                    profile={user.profile as ProfileType}
                    imageSize={IMAGE_SIZE.S64}
                  />
                </div>
                <div className="ml-3">
                  {user.profile && (
                    <div className="text-base font-medium ">{user.profile.fullName}</div>
                  )}
                  <div className="text-sm font-medium ">{user.email}</div>
                </div>
                <div className="flex flex-wrap">
                  <span className="relative inline-block h-8 w-8">
                    <Link
                      to={`/${user.userType.toLowerCase()}/matches/`}
                      onClick={() => closeNavbar}
                    >
                      <button
                        className="bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      >
                        <ChatAltIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                        {unviewedMessagesQuantityData
                          && !!unviewedMessagesQuantityData.unviewedMessagesQuantity
                          && (
                            <span className="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full">
                              {unviewedMessagesQuantityData.unviewedMessagesQuantity}
                            </span>
                          )
                        }
                      </button>
                    </Link>
                  </span>
                  <span className="relative inline-block h-8 w-8">
                    <button
                      onClick={() => setShowNotifications(!showNotifications)}
                      className="bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    >
                      <BellIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      {notificationsData && !!notificationsData.currentNotifications.length && (
                        <span className="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full">
                          {getNotificationsNumber(notificationsData.currentNotifications)}
                        </span>
                      )}
                    </button>
                  </span>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <UserNavigation />
              </div>
            </div>
          </Disclosure.Panel>
          <NotificationsSlideOver
            overlayState={showNotifications}
            setOverlayState={setShowNotifications}
            items={(notificationsData && notificationsData.currentNotifications) || []}
            closeNavbar={closeNavbar}
          />
        </>
      )}
    </Disclosure>
  );
}

export default DashboardNavbar;
