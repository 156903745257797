import { useTranslation } from 'react-i18next';
import { ApplicantPreferredLocationType, LimitedApplicantType } from '../../../__generated__/graphql';
import {
  JOB_TITLE, LANGUAGE, DRIVER_LICENSE_TYPE, JOB_QUALIFICATION,
} from '../../../constants';
import { LocationMap } from '../../applicant';
import { AttributeCard } from '../../shared';
import { LikeApplicantSection } from '../likes';

interface Props {
  item: LimitedApplicantType,
}

function DetailCard(props: Props) {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div>
            <div className="text-lg font-black">{JOB_QUALIFICATION[item.qualification]}</div>
            <div className="text-2xl font-light">{JOB_TITLE[item.title as keyof typeof JOB_TITLE]}</div>
          </div>
        </div>
        <div className="col-span-1 space-y-2">
          <LikeApplicantSection applicantId={item.id} />
        </div>
      </div>

      {item.goals && (
        <div className="my-10 text-left mx-auto ">
          <div className="text-lg font-bold  text-left">
            {t('Goals')}
          </div>
          <div className="grid gap-2">
            {item.goals.split('\n').map((str, index) => <p key={index}>{str}</p>)}
          </div>
        </div>
      )}

      {item.miscellaneous && (
        <div className="my-10 text-left mx-auto ">
          <div className="text-lg font-bold  text-left">
            {t('Miscellaneous')}
          </div>
          <div className="grid gap-2">
            {item.miscellaneous.split('\n').map((str, index) => <p key={index}>{str}</p>)}
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-10 mt-10">
        <div>
          <div className="font-semibold text-lg">
            {t('What Defines You')}
            <dl className="space-y-8 pt-2">
              {!!item?.driverLicence?.length && (
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Driver Licence')}
                    value={item.driverLicence.map((licence) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE]).join(', ')}
                  />
                </dd>
              )}
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={t('Travel activity/assembly')}
                  value={item.isOpenForTravelling ? t('Yes') : t('No')}
                />
              </dd>
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={t('Languages')}
                  value={item.languages.map((language) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
                />
              </dd>
              {!!item.skills.length && (
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Skills')}
                    value={item.skills.map((skill) => skill.name).join(', ')}
                  />
                </dd>
              )}
            </dl>
          </div>
          <div className="text-lg pt-10 pb-4 font-semibold">
            {t('Performance')}
            <dl className="space-y-8 pt-2">
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={`${t('Salary')}`}
                  value={`${item?.salaryDetails?.exactValue} ${item?.salaryDetails?.currencyCode}`}
                />
              </dd>
            </dl>
          </div>
        </div>
        <div>
          <div>
            <span className="text-xl font-bold ">
              {t('Preferred Location')}
              {' '}
              :
              {' '}
              <span className="text-secondaryGray">
                {item?.preferredLocation?.city.name}
              </span>
            </span>
            <div>
              <LocationMap
                calculatedLength={
                  item?.preferredLocation?.calculatedLength as number
                }
                location={
                  item.preferredLocation as ApplicantPreferredLocationType
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailCard;
