import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { INLINE_COUNTRIES_QUERY, INLINE_CITIES_QUERY, AREA_CODE_QUERY } from '../../api/queries/shared';
import {
  zIndexTweakedSelectStyles,
  zIndexTweakedSelectStylesOnError,
} from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Input, Select } from '../../layout/fields';
import { setSuccessAlert } from '../../redux/alertSlice';

function AddressForm(props) {
  const {
    refetchQueries, currentAddress, mutationSchema, companyId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: countriesData } = useQuery(INLINE_COUNTRIES_QUERY);

  const [
    getAreaCode,
    {
      data: areaCodeData,
      loading: areaCodeLoading,
      // notifyOnNetworkStatusChange is used to force loading indicator to work
    }] = useLazyQuery(AREA_CODE_QUERY, { notifyOnNetworkStatusChange: true });

  const [
    getCities,
    {
      data: citiesData,
    }] = useLazyQuery(INLINE_CITIES_QUERY);

  const [
    addressMutation,
    {
      data,
      loading,
    },
  ] = useMutation(mutationSchema, {
    refetchQueries,
  });

  const schema = yup.object({
    areaCode: yup.object().nullable().required(t('Requried')),
    city: yup.object().nullable().required(t('Requried')),
    street: yup.string().required(t('Requried')),
  });

  const {
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      areaCode: null,
      city: null,
      street: '',
      country: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        city: values.city.value,
        areaCode: values.areaCode.value,
        country: countriesData && countriesData.countries.find((item) => item.name === 'Germany').id,
      };
      if (companyId) {
        variables.company = companyId;
      }
      if (currentAddress) {
        variables.id = currentAddress.id;
      }
      addressMutation(
        {
          variables,
        },
      );
    },
  });

  const handleAreaCodeInputChange = (e) => {
    if (e.length === 5) {
      getAreaCode({ variables: { areaCode: e } });
    }
  };

  const handleAreaCodeChange = (e) => {
    setValues({
      areaCode: e,
      city: null,
    });
    getCities({ variables: { name: e.value } });
  };

  useEffect(() => {
    if (currentAddress) {
      setValues({
        ...currentAddress,
        city: {
          value: currentAddress.city.id,
          label: currentAddress.city.name,
        },
        areaCode: {
          value: currentAddress.areaCode.id,
          label: currentAddress.areaCode.id,
        },
      });
      getAreaCode({ variables: { areaCode: currentAddress.areaCode.id } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress, setValues, setFieldValue]);

  useEffect(() => {
    if (data && data.companyAddress && currentAddress) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [data, currentAddress, dispatch, t]);

  useEffect(() => {
    if (citiesData && citiesData.cities && citiesData.cities.objects.length) {
      const firstCity = citiesData.cities.objects[0];
      const city = { value: firstCity.id, label: firstCity.name };
      setFieldValue('city', city);
    }
  }, [citiesData, setFieldValue]);

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="sm:mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="rounded sm:rounded-none px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Country')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={{ label: 'Germany', value: null }}
                    isDisabled
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Area Code')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    onInputChange={handleAreaCodeInputChange}
                    value={formikValues.areaCode}
                    isLoading={areaCodeLoading}
                    options={
                      (areaCodeData
                        && areaCodeData.areaCode
                        && areaCodeData.areaCode.postalCode)
                        ? [{
                          value: areaCodeData.areaCode.postalCode,
                          label: areaCodeData.areaCode.postalCode,
                        }]
                        : []
                    }
                    onChange={(e) => handleAreaCodeChange(e)}
                    styles={
                      formikErrors.areaCode
                        ? zIndexTweakedSelectStylesOnError
                        : zIndexTweakedSelectStyles
                    }
                    menuPortalTarget={document.body}
                  />
                  {touched.areaCode && !!formikErrors.areaCode && (<div className="mt-2 text-sm text-red-600">{formikErrors.areaCode}</div>)}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('City')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    value={formikValues.city}
                    placeholder={!formikValues.areaCode ? t('Please select Area Code') : `${t('Select')}...`}
                    options={
                      citiesData
                      && citiesData.cities.objects.length
                      && citiesData.cities.objects.map(
                        (item) => ({ value: item.id, label: item.name }),
                      )
                    }
                    onChange={(e) => setFieldValue('city', e)}
                    isDisabled={formikValues.areaCode === null && !formikValues.city}
                    styles={
                      formikErrors.city
                        ? zIndexTweakedSelectStylesOnError
                        : zIndexTweakedSelectStyles
                    }
                    menuPortalTarget={document.body}
                  />
                  {touched.city && !!formikErrors.city && (<div className="mt-2 text-sm text-red-600">{formikErrors.city}</div>)}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="street"
                    name="street"
                    type="text"
                    value={formikValues.street}
                    errorMessage={touched.street && formikErrors.street}
                    label={t('Street')}
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              <button
                diasbled={loading.toString()}
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {currentAddress ? t('Save') : t('Done')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

AddressForm.propTypes = {
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
  currentAddress: PropTypes.objectOf(PropTypes.any),
  mutationSchema: PropTypes.objectOf(PropTypes.any),
  companyId: PropTypes.string,
};

export default AddressForm;
