import { gql } from '@apollo/client';

export const CURRENT_USER_QUERY = gql`
  query currentUser{
    currentUser{
      id
      email
      userType
      isOperational
      hasConfirmedEmail
      isOnboarded
      profile{
        imageSrcset
      }
    }
  }
`;

export const APPLICANT_ONBOARDING_STATUS_QUERY = gql`
  query applicantOnboardingStatus{
    applicantOnboardingStatus{
      hasProfile
      hasApplicant
      hasPreferredLocation
    }
  }
`;

export const MANAGER_ONBOARDING_STATUS_QUERY = gql`
  query managerOnboardingStatus{
    managerOnboardingStatus{
      hasProfile
      hasCompany
      hasCompanyPackage
    }
  }
`;
