/* eslint-disable max-len */
import handwerkOfferExample from '../../../assets/images/handwerk-offer-example.png';

function PlaceholderCard() {
  return (
    <div className="grid gap-4">
      <span className="text-2xl font-bold">Stellenangebote auf Sprungbrett Handwerk</span>
      <p>
        Hier findest du verschiedene Stellenangebote aus deiner Region. Das ganze ist genau so simpel, wie es aussieht. Du siehst die Stellenbezeichnung, den Arbeitsort, das Monatsgehalt und das Alter der Anzeige.
      </p>
      <div className="my-3">
        <img
          className="h-36 w-auto"
          src={handwerkOfferExample}
          alt="Handwerk Angebot Beispiel"
        />
      </div>
      <p>
        Daneben siehst du einen Daumen. Diesen klickst du an, wenn dir eine Anzeige gefällt. Eine Anzeige, die du schon magst, bekommt einen grünen Daumen. Klickst du einfach nur auf das Stellenangebot, werden dir die Details zur Stelle angezeigt.
      </p>
      <p>
        Viel Spaß beim Stöbern wünscht euch das Sprungbrett Team
      </p>
    </div>
  );
}

export default PlaceholderCard;
