import { gql } from '@apollo/client';

export const BENEFIT_MUTATION = gql`
mutation benefit(
  $benefitType: String!,
  $company: ID!,
  $description: String
  ){
    benefit(
      input:{
        benefitType: $benefitType
        company: $company
        description: $description
      }
    )
  { response{
    id
  }
    errors{
      field
      messages
    }
  }
}
`;

export const DELETE_BENEFIT_MUTATION = gql`
mutation deleteBenefit($benefit: ID!){
  deleteBenefit(input:{benefit: $benefit})
  { response
    errors{
      field
      messages
    }
  }
}
`;
