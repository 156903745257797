import { gql } from '@apollo/client';

export const COMPANY_INVITATION_MUTATION = gql`
  mutation companyInvitation(
    $id: ID,
    $company: ID!,
    $manager: ID!,
    $status: String!,
    ){
      companyInvitation(input:{
      id: $id
      company: $company
      manager: $manager
      status: $status
    }){
      response{
        id
        status
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const USE_COMPANY_INVITATION_MUTATION = gql`
  mutation useCompanyInvitation(
    $code: String!,
    ){
      useCompanyInvitation(input:{
      code: $code
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;
