import { gql } from '@apollo/client';

export const OFFER_MUTATION = gql`
mutation createOrUpdateOffer(
  $id: ID
  $company: ID!
  $description: String
  $driverLicence: String,
  $isActive : Boolean!
  $languages: String!
  $skills: [String]
  $title: String!
  $salary: Decimal!
  $qualification: String!
  $travellingRequired: Boolean!
  $useCompanyAddress: Boolean!

  $areaCode: ID,
  $city: ID,
  $country: ID,
  $street: String,
  ){
    offer(
    input:
    {
      id: $id,
      company: $company,
      description: $description,
      driverLicence: $driverLicence,
      isActive: $isActive,
      languages: $languages,
      skills: $skills,
      title: $title,
      salary: $salary,
      qualification: $qualification,
      travellingRequired: $travellingRequired,
      useCompanyAddress: $useCompanyAddress,

      areaCode: $areaCode,
      city: $city,
      country: $country,
      street: $street,
    })
    { response{
        id
        address{
          id
          street
          areaCode{
            id
          }
          city{
            id
            name
          }
          country{
            id
            name
          }
        }
        salaryDetails{
          id
          currencyCode
          exactValue
        }
        description
        driverLicence
        company{
          id
          name
          address{
            id
            street
            areaCode{
              id
            }
            city{
              id
              name
            }
            country{
              id
              name
            }
          }
        }
        isActive
        languages
        skills{
          id
          name
          nameLower
        }
        title
        fullTitle
        travellingRequired
        qualification
        useCompanyAddress
    }
    errors{
      field
      messages
    }
  }
}
`;

export const DELETE_OFFER_MUTATION = gql`
mutation deleteOffer(
  $offer: ID!
  ){
    deleteOffer(input:{
      offer: $offer
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;
