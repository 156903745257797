import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { OFFER_MUTATION } from '../../../api/mutations/offers';
import { OFFER_QUERY } from '../../../api/queries/offers';
import { INLINE_COUNTRIES_QUERY, INLINE_CITIES_QUERY, AREA_CODE_QUERY } from '../../../api/queries/shared';
import { INLINE_SKILLS_QUERY } from '../../../api/queries/skills';
import {
  TARGET_FLEID,
  LANGUAGE,
  JOB_TITLE,
  JOB_QUALIFICATION,
  DRIVER_LICENSE_TYPE,
} from '../../../constants';
import {
  getInlineOptionsFromEnumDict,
  updateSelectStylesOnError,
  getInlineOptionsFromQuery,
  convertInlineOptionsToString,
  classNames,
  getLabeledDriverLicenceOptions,
  getLabeledJobTitleOptions,
  zIndexTweakedSelectStyles,
  zIndexTweakedSelectStylesOnError,
} from '../../../helpers/utils';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Input, Switch, Select } from '../../../layout/fields';
import LoadingIndicator from '../../../layout/LoadingIndicator';
import { setSuccessAlert } from '../../../redux/alertSlice';
import DeleteOffer from './DeleteOffer';

const driverLicenceOptions = getInlineOptionsFromEnumDict(DRIVER_LICENSE_TYPE, false);

const labeledDriverLicenceOptions = getLabeledDriverLicenceOptions();

const languageOptions = getInlineOptionsFromEnumDict(LANGUAGE);

const titleOptions = getInlineOptionsFromEnumDict(JOB_TITLE, false);

const labeledJobTitleOptions = getLabeledJobTitleOptions();

const qualificationOptions = getInlineOptionsFromEnumDict(JOB_QUALIFICATION, false);

function OfferForm(props) {
  const {
    currentOffer, companyId, title, refetchQueries,
  } = props;

  const { t } = useTranslation();

  const translatedLanguageOptions = languageOptions.map(
    (item) => ({ value: item.value, label: t(item.label) }),
  );

  const [formikValues, setFormValues] = useState({
    description: '',
    driverLicence: [],
    isActive: true,
    languages: [translatedLanguageOptions.find((item) => item.value === 'de')],
    qualification: '',
    salary: '',
    skills: [],
    title: '',
    travellingRequired: false,
    useCompanyAddress: true,

    areaCode: null,
    city: null,
    country: null,
    street: '',

  });
  const [skillsOptions, setSkillsOptions] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: skillsData } = useQuery(INLINE_SKILLS_QUERY);

  const { data: countriesData } = useQuery(INLINE_COUNTRIES_QUERY);

  const [
    getAreaCode,
    {
      data: areaCodeData,
      loading: areaCodeLoading,
      // notifyOnNetworkStatusChange is used to force loading indicator to work
    }] = useLazyQuery(AREA_CODE_QUERY, { notifyOnNetworkStatusChange: true });

  const [
    getCities,
    {
      data: citiesData,
    }] = useLazyQuery(INLINE_CITIES_QUERY);

  const [
    offerMutation,
    {
      data: offerData,
      loading: offerLoading,
    },
  ] = useMutation(OFFER_MUTATION, {
    refetchQueries,
    update: (cache, { data: { offer } }) => {
      if (offer.response) {
        if (currentOffer && offer.response) {
          cache.writeQuery({
            query: OFFER_QUERY,
            variables: { offer: currentOffer.id },
            data: { offer: offer.response },
          });
        }
      }
    },
  });

  const schema = yup.object({
    description: yup.string(),
    driverLicence: yup.array(),
    isActive: yup.bool().required(t('Required')),
    languages: yup.array().required().min(1, t('Required')),
    salary: yup.number()
      .integer(t('Salary must be an integer.'))
      .required(t('Required')),
    skills: yup.array(),
    title: yup.string().required(t('Required')),
    travellingRequired: yup.bool().required(t('Required')),
    qualification: yup.string().required(t('Required')),

    areaCode: yup.object().nullable().when('useCompanyAddress', {
      is: false,
      then: yup.object().nullable()
        .required(t('Required')),
    }),
    city: yup.object().nullable().when('useCompanyAddress', {
      is: false,
      then: yup.object().nullable()
        .required(t('Required')),
    }),
    street: yup.string().when('useCompanyAddress', {
      is: false,
      then: yup.string()
        .required(t('Required')),
    }),
  });

  const {
    handleSubmit,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        company: companyId,
        driverLicence: convertInlineOptionsToString(values.driverLicence, TARGET_FLEID.VALUE),
        languages: convertInlineOptionsToString(values.languages, TARGET_FLEID.VALUE),
        salary: parseFloat(values.salary),
        skills: values.skills.map((item) => item.label),
        country: countriesData && countriesData.countries.find((item) => item.name === 'Germany').id,
        areaCode: values.areaCode && values.areaCode.value,
        city: values.city && values.city.value,
      };
      offerMutation(
        {
          variables,
        },
      );
    },
  });

  const handleFormValueChange = (field, value) => {
    const values = { ...formikValues };
    values[field] = value;
    setFormValues(values);
  };

  const handleAreaCodeInputChange = (e) => {
    if (e.length === 5) {
      getAreaCode({ variables: { areaCode: e } });
    }
  };

  const handleAreaCodeChange = (e) => {
    setFormValues({
      ...formikValues,
      areaCode: e,
      city: null,
    });
    getCities({ variables: { name: e.value } });
  };

  useEffect(() => {
    if (skillsData) {
      setSkillsOptions(getInlineOptionsFromQuery(skillsData, 'name'));
    }
  }, [skillsData]);

  useEffect(() => {
    if (currentOffer) {
      const currentDriverLicence = currentOffer.driverLicence.map(
        (item) => driverLicenceOptions.find((licence) => licence.value === item),
      );
      const currentLanguages = currentOffer.languages.map(
        (item) => translatedLanguageOptions.find(
          (language) => language.value === item,
        ),
      );
      const currentSkills = currentOffer.skills.map(
        (item) => skillsOptions.find((skill) => skill.value === item.id),
      ).filter((item) => item !== undefined);
      const currentValues = { ...currentOffer };
      currentValues.driverLicence = currentDriverLicence;
      currentValues.salary = currentOffer.salaryDetails.exactValue;
      currentValues.languages = currentLanguages;
      currentValues.skills = currentSkills;
      if (currentOffer.useCompanyAddress) {
        currentValues.street = currentOffer.company.address.street;
        currentValues.city = {
          value: currentOffer.company.address.city.id,
          label: currentOffer.company.address.city.name,
        };
        currentValues.country = currentOffer.company.address.country;
        currentValues.areaCode = {
          value: currentOffer.company.address.areaCode.id,
          label: currentOffer.company.address.areaCode.id,
        };
      } else {
        currentValues.street = currentOffer.address.street;
        currentValues.city = {
          value: currentOffer.address.city.id,
          label: currentOffer.address.city.name,
        };
        currentValues.country = currentOffer.address.country;
        currentValues.areaCode = {
          value: currentOffer.address.areaCode.id,
          label: currentOffer.address.areaCode.id,
        };
      }

      getAreaCode({ variables: { areaCode: currentValues.areaCode.value } });

      delete currentValues.salaryDetails;
      delete currentValues.address;
      setFormValues(currentValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffer, skillsOptions]);

  useEffect(() => {
    if (offerData && offerData.offer && offerData.offer.response) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
      navigate('/');
    }
  }, [offerData, dispatch, navigate, t]);

  useEffect(() => {
    if (citiesData && citiesData.cities && citiesData.cities.objects.length) {
      const firstCity = citiesData.cities.objects[0];
      const city = { value: firstCity.id, label: firstCity.name };
      handleFormValueChange('city', city);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="mt-5 grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4">
              <div className="text-xl font-bold ">{title}</div>
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Switch
                value={formikValues.isActive}
                onChange={(e) => handleFormValueChange('isActive', e)}
                label={t('Is Active')}
                className="flex justify-end"
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <label
                className="block text-sm font-bold "
              >
                {t('Title')}
                <span className="text-red-600"> *</span>
              </label>
              <Select
                value={titleOptions.find((item) => item.value === formikValues.title)}
                className="mt-1"
                options={labeledJobTitleOptions}
                onChange={(e) => handleFormValueChange('title', e.value)}
                styles={formikErrors.title && updateSelectStylesOnError}
                hasError={!!formikErrors.title}
                errorMessage={formikErrors.title}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                className="block text-sm font-bold "
              >
                {t('Qualification')}
                <span className="text-red-600"> *</span>
              </label>
              <Select
                value={qualificationOptions.find(
                  (item) => item.value === formikValues.qualification,
                )}
                className="mt-1"
                options={qualificationOptions}
                onChange={(e) => handleFormValueChange('qualification', e.value)}
                styles={formikErrors.qualification && updateSelectStylesOnError}
                hasError={!!formikErrors.qualification}
                errorMessage={formikErrors.qualification}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                id="salary"
                name="salary"
                type="number"
                value={formikValues.salary}
                errorMessage={touched.salary && formikErrors.salary}
                label={t('Salary Expectations')}
                required
                hasSuffix
                suffixValue="EUR"
                className="no-arrows"
                onChange={(e) => handleFormValueChange('salary', e.target.value)}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                className="block text-sm font-bold "
              >
                {t('Languages')}
                {` (${t('multiple selection possible')})`}
                <span className="text-red-600"> *</span>
              </label>
              <Select
                value={formikValues.languages}
                isMulti
                className="mt-1"
                options={translatedLanguageOptions}
                onChange={(e) => handleFormValueChange('languages', e)}
                styles={formikErrors.languages && updateSelectStylesOnError}
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <label
                className="block text-sm font-medium "
              >
                {t('Skills')}
                {` (${t('multiple selection possible')})`}
              </label>
              <Select
                isMulti
                isClearable
                value={formikValues.skills}
                className="mt-1"
                onMultiChange={(e) => (e ? handleFormValueChange('skills', e) : handleFormValueChange('skills', []))}
                options={skillsOptions}
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <label
                className="block text-sm font-medium "
              >
                {t('Driver Licence')}
                {` (${t('multiple selection possible')})`}
              </label>
              <Select
                value={formikValues.driverLicence}
                isMulti
                className="mt-1"
                options={labeledDriverLicenceOptions}
                onMultiChange={(e) => handleFormValueChange('driverLicence', e)}
                styles={formikErrors.driverLicence && updateSelectStylesOnError}
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              <label
                className="block text-sm font-medium "
              >
                {t('Description')}
              </label>
              <textarea
                id="description"
                name="description"
                rows={4}
                value={formikValues.description}
                className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                onChange={(e) => handleFormValueChange('description', e.target.value)}
              />
            </div>

            <div className="col-span-6 sm:col-span-6">
              <Switch
                value={formikValues.useCompanyAddress}
                onChange={() => handleFormValueChange('useCompanyAddress', !formikValues.useCompanyAddress)}
                label={t('Use Company Address')}
                className="flex"
              />
            </div>
            {!formikValues.useCompanyAddress && (
              <>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Country')}
                  </label>
                  <Select
                    value={{ label: 'Germany', value: null }}
                    isDisabled
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Area Code')}
                  </label>
                  <Select
                    onInputChange={handleAreaCodeInputChange}
                    isLoading={areaCodeLoading}
                    value={formikValues.areaCode}
                    options={
                      (areaCodeData
                        && areaCodeData.areaCode
                        && areaCodeData.areaCode.postalCode)
                        ? [{
                          value: areaCodeData.areaCode.postalCode,
                          label: areaCodeData.areaCode.postalCode,
                        }]
                        : []
                    }
                    onChange={(e) => handleAreaCodeChange(e)}
                    styles={
                      formikErrors.areaCode
                        ? zIndexTweakedSelectStylesOnError
                        : zIndexTweakedSelectStyles
                    }
                    menuPortalTarget={document.body}
                  />
                  {touched.areaCode && !!formikErrors.areaCode && (<div className="mt-2 text-sm text-red-600">{formikErrors.areaCode}</div>)}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('City')}
                  </label>
                  <Select
                    value={formikValues.city}
                    placeholder={!formikValues.areaCode ? t('Please select Area Code') : `${t('Select')}...`}
                    options={
                      citiesData
                      && citiesData.cities.objects.length
                      && citiesData.cities.objects.map(
                        (item) => ({ value: item.id, label: item.name }),
                      )
                    }
                    onChange={(e) => handleFormValueChange('city', e)}
                    isDisabled={formikValues.areaCode === null && !formikValues.city}
                    styles={
                      formikErrors.city
                        ? zIndexTweakedSelectStylesOnError
                        : zIndexTweakedSelectStyles
                    }
                    menuPortalTarget={document.body}
                  />
                  {touched.city && !!formikErrors.city && (<div className="mt-2 text-sm text-red-600">{formikErrors.city}</div>)}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="street"
                    name="street"
                    type="text"
                    value={formikValues.street}
                    errorMessage={formikErrors.street}
                    label={t('Street')}
                    required
                    onChange={(e) => handleFormValueChange('street', e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="col-span-6 sm:col-span-6">
              <Switch
                value={formikValues.travellingRequired}
                onChange={(e) => handleFormValueChange('travellingRequired', e)}
                label={t('Travel / Assembly')}
                className="flex"
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-lightGray text-right sm:px-6 grid grid-cols-2">
          {currentOffer
            && (
              <DeleteOffer
                offerId={currentOffer.id}
                offerTitle={currentOffer.fullTitle}
                refetchQueries={refetchQueries}
              />
            )}
          <div className={classNames(!currentOffer && 'col-span-2')}>
            {offerLoading
              ? (
                <LoadingIndicator className="flex justify-end py-4 mr-2" />

              )
              : (
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  {t('Save')}
                </button>
              )}
          </div>
        </div>
      </div>
    </form>
  );
}

OfferForm.propTypes = {
  title: PropTypes.string,
  currentOffer: PropTypes.objectOf(PropTypes.any),
  companyId: PropTypes.string,
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
};

export default OfferForm;
