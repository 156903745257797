import { useQuery, useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import { PublicCompanyType, PublicOfferType } from '../../../__generated__/graphql';
import { PUBLIC_OFFER_QUERY } from '../../../api/queries/offers';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { USER_TYPE, IMAGE_SIZE } from '../../../constants';
import {
  CompanyLogoImage,
} from '../../../layout';
import { LikeOfferSection } from '../../applicant/likes';
import AddressMap from '../AddressMap';

interface Props {
  item: PublicOfferType,
}

function OffersWidgetCard(props: Props) {
  const { item } = props;
  const client = useApolloClient();

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  let viewerIsApplicant = false;
  let address;
  if (item.useCompanyAddress) {
    address = item?.company?.address;
  } else {
    address = item.address;
  }
  if (userData && userData.currentUser && userData.currentUser.userType === USER_TYPE.APPLICANT) {
    viewerIsApplicant = true;
  }

  return (
    <div
      className="group relative bg-white border-2 border-darkerGray flex flex-col"
    >
      <div className="group-hover:opacity-80">
        <AddressMap
          fullAddress={address?.fullAddress as string}
          coordinates={address?.coordinates as string[]}
        />
      </div>
      <div className="flex-1 p-4 space-y-2 flex flex-col">
        <Link
          to={`/offer/${item.id}`}
          onMouseEnter={() => client.query({
            query: PUBLIC_OFFER_QUERY,
            variables: { offer: item.id },
            fetchPolicy: 'network-only',
          })}
        >
          <div className="flex items-center space-x-3">
            <span>{item.fullTitle}</span>
          </div>
          <p className="text-sm  h-5">{address?.city.name}</p>
          <p className="text-sm  h-5 truncate">{item.description}</p>
          <div className="flex items-center mt-5 space-x-3">
            <div className="flex-shrink-0 h-12 w-12">
              <CompanyLogoImage
                className="h-12 w-12 rounded-lg text-2xl"
                company={item.company as PublicCompanyType}
                imageSize={IMAGE_SIZE.S64}
              />
            </div>
            <span className="ml-2">{item?.company?.name}</span>
          </div>
        </Link>
        {viewerIsApplicant && (
          <div className="flex-1 flex flex-col justify-end">
            <div className="mt-5"><LikeOfferSection offerId={item.id} /></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OffersWidgetCard;
