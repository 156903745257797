import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { NotificationType } from '../../../__generated__/graphql';
import EmptyNotification from './EmptyNotification';
import NotificationsList from './NotificationsList';

interface Props {
  overlayState: boolean,
  setOverlayState: (value: boolean) => void,
  items: NotificationType[],
  closeNavbar: () => void,
}

function NotificationsSlideOver(props: Props) {
  const {
    overlayState, setOverlayState, items, closeNavbar,
  } = props;

  const hideNotifications = () => {
    setOverlayState(false);
    closeNavbar();
  };

  return (
    <Transition.Root show={overlayState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-hidden"
        onClose={setOverlayState}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-secondaryGray bg-opacity-75 sm:bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 sm:pl-0 w-full sm:w-1/4 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-9 pt-2 plr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="bg-primary text-white rounded-md"
                      onClick={() => setOverlayState(false)}
                    >
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="inline-block align-bottom rounded-lg pl-4 pr-2 pt-2 text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-lg w-full">
                  {items.length
                    ? (
                      <NotificationsList
                        items={items}
                        hideNotifications={hideNotifications}
                      />
                    )
                    : (
                      <EmptyNotification />

                    )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NotificationsSlideOver;
