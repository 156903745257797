import { useTranslation } from 'react-i18next';
import { RestrictedMessageThreadType } from '../../../__generated__/graphql';
import { PreviewCard as OfferPreviewCard } from '../../applicant/chats';
import { PreviewCard as ApplicantPreviewCard } from '../../manager/chats';

interface Props {
  items: RestrictedMessageThreadType[],
  currentItem: RestrictedMessageThreadType | null,
  handlePreviewClick: (item: RestrictedMessageThreadType) => void,
  cardIsOffer?: boolean,
  cardIsApplicant?: boolean,
}

function PreviewCardList(props: Props) {
  const {
    items, currentItem, handlePreviewClick, cardIsOffer, cardIsApplicant,
  } = props;
  const { t } = useTranslation();

  if (!items.length) {
    return <div className="text-center py-8 text-lg">{t('No records found')}</div>;
  }

  return (
    <div
      id="styled-scroll"
      className="md:max-h-[80vh] overflow-y-auto pr-2 grid gap-4"
    >
      {cardIsOffer && (
        items.map((item) => (
          <OfferPreviewCard
            key={item.id}
            item={item}
            isCurrent={currentItem?.id === item.id}
            handlePreviewClick={handlePreviewClick}
          />
        )))}

      {cardIsApplicant && (
        items.map((item) => (
          <ApplicantPreviewCard
            key={item.id}
            item={item}
            isCurrent={currentItem?.id === item.id}
            handlePreviewClick={handlePreviewClick}
          />
        )))}
    </div>
  );
}

export default PreviewCardList;
