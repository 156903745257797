import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { RestrictedMessageThreadType } from '../../../__generated__/graphql';
import { READ_THREAD_MUTATION } from '../../../api/mutations/chats';
import {
  MANAGER_THREADS_QUERY,
  UNVIEWED_MESSAGES_QUANTITY_QUERY,
} from '../../../api/queries/chats';
import { PlaceholderCard } from '../../../components/manager/chats';
import {
  ChatSection, PreviewCardList, ContactsSlideOver, ContactsButton, ArchiveChatSection,
} from '../../../components/shared/chats';
import { CHAT_POLLING_INTERVAL } from '../../../config';
import { USER_TYPE } from '../../../constants';
import { LoadingIndicator } from '../../../layout';
import { ManagerDashboardLayout } from '../../../layout/layouts/dashboard';

function Chat() {
  const [currentItem, setCurrentItem] = useState<RestrictedMessageThreadType | null>(null);
  const [overlayModal, setOverlayModal] = useState(false);
  const [showArchive, setShowArchive] = useState(false);

  const {
    data: threadsData,
    loading: threadsLoading,
  } = useQuery(
    MANAGER_THREADS_QUERY,
    {
      pollInterval: CHAT_POLLING_INTERVAL,
    },
  );

  const [
    readThreadMutation,
  ] = useMutation(READ_THREAD_MUTATION, {
    refetchQueries: [
      { query: MANAGER_THREADS_QUERY },
      { query: UNVIEWED_MESSAGES_QUANTITY_QUERY },
    ],
  });

  const [
    getUnviewedMessages,
  ] = useLazyQuery(UNVIEWED_MESSAGES_QUANTITY_QUERY);

  const handlePreviewClick = (item: RestrictedMessageThreadType) => {
    readThreadMutation({ variables: { thread: item.id } });
    setOverlayModal(false);
    setCurrentItem(item);
  };

  useEffect(() => {
    // Synchronize UNVIEWED_MESSAGES_QUANTITY_QUERY polling timer
    getUnviewedMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManagerDashboardLayout>
      <div className="sm:rounded-lg bg-white">
        <main className="mx-auto px-4 py-8 max-w-7xl">
          <div className="md:grid md:grid-cols-11 md:gap-x-8">
            <div className="md:hidden">
              <ContactsButton
                handleClick={() => setOverlayModal(true)}
              />
            </div>
            <div className="hidden md:grid col-span-4 gap-4 content-start">
              <div className="h-[80vh]">
                {threadsLoading && (!threadsData)
                  ? (<LoadingIndicator className="flex h-[80vh] justify-center items-center" />)
                  : (
                    <PreviewCardList
                      cardIsApplicant
                      items={threadsData ? threadsData.managerThreads : []}
                      currentItem={currentItem}
                      handlePreviewClick={handlePreviewClick}
                    />
                  )}
              </div>
            </div>
            <div
              id="styled-scroll"
              className="col-span-7 overflow-y-auto pr-2 pt-2 md:pt-0"
            >
              {currentItem
                ? (
                  showArchive
                    ? (
                      <ArchiveChatSection
                        currentUserType={USER_TYPE.MANAGER}
                        threadId={currentItem.id}
                        hideArchive={() => setShowArchive(false)}
                      />
                    )
                    : (
                      <ChatSection
                        currentUserType={USER_TYPE.MANAGER}
                        threadId={currentItem.id}
                        openArchive={() => setShowArchive(true)}
                      />
                    ))
                : <PlaceholderCard />}
            </div>
          </div>
        </main>
        <ContactsSlideOver
          cardIsApplicant
          currentItem={currentItem}
          modalState={overlayModal}
          setModalState={setOverlayModal}
          items={threadsData ? threadsData.managerThreads : []}
          handlePreviewClick={handlePreviewClick}
        />
      </div>
    </ManagerDashboardLayout>
  );
}

export default Chat;
