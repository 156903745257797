import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInvitationType, ManagerType } from '../../../../../__generated__/graphql';
import { REMOVE_MANAGER_MUTATION } from '../../../../../api/mutations/companies';
import { COMPANY_QUERY } from '../../../../../api/queries/companies';
import { Warning } from '../../../../../layout/modals';
import ManagerCard from './ManagerCard';
import PendingManagerCard from './PendingManagerCard';

interface Props {
  companyId: string,
  pendingInvitations: CompanyInvitationType[],
  managers: ManagerType[],
}

function ManagersList(props: Props) {
  const { pendingInvitations, managers, companyId } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const { t } = useTranslation();
  const [
    removeManagerMutation,
    {
      loading: mutationLoading,
    },
  ] = useMutation(REMOVE_MANAGER_MUTATION, {
    refetchQueries: [{ query: COMPANY_QUERY, variables: { company: companyId } }],
  });

  const handleRemove = () => {
    if (!mutationLoading) {
      removeManagerMutation({
        variables: {
          company: companyId,
          manager: currentItem,
        },
      });
    }
    setShowConfirmationModal(false);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const requireConfirmation = (itemId: string) => {
    if (!mutationLoading) {
      setCurrentItem(itemId);
      toggleConfirmationModal();
    }
  };
  return (
    <>
      {!!pendingInvitations.length && (
        <div className="px-2 mb-6 sm:px-0">
          <div className="text-sm leading-6 font-medium ">{t('Pending Invites')}</div>
          <div className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6">
            {pendingInvitations.map((item) => (
              <PendingManagerCard
                key={item.id}
                companyId={companyId}
                invitation={item}
              />
            ))}
          </div>
        </div>
      )}
      {!!managers && (
        <div className="px-2 space-y-2 sm:px-0 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
          {
            managers.map((item) => (
              <ManagerCard
                key={item.id}
                item={item}
                handleRemove={requireConfirmation}
                isOwner={!!item.ownedCompanies.find((companyItem) => companyItem.id === companyId)}
              />
            ))
          }
        </div>
      )}
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleRemove}
        title={t('Remove Manager')}
      />
    </>
  );
}

export default ManagersList;
