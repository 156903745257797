import {
  EyeIcon,
  ThumbUpIcon,
  ExternalLinkIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OfferType } from '../../../../__generated__/graphql';
import { classNames } from '../../../../helpers/utils';
import { TimeAgo } from '../../../../layout';

interface Props {
  item: OfferType,
}

function OfferCard(props: Props) {
  const { item } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-10 grid-flow-col">
        <Link to={`/manager/offer/${item.id}/update/`} className="col-span-9 min-w-0 space-y-3">
          <div className="flex space-x-3">
            <span
              className={classNames(
                item.isActive ? 'bg-green-100' : 'bg-darkerGray',
                'h-4 w-4 rounded-full flex items-center justify-center',
              )}
              aria-hidden="true"
            >
              <span
                className={classNames(
                  item.isActive ? 'bg-green-400' : 'bg-darkerGray',
                  'h-2 w-2 rounded-full',
                )}
              />
            </span>
            <span className="block">
              <h2 className="text-sm font-medium">
                {item.fullTitle}
              </h2>
            </span>
          </div>
        </Link>
        <div className="flex flex-col items-end mb-2">
          <div className="flex">
            <Link
              to={`/manager/offer/${item.id}/update/`}
              className="relative text-sm  font-medium"
            >
              <PencilAltIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </Link>
            <Link
              to={`/offer/${item.id}/`}
              target="_blank"
              rel="noopener noreferrer"
              className="relative text-sm  font-medium"
            >
              <ExternalLinkIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
      <Link to={`/manager/offer/${item.id}/update/`} className="min-w-0 space-y-3">
        <span className="relative group flex items-center space-x-2.5 text-sm ">
          {item.descriptionPreview}
        </span>
      </Link>
      <div className="flex flex-col flex-shrink-0 items-end">
        <p className="flex text-sm space-x-2">
          <span className="font-medium ">
            {t('Created')}
            {' '}
            <TimeAgo date={item.createdAt} />
          </span>
          <span aria-hidden="true">&middot;</span>
          {item.salaryDetails && (
            <>
              <span className="flex font-medium ">
                {item.salaryDetails.valueLabel}
              </span>
              <span aria-hidden="true">&middot;</span>
            </>
          )}
          <Link to={`/manager/activity/views/${item.id}`}>
            <span className="flex font-medium  hover:">
              {item.totalViewsQuantity}
              {' '}
              <EyeIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </span>
          </Link>
          <span aria-hidden="true">&middot;</span>
          <Link to={`/manager/activity/likes/${item.id}`}>
            <span className="flex font-medium  hover:">
              {item.likesQuantity}
              {' '}
              <ThumbUpIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default OfferCard;
