import { useTranslation } from 'react-i18next';
import { Footer } from '../layout';

function DeactivatedCompany() {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen flex justify-center">
      <div className="grid items-center">
        <div className="text-center self-end text-darkestGray">
          <div className="text-2xl leading-6 font-medium ">{t('Sorry')}</div>
          <div className="mt-5 text-xl">
            <p>{t('Your Company is deactivated.')}</p>
            <p>{t('Please contact us to reactivate it.')}</p>
            <a href="mailto:support@sprungbrett-handwerk.de">support@sprungbrett-handwerk.de</a>
          </div>
        </div>
        <div className="self-end">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default DeactivatedCompany;
