import { gql } from '@apollo/client';

export const AVAILABLE_PACKAGE_PRICE_GRID_ITEMS_QUERY = gql`
  query availablePackagePriceGridItems(
      $company: ID!,
      $paymentCycle: PaymentCycle!,
    ) {
    availablePackagePriceGridItems(
        company: $company,
        paymentCycle: $paymentCycle
    ){
      id
      companySize
      paymentCycle
      value
      package{
        id
        title
        currencyCode
        description
      }
    }
  }
`;

export const PACKAGE_PRICE_GRID_ITEM_QUERY = gql`
  query packagePriceGridItem($company: ID!) {
    packagePriceGridItem(company: $company){
      id
      grossValue
      paymentCycle
      value
      package{
        currencyCode
      }
    }
  }
`;
