import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { SOCIAL_AUTH_PROVIDER } from '../../../constants';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setErrorAlert } from '../../../redux/alertSlice';
import SocialButton from './SocialButton';

const fetchingDataErrorLabel = 'Fetching data error. Please try again later.';

interface Props {
  className?: string,
  disabled: boolean,
  label: string,
  handleAuth: (value: { token: string, provider: string }) => void,
}

function GoogleAuthButton(props: Props) {
  const {
    className, label, handleAuth, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => (handleAuth(
      { token: tokenResponse.access_token, provider: SOCIAL_AUTH_PROVIDER.GOOGLE },
    )),
    onError: () => dispatch(setErrorAlert([t(fetchingDataErrorLabel)])),
  });

  return (
    <div className={className || ''}>
      <SocialButton
        onClick={() => login()}
        disabled={disabled}
        label={label}
        iconPath="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48"
      />
    </div>
  );
}

export default GoogleAuthButton;
