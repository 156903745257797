import { classNames } from '../../helpers/utils';

interface Props {
  item: { key?: string, label: string, value: boolean },
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabled?: boolean,
}

function Checkbox(props: Props) {
  const { item, onChange, disabled } = props;
  const className = classNames(disabled ? 'border-gray-900 text-darkestGray' : 'border-lightGray', 'focus:ring-primary h-4 w-4 text-primary border-lightGray rounded cursor-pointer');
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={item.key}
          name={item.key}
          onChange={onChange}
          checked={item.value}
          disabled={disabled}
          type="checkbox"
          className={className}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={item.key} className="font-medium ">
          {item.label}
        </label>
      </div>
    </div>
  );
}

export default Checkbox;
