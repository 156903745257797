import { gql } from '@apollo/client';

export const DISMISS_NOTIFICATION_MUTATION = gql`
mutation dismissNotification($notification: ID!){
  dismissNotification(input:{notification: $notification})
  { response
    errors{
      field
      messages
    }
  }
}
`;
