/* eslint-disable no-undef */
export const CHAT_POLLING_INTERVAL = 15000;
export const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export const ROOT_URL = window.location.origin;
let apiHost;
let googleAppClientId;
let facebookAppClientId;
let sentrySDN;
let paypalClientId;
switch (window.location.hostname) {
  case 'app.sprungbrett-handwerk.de':
    apiHost = 'https://api.app.sprungbrett-handwerk.de';
    sentrySDN = 'https://09051609c80c45bdbb81e947888994ae@o315667.ingest.sentry.io/6273105';
    break;
  case 'handwerk.test01.lux-medien.com':
    apiHost = 'https://api.handwerk.test01.lux-medien.com';
    sentrySDN = 'https://510388608a4b4616a52e537c1245d786@o315667.ingest.sentry.io/6273100';
    break;
  case 'handwerk.dev01.lux-medien.com':
    apiHost = 'https://api.handwerk.dev01.lux-medien.com';
    break;
  default:
    apiHost = 'http://localhost:8000';
    break;
}

switch (window.location.hostname) {
  case 'app.sprungbrett-handwerk.de':
    googleAppClientId = '424007556586-614touae2bs5mb4p8undnd4hst35klu6.apps.googleusercontent.com';
    paypalClientId = 'AR3xZuo0wW9h6sLw0Q6GyWEzEtrde7kZoN41wRjbyeixIo0dW-WtmhpYUZPdLWLoXrrKsr6LRE17Dj-Y';
    break;
  default:
    googleAppClientId = '771048045327-ec1vd4j027fj4bp7hptl5m7v6b3nmjti.apps.googleusercontent.com';
    facebookAppClientId = '2883903711920912';
    paypalClientId = 'AT9HeP2VcBST5lkKGLmwy3Yh2Kme-q_KFT0TOJgPUBc4-rtEXiKEOTIn8MaywWWffN0mwepiPqAw50i0';
    break;
}

export const API_HOST = apiHost;
export const API_URL = `${API_HOST}/graphql/`;
export const GOOGLE_APP_CLIENT_ID = googleAppClientId;
export const FACEBOOK_APP_CLIENT_ID = facebookAppClientId;
export const SENTRY_SDN = sentrySDN;
export const PAYPAL_CLIENT_ID = paypalClientId;
