import { useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import IBAN from 'iban';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FINALIZE_SEPA_PAYMENT_MUTATION } from '../../../../../../api/mutations/ecommerce';
import { LoadingIndicator } from '../../../../../../layout';
import IBANModal from './IBANModal';

interface Props {
  companyId: string,
  refetchQueries: { query: DocumentNode }[],
}

export default function SEPA(props: Props) {
  const { companyId, refetchQueries } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [iban, setIban] = useState('');
  const [ibanError, setIbanError] = useState('');
  const { t } = useTranslation();

  const [
    finalizeSEPAPaymentMutation,
    {
      loading: finalizePaymentLoading,
    }] = useMutation(FINALIZE_SEPA_PAYMENT_MUTATION, {
    refetchQueries,
  });

  const finalizePayment = () => {
    finalizeSEPAPaymentMutation({
      variables: {
        company: companyId,
        iban,
      },
    });
  };

  const handleIBANInput = (ibanInput: string) => {
    if (!IBAN.isValid(ibanInput)) {
      setIbanError(t('Please provide correct IBAN'));
    } else {
      setIbanError('');
      setModalOpen(false);
      setIban(ibanInput);
    }
  };

  useEffect(() => {
    if (iban) {
      finalizePayment();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iban]);

  return (
    <div className="divide-y divide-gray-200 rounded-lg border border-gray-200 hover:shadow-xl">
      {finalizePaymentLoading
        ? (<LoadingIndicator className="flex justify-center items-center h-36" />)
        : (
          <div className="p-6">
            <h2 className="text-lg font-medium leading-6">{t('Pay with')}</h2>
            <div
              tabIndex={0}
              role="button"
              onClick={() => setModalOpen(true)}
              className="cursor-pointer mt-4 block w-full rounded border bg-[#0070ba] py-2 text-xl text-center italic font-semibold text-white hover:text-gray-100"
            >
              SEPA
            </div>
          </div>
        )}
      <IBANModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        ibanError={ibanError}
        handleIBANInput={handleIBANInput}
      />
    </div>
  );
}
