import { MailIcon, PhoneIcon, GlobeAltIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyType, OfferType } from '../../../__generated__/graphql';
import {
  LANGUAGE, DRIVER_LICENSE_TYPE, JOB_TITLE, JOB_QUALIFICATION, IMAGE_SIZE,
} from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { CompanyLogoImage, CompanyProfileImage } from '../../../layout';
import { ApplicantOfferViewTrigger } from '../../offer';
import { AttributeCard } from '../../shared';
import { LikeOfferSection } from '../likes';

interface Props {
  item: OfferType,
  viewerIsApplicant: boolean,
}

export default function SmallScreenDetailCard(props: Props) {
  // Is showed only on small screens.
  const {
    item, viewerIsApplicant,
  } = props;
  const { t } = useTranslation();

  return (
    <div id="styled-scroll" className="p-4 h-full flex flex-col bg-darkerGray overflow-y-auto">
      <div className="flex-1">
        <div className="relative bg-white rounded-xl">
          {viewerIsApplicant && <ApplicantOfferViewTrigger offerId={item.id} />}
          <CompanyLogoImage
            className="w-full aspect-square rounded-lg text-7xl"
            company={item.company as CompanyType}
            imageSize={IMAGE_SIZE.S512}
          />

          <div className="grid gap-4">
            <div className="w-full px-4">
              <div className={classNames(viewerIsApplicant ? '' : 'mt-10', 'grid')}>
                <div>
                  <div>
                    {viewerIsApplicant && <div className="py-6"><LikeOfferSection offerId={item.id} /></div>}
                  </div>
                  <div className="break-words">
                    <div className="font-semibold">{JOB_QUALIFICATION[item.qualification]}</div>
                    <div className="text-xl font-normal">{JOB_TITLE[item.title as keyof typeof JOB_TITLE]}</div>
                  </div>
                  {item.description && (
                    <div className="mt-10 text-left mx-auto ">
                      {item.description.split('\n').map((str, index) => <p className="pb-2" key={index}>{str}</p>)}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-10">
                <dl className="space-y-8">
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Salary')}
                      value={`${item?.salaryDetails?.exactValue} ${item?.salaryDetails?.currencyCode}`}
                    />
                  </dd>
                  {!!item.skills.length && (
                    <dd className="rounded-lg bg-darkerGray">
                      <AttributeCard
                        label={t('Skills')}
                        value={item.skills.map((skill) => skill.name).join(', ')}
                      />
                    </dd>
                  )}
                  {!!item?.driverLicence?.length && (
                    <dd className="rounded-lg bg-darkerGray">
                      <AttributeCard
                        label={t('Driver Licence')}
                        value={item?.driverLicence
                          .map((licence) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE])
                          .join(', ')}
                      />
                    </dd>
                  )}
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Languages')}
                      value={item.languages.map((language) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
                    />
                  </dd>
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Travelling')}
                      value={!item.travellingRequired ? t('Not Required') : t('Required')}
                    />
                  </dd>
                </dl>
              </div>
              <div className="mt-10">
                <Link
                  to={`/company/${item?.company?.id}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm  font-medium"
                >
                  <div className="text-xl font-bold  text-left">
                    {item?.company?.name}
                  </div>
                  <CompanyProfileImage
                    className="w-full aspect-[16/8] object-center object-cover rounded-lg text-5xl"
                    company={item.company as CompanyType}
                    imageSize={IMAGE_SIZE.S512}
                  />
                </Link>
              </div>

              <div className="mt-10 mb-4">
                <div>
                  <div className="text-xl font-bold  text-left">
                    {t('Contacts')}
                  </div>
                  <dl className="space-y-2">
                    {item?.company?.website && (
                      <a target="_blank" href={item?.company?.website} rel="noopener noreferrer">
                        <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                          <GlobeAltIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                          <span className="ml-3">{item?.company?.website}</span>
                        </dd>
                      </a>
                    )}
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <PhoneIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{item?.company?.phone}</span>
                    </dd>
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <MailIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{item?.company?.email}</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
