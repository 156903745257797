import { useTranslation } from 'react-i18next';
import { PaginatedPossibleCompanyMatchType, PossibleCompanyMatchType } from '../../../__generated__/graphql';
import { Pagination } from '../../../layout';
import PossibleMatchCompanyCard from './PossibleMatchCompanyCard';

interface Props {
  items: PaginatedPossibleCompanyMatchType,
  setPage: (value: number) => void,
}

function PossibleMatches(props: Props) {
  const { items, setPage } = props;

  const { t } = useTranslation();

  return (
    <div className="sm:rounded-lg bg-white">
      <main className="mx-auto px-4 pt-8 max-w-7xl">
        <div className="pb-4 px-4 sm:px-6">
          <h1 className="text-2xl font-extrabold tracking-tight ">{t('Possible Matches')}</h1>
          <p className="mt-4 text-base ">
            {t('All these companies are interested in you. Like them to show that you can be a good match!')}
          </p>
        </div>
        <div className="mx-auto pt-2 px-4 sm:px-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {items?.objects?.map((item) => (
              <PossibleMatchCompanyCard key={item?.id} item={item as PossibleCompanyMatchType} />
            ))}
          </div>
          <div className="h-14 mx-4">
            <Pagination
              page={items.page as number}
              hasNext={items.hasNext as boolean}
              hasPrev={items.hasPrev as boolean}
              elidedPageRange={items.elidedPageRange as string[]}
              onPageChange={setPage}
              omitBorder
              omitNumbers
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default PossibleMatches;
