import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { PUBLIC_APPLICANT_QUERY } from '../api/queries/applicants';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { DetailsApplicant, LimitedApplicant } from '../components/applicant/publicApplicant';
import { SmallScreenDetailCard, SmallScreenSearchCard } from '../components/manager/applicants';
import { USER_TYPE, PUBLIC_APPLICANT_TYPE } from '../constants';
import { DashboardLayout } from '../layout/layouts';

function Applicant() {
  const { id } = useParams();
  const {
    data: applicantData,
  } = useQuery(PUBLIC_APPLICANT_QUERY, {
    variables: { applicant: id },
  });

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  let viewerIsManager = false;

  if (userData && userData.currentUser && userData.currentUser.userType === USER_TYPE.MANAGER) {
    viewerIsManager = true;
  }

  return (
    <DashboardLayout>
      {applicantData && applicantData.publicApplicant && (
        <>
          <div className="hidden md:block relative bg-white py-8 sm:rounded-md">
            {applicantData.publicApplicant.__typename === PUBLIC_APPLICANT_TYPE.DETAILS
              ? (
                <DetailsApplicant
                  applicant={applicantData.publicApplicant}
                  viewerIsManager={viewerIsManager}
                />
              )
              : (
                <LimitedApplicant
                  applicant={applicantData.publicApplicant}
                  viewerIsManager={viewerIsManager}
                />
              )}
          </div>
          <div className="md:hidden">
            {applicantData.publicApplicant.__typename === PUBLIC_APPLICANT_TYPE.DETAILS
              ? (
                <SmallScreenDetailCard
                  item={applicantData.publicApplicant}
                  viewerIsApplicant={!viewerIsManager}
                />
              )
              : (
                <SmallScreenSearchCard
                  item={applicantData.publicApplicant}
                  viewerIsApplicant={!viewerIsManager}
                />
              )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default Applicant;
