import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_SETTINGS_MUTATION } from '../../../api/mutations/notificationSettings';
import { NOTIFICATION_SETTINGS_QUERY } from '../../../api/queries/notificationSettings';
import { NOTIFICATION_CHANNELS } from '../../../constants';
import { toTitleCase } from '../../../helpers/utils';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Checkbox } from '../../../layout/fields';
import { setSuccessAlert } from '../../../redux/alertSlice';

function NotificationSettingsForm(props) {
  const { user } = props;
  const [notificationSettings, setNotificationSettings] = useState([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: queryData } = useQuery(NOTIFICATION_SETTINGS_QUERY);
  const [
    settingsMutation,
    {
      data: mutationData,
      loading,
    }] = useMutation(NOTIFICATION_SETTINGS_MUTATION, {
    update: (cache, { data }) => {
      if (data.notificationSettings.response) {
        cache.writeQuery({
          query: NOTIFICATION_SETTINGS_QUERY,
          data: { notificationSettings: data.notificationSettings.response },
        });
      }
    },
  });

  const handleCheckboxChange = (e) => {
    const cpSettings = [...notificationSettings];
    const affectedItem = cpSettings.find((item) => item.key === e.target.name);
    affectedItem.value = !affectedItem.value;
    setNotificationSettings(cpSettings);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    settingsMutation({
      variables: {
        user: user.id,
        id: queryData.notificationSettings.id,
        notificationChannels: notificationSettings.filter(
          (item) => item.value === true,
        ).map((item) => item.key).join(),
      },
    });
  };

  useEffect(() => {
    if (queryData && queryData.notificationSettings) {
      const initialValues = [];
      Object.keys(
        NOTIFICATION_CHANNELS,
      ).map((item) => {
        initialValues.push(
          {
            key: item,
            label: t(toTitleCase(item)),
            value: !!queryData.notificationSettings.notificationChannels.find(
              (fItem) => fItem === item,
            ),
          },
        );
        return null;
      });
      setNotificationSettings(initialValues);
    }
  }, [queryData, t]);

  useEffect(() => {
    if (mutationData) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [mutationData, dispatch, t]);
  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden rounded sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="h3 mb-4 text-lg leading-6 font-medium ">{t('Notification Channels')}</div>
              <fieldset className="space-y-5">
                <Checkbox key="UI" item={{ label: t('Application Interface'), value: true }} disabled />
                {notificationSettings.map((item) => (
                  <Checkbox key={item.key} item={item} onChange={handleCheckboxChange} />
                ))}
              </fieldset>
            </div>

            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              <button
                diasbled={loading.toString()}
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

NotificationSettingsForm.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
};

export default NotificationSettingsForm;
