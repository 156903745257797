import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SearchForOffersButton() {
  const { t } = useTranslation();
  return (
    <Link
      to="/applicant/offers/"
    >
      <span
        className=" bg-darkerGray px-1 border border-transparent rounded-md py-2 text-sm font-medium  hover:bg-lightGray"
      >
        {t('Search for Offers?')}
      </span>
    </Link>
  );
}

export default SearchForOffersButton;
