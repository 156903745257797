import { Footer } from '../../layout';

interface Props {
  label: string,
  code: number,
}

function ErrorPageTempate(props: Props) {
  const { label, code } = props;
  return (
    <div className="min-h-screen flex justify-center">
      <div className="grid items-center">
        <div className="text-center self-end">
          <div className="text-5xl leading-6 font-medium ">{code}</div>
          <div className="mt-5 max-w-xl text-xl ">
            <p>{label}</p>
          </div>
        </div>
        <div className="self-end">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ErrorPageTempate;
