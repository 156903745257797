import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitType } from '../../../../../__generated__/graphql';
import { DELETE_BENEFIT_MUTATION } from '../../../../../api/mutations/benefits';
import { COMPANY_QUERY } from '../../../../../api/queries/companies';
import { Warning } from '../../../../../layout/modals';
import AddBenefitCard from './AddBenefitCard';
import AddBenefitModal from './AddBenefitModal';
import BenefitCard from './BenefitCard';

interface Props {
  companyId: string,
  benefits: BenefitType[],
}

function BenefitsList(props: Props) {
  const { benefits, companyId } = props;
  const [modal, setModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const { t } = useTranslation();
  const refetchQueries = [{ query: COMPANY_QUERY, variables: { company: companyId } }];

  const [
    deleteMutation,
    {
      loading,
    },
  ] = useMutation(DELETE_BENEFIT_MUTATION, {
    refetchQueries,
  });

  const handleDelete = () => {
    if (!loading) {
      deleteMutation({ variables: { benefit: currentItem } });
    }
    setShowConfirmationModal(false);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const requireConfirmation = (itemId: string) => {
    if (!loading) {
      setCurrentItem(itemId);
      toggleConfirmationModal();
    }
  };

  return (
    <div className="px-2 gap-2 grid grid-cols-2 sm:px-0 sm:grid-cols-3 sm:gap-6">
      {!!benefits && (
        benefits.map((item) => (
          <BenefitCard
            key={item.id}
            item={item}
            handleDelete={requireConfirmation}
          />
        ))
      )}
      <AddBenefitCard
        onClick={() => setModal(true)}
      />
      {modal && (
        <AddBenefitModal
          benefits={benefits}
          companyId={companyId}
          viewModal={modal}
          closeModal={() => setModal(false)}
          refetchQueries={refetchQueries}
        />
      )}
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleDelete}
        title={t('Delete Benefit')}
      />
    </div>
  );
}

export default BenefitsList;
