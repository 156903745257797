import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PublicCompanyType, RestrictedMessageThreadType } from '../../../__generated__/graphql';
import { JOB_TITLE, JOB_QUALIFICATION, IMAGE_SIZE } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { CompanyLogoImage } from '../../../layout';

interface Props {
  item: RestrictedMessageThreadType,
  isCurrent: boolean,
  handlePreviewClick: (item: RestrictedMessageThreadType) => void,
}

function PreviewCard(props: Props) {
  const { item, isCurrent, handlePreviewClick } = props;
  const { t } = useTranslation();

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(isCurrent ? 'border-4 border-primary shadow-xl' : 'border-t-lightGray border-l-lightGray shadow-md', 'border hover:shadow-xl px-2 py-4')}
      onClick={() => handlePreviewClick(item)}
    >
      <div className="grid grid-cols-9">
        <div className="col-span-2">
          <CompanyLogoImage
            className="h-14 w-14 rounded-lg text-lg"
            company={item?.offer?.company as PublicCompanyType}
            imageSize={IMAGE_SIZE.S64}
          />
        </div>
        <div className="col-span-5 break-words">
          <div className="font-semibold ">
            {JOB_TITLE[item?.offer?.title as keyof typeof JOB_TITLE]}
          </div>
          <div className="text-sm ">
            {JOB_QUALIFICATION[item?.offer?.qualification as keyof typeof JOB_QUALIFICATION]}
          </div>
        </div>
        <div className="col-span-2 flex flex-col space-y-2 items-center justify-center">
          {!item.isViewed && (
            <span className="shadow-lg inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary text-white">
              {t('Unread')}
            </span>
          )}
          {!item.hasMessages && (
            <span className="shadow-lg inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary text-white">
              {t('New')}
            </span>
          )}
          <Link
            to={`/offer/${item?.offer?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLinkIcon className="h-6 w-6" aria-hidden="true" />
          </Link>
        </div>
      </div>
      <div className="text-primary py-1 text-sm">{item?.offer?.company?.name}</div>
    </div>
  );
}

export default PreviewCard;
