import { gql } from '@apollo/client';

export const CURRENT_NOTIFICATIONS_QUERY = gql`
  query currentNotifications{
    currentNotifications{
      id
      createdAt
      subject
      body
      targetPath
    }
  }
`;
