import { useQuery, useMutation } from '@apollo/client';
import { ThumbUpIcon as ThumbUpIconOutline } from '@heroicons/react/outline';
import { ThumbUpIcon as ThumbUpIconSolid } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APPLICANT_OFFER_LIKE_MUTATION } from '../../../api/mutations/likes';
import { DOES_APPLICANT_LIKE_OFFER_QUERY } from '../../../api/queries/likes';
import { MATCHED_APPLICANT_OFFERS_QUERY } from '../../../api/queries/offers';
import { classNames } from '../../../helpers/utils';

interface Props {
  offerId: string,
}

function LikeOfferSection(props: Props) {
  const { offerId } = props;
  const [offerLiked, setOfferLiked] = useState(undefined);
  const { t } = useTranslation();

  const {
    data: likeData,
  } = useQuery(DOES_APPLICANT_LIKE_OFFER_QUERY, { variables: { offer: offerId } });

  const [
    likeMutation,
    {
      loading: mutationLoading,
    }] = useMutation(APPLICANT_OFFER_LIKE_MUTATION, {
    refetchQueries: [
      { query: DOES_APPLICANT_LIKE_OFFER_QUERY, variables: { offer: offerId } },
      { query: MATCHED_APPLICANT_OFFERS_QUERY, variables: { page: 1 } }],
  });

  const handleButtonClick = () => {
    likeMutation({
      variables: {
        offer: offerId,
      },
    });
  };

  useEffect(() => {
    if (likeData) {
      setOfferLiked(likeData.doesApplicantLikeOffer);
    }
  }, [likeData]);

  if (offerLiked === undefined) {
    // Simulate button space
    return (
      <div
        className="py-2"
      >
        <div className="h-5" />
      </div>
    );
  }
  return (
    <div
      tabIndex={0}
      role="button"
      data-disabled={mutationLoading}
      onClick={() => handleButtonClick()}
      className={classNames(offerLiked ? 'bg-darkerGray hover:bg-darkerGray' : 'bg-primary hover:bg-primary', 'py-2 items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md')}
    >
      {offerLiked
        ? (
          <div className="flex justify-center text-secondary">
            <ThumbUpIconSolid className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span className="flex items-center">
              {t('I like it')}
            </span>
          </div>
        )
        : (
          <div className="flex justify-center text-white">
            <ThumbUpIconOutline className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span className="flex items-center">
              {t('Like')}
            </span>
          </div>
        )}
    </div>
  );
}

export default LikeOfferSection;
