import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  link: string,
}

function ShowProfileButton(props: Props) {
  const { link } = props;
  const { t } = useTranslation();
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="py-2 items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        <div className="flex justify-center">
          <ExternalLinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          <span className="flex items-center">
            {t('Show Profile')}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default ShowProfileButton;
