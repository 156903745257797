import { useTranslation } from 'react-i18next';
import { LocationMap, File } from '..';
import { ApplicantPreferredLocationType, DetailsApplicantType, FileType } from '../../../__generated__/graphql';
import {
  LANGUAGE, DRIVER_LICENSE_TYPE, JOB_QUALIFICATION, JOB_TITLE, IMAGE_SIZE,
} from '../../../constants';
import { getFormattedDate } from '../../../helpers/utils';
import { UserImage } from '../../../layout';
import { LikeApplicantSection } from '../../manager/likes';
import { AttributeCard } from '../../shared';

interface Props {
  applicant: DetailsApplicantType,
  viewerIsManager: boolean,
}

function DetailsApplicant(props: Props) {
  const { applicant, viewerIsManager } = props;
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="col-span-2 mx-auto px-8">
        <div className="grid grid-cols-3">
          <div className="col-span-1">
            <UserImage
              profile={applicant.profile}
              className="w-full border rounded-lg"
              imageSize={IMAGE_SIZE.S512}
            />
            <div>
              {viewerIsManager
                && (
                  <div className="pt-2"><LikeApplicantSection applicantId={applicant.id} /></div>
                )}
            </div>
          </div>
          <div className="pl-4 col-span-2">
            <div className="text-4xl font-extrabold">
              {applicant.profile.fullName}
            </div>
            <div className="flex text-sm font-semibold py-6">
              {t('Birthdate')}
              :
              {' '}
              {getFormattedDate(applicant.birthdate)}
            </div>
            <div>
              <div className=" text-xl font-black">{JOB_QUALIFICATION[applicant.qualification]}</div>
              <div className=" text-2xl font-light">{JOB_TITLE[applicant.title as keyof typeof JOB_TITLE]}</div>
            </div>
          </div>
        </div>
        {applicant.goals && (
          <div className="mt-10 text-left mx-auto ">
            <div className="text-xl font-bold  text-left">
              {t('Goals')}
            </div>
            <div className="grid gap-2">
              {applicant.goals.split('\n').map((str, index) => <p key={index}>{str}</p>)}
            </div>
          </div>
        )}

        {applicant.miscellaneous && (
          <div className="mt-10 text-left mx-auto ">
            <div className="text-xl font-bold  text-left">
              {t('Miscellaneous')}
            </div>
            <div className="grid gap-2">
              {applicant.miscellaneous.split('\n').map((str, index) => <p key={index}>{str}</p>)}
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 mt-10 gap-12">
          <div>
            <span className="text-xl font-bold ">
              {t('Preferred Location')}
              {' '}
              :
              {' '}
              <span className="text-secondaryGray">
                {applicant?.preferredLocation?.city.name}
              </span>
            </span>
            <div>
              <LocationMap
                calculatedLength={
                  applicant?.preferredLocation?.calculatedLength as number
                }
                location={
                  applicant.preferredLocation as ApplicantPreferredLocationType
                }
              />
            </div>
          </div>
          <div className="pt-10 md:pt-0">
            {applicant.cv && (
              <div>
                <span className="text-xl font-bold">{t('CV')}</span>
                <File item={applicant.cv} />
              </div>
            )}
            {!!applicant?.certificates?.length && (
              <div className="pt-10">
                <span className="text-xl font-bold">{t('Certificates')}</span>
                <div className="grid gap-4">
                  {applicant?.certificates?.map((item) => (
                    <File key={item?.id} item={item as FileType} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-8 max-w-7xl">
        <div>
          <div className="font-semibold text-lg">
            {t('About me')}
            <dl className="space-y-8 pt-2">
              {!!applicant?.driverLicence?.length && (
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Driver Licence')}
                    value={applicant?.driverLicence
                      .map((licence) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE])
                      .join(', ')}
                  />
                </dd>
              )}
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={t('Travel activity/assembly')}
                  value={applicant.isOpenForTravelling ? t('Yes') : t('No')}
                />
              </dd>
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={t('Languages')}
                  value={applicant.languages.map((language) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
                />
              </dd>
              {!!applicant.skills.length && (
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Skills')}
                    value={applicant.skills.map((skill) => skill.name).join(', ')}
                  />
                </dd>
              )}
            </dl>
          </div>
          <div className="text-lg pt-10 pb-4 font-semibold">
            {t('Performance')}
            <dl className="space-y-8 pt-2">
              <dd className="rounded-lg bg-darkerGray">
                <AttributeCard
                  label={`${t('Salary')}`}
                  value={`${applicant?.salaryDetails?.exactValue} ${applicant?.salaryDetails?.currencyCode}`}
                />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsApplicant;
