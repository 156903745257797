/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  JOB_QUALIFICATION,
} from '../../../constants';
import {
  zIndexTweakedSelectStyles,
  getLabeledJobTitleOptions,
} from '../../../helpers/utils';
import { Range, AreaRadiusSelect, Select } from '../../../layout/fields';
import { OffersFormikInitialValuesType } from '../../../pages/applicant/offers/Offers';
import InfiniteCitiesSelect from '../InfiniteCitiesSelect';

const labeledJobTitleOptions = getLabeledJobTitleOptions();

const initialQualificationOptions = Object.entries(JOB_QUALIFICATION).map(
  (item) => {
    const [key, value] = item;
    return { value: key, label: value, checked: false };
  },
);

interface Props {
  overlayState: boolean,
  setOverlayState: (value: boolean) => void,
  values: OffersFormikInitialValuesType,
  handleSubmit: () => void,
  setFieldValue: (field: string, value: any) => void,
  skipSmall?: boolean,
  initialSalaryRange: number[],
  ClearFilter: () => void,
}

function MatchingFilterSlideOver(props: Props) {
  const {
    overlayState, setOverlayState, values, handleSubmit,
    setFieldValue, skipSmall, initialSalaryRange, ClearFilter,
  } = props;

  const [qualificationOptions] = useState(initialQualificationOptions);
  const { t } = useTranslation();

  const handleCityChange = (e: SelectOption) => {
    if (e) {
      setFieldValue('city', { label: e.label, value: e.value });
    } else {
      setFieldValue('city', null);
      setFieldValue('areaRadius', null);
    }
  };

  const handleSave = () => {
    handleSubmit();
    setOverlayState(false);
  };

  const refDiv = useRef(null);

  return (
    <Transition.Root show={overlayState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-hidden"
        onClose={setOverlayState}
        initialFocus={refDiv}
      >
        <div ref={refDiv} className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-secondaryGray bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 left-0 pr-10 sm:pr-0 w-full sm:w-1/3 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-9 pt-2 pl-2 flex sm:-mr-10 sm:pl-4">
                    <button
                      type="button"
                      className="bg-primary text-white rounded-md"
                      onClick={() => setOverlayState(false)}
                    >
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-lg w-full sm:p-6 overflow-y-auto h-full overflow-x-auto">
                  <div>
                    <form className="divide-lightGray">
                      <fieldset>
                        <legend className="block text-sm font-medium ">
                          {t('Title')}
                          {` (${t('multiple selection possible')})`}
                        </legend>
                        <div className="pt-3 space-y-3">
                          <Select
                            value={values.title}
                            isClearable
                            isMulti
                            options={labeledJobTitleOptions.map(item => item.options).flat()}
                            onMultiChange={(e) => (e ? setFieldValue('title', e) : setFieldValue('title', []))}
                            // eslint-disable-next-line no-undef
                            menuPortalTarget={document.body}
                            styles={zIndexTweakedSelectStyles}
                          />
                        </div>
                      </fieldset>
                      <div className="pt-4">
                        <fieldset>
                          <legend className="block text-sm font-medium ">
                            {t('Qualification')}
                            {` (${t('multiple selection possible')})`}
                          </legend>
                          <div className="pt-3 space-y-3">
                            <Select
                              value={values.qualification}
                              isClearable
                              isMulti
                              options={qualificationOptions}
                              onMultiChange={(e) => (e ? setFieldValue('qualification', e) : setFieldValue('qualification', []))}
                              // eslint-disable-next-line no-undef
                              menuPortalTarget={document.body}
                              styles={zIndexTweakedSelectStyles}
                            />
                          </div>
                        </fieldset>
                      </div>
                      <div className="pt-4">
                        <fieldset>
                          <legend className="pt-3 block text-sm font-medium ">{t('City')}</legend>
                          <div className="pt-3 space-y-3">
                            <InfiniteCitiesSelect
                              value={
                                values.city
                                  && { label: values.city.label, value: values.city.value } as SelectOption
                                }
                              isClearable
                              skipSmall={skipSmall ? true : false}
                              onChange={handleCityChange}
                            />
                          </div>
                          <div>
                            <legend className="pt-3 text-sm font-medium ">{t('Area Radius')}</legend>
                            <div className="pt-3 space-y-3">
                              <AreaRadiusSelect
                                value={values.areaRadius}
                                isDisabled={!values.city}
                                onChange={(e) => setFieldValue('areaRadius', e)}
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {initialSalaryRange && (
                        <div className="pt-4">
                          <fieldset>
                            <legend className="block text-sm font-medium ">{t('Salary Range (EUR)')}</legend>
                            <div className="pt-2 px-4">
                              <Range
                                min={initialSalaryRange[0]}
                                max={initialSalaryRange[1]}
                                values={values.salaryRange}
                                onChange={(e) => setFieldValue('salaryRange', e)}
                              />
                              {!!values?.salaryRange?.length && (
                              <div className="flex items-center justify-center space-x-4">
                                <input
                                  type="text"
                                  name="salaryMin"
                                  id="salaryMin"
                                  className="shadow-sm focus:ring-primary focus:border-primary block w-20 sm:text-sm border-lightGray rounded-md"
                                  value={values.salaryRange[0]}
                                  onChange={(e) => setFieldValue('salaryRange', [e.target.value, (values && values.salaryRange && values.salaryRange[1])])}
                                />
                                <div>—</div>
                                <input
                                  type="text"
                                  name="salaryMax"
                                  id="salaryMax"
                                  className="shadow-sm focus:ring-primary focus:border-primary block w-20 sm:text-sm border-lightGray rounded-md"
                                  value={values.salaryRange[1]}
                                  onChange={(e) => setFieldValue('salaryRange', [(values && values.salaryRange && values.salaryRange[0]), e.target.value])}
                                />
                              </div>
                              )}
                            </div>
                          </fieldset>
                        </div>
                      )}
                    </form>
                  </div>
                  <div className="mt-5">
                    <div className="flex justify-end space-x-2">
                      <button
                        type="button"
                        className="inline-flex justify-center w-24 rounded-md border border-transparent shadow-sm px-4 py-2 bg-darkestGray font-medium text-white hover:bg-darkestGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkestGray text-sm"
                        onClick={() => ClearFilter()}
                      >
                        {t('Cancel')}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center w-24 rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-sm"
                        onClick={() => handleSave()}
                      >
                        {t('Save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default MatchingFilterSlideOver;
