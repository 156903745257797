interface Props {
  label: string,
  value: string,
}

function AttributeCard(props: Props) {
  const { label, value } = props;
  return (
    <div className="overflow-visible relative shadow-lg rounded-xl">
      <div className="py-2 pr-2 pl-4 absolute w-full h-11 rounded-bl-lg rounded-br-[50px] rounded-tr-lg rounded-tl-lg bg-darkerGray">
        <span className=" font-bold text-base">{label}</span>
      </div>
      <div className="flex flex-col pt-14 pb-2 pl-4 bg-lightGray rounded-xl">
        <span className=" text-sm font-medium">{value}</span>
      </div>
    </div>
  );
}

export default AttributeCard;
