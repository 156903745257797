import { useQuery } from '@apollo/client';
import {
  UserCircleIcon, UserIcon, BriefcaseIcon, OfficeBuildingIcon, TruckIcon,
} from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import Sidebar from '../../../components/settings/Sidebar';
import { USER_TYPE } from '../../../constants';
import LoadingIndicator from '../../LoadingIndicator';
import DashboardLayout from '../DashboardLayout';

interface Props {
  children: ReactNode,
}

function SettingsLayout({ children }: Props) {
  const { data, loading } = useQuery(CURRENT_USER_QUERY);
  if (loading) {
    <LoadingIndicator />;
  }
  if (data && data.currentUser) {
    const user = data.currentUser;
    let navigation = [
      {
        name: 'Account', href: '/settings/account/', icon: UserIcon,
      },
    ];
    if (user.isOnboarded) {
      if (user.userType === USER_TYPE.APPLICANT) {
        navigation = [
          ...navigation,
          {
            name: 'Profile', href: '/settings/profile/', icon: UserCircleIcon,
          },
          {
            name: 'Applicant', href: '/settings/applicant/', icon: BriefcaseIcon,
          },
          {
            name: 'Preferred Working Location', href: '/settings/preferred-location/', icon: TruckIcon,
          },
        ];
      } else if (user.userType === USER_TYPE.MANAGER) {
        navigation = [
          ...navigation,
          {
            name: 'Profile', href: '/settings/profile/', icon: UserCircleIcon,
          },
          {
            name: 'Companies', href: '/settings/companies/', icon: OfficeBuildingIcon,
          },
        ];
      }
    }
    return (
      <DashboardLayout>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <Sidebar
            navigationItems={navigation}
          />
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            { children }
          </div>
        </div>
      </DashboardLayout>
    );
  }
  return '';
}

export default SettingsLayout;
