import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

interface Props {
  values: number[] | undefined,
  min: number,
  max: number,
  onChange: (selectedValue: number[]) => void,
}

function RangeInput(props: Props) {
  const {
    min, max, values, onChange,
  } = props;

  return (
    <Range
      value={values}
      min={min}
      max={max}
      onChange={onChange}
      className="mb-2"
      trackStyle={[{ backgroundColor: '#054787' }]}
      handleStyle={[{ backgroundColor: '#054787' }, { backgroundColor: '#054787' }]}
      dotStyle={{ backgroundColor: '#054787' }}
      activeDotStyle={{ backgroundColor: '#054787' }}
    />
  );
}

export default RangeInput;
