import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!){
      tokenAuth(email: $email, password: $password)
      { 
        token
        refreshToken
      }
  }
`;

export const REFRESH_JWT_MUTATION = gql`
  mutation refreshToken($refreshToken: String!){
    refreshToken(refreshToken: $refreshToken)
    {
      token
      refreshToken
    }
  }
`;

export const REVOKE_REFRESH_TOKEN_MUTATION = gql`
  mutation revokeToken($refreshToken: String!){
    revokeToken(refreshToken: $refreshToken)
    {
      revoked
    }
  }
`;

export const SOCIAL_LOGIN_MUTATION = gql`
  mutation socialLogin(
    $token: String!,
    $provider: String!,
    ){
      socialLogin(input:{
        token: $token,
        provider: $provider,
      }){
        response{
          token
          refreshToken
        }
          errors{
            field
            messages
          }
        }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation register(
    $email: String!,
    $password1: String!,
    $password2: String!,
    $userType: String!,
    ){
      register(input:{
        email: $email,
        password1: $password1,
        password2: $password2,
        userType: $userType
      }){
        response
          errors{
            field
            messages
          }
        }
  }
`;

export const SOCIAL_REGISTER_MUTATION = gql`
  mutation socialRegister(
    $token: String!,
    $provider: String!,
    $userType: String!,
    ){
      socialRegister(input:{
        token: $token,
        provider: $provider,
        userType: $userType
      }){
        response{
          token
          refreshToken
        }
          errors{
            field
            messages
          }
        }
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation userDelete($user: ID!){
    userDelete(input:{
      user: $user
    }){
      response
      errors{
        field
        messages
      }
    }
  }
`;

export const USER_EMAIL_MUTATION = gql`
  mutation emailUser(
    $user: UUID!,
    $email: String!
    ){
    emailUser(input:{
      user: $user
      email: $email
    }){
      response{
        id
        email
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const USER_PASSWORD_MUTATION = gql`
  mutation passwordUser(
    $user: UUID!,
    $password: String!
    ){
      passwordUser(input:{
      user: $user
      password: $password
    }){
      response{
        id
        email
      }
      errors{
        field
        messages
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!){
  resetPassword(input:{email: $email}){ response }
}
`;

export const RESET_PASSWORD_CONFIRMATION_MUTATION = gql`
mutation resetPasswordConfirmation(
  $uid: String!
  $token: String!
  $password: String!
  ){
  resetPasswordConfirmation(
    input:{
      uid: $uid
      token: $token
      password: $password
    })
    {
      response
      errors{
        field
        messages
      }
    }
}
`;

export const CONFIRM_EMAIL_MUTATION = gql`
mutation confirmEmail($token: String!){
  confirmEmail(input:{token: $token}){
    response{
      token
      refreshToken
    }
    errors{
      field
      messages
    }
  }
}
`;
