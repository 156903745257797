import { classNames } from '../../../helpers/utils';

interface Props {
  label: string,
  iconPath: string,
  disabled: boolean,
  onClick: () => void,
}

function SocialButton(props: Props) {
  const {
    label, iconPath, disabled, onClick,
  } = props;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(disabled ? 'bg-secondaryGray border-secondaryGray text-white' : 'bg-white hover:bg-lightGray', 'flex w-full justify-center py-2 px-4 border-2 border-darkerGray rounded-md shadow-sm text-sm font-medium')}
    >
      <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
        <path d={iconPath} />
      </svg>
      <span className="ml-4 flex-1 flex">{label}</span>
    </button>
  );
}

export default SocialButton;
