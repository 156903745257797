import { useQuery } from '@apollo/client';
import {
  SearchCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CURRENT_COMPANY_DASHBOARD_QUERY } from '../../../../api/queries/companies';
import { CURRENT_MANAGER_QUERY } from '../../../../api/queries/managers';
import { SORT_TYPE, FILTER_TYPE, IMAGE_SIZE } from '../../../../constants';
import { CompanyLogoImage } from '../../../../layout';
import OffersFilter from './OffersFilter';
import OffersList from './OffersList';
import OffersSort from './OffersSort';

function CompanyCard() {
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState<string>(FILTER_TYPE.NONE);
  const [orderBy, setOrderBy] = useState<string>(SORT_TYPE.DEFAULT);
  const { data: managerData } = useQuery(CURRENT_MANAGER_QUERY);
  const { data: companyData } = useQuery(CURRENT_COMPANY_DASHBOARD_QUERY);

  const handleActiveClick = () => {
    setFilterBy(FILTER_TYPE.ACTIVE);
  };

  const handleClosedClick = () => {
    setFilterBy(FILTER_TYPE.CLOSED);
  };

  return (
    <>
      {companyData && companyData.currentCompany && (
        <div className="bg-white">
          <div className="pl-4 pr-6 pt-4 pb-2 sm:pl-6 lg:pl-8 ">
            <div className="flex items-center justify-between">
              <div className="flex-1 space-y-8">
                <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center">
                  <div className="flex items-center space-x-3">
                    <CompanyLogoImage
                      company={companyData.currentCompany}
                      className="h-14 w-14 rounded-xl text-2xl"
                      imageSize={IMAGE_SIZE.S64}
                    />
                    <div className="space-y-1">
                      <div className="text-lg font-medium ">{companyData.currentCompany.name}</div>
                      <a href="#" className="group flex items-center space-x-0.5">
                        <span className="text-sm  group-font-medium">
                          {managerData && managerData.currentManager.profile.fullName}
                        </span>
                      </a>
                    </div>
                  </div>
                  {/* Action buttons */}
                  <div className="flex flex-col sm:flex-row">
                    <Link to="/manager/offer/create/">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary xl:w-full"
                      >
                        {t('New Offer')}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-8">
                  {!!companyData.currentCompany.activeOffersQuantity && (
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center space-x-2"
                      onClick={() => handleActiveClick()}
                    >
                      <SearchCircleIcon className="h-5 w-5 " aria-hidden="true" />
                      <span className="text-sm  font-medium">
                        {companyData.currentCompany.activeOffersQuantity}
                        {' '}
                        {t('Active')}
                      </span>
                    </div>
                  )}
                  {!!companyData.currentCompany.closedOffersQuantity && (
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center space-x-2"
                      onClick={() => handleClosedClick()}
                    >
                      <CheckCircleIcon className="h-5 w-5 " aria-hidden="true" />
                      <span className="text-sm  font-medium">
                        {companyData.currentCompany.closedOffersQuantity}
                        {' '}
                        {t('Closed')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Offers List */}
      <div className="bg-white lg:min-w-0 lg:flex-1">
        <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-lightGray sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
          <div className="flex items-center">
            <h1 className="flex-1 text-lg font-medium mr-4">{t('Offers')}</h1>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0">
              <OffersFilter
                filterBy={filterBy}
                setFilterBy={setFilterBy}
              />
              <OffersSort
                orderBy={orderBy as string}
                setOrderBy={setOrderBy}
              />
            </div>
          </div>
        </div>
        {companyData && (
          <OffersList
            filterBy={filterBy as string}
            orderBy={orderBy as string}
          />
        )}
      </div>
    </>
  );
}

export default CompanyCard;
