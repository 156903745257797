import { gql } from '@apollo/client';

export const AREA_CODE_QUERY = gql`
  query areaCode(
    $areaCode: ID!
    ) {
    areaCode(
      areaCode: $areaCode
      ){
        id
        postalCode
    }
  }
`;

export const INLINE_CITIES_QUERY = gql`
  query inlineCities(
    $name: String!
    $skipSmall: Boolean
    $skip: Int
    ) {
    cities(
      name: $name
      skipSmall: $skipSmall
      skip: $skip
      ){
      hasNext
      objects{
        id
        name
        postcodesLabel
      }
    }
  }
`;

export const INLINE_COUNTRIES_QUERY = gql`
  query inlineCountries{
    countries{
      id
      name
    }
  }
`;

export const INLINE_AREA_CODES_QUERY = gql`
  query inlineAreaCodes(
    $city: ID!
    ) {
    areaCodes(
      city: $city
      ){
      postalCode
    }
  }
`;
