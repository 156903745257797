import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY_SIZE } from '../../../constants';

interface Props {
  setPassessChecks: (value: boolean) => void,
  companySize: string,
}

function LegalsConfirmation(props: Props) {
  const { setPassessChecks, companySize } = props;
  const [acceptsLegals, setAcceptsLegals] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setPassessChecks(acceptsLegals);
  }, [acceptsLegals, setPassessChecks]);

  return (
    <div className="space-y-1">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id="legals"
            name="legals"
            onChange={() => setAcceptsLegals(!acceptsLegals)}
            checked={acceptsLegals}
            type="checkbox"
            className="border-secondaryGray focus:ring-primary h-4 w-4 text-primary rounded cursor-pointer"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="legals" className="font-medium">
            {t('I have read and accepted the')}
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sprungbrett-handwerk.de/allgemeine-geschaeftsbedingungen.html"
              className="underline"
            >
              {t('terms and conditions')}
            </a>
            ,
            {' '}
            {t('as well as the')}
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sprungbrett-handwerk.de/sprungbrett-handwerk-preisliste.html"
              className="underline"
            >
              {t('price list')}
            </a>
            {' '}
            {t('read and accepted and the')}
            {' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sprungbrett-handwerk.de/datenschutz.html"
              className="underline"
            >
              {t('privacy policy')}
            </a>
            {' '}
            {t('is noted')}
            .
            {companySize && (
              <>
                {' '}
                {t('Furthermore, I confirm the recorded company size of')}
                {' '}
                {COMPANY_SIZE[companySize as keyof typeof COMPANY_SIZE]}
                {' '}
                {t('employees')}
                .
              </>
            )}
          </label>
        </div>
      </div>
    </div>
  );
}

export default LegalsConfirmation;
