import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { ManagerType } from '../../../../__generated__/graphql';
import { CHANGE_COMPANY_OWNER_MUTATION } from '../../../../api/mutations/companies';
import { COMPANY_QUERY } from '../../../../api/queries/companies';
import { MANAGER_COMPANIES_QUERY } from '../../../../api/queries/managers';
import { updateSelectStylesOnError } from '../../../../helpers/utils';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { Select } from '../../../../layout/fields';
import { setSuccessAlert } from '../../../../redux/alertSlice';

function getInlineOptionsFromCandidates(queryCandidates: ManagerType[]) {
  return queryCandidates.map(
    (item) => (
      {
        label: (item?.profile?.fullName as string),
        value: (item.id as string),
      }
    ),
  );
}

interface Props {
  companyId: string,
  ownerCandidates: ManagerType[],
  unsetOwner: () => void,
}

function OwnershipForm(props: Props) {
  const { companyId, ownerCandidates, unsetOwner } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [
    ownershipMutation,
    {
      data,
      loading,
    },
  ] = useMutation(CHANGE_COMPANY_OWNER_MUTATION, {
    refetchQueries: [
      { query: COMPANY_QUERY, variables: { company: companyId } },
      { query: MANAGER_COMPANIES_QUERY },
    ],
  });

  const schema = yup.object({
    newOwner: yup.string().required(t('Requried')),
  });

  const {
    handleSubmit,
    setFieldValue,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      company: companyId,
      newOwner: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      ownershipMutation(
        {
          variables,
        },
      );
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
      unsetOwner();
    }
  }, [data, dispatch, unsetOwner, t]);

  const ownerCandidatesOptions = getInlineOptionsFromCandidates(ownerCandidates);

  if (data && data.changeCompanyOwner.response) {
    return <Navigate to={`/settings/companies/${companyId}/general/`} />;
  }

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="sm:mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="rounded sm:rounded-none px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-1">

                <div className="py-2">
                  {ownerCandidatesOptions.length
                    ? (
                      <>
                        <label
                          className="block text-sm font-medium "
                        >
                          Transfer Ownership to
                        </label>
                        <Select
                          value={ownerCandidatesOptions.find(
                            (item) => item.value === formikValues.newOwner,
                          )}
                          classNamePrefix="z-50"
                          options={ownerCandidatesOptions}
                          onChange={(e) => setFieldValue('newOwner', e.value)}
                          //@ts-ignore
                          styles={formikErrors.newOwner && updateSelectStylesOnError}
                        />
                      </>
                    )
                    : (
                      <div className="text-sm font-medium ">
                        <div>{t('You have no one to transfer your Company Ownership.')}</div>
                        <div className="mt-4">{t('To add another user of your company, send your invitation code, which you can find under:')}</div>
                        <div>
                          {t('User menu')}
                          {' -> '}
                          {t('Settings')}
                          {' -> '}
                          {t('Company')}
                          {' -> '}
                          {t('General data')}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {!!ownerCandidatesOptions.length && (
              <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
                <button
                  disabled={loading}
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  {t('Save')}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default OwnershipForm;
