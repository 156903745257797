import { gql } from '@apollo/client';

export const INLINE_SKILLS_QUERY = gql`
  query inlineSkills{
    skills{
      id
      name
    }
  }
`;
