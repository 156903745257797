import {
  LocationMarkerIcon, ClockIcon, CurrencyEuroIcon,
} from '@heroicons/react/solid';
import { OfferType } from '../../../__generated__/graphql';
import { JOB_TITLE, JOB_QUALIFICATION, IMAGE_SIZE } from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { CompanyLogoImage, TimeAgo } from '../../../layout';
import { LikeOfferIconButton } from '../likes';

interface Props {
  item: OfferType,
  isCurrent: boolean,
  handlePreviewClick: ((item: OfferType) => void),
}

function PreviewCard(props: Props) {
  const { item, isCurrent, handlePreviewClick } = props;
  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(isCurrent ? 'border-4 border-primary shadow-xl' : 'border-t-lightGray border-l-lightGray shadow-md', 'border hover:shadow-xl px-2 py-4')}
      //@ts-ignore
      onClick={() => handlePreviewClick(item)}
    >
      <div className="grid grid-cols-8">
        <div className="col-span-2">
          <CompanyLogoImage
            className="h-14 w-14 rounded-lg text-lg"
            company={item.company}
            imageSize={IMAGE_SIZE.S64}
          />
        </div>
        <div className="col-span-5 break-words">
          <div className="font-semibold ">
            {JOB_TITLE[item.title as keyof typeof JOB_TITLE]}
          </div>
          <div className="text-sm ">
            {JOB_QUALIFICATION[item.qualification]}
          </div>
        </div>
        <div className="col-span-1 flex items-center">
          <div>
            <LikeOfferIconButton offerId={item.id} className="h-7 w-7" />
          </div>
        </div>
      </div>
      <div className="text-primary py-1 text-sm">{item.company.name}</div>
      <div className="grid grid-cols-3 text-secondaryGray text-xs">
        <div className="flex items-center gap-1">
          <LocationMarkerIcon className="h-5 w-5" />
          {item.useCompanyAddress
            ? item?.company?.address?.city.name
            : item?.address?.city.name}
        </div>
        <div className="flex items-center gap-1">
          <CurrencyEuroIcon className="h-5 w-5" />
          {`${item?.salaryDetails?.exactValue} ${item?.salaryDetails?.currencyCode}`}
        </div>
        <div className="flex items-center gap-1">
          <ClockIcon className="h-5 w-5" />
          <div className="truncate">
            <TimeAgo date={item.createdAt} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewCard;
