import { XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { TimeAgo } from '../../../layout';

interface Props {
  id: string,
  timestamp: string,
  targetPath: string,
  subject: string,
  body: string,
  handleDismiss: (value: string) => void,
  hideNotifications: () => void,
}

function Notification(props: Props) {
  const {
    id, timestamp, subject, body, handleDismiss, targetPath, hideNotifications,
  } = props;

  return (
    <>
      <div className="flex justify-between space-x-3">
        <div className="min-w-0 flex-1">
          <Link to={targetPath} onClick={() => targetPath && hideNotifications()}>
            <div className="block focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-xs text-secondaryGray  truncate"><TimeAgo date={timestamp} /></p>
            </div>
          </Link>
          <XIcon
            onClick={() => handleDismiss(id)}
            className="cursor-pointer flex-1  absolute top-3 right-3 block h-5 w-5 transform -translate-y-1/2 translate-x-1/2 rounded-full"
          />
        </div>
      </div>
      <Link to={targetPath} onClick={() => targetPath && hideNotifications()}>
        <div className="mt-1">
          <p className="text-sm font-semibold ">{subject}</p>
          <p className="mt-1 text-sm ">{body}</p>
        </div>
      </Link>
    </>
  );
}

export default Notification;
