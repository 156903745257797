import moment from 'moment';
import Datetime from 'react-datetime';
import 'moment/locale/de';
import '../../assets/css/plugins/react-datetime.css';

interface Props {
  className: string,
  handleChange: (value: string) => void,
  errorMessage: string,
  value: string,
}

function DateInput(props: Props) {
  const {
    className, handleChange, errorMessage, value,
  } = props;

  const onChange = (e: string | moment.Moment) => {
    const format = 'YYYY-MM-DD';
    if (e) {
      const dateIsValid = moment(e, format, true).isValid();
      if (dateIsValid) {
        handleChange(moment(e).format(format));
      } else {
        handleChange(e as string);
      }
    } else {
      handleChange('');
    }
  };

  const hasError = !!errorMessage || false;
  const inputClasses = hasError
    ? 'appearance-none block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
    : 'appearance-none block w-full px-3 py-2 border-2 border-darkerGray rounded-md shadow-sm placeholder-darkerGray focus:outline-none focus:ring-primary focus:border-primary sm:text-sm';
  return (
    <>
      <Datetime
        initialValue={new Date(value)}
        dateFormat
        timeFormat={false}
        className={className}
        onChange={(e) => onChange(e)}
        closeOnSelect
        inputProps={{ className: inputClasses }}
      />
      {hasError && (
      <p className="mt-2 text-sm text-red-600">
        {errorMessage}
      </p>
      )}
    </>
  );
}

export default DateInput;
