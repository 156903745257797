import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../../../../__generated__/graphql';
import { DASHBOARD_OFFERS_QUERY } from '../../../../api/queries/offers';
import { SORT_TYPE, FILTER_TYPE } from '../../../../constants';
import { Pagination } from '../../../../layout';
import OfferCard from './OfferCard';

interface Props {
  filterBy: string,
  orderBy: string,
}

function OffersList(props: Props) {
  const { filterBy, orderBy } = props;
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const { data, fetchMore } = useQuery(DASHBOARD_OFFERS_QUERY, {
    variables: {
      page: 1,
      filterBy: FILTER_TYPE.NONE,
      orderBy: SORT_TYPE.DEFAULT,
    },
  });

  useEffect(() => {
    fetchMore({
      variables: {
        page,
        filterBy,
        orderBy,
      },
    });
  }, [fetchMore, page, filterBy, orderBy]);

  if (data && data.dashboardOffers) {
    if (!data.dashboardOffers.objects.length) {
      return (
        <div className="p-8">
          <div className="text-2xl py-3">{t('Ready to meet the next member of your team?')}</div>
          <div className="py-2">
            {t('You have not created any job offers yet? Use the')}
            {' '}
            <span className="font-bold">
              &quot;
              {t('New job offer')}
              &quot;
            </span>
            {' '}
            {t('button to easily post a job.')}
          </div>
          <div className="py-2">{t("Occupation, desired qualification and your salary expectations - that's all you need to get started.")}</div>
        </div>
      );
    }

    return (
      <>
        <ul
          className="relative z-0 divide-y divide-lightGray border-b border-lightGray"
        >
          {data.dashboardOffers.objects.map((item: OfferType) => (
            <li
              key={item.id}
              className="relative pl-4 pr-6 py-5 hover:bg-lightGray sm:py-6 sm:pl-6 lg:pl-8"
            >
              <OfferCard
                item={item}
              />
            </li>
          ))}
        </ul>
        <div className="h-14 mx-4">
          <Pagination
            page={data.dashboardOffers.page}
            hasNext={data.dashboardOffers.hasNext}
            hasPrev={data.dashboardOffers.hasPrev}
            elidedPageRange={data.dashboardOffers.elidedPageRange}
            onPageChange={setPage}
            omitBorder
          />
        </div>
      </>
    );
  }
  return '';
}

export default OffersList;
