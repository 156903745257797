import { useTranslation } from 'react-i18next';
import { PaginatedRecommendedOffersType } from '../../../__generated__/graphql';
import { OffersWidget } from '../../shared/offersWidget';

interface Props {
  items: PaginatedRecommendedOffersType,
  setPage: (page: number) => void,
}

function RecommendedOffers(props: Props) {
  const { items, setPage } = props;
  const { t } = useTranslation();

  return (
    <div className="sm:rounded-lg bg-white">
      <main className="mx-auto px-4 pt-8 max-w-7xl">
        <div className="pb-4 px-4 sm:px-6">
          <h1 className="text-2xl font-extrabold tracking-tight ">{t('Recommeded Offers')}</h1>
          <p className="mt-4 text-base ">
            {t('These offers are located around you. Look closer and pick!')}
          </p>
        </div>
        <OffersWidget
          items={items}
          setPage={setPage}
        />
      </main>
    </div>
  );
}

export default RecommendedOffers;
