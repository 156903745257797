import { useQuery, useMutation } from '@apollo/client';
import { ThumbUpIcon as ThumbUpIconOutline } from '@heroicons/react/outline';
import { ThumbUpIcon as ThumbUpIconSolid } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { APPLICANT_OFFER_LIKE_MUTATION } from '../../../api/mutations/likes';
import { DOES_APPLICANT_LIKE_OFFER_QUERY } from '../../../api/queries/likes';
import { MATCHED_APPLICANT_OFFERS_QUERY } from '../../../api/queries/offers';

interface Props {
  offerId: string,
  className: string,
}

function LikeOfferIconButton(props: Props) {
  const { offerId, className } = props;
  const [offerLiked, setOfferLiked] = useState(undefined);

  const {
    data: likeData,
  } = useQuery(DOES_APPLICANT_LIKE_OFFER_QUERY, { variables: { offer: offerId } });

  const [
    likeMutation,
    {
      loading: mutationLoading,
    }] = useMutation(APPLICANT_OFFER_LIKE_MUTATION, {
    refetchQueries: [
      { query: DOES_APPLICANT_LIKE_OFFER_QUERY, variables: { offer: offerId } },
      { query: MATCHED_APPLICANT_OFFERS_QUERY, variables: { page: 1 } }],
  });

  const handleButtonClick = () => {
    likeMutation({
      variables: {
        offer: offerId,
      },
    });
  };

  useEffect(() => {
    if (likeData) {
      setOfferLiked(likeData.doesApplicantLikeOffer);
    }
  }, [likeData]);

  if (offerLiked === undefined) {
    return '';
  }
  return (
    offerLiked
      ? (
        <div
          tabIndex={0}
          role="button"
          data-disabled={mutationLoading}
          onClick={() => handleButtonClick()}
        >
          <div className="flex justify-center text-secondary">
            <ThumbUpIconSolid className={className} />
          </div>
        </div>
      )
      : (
        <div
          tabIndex={0}
          role="button"
          data-disabled={mutationLoading}
          onClick={() => handleButtonClick()}
        >
          <div className="flex justify-center text-secondaryGray">
            <ThumbUpIconOutline className={className} />
          </div>
        </div>
      )

  );
}

export default LikeOfferIconButton;
