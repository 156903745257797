const de = {
  translation: {
    Email: 'E-Mail-Adresse',
    Password: 'Passwort',
    'Confirm Password': 'Passwort bestätigen',
    'Forgot password?': 'Passwort vergessen?',
    'Already registered?': 'Bereits registriert?',
    "Don't have an account?": 'Du hast noch kein Konto?',
    Register: 'Registrieren',
    'Log in': 'Einloggen',
    "I am Manager: I'm looking for applicants.": 'Ich bin Unternehmer: Ich suche Verstärkung.',
    "I am an Applicant: I'm looking for a job.": 'Ich bin Handwerker: Ich suche einen coolen Job.',
    Or: 'Oder',
    'Continue with Google': 'Weiter mit Google',
    'Continue with Facebook': 'Weiter mit Facebook',

    'Email is Required': 'E-Mail ist erforderlich',
    'Password is Required': 'Passwort wird benötigt',
    'Password Confirmation is Required': 'Passwortbestätigung ist erforderlich',
    'User Type is Required': 'Benutzertyp ist erforderlich',
    'Fetching data error. Please try again later.': 'Fehler beim Abrufen von Daten. Bitte versuche es später erneut.',

    'Craftsman Registration': 'Als Handwerker registrieren',
    'Register quickly and easily with Springboard Crafts so that you can find the job that really suits you. What are you waiting for?': 'Registriere Dich schnell und einfach beim Sprungbrett Handwerk, damit Du den Job findest, der wirklich zu Dir passt. Worauf wartest Du noch?',
    'Register a Craft Business': 'Handwerksbetrieb registrieren',

    'Register Business': 'Betrieb registrieren',
    'Register your craft business quickly and easily now, so you can get started right away, create job advertisements and find the right employees for your team.': 'Jetzt schnell und einfach Deinen Handwerksbetrieb registrieren, So kannst Du sofort loslegen, Stellenausschreibungen anlegen und die richtigen Mitarbeiter für Dein Team finden.',
    'Register as a Craftsman instead': 'Stattdessen als Handwerker registrieren',
    'Reset Your Password': 'Setze Dein Passwort zurück',
    'Your new password was applied.': 'Ihr neues Passwort wurde übernommen.',
    'Please use it to log in the app.': 'Bitte melde Dich damit in der App an.',
    'Back to Login page': 'Zurück zur Login-Seite',
    Reset: 'Zurücksetzen',

    'Welcome to Sprungbrett Handwerk!': 'Willkommen bei Sprungbrett Handwerk!',
    'We have sent the confirmation email letter to your email box, used in registration process.': 'Wir haben die Bestätigung an Deine Mail-Adresse gesendet, die Du während der Registrierung angegeben hast.',
    'Please confirm your email by following the link in the confirmation letter.': 'Bitte bestätige Deine E-Mail, indem Du auf den Bestätigungslink klickst.',

    'Your E-mail was successfully confirmed. You can use an application now.': 'Deine E-Mail wurde erfolgreich bestätigt. Du kannst Dich jetzt anmelden.',

    Error: 'Fehler',
    'Bad Request': 'Ungültige Anforderung',
    Unauthorized: 'Nicht autorisiert',
    Forbidden: 'Verboten',
    'Not Found': 'Nicht gefunden',
    'Internal Server Error': 'interner Serverfehler',
    'Bad Gateway': 'Bad Gateway',

    'Please drop your CV here or click this area to select': 'Hier kannst Du Deinen Lebenslauf hin ziehen. Oder klick hier drauf, um eine Datei auszuwählen.',
    'Please drop your Certificates here or click this area to select': 'Zertifizierungen kannst Du hier ablegen. Oder klicken hier drauf und wähle eine Datei aus.',

    Selected: 'Ausgewählt',
    Select: 'Wählen',
    Confirm: 'Bestätige',
    Remove: 'Entfernen',

    'Error(s) in your submission': 'Fehler beim Speichern',

    'Please confirm this action.': 'Bitte bestätigen.',
    'Do you want to proceed?': 'Willst Du fortfahren?',
    Cancel: 'Abbrechen',

    'Email Confirmation': 'E-Mail Bestätigung',
    Onboarding: 'Onboarding',
    Dashboard: 'Dashboard',
    Applicants: 'Handwerker',
    Matched: 'Matches',
    Offers: 'Stellenangebote',
    Settings: 'Einstellungen',
    'Log out': 'Ausloggen',

    'Error(s) occured': ' Fehler sind aufgetreten',

    Previous: 'zurück',
    Next: 'weiter',

    Account: 'Account',
    Profile: 'Nutzerprofil',
    Applicant: 'Handwerker',
    'Preferred Working Location': 'Bevorzugter Arbeitsort',
    Companies: 'Unternehmen',

    Company: 'Unternehmen',
    Title: 'Beruf',
    City: 'Stadt',
    'Salary (EUR)': 'Gehalt (EUR)',
    Actions: 'Aktionen',

    'Company Address': 'Firmenanschrift',

    Requried: 'Erforderlich',
    'Changes were applied.': 'Änderungen wurden übernommen.',
    'First Name': 'Vorname',
    'Last Name': 'Nachname',
    Save: 'Speichern',
    Back: 'Zurück',

    'Your CV': 'Dein Lebenslauf',
    Qualification: 'Qualifikation',
    'Salary Expectations': 'Gehaltsvorstellung (monatlich)',
    Birthdate: 'Geburtstag',
    Languages: 'Sprachen',
    Skills: 'Fähigkeiten',
    '(Newly created Skills will appear once they pass moderation)': '(Neu erstellte Fähigkeiten werden angezeigt, sobald sie es durch unsere Moderation geschafft haben)',
    'Select or Add New...': 'Auswählen oder hinzufügen...',
    'Driver Licence': 'Führerscheinklassen',
    Certificates: 'Zertifizierungen',
    Goals: 'Ziele und Wünsche',
    Miscellaneous: 'Sonstiges',
    'I am open to travel/assembly': 'Ich bin offen für Reisetätigkeiten/Montage', // attribute of Applicant form

    Country: 'Land',

    'You like this Company!': 'Du magst dieses Unternehmen!',
    'Unlike?': 'Unlike?',
    Like: 'Like',
    Unlike: 'Unlike',

    'You like this Offer!': 'Du magst dieses Stellenangebot',

    Offer: 'Stellenangebot',

    Likes: 'Likes',
    Views: 'Views',

    'Company Name': 'Name des Unternehmens',

    'Additinal Company was created.': 'Zusätzliches Unternehmen wurde erstellt.',
    'Create Additional Company': 'Zusätzliches Unternehmen erstellen',

    Phone: 'Telefonnummer',
    'Company Size': 'Unternehmensgröße',
    Branche: 'Branche',
    Website: 'Webseite',
    Description: 'Beschreibung',
    Highlights: 'Highlights',
    'Area Code': 'PLZ',
    'Please select Area Code': 'Bitte PLZ auswählen',
    Street: 'Straße',
    'Logo Image': 'Logo',
    'Profile Image': 'Profilbild',

    'Applicants Activity': 'Aktivitäten',
    'View all activity': 'Alle Aktivitäten anzeigen',

    Liked: 'Gefällt',

    'New Offer': 'Neues Stellenangebot',
    Active: 'Aktiv',
    Closed: 'Inaktiv',

    'Filter by': 'Filtern nach',
    None: 'Nichts',

    'Sort by': 'Sortiere nach',

    Default: 'Standard',

    'Ready to meet the next member of your team?': 'Bereit, das nächste Mitglied in Deinem Team kennenzulernen?',
    'You have not created any job offers yet? Use the': 'Du hast bislang noch keine Stellenangebote erstellt. Benutze den Button',
    'New job offer': 'Neues Stellenangebot',
    'button to easily post a job.': ', um ganz einfach eine Stelle auszuschreiben.',
    "Occupation, desired qualification and your salary expectations - that's all you need to get started.": 'Beruf, gewünschte Qualifikation und Deine Gehaltsvorstellung - das ist alles, was Du brauchst, um loszulegen.',

    Created: 'Erstellt',

    'Offer was permanently deleted.': 'Stelle wurde endgültig gelöscht.',
    Delete: 'Löschen',
    'Delete Offer': 'Stelle löschen',

    'Is Active': 'Ist aktiv',

    'Use Company Address': 'Firmenadresse verwenden',
    'Travel / Assembly': 'Reisetätigkeit / Montage',

    'Invitation Code was successfully applied. You will be notified on invitation review.': 'Einladungscode wurde erfolgreich eingelöst. Du erhältst in Kürze eine Benachrichtigung.',
    'Invitation Code': 'Einladungscode',
    Apply: 'Bestätigen',

    'Join Company Using Code': 'Unternehmen mit Einladungs-Code beitreten',
    'Create New Company': 'Neues Unternehmen erstellen',

    APPROVED: 'GENEHMIGT',
    PENDING: 'STEHT AUS',
    REJECTED: 'ABGELEHNT',
    Owner: 'Eigentümer',

    'Congratulations! You are ready to use an application now.': 'Sauber! Du kannst Sprungbrett Handwerk nun nutzen.',

    Filters: 'Filter',

    'You like this Applicant!': 'Du magst diesen Handwerker!',

    'Change Email': 'E-Mail ändern',
    'You will be forced to login again on success.': 'Du wirst Dich daraufhin neu anmelden müssen.',
    'New Email': 'Neue E-Mail-Adresse',

    'Your Authentication Credentials have been changed. Please login again.': 'Deine Zugangsdaten wurden geändert. Bitte melde Dich erneut an.',
    'Change Password': 'Passwort ändern',

    'Here you can change your account Password.': 'Hier kannst Du Dein Passwort ändern.',
    'New Password': 'Neues Passwort',

    'Your Account was permanently deleted.': 'Dein Konto wurde dauerhaft gelöscht.',
    'Onboarding is not finished': 'Onboarding ist noch nicht abgeschlossen',
    'Please finish the onboarding process in order to get full settings panel access.': 'Bitte schließe den Onboarding-Prozess ab, um den vollständigen Zugriff auf das Profil zu erhalten.',
    'Finish Onboarding': 'Onboarding abschließen',
    'Delete Account': 'Konto löschen',
    'By clicking the button below you will permanently delete your account.': 'Durch Klicken auf die Schaltfläche unten löschst Du Dein Konto endgültig.',

    'Notification Channels': 'Benachrichtigungskanäle',
    'Application Interface': 'Über die Webseite',

    'Return to Chat': 'Zurück zum Chat',
    'There are no messages in this conversation just yet.': 'Noch keine Nachrichten vorhanden.',

    'Search for older messages in Archive': 'Archiv durchsuchen',

    'Open options': 'Optionen',
    'Delete Message': 'Nachricht löschen',

    Contacts: 'Kontakt',

    'Close menu': 'Menü schließen',

    Chat: 'Chat',
    'Chat Archive': 'Chat-Archiv',
    'Here you can browse your chatting history.': 'Hier kannst Du den Chatverlauf durchsuchen.',
    'Please pick the conversation on the left in order to start.': 'Bitte links einen Chat auswählen.',

    'Like job offer or company': 'Stellenangebot oder Betrieb liken',
    'Now like a job offer or a company in the "Dashboard" or under "Job offers". The company will be notified immediately and can also like your profile.': 'Jetzt ein Stellenangebot oder einen Betrieb im "Dashboard" oder unter "Stellenangebote" liken. Der Betrieb wird unmittelbar darüber benachrichtigt und kann Dein Profil ebenfalls liken.',

    Match: 'Match',
    'You will be notified when your profile receives a "Like" from a company. If a job offer or a company is interesting for you, you return the Like and you can chat with each other here.': 'Du wirst darüber benachrichtigt, wenn Dein Profil von einem Betrieb einen "Like" erhält. Ist ein Stellenangebot oder ein Betrieb für Dich interessant, gibst Du den Like zurück und ihr könnt hier miteinander chatten. ',
    'You will be notified when your company or one of your job advertisements receives a "Like" from a craftsman. If the craftsman is interesting for your company, you return a Like and you can chat with each other here.': 'Du wirst darüber benachrichtigt, wenn Dein Betrieb oder eine Deiner Stellenanzeigen von einem Handwerker einen "Like" erhält. Ist der Handwerker für Deinen Betrieb interessant, gibst Du einen Like zurück und ihr könnt hier miteinander chatten.',

    'New job ads?': 'Neue Stellenanzeigen?',
    'As soon as a company you have liked posts a new job, it will be displayed here immediately.': 'Sobald ein von Dir geliketer Betrieb eine neue Stelle ausschreibt, wird Dir diese sofort hier angezeigt.',

    'New job offer?': 'Neue Stellenanzeigen?',
    'If you have more jobs posted and you are connected to a craftsman via a Like, the craftsman will automatically see all of those jobs from you.': 'Wenn Du weitere Stellen ausgeschrieben hast und Du über einen Like mit einem Handwerker verbunden bist, bekommt der Handwerker automatisch alle diese Stellen von dir angezeigt.',

    Tip: 'Tipp',
    'Your profile': 'Dein Profil',
    'Your company profile': 'Dein Unternehmensprofil',
    'You should have created a meaningful profile of yourself. This increases your chances significantly.': 'Du solltest ein aussagekräftiges Profil von Dir erstellt haben. Dies erhöht Deine Chancen deutlich.',
    'You should have created a meaningful company profile. This significantly increases your chances of finding a new member for your team.': 'Du solltest ein aussagekräftiges Unternehmensprofil erstellt haben. Dies erhöht Deine Chancen deutlich, ein neues Mitglied für Dein Team zu finden.',

    'Like, match and get to know each other': 'Like, Match und kennenlernen',

    'In two simple steps you can quickly benefit from Sprungbrett Handwerk.': 'In zwei einfachen Schritten kannst Du hier sehr schnell von Sprungbrett Handwerk profitieren.',
    'The Company that owns these offers likes you and you like these offers!': 'Du magst die Stelle - und die Stelle mag Dich!',
    'Here you can get in touch with company managers.': 'Hier kannst Du mit den Unternehmen unverbindlich chatten.',
    'You have no matches yet.': 'Du hast noch keine Matches.',

    'Available Offers': 'Verfügbare Stellen',
    'You can use filter to get more relevant results.': 'Du kannst den Filter verwenden, um relevantere Ergebnisse zu erhalten.',

    'No records yet in': 'Noch keine',

    'Available Applicants': 'Verfügbare Handwerker',

    'Here you can profit very quickly from Sprungbrett Handwerk.': 'Hier kannst Du hier sehr schnell von Sprungbrett Handwerk profitieren.',

    'You liked these applicants and they liked offers you propose!': 'Diese Handwerker mögen ein Stellenangebot und Du magst sie.',
    'Here You can get in touch with them.': 'Hier kannst Du in Kontakt treten.',

    'Create Offer': 'Stellenangebot erstellen',
    'Update Offer': 'Stellenangebot bearbeiten',

    Notifications: 'Benachrichtigungen',

    'Current company is': 'Aktuelles Unternehmen ist',
    'Change?': 'Veränderung?',
    Invites: 'Einladungen',
    'Pending Invites': 'Ausstehende Einladungen',
    'Join Company via Code': 'Unternehmen per Code beitreten',
    'Leave Company': 'Unternehmen verlassen',

    'Join Company': 'Unternehmen beitreten',

    'Switch Company': 'Unternehmen wechseln',

    'General Data': 'Allgemeine Daten',
    Benefits: 'Leistungen',
    Address: 'Adresse',
    Managers: 'Manager',
    Ownership: 'Inhaberschaft',

    'Add Benefit': 'Vorteil hinzufügen',
    'Benefit Type': 'Vorteils-Variante',
    'Delete Benefit': 'Vorteil löschen',

    Vacation: 'Urlaub',
    'Company Car': 'Firmenwagen',
    'Workwear/PPE': 'Betriebskleidung/PSA',
    'Modern It Equipment': 'Moderne IT-Ausstattung',
    'Capital Accumulation Benefits': 'Vermögenswirksame Leistungen',
    'Further Education': 'Weiterbildung',
    'Career Development': 'Laufbahnentwicklung',
    'Bonus System': 'Bonussystem',
    'Company Activities': 'Unternehmensaktivitäten',
    'Working Hours': 'Arbeitszeit',
    'Leasing Options': 'Leasing-Optionen',
    'Flat Hierarchy': 'Flache Hierarchie',

    'Remove Manager': 'Manager entfernen',

    'Transfer Ownership to': 'Inhaberschaft übertragen an',
    'You have no one to transfer your Company Ownership.': 'Kein weiterer Nutzer vorhanden, um die Inhaberschaft zu übertragen.',

    'Travelling Attitude': 'Einstellung zum Außendienst',
    'Is open for it.': 'Ist dafür offen.',
    "Doesn't consider as an option.": 'Zieht das nicht in Betracht',
    CV: 'Lebenslauf',

    Location: 'Ort',

    Loading: 'Bitte warten',
    'Please fill the form below in order to get started.': 'Formular ausfüllen und loslegen.',

    'Write Something': 'Schreibe etwas',

    'Possible Matches': 'Passende Stellenangebote',
    'These companies are interested in you. Like them to show that you can be a good match!': 'Diese Unternehmen finden Dich interessanten. Zurück liken, um Kontakt aufnehmen zu können!',

    'Salary Range (EUR)': 'Gehaltsspanne (EUR)',
    'Area Radius': 'Umkreis',

    'All these companies are interested in you. Like them to show that you can be a good match!': 'Alle diese Unternehmen interessieren sich für Dich. Einfach liken, und bei einem Match könnt ihr in Kontakt treten.',
    Done: 'Fertig',
    'Show matching options?': 'Passende Optionen anzeigen?',

    'Recommeded Offers': 'Empfohlene Angebote',
    'These offers are located around you. Look closer and pick!': 'Diese Stellen befinden sich in Deiner Nähe. Klick einfach auf ein Stellenangebot, um Dir Details anzeigen zu lassen.',

    'Nothing yet': 'Noch nichts da',
    "Our algorithms haven't found any jobs near you yet - be patient.": 'Unsere Algorithmen haben bislang noch keine Jobs in Deiner Nähe gefunden - ein wenig Geduld noch.',
    'Alternatively, you can already look at jobs from other regions.': 'Alternativ kannst Du Dir bereits jetzt schon Stellenangebote aus anderen Regionen ansehen.',
    'Search for Offers?': 'Jobs suchen?',
    'Search for Applicants?': 'Handwerker suchen?',

    Required: 'Pflichtfeld',

    'Company Branche': 'Standort des Unternehmens',
    'Head Office': 'Sitz des Unternehmens',

    'Not Required': 'Optional',

    Contribution: 'Einbringung',
    'Travel activity/assembly': 'Reisetätigkeit/Montage',
    Yes: 'Ja',
    No: 'Nein',
    Performance: 'Vorstellung',
    Salary: 'Gehalt',
    Month: 'Monat',
    'Preferred Location': 'Bevorzugter Standort',

    Afrikaans: 'Afrikaans',
    Arabic: 'Arabisch',
    Asturian: 'Asturisch',
    Azerbaijani: 'Aserbaidschanisch',
    Bulgarian: 'Bulgarisch',
    Belarusian: 'Belarussisch',
    Bengali: 'Bengali',
    Breton: 'Bretonisch',
    Bosnian: 'Bosnisch',
    Catalan: 'Katalanisch',
    Czech: 'Tschechisch',
    Welsh: 'Walisisch',
    Danish: 'Dänisch',
    German: 'Deutsch',
    'Lower Sorbian': 'Niedersorbisch',
    Greek: 'Griechisch',
    English: 'Englisch',
    Esperanto: 'Esperanto',
    Spanish: 'Spanisch',
    Estonian: 'Estnisch',
    Basque: 'Baskisch',
    Persian: 'Persisch',
    Finnish: 'Finnisch',
    French: 'Französisch',
    Frisian: 'Friesisch',
    Irish: 'Irisch',
    'Scottish Gaelic': 'Schottisch Gälisch',
    Galician: 'Galizisch',
    Hebrew: 'Hebräisch',
    Hindi: 'Hindi',
    Croatian: 'Kroatisch',
    'Upper Sorbian': 'Obersorbisch',
    Hungarian: 'Ungarisch',
    Armenian: 'Armenisch',
    Interlingua: 'Interlingua',
    Indonesian: 'Indonesisch',
    Igbo: 'Igbo',
    Ido: 'Ido',
    Icelandic: 'Isländisch',
    Italian: 'Italienisch',
    Japanese: 'Japanisch',
    Georgian: 'Georgisch',
    Kabyle: 'Kabyle',
    Kazakh: 'Kasachisch',
    Khmer: 'Khmer',
    Kannada: 'Kannada',
    Korean: 'Koreanisch',
    Kyrgyz: 'Kyrgyz',
    Luxembourgish: 'Luxembourgish',
    Lithuanian: 'Litauisch',
    Latvian: 'Lettisch',
    Macedonian: 'Mazedonisch',
    Malayalam: 'Malayalam',
    Mongolian: 'Mongolisch',
    Marathi: 'Marathi',
    Burmese: 'Birmanisch',
    'Norwegian Bokmål': 'Norwegisches Bokmål',
    Nepali: 'Nepalesisch',
    Dutch: 'Niederländisch',
    'Norwegian Nynorsk': 'Norwegisch Nynorsk',
    Ossetic: 'Ossetisch',
    Punjabi: 'Punjabi',
    Polish: 'Polieren',
    Portuguese: 'Portugiesisch',
    Romanian: 'Rumänisch',
    Russian: 'Russisch',
    Slovak: 'Slowakisch',
    Slovenian: 'Slowenisch',
    Albanian: 'Albanisch',
    Serbian: 'Serbisch',
    Swedish: 'Schwedisch',
    Swahili: 'Swahili',
    Tamil: 'Tamil',
    Telugu: 'Telugu',
    Tajik: 'Tadschikisch',
    Thai: 'Thai',
    Turkmen: 'Turkmenen',
    Turkish: 'Türkisch',
    Tatar: 'Tataren',
    Udmurt: 'Udmurtien',
    Ukrainian: 'Ukrainisch',
    Urdu: 'Urdu',
    Uzbek: 'Usbekisch',
    Vietnamese: 'Vietnamesisch',

    'Willingness to travel is required': 'Reisebereitschaft ist erforderlich (Außendienst)',
    'Willingness to travel is not required': 'Reisebereitschaft ist nicht erforderlich (kein Außendienst)',

    'What Defines You': 'Was Dich ausmacht',
    'Your Advantages': 'Deine Stärken',
    'Job Advantages': 'Was wir bieten',
    'Show Profile': 'Profil Anzeigen',
    Published: 'Veröffentlicht',
    Filter: 'Filtern',

    'I like it': 'Gefällt mir',

    'No records found': 'Keine Ergebnisse',

    Imprint: 'Impressum',
    'Privacy Policy': 'Datenschutz',
    'Terms of Service': 'AGB',
    'Price list': 'Preisliste',

    'No activities have taken place so far': 'Bisher fanden noch keine Aktivitäten statt',

    Matches: 'Matches',

    'Company Benefits': 'Mitarbeitervorteile',
    'Company Offers': 'Firmenangebote',
    'About me': 'Über mich',

    'Passwords must match': 'Die Passwörter müssen übereinstimmen',

    'Create User and verify credentials.': 'Benutzer erstellen und Anmeldedaten überprüfen.',
    'Enter your user profile data.': 'Gib Deine Benutzerprofildaten an.',
    'User Profile': 'Nutzerprofil',
    'Fill in the applicant form to get the best results for your requirements.': 'Fülle das Bewerberformular aus, um die besten Ergebnisse für Deine Anforderungen zu erzielen.',
    Craftworker: 'Handwerker',
    'The place where you want to find an offer.': 'Der Ort, an dem Du ein Angebot finden möchtest.',
    'Preferred Place Of Work': 'Bevorzugter Arbeitsort',
    'Create or join the Company you want to manage.': 'Erstellen oder treten Sie dem Unternehmen bei, das Sie verwalten möchten.',

    'Confirm Manager Approval': 'Bestätigen Sie die Manager-Genehmigung',
    'Confirm Manager Rejection': 'Bestätigen Sie die Ablehnung durch den Manager',

    'No options': 'Keine Optionen',

    'Invalid Date': 'Ungültiges Datum',

    'Multiple Files Not Allowed': 'Mehrere Dateien nicht erlaubt',
    'Uploaded File type is not supported': 'Hochgeladener Dateityp wird nicht unterstützt',
    'Maximum File size:': 'Maximale Dateigröße:',
    'Maximum multiple Files size:': 'Maximale Größe mehrerer Dateien:',

    'Next Step': 'Nächster Schritt',

    'Filter by Offer': 'Nach Stellenanzeige filtern',

    'We have sent the new password to your E-mail.': 'Wir haben das neue Passwort an Deine E-Mail gesendet.',

    Unread: 'Ungelesen',
    New: 'Neu',

    Sorry: 'Entschuldigung',
    'Your Company is deactivated.': 'Dein Unternehmen ist deaktiviert.',
    'Please contact us to reactivate it.': 'Bitte kontaktiere uns, um es wieder zu aktivieren.',

    Package: 'Paket',
    'Membership for 1 year with unlimited access': 'Mitgliedschaft für 1 Jahr mit uneingeschränktem Zugriff',
    'Annual fee': 'Jahresbeitrag',
    'Monthly fee': 'Monatsbeitrag',
    'Company Package': 'Firmenpaket',
    'Services Package to your Company.': 'Dienstleistungspaket für Ihr Unternehmen.',
    'Buy package now': 'Paket jetzt kaufen',
    Checkout: 'Auschecken',
    'Please choose the payment option.': 'Bitte wähle Deine Zahlungsoption.',
    'Apply the payment for the Company Package.': 'Wenden Sie die Zahlung für das Unternehmenspaket an.',
    'Pay with': 'Bezahlen mit',
    'Please provide correct IBAN': 'Bitte geben Sie die korrekte IBAN an',
    'Please enter your IBAN and we will send you the SEPA direct debit mandate by e-mail': 'Bitte gib Deine IBAN ein und wir senden Dir das SEPA Lastschriftmandat per E-Mail',

    'In order to delete your account please contact us via email:': 'Um Ihr Konto zu löschen, kontaktieren Sie uns bitte per E-Mail:',

    'Code of Conduct': 'Code of Conduct',

    'Problems with signing in?': 'Probleme mit der Anmeldung?',
    'If you have problems with the registration, then contact us and we will help you.': 'Falls Du Probleme mit der Anmeldung hast, dann kontaktiere uns und wir helfen Dir weiter.',
    "If you can't find an email in your inbox, it may be in your spam folder.": 'Falls Du im Posteingang keine Mail finden kannst, dann befindet sie sich eventuell in Deinem Spam-Ordner.',

    'multiple selection possible': 'Mehrfachauswahl möglich',

    'Reset password': 'Passwort zurücksetzen',

    'To add another user of your company, send your invitation code, which you can find under:': 'Um einen weiteren Nutzer Deines Betriebes hinzuzufügen, versendet euren Einladungscode.',
    'User menu': 'Benutzermenü',
    'General data': 'Allgemeine Daten',

    'corresponds to': 'entspricht',
    Annual: 'Jährlich',
    Monthly: 'Monatlich',
    '+ VAT': '+ USt.',
    'per month': 'pro Monat',

    'I have read and accepted the': 'Ich habe die',
    'terms and conditions': 'AGB',
    'as well as the': 'sowie die',
    'price list': 'Preisliste',
    'read and accepted and the': 'gelesen und akzeptiert und die',
    'privacy policy': 'Datenschutzerklärung',
    'is noted': 'zur Kenntnis genommen',
    'Furthermore, I confirm the recorded company size of': 'Weiterhin bestätige ich die erfasste Unternehmensgröße von',
    employees: 'Mitarbeitern',

    'Craftsman on Sprungbrett Handwerk': 'Handwerker auf Sprungbrett Handwerk',
    'Here you will find various craftsmen looking for jobs in your region. The whole thing is as simple as it looks. You see the profession and the qualification of the craftsman, the place of residence and the desired monthly salary.': 'Hier findest Du verschiedene Handwerker auf Jobsuche aus Deiner Region. Das ganze ist genau so simpel, wie es aussieht. Du siehst den Beruf und die Qualifikation des Handwerkers, den Wohnort und das gewünschte Monatsgehalt.',
    'Next to it you will see a thumb. You click on this thumb if you like a craftsman. A craftsman you already like gets a green thumb. You get details about the craftsman when you click on it. Once both have given the "green thumb", you have a match. Then you see more details, the full name, a profile picture and you can chat with each other. Have fun browsing and good luck with your employee search.': 'Daneben siehst Du einen Daumen. Diesen klickst Du an, wenn Dir ein Handwerker gefällt. Ein Handwerker, den Du schon magst, bekommt einen grünen Daumen. Details zum Handwerker erhältst du, wenn Du drauf klickst. Sobald beide den "grünen Daumen" gegeben haben, habt ihr ein Match. Dann siehst Du mehr Details, den vollen Namen, ein Profilbild und ihr könnt miteinander chatten. Viel Spaß beim Stöbern und viel Erfolg bei der Mitarbeitersuche.',

    'Salary must be an integer.': 'Das Gehalt muss eine ganze Zahl sein.',
    '2024 is completely free and you will not be charged anything. Only from 2025 will we calculate the annual usage fee according to our price structure for your company size.': '2024 ist vollständig kostenfrei und Dir wird nichts berechnet. Erst ab 2025 berechnen wir die jährliche Nutzungsgebühr gemäß unserer Preisstruktur für Deine Unternehmensgröße.',

  },
};

export default de;
