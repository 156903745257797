import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyInvitationType, SimplifiedCompanyType } from '../../__generated__/graphql';
import { LEAVE_COMPANY_MUTATION } from '../../api/mutations/companies';
import { APPLICANT_LIKES_QUERY } from '../../api/queries/activities';
import { UNVIEWED_MESSAGES_QUANTITY_QUERY } from '../../api/queries/chats';
import { CURRENT_COMPANY_DASHBOARD_QUERY } from '../../api/queries/companies';
import { MANAGER_COMPANIES_QUERY } from '../../api/queries/managers';
import { CURRENT_NOTIFICATIONS_QUERY } from '../../api/queries/notifications';
import { DASHBOARD_OFFERS_QUERY } from '../../api/queries/offers';
import {
  CompanyCard, ActionCompanyCard,
  AddCompanyModal, SwitchCompanyModal,
} from '../../components/settings/manager/companies';
import { MODERATION_STATUS, SORT_TYPE, FILTER_TYPE } from '../../constants';
import { SettingsLayout } from '../../layout/layouts/dashboard';
import { Warning } from '../../layout/modals';

const showCreateCompanyOption = false;

function Companies() {
  const [joinCompanyModal, setJoinCompanyModal] = useState(false);
  const [switchCompanyModal, setSwitchCompanyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const { t } = useTranslation();
  const {
    data: managerCompaniesData,
  } = useQuery(MANAGER_COMPANIES_QUERY);

  const [
    leaveMutation,
    {
      loading: mutationLoading,
    },
  ] = useMutation(LEAVE_COMPANY_MUTATION, {
    refetchQueries: [{ query: MANAGER_COMPANIES_QUERY }],
  });

  const handleLeave = () => {
    if (!mutationLoading) {
      leaveMutation({ variables: { company: currentItem } });
    }
    setShowConfirmationModal(false);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const requireConfirmation = (itemId: string) => {
    if (!mutationLoading) {
      setCurrentItem(itemId);
      toggleConfirmationModal();
    }
  };

  let filteredInvites = [];
  if (managerCompaniesData && !!managerCompaniesData.currentManager.invitations.length) {
    filteredInvites = managerCompaniesData.currentManager.invitations.filter(
      (item: CompanyInvitationType) => item.status === MODERATION_STATUS.PENDING,
    );
  }
  return (
    <SettingsLayout>
      {managerCompaniesData && (
        <>
          {managerCompaniesData.currentManager.companies.length > 1 && (
            <div className="group text-sm ">
              {t('Current company is')}
              {' '}
              {managerCompaniesData.currentManager.currentCompany.name}
              .
              {' '}
              <span
                role="button"
                tabIndex={0}
                onClick={() => setSwitchCompanyModal(true)}
                className="cursor-pointer  font-medium"
              >
                {t('Change?')}
              </span>
            </div>
          )}
          {!!filteredInvites.length && (
            <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 sm:grid-cols-2 mb-5">
              <div>
                <div className="text-sm leading-6 font-medium ">{t('Pending Invites')}</div>
                <div className="grid grid-cols-2 gap-6">
                  {filteredInvites.map((item: CompanyInvitationType) => (
                    <div key={item.id} className="col-span-6 sm:col-span-3">
                      <CompanyCard
                        name={item.company.name}
                        owner={item?.company?.owner?.profile?.fullName as string}
                        isReadonly
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {managerCompaniesData.currentManager.companies.map((company: SimplifiedCompanyType) => (
              <CompanyCard
                key={company.id}
                id={company.id}
                name={company.name}
                owner={company?.owner?.profile?.fullName as string}
                handleLeave={requireConfirmation}
              />
            ))}
          </div>

          <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {showCreateCompanyOption && (
              <Link to="/manager/company/create/">
                <ActionCompanyCard
                  title={t('Create Additional Company')}
                  onClick={() => setJoinCompanyModal(true)}
                />
              </Link>
            )}
            <ActionCompanyCard
              title={t('Join Company via Code')}
              onClick={() => setJoinCompanyModal(true)}
            />
          </div>

        </>
      )}
      {joinCompanyModal && (
        <AddCompanyModal
          viewModal={joinCompanyModal}
          closeModal={() => setJoinCompanyModal(false)}
          refetchQueries={[{
            query: MANAGER_COMPANIES_QUERY,
          }]}
        />
      )}
      {switchCompanyModal && (
        <SwitchCompanyModal
          viewModal={switchCompanyModal}
          closeModal={() => setSwitchCompanyModal(false)}
          refetchQueries={[
            { query: CURRENT_COMPANY_DASHBOARD_QUERY },
            { query: MANAGER_COMPANIES_QUERY },
            { query: APPLICANT_LIKES_QUERY },
            { query: CURRENT_NOTIFICATIONS_QUERY },
            { query: UNVIEWED_MESSAGES_QUANTITY_QUERY },
            {
              query: DASHBOARD_OFFERS_QUERY,
              variables: {
                page: 1,
                filterBy: FILTER_TYPE.NONE,
                orderBy: SORT_TYPE.DEFAULT,
              },
            },
          ]}
          companiesList={
            managerCompaniesData.currentManager.companies.filter(
              (item: SimplifiedCompanyType) => item.id !== managerCompaniesData.currentManager.currentCompany.id,
            )
          }
        />
      )}
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleLeave}
        title="Unternehmen verlassen"
      />
    </SettingsLayout>
  );
}

export default Companies;
