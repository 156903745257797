import { useQuery } from '@apollo/client';
import {
  CurrencyEuroIcon, LocationMarkerIcon, MapIcon, CalendarIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitType, SkillType } from '../__generated__/graphql';
import { PUBLIC_OFFER_QUERY } from '../api/queries/offers';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { LikeOfferSection, LikeCompanySection } from '../components/applicant/likes';
import { SmallScreenDetailCard } from '../components/applicant/offers';
import { ApplicantOfferViewTrigger } from '../components/offer';
import { AttributeCard, BenefitPlateCard } from '../components/shared';
import { ShowProfileButton } from '../components/shared/buttons';
import {
  LANGUAGE, USER_TYPE, DRIVER_LICENSE_TYPE,
  JOB_TITLE, JOB_QUALIFICATION,
  IMAGE_SIZE,
} from '../constants';
import { getFormattedDate, classNames } from '../helpers/utils';
import { CompanyLogoImage } from '../layout';
import { DashboardLayout } from '../layout/layouts';

function Offer() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    data: offerData,
  } = useQuery(PUBLIC_OFFER_QUERY, {
    variables: { offer: id },
  });

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  let viewerIsApplicant = false;
  if (userData && userData.currentUser && userData.currentUser.userType === USER_TYPE.APPLICANT) {
    viewerIsApplicant = true;
  }

  return (
    <DashboardLayout>
      {offerData && offerData.publicOffer && (
        <>
          <div className="hidden sm:block relative bg-white py-4 sm:p-8 sm:rounded-md">
            {viewerIsApplicant
              && (<ApplicantOfferViewTrigger offerId={id as string} />
              )}
            <div className="grid grid-cols-12 gap-2">
              <div className={classNames(offerData.publicOffer.description ? 'col-span-12 lg:col-span-6' : 'col-span-11', 'grid grid-cols-6 gap-4')}>
                <div className="col-span-5 flex">
                  <div className="grid">
                    <CompanyLogoImage
                      company={offerData.publicOffer.company}
                      className="max-w-[250px] max-h-[250px] min-w-[200px] min-h-[200px] w-full border rounded-lg text-7xl"
                      imageSize={IMAGE_SIZE.S256}
                    />
                    <div>
                      {viewerIsApplicant
                        && (
                          <div className="pt-2"><LikeOfferSection offerId={id as string} /></div>
                        )}
                    </div>
                  </div>
                  <div className="pl-8">
                    <div className="text-2xl font-bold  ">
                      {JOB_TITLE[offerData.publicOffer.title as keyof typeof JOB_TITLE]}
                    </div>
                    <div className="flex text-primary font-semibold py-2">
                      {offerData.publicOffer.company.name}
                    </div>
                    <dl className="space-y-1">
                      <dd className="flex items-center">
                        <CurrencyEuroIcon className="h-5 w-5 mr-1" />
                        {offerData.publicOffer.salaryDetails.exactValue}
                        {' '}
                        {offerData.publicOffer.salaryDetails.currencyCode}
                      </dd>
                      <dd className="flex items-center">
                        <LocationMarkerIcon className="h-5 w-5 mr-1" />
                        {offerData.publicOffer.useCompanyAddress
                          ? offerData.publicOffer.company.address.city.name
                          : offerData.publicOffer.address.city.name}
                      </dd>
                      <dd className="flex items-center">
                        <MapIcon className="h-5 w-5 mr-1" />
                        {offerData.publicOffer.travellingRequired
                          ? t('Willingness to travel is required')
                          : t('Willingness to travel is not required')}
                      </dd>
                      <dd className="flex items-center">
                        <CalendarIcon className="h-5 w-5 mr-1" />
                        {t('Published')}
                        :
                        {' '}
                        {getFormattedDate(offerData.publicOffer.createdAt)}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              {offerData.publicOffer.description && (
                <div className="col-span-12 lg:col-span-5">
                  {offerData.publicOffer.description.split('\n').map((str: string, index: number) => <p className="pb-2" key={index}>{str}</p>)}
                </div>
              )}
            </div>

            <div className="grid grid-cols-3 gap-10 py-8">
              <div className="font-semibold text-lg col-span-1">
                {t('What Defines You')}
                <dl className="space-y-4 pt-2">
                  {!!offerData.publicOffer.driverLicence.length && (
                    <dd className="rounded-lg bg-darkerGray shadow-md">
                      <AttributeCard
                        label={t('Driver Licence')}
                        value={offerData.publicOffer.driverLicence.map((licence: string) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE]).join(', ')}
                      />
                    </dd>
                  )}
                  <dd className="rounded-lg bg-darkerGray shadow-md">
                    <AttributeCard
                      label={t('Qualification')}
                      value={JOB_QUALIFICATION[offerData.publicOffer.qualification as keyof typeof JOB_QUALIFICATION]}
                    />
                  </dd>
                  <dd className="rounded-lg bg-darkerGray shadow-md">
                    <AttributeCard
                      label={t('Languages')}
                      value={offerData.publicOffer.languages.map((language: string) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
                    />
                  </dd>
                  {!!offerData.publicOffer.skills.length && (
                    <dd className="rounded-lg bg-darkerGray shadow-md">
                      <AttributeCard
                        label={t('Skills')}
                        value={offerData.publicOffer.skills.map((skill: SkillType) => skill.name).join(', ')}
                      />
                    </dd>
                  )}
                </dl>
              </div>
              {!!offerData.publicOffer.company.benefits.length && (
                <div className="font-semibold text-lg col-span-2">
                  {t('Company Benefits')}
                  <div className="grid grid-cols-3 gap-12 pt-2">
                    {offerData.publicOffer.company.benefits.map((benefit: BenefitType) => (
                      <BenefitPlateCard
                        key={benefit.id}
                        benefitType={benefit.benefitType}
                        description={benefit.description}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="pt-8">
              <div className="font-semibold text-lg pt-4">
                {offerData.publicOffer.company.name}
              </div>
              {offerData.publicOffer.company.description && (
                <div className="grid gap-2 pt-4">
                  {offerData.publicOffer.company.description.split('\n').map((str: string, index: number) => <p key={index}>{str}</p>)}
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 pt-2">
              <div className="grid grid-cols-2 gap-3">
                {userData
                  && userData.currentUser
                  && userData.currentUser.userType === USER_TYPE.APPLICANT
                  && (
                    <div>
                      <LikeCompanySection companyId={offerData.publicOffer.company.id} />
                    </div>
                  )}
                <div className="col-span-1">
                  <ShowProfileButton link={`/company/${offerData.publicOffer.company.id}`} />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden">
            <SmallScreenDetailCard
              item={offerData.publicOffer}
              viewerIsApplicant={viewerIsApplicant}
            />
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default Offer;
