import L from 'leaflet';
import {
  MapContainer, Marker, TileLayer, Tooltip,
} from 'react-leaflet';
import '../../assets/css/plugins/leaflet.css';
import icon from '../../assets/images/leaflet/marker-icon-2x-blue.png';

// eslint-disable-next-line new-cap
//@ts-ignore
const markerIcon = new L.icon({
  iconUrl: icon,
  iconSize: [30, 45],
});

interface Props {
  fullAddress: string,
  coordinates: string[],
  height?: string,
}

function AddressMap(props: Props) {
  const { fullAddress, coordinates, height } = props;
  // Prepare coordinates: stringify
  const strCoordinates = coordinates.map((item) => `${item}`);
  const containerHeight = height || '35vh';

  return (
    <MapContainer
      //@ts-ignore
      center={strCoordinates}
      zoom={14}
      scrollWheelZoom={false}
      style={{ height: containerHeight, zIndex: 5 }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker 
        //@ts-ignore
        position={strCoordinates} 
        icon={markerIcon}
      >
        <Tooltip offset={[15, 0]}>{fullAddress}</Tooltip>
      </Marker>
    </MapContainer>
  );
}

export default AddressMap;
