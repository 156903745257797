import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENT_USER_QUERY } from '../../api/queries/users';
import {
  ChangeEmail, ChangePassword,
  DeleteAccount, NotificationSettingsForm,
} from '../../components/settings/account';
import { classNames } from '../../helpers/utils';
import { Select } from '../../layout/fields';
import { SettingsLayout } from '../../layout/layouts/dashboard';

function getTabs(isOperational: boolean) {
  if (isOperational) {
    return [
      { label: 'Notifications', value: 'notifications' },
      { label: 'Email', value: 'email' },
      { label: 'Password', value: 'password' },
      { label: 'Delete Account', value: 'delete-account' },
    ];
  }
  return [
    { label: 'Delete Account', value: 'delete-account' },
  ];
}

function Account() {
  const [tab, setTab] = useState('');
  const [tabs, setTabs] = useState<SelectOption[]>([]);
  const { t } = useTranslation();
  const { data } = useQuery(CURRENT_USER_QUERY);

  useEffect(() => {
    if (data && data.currentUser) {
      setTabs(getTabs(data.currentUser.isOperational));
      if (data.currentUser.isOperational) {
        setTab('notifications');
      } else { setTab('delete-account'); }
    }
  }, [data]);

  const translatedTabs = tabs?.map((item) => (
    {
      ...item,
      label: t(item.label),
    }
  ));

  return (
    <SettingsLayout>
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="sm:hidden px-2">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            {/* @ts-ignore */}
            <Select
              value={translatedTabs?.find((item) => item.value === tab)}
              onChange={(e) => setTab(e.value as string)}
              options={translatedTabs}
            >
              {translatedTabs?.map((item) => (
                <option key={item.value as string}>{item.label}</option>
              ))}
            </Select>
          </div>
          <div className="hidden sm:overflow-hidden sm:block sm:rounded-md">
            <nav className="relative z-0 rounded-lg shadow flex divide-x divide-lightGray" aria-label="Tabs">
              {translatedTabs?.map((item, tabIdx) => (
                <div
                  onClick={() => setTab(item.value as string)}
                  key={item.label}
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    item.value === tab ? '' : ' hover:',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-lightGray focus:z-10',
                  )}
                  aria-current={item.value === tab ? 'page' : undefined}
                >
                  <span>
                    {item.label}
                  </span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      item.value === tab ? 'bg-primary' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    )}
                  />
                </div>
              ))}
            </nav>
          </div>
          {data && data.currentUser && (
            <div className="sm:mt-4">
              {tab === 'notifications'
                && (
                  <NotificationSettingsForm user={data.currentUser} />
                )}
              {tab === 'email'
                && (
                  <ChangeEmail user={data.currentUser} />
                )}
              {tab === 'password'
                && (
                  <ChangePassword user={data.currentUser} />
                )}
              {tab === 'delete-account'
                && (
                  <DeleteAccount user={data.currentUser} />
                )}
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  );
}

export default Account;
