import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { LOGIN_MUTATION, SOCIAL_LOGIN_MUTATION } from '../../api/mutations/users';
import { SocialAuth } from '../../components/auth/social';
import SupportContactSection from '../../components/auth/SupportContactSection';
import { SOCIAL_AUTH_TYPE } from '../../constants';
import { setAuthCookies } from '../../helpers/auth';
import { Input } from '../../layout/fields';
import { AuthLayout } from '../../layout/layouts';

function Login() {
  const { t } = useTranslation();
  const googleButtonLabel = t('Continue with Google');
  const facebookButtonLabel = t('Continue with Facebook');

  const anySocialLoginEnabled = true;

  const [
    login,
    {
      data: loginData,
      loading: loginLoading,
    }] = useMutation(LOGIN_MUTATION);

  const [
    socialLoginMutation,
    {
      data: socialData,
      loading: socialLoading,
    },
  ] = useMutation(SOCIAL_LOGIN_MUTATION);

  const schema = yup.object({
    email: yup.string().required(t('Email is Required')),
    password: yup.string().required(t('Password is Required')),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      login({ variables });
    },
  });

  useEffect(() => {
    if (loginData && loginData.tokenAuth && loginData.tokenAuth.token) {
      setAuthCookies(loginData.tokenAuth.token, loginData.tokenAuth.refreshToken);
      window.location.href = '/';
    }
  }, [loginData]);

  useEffect(() => {
    if (socialData) {
      if (socialData && socialData.socialLogin && socialData.socialLogin.response) {
        setAuthCookies(
          socialData.socialLogin.response.token,
          socialData.socialLogin.response.refreshToken,
        );
        window.location.href = '/';
      }
    }
  }, [socialData]);

  return (
    <AuthLayout>
      <h2 className="my-6 text-2xl font-extrabold ">{t('Log in')}</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            //@ts-ignore
            errorMessage={touched.email && formikErrors.email}
            label={t('Email')}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            //@ts-ignore
            errorMessage={touched.password && formikErrors.password}
            label={t('Password')}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1 sm:space-y-0 sm:flex sm:justify-between">
          <div className="text-sm">
            <Link
              to="/authentication/register/applicant/"
              className="font-medium text-primary hover:text-primary transition duration-150"
            >
              {t("Don't have an account?")}
            </Link>
          </div>
          <div className="text-sm">
            <Link
              to="/authentication/reset"
              className="font-medium text-primary hover:text-primary transition duration-150"
            >
              {t('Forgot password?')}
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={(loginLoading || socialLoading) && true}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary transition duration-150 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {t('Log in')}
          </button>
        </div>
      </form>
      {anySocialLoginEnabled && (
        <>
          <div className="my-6 relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-lightGray" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white ">{t('Or')}</span>
            </div>
          </div>

          <div>
            <SocialAuth
              executeMutation={socialLoginMutation}
              googleButtonLabel={googleButtonLabel}
              facebookButtonLabel={facebookButtonLabel}
              authType={SOCIAL_AUTH_TYPE.LOGIN}
            />
          </div>
        </>
      )}

      <div>
        <SupportContactSection />
      </div>
    </AuthLayout>
  );
}

export default Login;
