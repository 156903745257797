import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { OfferType } from '../../../__generated__/graphql';
import {
  classNames,
} from '../../../helpers/utils';
import { Select } from '../../../layout/fields';

interface Props {
  tab: string,
  likesQuantity: number | undefined,
  viewsQuantity: number | undefined,
  offers: OfferType[],
  currentOffer: string,
}

function Tabs(props: Props) {
  const {
    tab, viewsQuantity, likesQuantity, offers, currentOffer,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filterOptions = offers && offers.length
    ? offers.map((item) => (
      {
        label: item.fullTitle,
        value: item.id,
      } as SelectOption
    ))
    : [];

  const tabs = [
    {
      label: 'Likes', value: 'likes', count: likesQuantity,
    },
    {
      label: 'Views', value: 'views', count: viewsQuantity,
    },
  ];

  const onOfferChange = (value: string) => {
    navigate(`/manager/activity/${tab}/${value}`);
  };

  return (
    <>
      <div className="sm:hidden">
        <Select
          className="mt-4"
          value={tabs.find((item) => item.value === tab)}
          options={tabs}
          onChange={(e) => navigate(`/manager/activity/${e.value}/`)}
        />
        <Select
          className="mt-2"
          placeholder={t('Filter by Offer')}
          isClearable
          options={filterOptions}
          value={filterOptions.find((item) => item.value === currentOffer)}
          onChange={(e) => onOfferChange(e ? e.value as string : '')}
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-lightGray">
          <div className="grid grid-cols-3">
            <div className="col-span-2">
              <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((item) => (
                  <Link
                    key={item.value}
                    to={`/manager/activity/${item.value}/${currentOffer}`}
                    className={classNames(
                      item.value === tab
                        ? 'border-primary text-primary'
                        : 'border-transparent  hover:border-lightGray',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                    )}
                  >
                    {t(item.label)}
                    {item.count ? (
                      <span
                        className={classNames(
                          item.value === tab ? 'bg-lightGray' : 'bg-darkerGray ',
                          'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
                        )}
                      >
                        {item.count}
                      </span>
                    ) : null}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="mt-4">
              <Select
                placeholder={t('Filter by Offer')}
                options={filterOptions}
                isClearable
                value={filterOptions.find((item) => item.value === currentOffer)}
                onChange={(e) => onOfferChange(e ? e.value as string : '')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabs;
