import { gql } from '@apollo/client';

export const DOES_APPLICANT_LIKE_OFFER_QUERY = gql`
  query doesApplicantLikeOffer($offer: ID!) {
    doesApplicantLikeOffer(offer: $offer)
  }
`;

export const DOES_APPLICANT_LIKE_COMPANY_QUERY = gql`
  query doesApplicantLikeCompany($company: ID!) {
    doesApplicantLikeCompany(company: $company)
  }
`;

export const DOES_COMPANY_LIKE_APPLICANT_QUERY = gql`
  query doesCompanyLikeApplicant($applicant: ID!) {
    doesCompanyLikeApplicant(applicant: $applicant)
  }
`;

export const MATCHED_APPLICANT_OFFER_LIKES_QUERY = gql`
query matchedApplicantOfferLikes(
  $page: Int
){
  matchedApplicantOfferLikes(
    page: $page
  ){
    page
    pages
    hasNext
    hasPrev
    elidedPageRange
    totalObjectsQuantity
    objects{
      id
      applicant{
        id
        title
        qualification
        profile{
          id
          fullName
          image
        }
        user{
          id
          email
        }
      }
      offer{
        id
        title
        qualification
        company{
          id
          name
          logoImage
        }
        salaryDetails{
          id
          exactValue
          currencyCode
        }
      }
    }
  }
}
`;
