import { useTranslation } from 'react-i18next';
import { SORT_TYPE } from '../../../../constants';
import { getInlineOptionsFromEnumDict } from '../../../../helpers/utils';
import { Select } from '../../../../layout/fields';

const sortOptions = getInlineOptionsFromEnumDict(SORT_TYPE);

interface Props {
  orderBy: string,
  setOrderBy: (value: string) => void,
}

function OffersSort(props: Props) {
  const { orderBy, setOrderBy } = props;
  const { t } = useTranslation();

  const handleClick = (e: SelectOption) => {
    if (e) {
      setOrderBy(e.value as string);
    } else {
      setOrderBy(SORT_TYPE.DEFAULT);
    }
  };

  const translatedSortOptions = sortOptions.map(
    (item) => ({ value: item.value, label: t(item.label) }),
  );

  return (
    <div className="relative w-40">
      <Select
        value={orderBy !== SORT_TYPE.DEFAULT
          ? translatedSortOptions.find((item) => item.value === orderBy) : undefined}
        isClearable
        options={translatedSortOptions}
        onChange={handleClick}
        placeholder={`${t('Sort by')}...`}
      />
    </div>
  );
}

export default OffersSort;
