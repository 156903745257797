import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { de, en } from './locales';

let resources;
let debug;
switch (window.location.hostname) {
  case 'app.sprungbrett-handwerk.de':
    resources = {
      de,
    };
    debug = false;
    break;
  default:
    resources = {
      en,
      de,
    };
    debug = true;
    break;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'de',
    debug,
  });

export default i18n;
