import { gql } from '@apollo/client';

export const COMPANY_INVITATIONS_QUERY = gql`
  query companyInvitations{
    companyInvitations{
      id
      status
      company{
        id
        name
        owner{
          id
          profile{
            id
            fullName
          }
        }
      }
    }
  }
`;
