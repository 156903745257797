import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import { CONFIRM_EMAIL_MUTATION } from '../../api/mutations/users';
import { setAuthCookies, clearAuthCookies } from '../../helpers/auth';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setSuccessAlert } from '../../redux/alertSlice';

function EmailConfirmationTokenProcessor() {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [confirmEmail,
    {
      data,
      error,
    }] = useMutation(CONFIRM_EMAIL_MUTATION);

  useEffect(() => {
    confirmEmail({ variables: { token } });
  }, [confirmEmail, token]);

  if (data && data.confirmEmail.response && data.confirmEmail.response.token) {
    setAuthCookies(data.confirmEmail.response.token, data.confirmEmail.response.refreshToken);
    dispatch(setSuccessAlert(
      [`${t('Your E-mail was successfully confirmed. You can use an application now.')}`],
    ));
    return <Navigate to="/" />;
  }
  if (error || (data && data.confirmEmail && data.confirmEmail.errors.length)) {
    clearAuthCookies();
    return <Navigate to="/authentication/login" />;
  }
  return '';
}

export default EmailConfirmationTokenProcessor;
