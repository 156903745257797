import { useTranslation } from 'react-i18next';
import { default as ReactSelect } from 'react-select';
import type { CSSObjectWithLabel } from 'react-select';
import {
  updateSelectTheme,
} from '../../helpers/utils';

interface Props {
  value?: (SelectOption | null) | (SelectOption[] | null),
  onChange?: (value: SelectOption ) => void,
  onMultiChange?: (value: SelectOption[]) => void,
  isClearable?: boolean,
  isDisabled?: boolean,
  isMulti?: boolean,
  isLoading?: boolean,
  className?: string,
  classNamePrefix?: string,
  options?: SelectOption[] | { label: string, options: SelectOption[] }[],
  styles?: {
    control?: any,
    menuPortal?: (base: CSSObjectWithLabel) => CSSObjectWithLabel,
  },
  menuPortalTarget?: HTMLElement,
  placeholder?: string,
  onInputChange?: (value: string) => void,
  errorMessage?: string,
}

function Select(props: Props) {
  const {
    value, isMulti, isClearable, isDisabled, className, options, onChange, onMultiChange, styles, menuPortalTarget,
    placeholder, onInputChange, classNamePrefix, errorMessage, isLoading,
  } = props;
  const { t } = useTranslation();

  const hasError = !!errorMessage || false;

  return (
    <>
      <ReactSelect
        placeholder={placeholder || `${t('Select')}...`}
        value={value || null}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        className={className || ''}
        classNamePrefix={classNamePrefix || ''}
        options={options || []}
        //@ts-ignore
        onChange={isMulti ? onMultiChange : onChange}
        onInputChange={onInputChange}
        theme={(theme) => updateSelectTheme(theme)}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={() => (t('No options'))}
        styles={styles}
      />
      {hasError && (
        <p className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </>
  );
}

export default Select;
