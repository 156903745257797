import { useMutation } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { DELETE_OFFER_MUTATION } from '../../../api/mutations/offers';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import LoadingIndicator from '../../../layout/LoadingIndicator';
import { Warning } from '../../../layout/modals';
import { setSuccessAlert } from '../../../redux/alertSlice';

interface Props {
  offerId: string,
  offerTitle: string,
  refetchQueries: ({ query: DocumentNode, variables: { page: number, filterBy: string, orderBy: string } } | { query: DocumentNode })[],
}

function DeleteOffer(props: Props) {
  const { offerId, offerTitle, refetchQueries } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    deleteOfferMutation,
    {
      data: deleteOfferData,
      loading: deleteOfferLoading,
    }] = useMutation(DELETE_OFFER_MUTATION, {
    refetchQueries,
  });

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  function requireConfirmation() {
    toggleConfirmationModal();
  }

  const handleDelete = () => {
    deleteOfferMutation({
      variables: {
        offer: offerId,
      },
    });
  };

  useEffect(() => {
    if (deleteOfferData) {
      dispatch(setSuccessAlert(
        [`${offerTitle} ${t('Offer was permanently deleted.')}`],
      ));
    }
  }, [deleteOfferData, deleteOfferMutation, dispatch, offerTitle, t]);

  if (deleteOfferData) {
    return <Navigate to="/" />;
  }

  return (deleteOfferLoading
    ? (
      <LoadingIndicator className="flex justify-start py-4" />

    )
    : (
      <div className="flex justify-start">
        <button
          type="button"
          onClick={requireConfirmation}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
        >
          {t('Delete')}
        </button>
        <Warning
          viewModal={showConfirmationModal}
          toggleModal={toggleConfirmationModal}
          actionTrigger={handleDelete}
          title={t('Delete Offer')}
        />
      </div>
    ));
}

DeleteOffer.propTypes = {
  offerId: PropTypes.string,
  offerTitle: PropTypes.string,
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
};

export default DeleteOffer;
