import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import {
  classNames,
} from '../helpers/utils';

interface Props {
  page: number,
  hasNext: boolean,
  hasPrev: boolean,
  elidedPageRange: string[],
  onPageChange: (page: number) => void,
  omitBorder?: boolean,
  omitNumbers?: boolean,
}

function Pagination(props: Props) {
  const { t } = useTranslation();
  const {
    page, hasNext, hasPrev, elidedPageRange, onPageChange, omitBorder, omitNumbers,
  } = props;

  const handleClick = (item: number) => {
    if (!!Number(item) === true) {
      onPageChange(Number(item));
    }
  };
  return (
    <nav
      className={classNames(omitBorder ? '' : 'border-t', 'border-lightGray px-4 flex items-center justify-between sm:px-0')}
      aria-label="Pagination"
    >
      <div className="-mt-px w-0 flex-1 flex">
        {hasPrev && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleClick(Number(page) - 1)}
            className={classNames(omitNumbers ? '' : 'border-t-2', 'border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium  hover:border-lightGray')}
          >
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 " aria-hidden="true" />
            {t('Previous')}
          </div>
        )}
      </div>

      {!omitNumbers && (
        <div className="hidden md:-mt-px md:flex">
          {elidedPageRange.length > 1 && elidedPageRange.map((item, index) => (
            <div
              key={index}
              tabIndex={0}
              role="button"
              onClick={() => handleClick(Number(item))}
              className={classNames(
                item === page.toString()
                  ? 'border-primary text-primary border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
                  : 'border-transparent  hover:border-lightGray border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                '',
              )}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      <div className="-mt-px w-0 flex-1 flex justify-end">
        {hasNext && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleClick(Number(page) + 1)}
            className={classNames(omitNumbers ? '' : 'border-t-2', ' border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium  hover:border-lightGray')}
          >
            {t('Next')}
            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 " aria-hidden="true" />
          </div>
        )}
      </div>
    </nav>
  );
}

export default Pagination;
