/* eslint-disable no-nested-ternary */
import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ApplicantOnboardingStatusType, ManagerOnboardingStatusType } from '../../../__generated__/graphql';
import { USER_TYPE, ONBOARDING_STEP_STATUS } from '../../../constants';
import { classNames } from '../../../helpers/utils';

function getSteps(onboardingStatus: ApplicantOnboardingStatusType | ManagerOnboardingStatusType, userType: string) {
  const steps = [
    {
      name: 'Account', description: 'Create User and verify credentials.', status: ONBOARDING_STEP_STATUS.COMPLETE,
    },
    {
      name: 'User Profile',
      description: 'Enter your user profile data.',
      status: !onboardingStatus.hasProfile
        ? ONBOARDING_STEP_STATUS.CURRENT : ONBOARDING_STEP_STATUS.COMPLETE,
    },
  ];

  type RestStepType = { name: string, description: string, status: string }[];
  let restSteps: RestStepType = [];

  if (userType === USER_TYPE.APPLICANT) {
    const applicantOnboardingStatus = onboardingStatus as ApplicantOnboardingStatusType;
    let applicantStatus = ONBOARDING_STEP_STATUS.UPCOMING;
    if (applicantOnboardingStatus.hasApplicant) {
      applicantStatus = ONBOARDING_STEP_STATUS.COMPLETE;
    } else if (onboardingStatus.hasProfile && !applicantOnboardingStatus.hasApplicant) {
      applicantStatus = ONBOARDING_STEP_STATUS.CURRENT;
    }

    const addressStatus = applicantOnboardingStatus.hasApplicant
      ? ONBOARDING_STEP_STATUS.CURRENT : ONBOARDING_STEP_STATUS.UPCOMING;
    restSteps = [
      {
        name: 'Craftworker', description: 'Fill in the applicant form to get the best results for your requirements.', status: applicantStatus,
      },
      {
        name: 'Preferred Place Of Work', description: 'The place where you want to find an offer.', status: addressStatus,
      },
    ];
  }
  if (userType === USER_TYPE.MANAGER) {
    const managerOnboardingStatus = onboardingStatus as ManagerOnboardingStatusType;
    let companyStatus = ONBOARDING_STEP_STATUS.UPCOMING;
    let packageStatus = ONBOARDING_STEP_STATUS.UPCOMING;
    const checkoutStatus = managerOnboardingStatus.hasCompanyPackage
      ? ONBOARDING_STEP_STATUS.CURRENT : ONBOARDING_STEP_STATUS.UPCOMING;

    if (managerOnboardingStatus.hasCompany) {
      companyStatus = ONBOARDING_STEP_STATUS.COMPLETE;
    } else if (onboardingStatus.hasProfile && !managerOnboardingStatus.hasCompany) {
      companyStatus = ONBOARDING_STEP_STATUS.CURRENT;
    }

    if (managerOnboardingStatus.hasCompanyPackage) {
      packageStatus = ONBOARDING_STEP_STATUS.COMPLETE;
    } else if (onboardingStatus.hasProfile
      && managerOnboardingStatus.hasCompany
      && !managerOnboardingStatus.hasCompanyPackage
    ) {
      packageStatus = ONBOARDING_STEP_STATUS.CURRENT;
    }

    restSteps = [
      {
        name: 'Company', description: 'Create or join the Company you want to manage.', status: companyStatus,
      },
      {
        name: 'Company Package', description: 'Services Package to your Company.', status: packageStatus,
      },
      {
        name: 'Checkout', description: 'Apply the payment for the Company Package.', status: checkoutStatus,
      },
    ];
  }
  return [...steps, ...restSteps];
}

interface Props {
  onboardingStatus: ApplicantOnboardingStatusType | ManagerOnboardingStatusType,
  userType: string,
}

function Sidebar(props: Props) {
  const { onboardingStatus, userType } = props;
  const { t } = useTranslation();
  const steps = getSteps(onboardingStatus, userType);
  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <nav aria-label="Progress">
              <ol className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                    {step.status === ONBOARDING_STEP_STATUS.COMPLETE ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-primary" aria-hidden="true" />
                        ) : null}
                        <div className="relative flex items-start group">
                          <span className="h-9 flex items-center">
                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary rounded-full group-hover:bg-primary">
                              <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                            </span>
                          </span>
                          <span className="ml-4 min-w-0 flex flex-col">
                            <span className="text-xs font-semibold tracking-wide uppercase">{t(step.name)}</span>
                            <span className="text-sm ">{t(step.description)}</span>
                          </span>
                        </div>
                      </>
                    ) : step.status === ONBOARDING_STEP_STATUS.CURRENT ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-lightGray" aria-hidden="true" />
                        ) : null}
                        <div className="relative flex items-start group" aria-current="step">
                          <span className="h-9 flex items-center" aria-hidden="true">
                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-primary rounded-full">
                              <span className="h-2.5 w-2.5 bg-primary rounded-full" />
                            </span>
                          </span>
                          <span className="ml-4 min-w-0 flex flex-col">
                            <span className="text-xs font-semibold tracking-wide uppercase text-primary">{t(step.name)}</span>
                            <span className="text-sm ">{t(step.description)}</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-lightGray" aria-hidden="true" />
                        ) : null}
                        <div className="relative flex items-start group">
                          <span className="h-9 flex items-center" aria-hidden="true">
                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-lightGray rounded-full group-hover:border-darkerGray">
                              <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-lightGray" />
                            </span>
                          </span>
                          <span className="ml-4 min-w-0 flex flex-col">
                            <span className="text-xs font-semibold tracking-wide uppercase ">{t(step.name)}</span>
                            <span className="text-sm ">{t(step.description)}</span>
                          </span>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
