import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error401() {
  const { t } = useTranslation();
  const label = `${t('Error')}: ${t('Unauthorized')}.`;
  return (
    <ErrorPageTempate
      label={label}
      code={401}
    />
  );
}

export default Error401;
