import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { COMPLETE_COMPANY_MUTATION } from '../../../api/mutations/companies';
import { MINIFIED_CURRENT_MANAGER_QUERY } from '../../../api/queries/managers';
import { INLINE_COUNTRIES_QUERY, INLINE_CITIES_QUERY, AREA_CODE_QUERY } from '../../../api/queries/shared';
import {
  COMPANY_SIZE,
  BRANCHE_TYPE,
  IMAGE_ASPECT_RATIO,
} from '../../../constants';
import {
  getInlineOptionsFromEnumDict,
  updateSelectStylesOnError,
  zIndexTweakedSelectStyles,
  zIndexTweakedSelectStylesOnError,
} from '../../../helpers/utils';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Input, Image, Select } from '../../../layout/fields';
import { ErrorsList } from '../../../layout/forms';
import LoadingIndicator from '../../../layout/LoadingIndicator';
import { setSuccessAlert } from '../../../redux/alertSlice';

const companySizeOptions = getInlineOptionsFromEnumDict(COMPANY_SIZE);

const brancheOptions = getInlineOptionsFromEnumDict(BRANCHE_TYPE, false);

function CompleteCompanyForm(props) {
  const { refetchQueries } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: managerData } = useQuery(MINIFIED_CURRENT_MANAGER_QUERY);
  const { data: countriesData } = useQuery(INLINE_COUNTRIES_QUERY);

  const [
    getAreaCode,
    {
      data: areaCodeData,
      loading: areaCodeLoading,
      // notifyOnNetworkStatusChange is used to force loading indicator to work
    }] = useLazyQuery(AREA_CODE_QUERY, { notifyOnNetworkStatusChange: true });

  const [
    getCities,
    {
      data: citiesData,
    }] = useLazyQuery(INLINE_CITIES_QUERY);

  const [
    companyMutation,
    {
      data: companyData,
      loading,
    },
  ] = useMutation(COMPLETE_COMPANY_MUTATION, {
    refetchQueries,
  });

  const schema = yup.object({
    companySize: yup.string().required(t('Requried')),
    description: yup.string(),
    email: yup.string().required(t('Requried')),
    highlights: yup.string(),
    logoImage: yup.string().nullable(),
    name: yup.string().required(t('Requried')),
    phone: yup.string().required(t('Requried')),
    profileImage: yup.string().nullable(),
    website: yup.string(),
    areaCode: yup.object().nullable().required(t('Requried')),
    city: yup.object().nullable().required(t('Requried')),
    street: yup.string().required(t('Requried')),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      companySize: '',
      description: '',
      email: '',
      highlights: '',
      logoImage: '',
      name: '',
      phone: '',
      profileImage: '',
      website: '',
      areaCode: null,
      city: null,
      street: '',
      country: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        owner: managerData.currentManager.id,
        invitationCode: uuidv4(),
        city: values.city.value,
        areaCode: values.areaCode.value,
        country: countriesData && countriesData.countries.find((item) => item.name === 'Germany').id,
      };
      companyMutation(
        {
          variables,
        },
      );
    },
  });

  const handleLogoImageSelection = (imageData) => {
    setFieldValue('logoImage', imageData);
  };

  const handleProfileImageSelection = (imageData) => {
    setFieldValue('profileImage', imageData);
  };

  const handleAreaCodeInputChange = (e) => {
    if (e.length === 5) {
      getAreaCode({ variables: { areaCode: e } });
    }
  };

  const handleAreaCodeChange = (e) => {
    setValues({
      ...formikValues,
      areaCode: e,
      city: null,
    });
    getCities({ variables: { name: e.value } });
  };

  useEffect(() => {
    if (companyData && companyData.completeCompany.response) {
      dispatch(setSuccessAlert(
        [t('Additinal Company was created.')],
      ));
      navigate('/');
    }
  }, [companyData, navigate, dispatch, t]);

  useEffect(() => {
    if (citiesData && citiesData.cities && citiesData.cities.objects.length) {
      const firstCity = citiesData.cities.objects[0];
      const city = { value: firstCity.id, label: firstCity.name };
      setFieldValue('city', city);
    }
  }, [citiesData, setFieldValue]);

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={formikValues.name}
                  errorMessage={touched.name && formikErrors.name}
                  label={t('Company Name')}
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={formikValues.email}
                  errorMessage={touched.email && formikErrors.email}
                  label={t('Email')}
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formikValues.phone}
                  errorMessage={touched.phone && formikErrors.phone}
                  label={t('Phone')}
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-bold"
                >
                  {t('Company Size')}
                  <span className="text-red-600"> *</span>
                </label>
                <Select
                  className="mt-1"
                  value={companySizeOptions.find(
                    (item) => item.value === formikValues.companySize,
                  )}
                  options={companySizeOptions}
                  onChange={(e) => setFieldValue('companySize', e.value)}
                  styles={formikErrors.companySize && updateSelectStylesOnError}
                />
                {touched.companySize && !!formikErrors.companySize && (<div className="mt-2 text-sm text-red-600">{formikErrors.companySize}</div>)}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-bold "
                >
                  {t('Country')}
                  <span className="text-red-600"> *</span>
                </label>
                <Select
                  value={{ label: 'Germany', value: null }}
                  isDisabled
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-bold"
                >
                  {t('Area Code')}
                  <span className="text-red-600"> *</span>
                </label>
                <Select
                  onInputChange={handleAreaCodeInputChange}
                  value={formikValues.areaCode}
                  isLoading={areaCodeLoading}
                  options={
                    (areaCodeData
                      && areaCodeData.areaCode
                      && areaCodeData.areaCode.postalCode)
                      ? [{
                        value: areaCodeData.areaCode.postalCode,
                        label: areaCodeData.areaCode.postalCode,
                      }]
                      : []
                  }
                  onChange={(e) => handleAreaCodeChange(e)}
                  styles={
                    formikErrors.areaCode
                      ? zIndexTweakedSelectStylesOnError
                      : zIndexTweakedSelectStyles
                  }
                  menuPortalTarget={document.body}
                />
                {touched.areaCode && !!formikErrors.areaCode && (<div className="mt-2 text-sm text-red-600">{formikErrors.areaCode}</div>)}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm font-bold "
                >
                  {t('City')}
                  <span className="text-red-600"> *</span>
                </label>
                <Select
                  value={formikValues.city}
                  placeholder={!formikValues.areaCode ? t('Please select Area Code') : `${t('Select')}...`}
                  options={
                    citiesData
                    && citiesData.cities.objects.length
                    && citiesData.cities.objects.map(
                      (item) => ({ value: item.id, label: item.name }),
                    )
                  }
                  onChange={(e) => setFieldValue('city', e)}
                  isDisabled={formikValues.areaCode === null && !formikValues.city}
                  styles={
                    formikErrors.city
                      ? zIndexTweakedSelectStylesOnError
                      : zIndexTweakedSelectStyles
                  }
                  menuPortalTarget={document.body}
                />
                {touched.city && !!formikErrors.city && (<div className="mt-2 text-sm text-red-600">{formikErrors.city}</div>)}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="street"
                  name="street"
                  type="text"
                  value={formikValues.street}
                  errorMessage={touched.street && formikErrors.street}
                  label={t('Street')}
                  required
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  className="block text-sm "
                >
                  {t('Branche')}
                </label>
                <Select
                  className="mt-1"
                  value={brancheOptions.find(
                    (item) => item.value === formikValues.branche,
                  )}
                  options={brancheOptions}
                  onChange={(e) => setFieldValue('branche', e.value)}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="website"
                  name="website"
                  type="text"
                  value={formikValues.website}
                  errorMessage={touched.website && formikErrors.website}
                  label={t('Website')}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label
                  className="block text-sm font-medium "
                >
                  {t('Description')}
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows={4}
                  className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                  value={formikValues.description}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label
                  className="block text-sm font-medium "
                >
                  {t('Highlights')}
                </label>
                <textarea
                  id="highlights"
                  name="highlights"
                  rows={4}
                  className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                  value={formikValues.highlights}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label
                  className="block text-sm font-medium "
                >
                  {t('Logo Image')}
                </label>
                <Image
                  image={formikValues.logoImage}
                  handleSuccess={handleLogoImageSelection}
                  handleError={(e) => setErrors({ logoImage: e })}
                  isCompany
                  aspectRatio={IMAGE_ASPECT_RATIO.SQUARE}
                />
                {!!formikErrors.logoImage && (
                  <ErrorsList
                    errors={[formikErrors.logoImage]}
                    className="mt-2"
                  />
                )}
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label
                  className="block text-sm font-medium "
                >
                  {t('Profile Image')}
                </label>
                <Image
                  image={formikValues.profileImage}
                  handleSuccess={handleProfileImageSelection}
                  handleError={(e) => setErrors({ profileImage: e })}
                  isCompany
                  aspectRatio={IMAGE_ASPECT_RATIO.DVD}
                />
                {!!formikErrors.profileImage && (
                  <ErrorsList
                    errors={[formikErrors.profileImage]}
                    className="mt-2"
                  />
                )}
              </div>
            </div>

          </div>
          <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
            {loading
              ? (
                <LoadingIndicator className="flex justify-end py-4 mr-2" />

              )
              : (
                <div className="flex justify-between">
                  <Link
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-darkestGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkestGray"
                    to="/settings/companies/"
                  >
                    {t('Back')}
                  </Link>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    {t('Save')}
                  </button>
                </div>

              )}
          </div>
        </form>
      </div>
    </div>
  );
}

CompleteCompanyForm.propTypes = {
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
};

export default CompleteCompanyForm;
