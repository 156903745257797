import { RadioGroup } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { PAYMENT_CYCLE } from '../../../../../constants';
import { classNames, getInlineOptionsFromEnumDict } from '../../../../../helpers/utils';

const cycleOptions = getInlineOptionsFromEnumDict(PAYMENT_CYCLE, true).reverse();

interface Props {
  paymentCycle: string,
  setPaymentCycle: (value: string) => void,
}

function PaymentCycle(props: Props) {
  const { paymentCycle, setPaymentCycle } = props;
  const { t } = useTranslation();
  // Temporarily Disable payment cicle rendering 
  const isDisabled = true;
  
  if (isDisabled) {
    return <></>;
  }
  return (
    <div className="mt-4 flex justify-center">
      <RadioGroup
        value={paymentCycle}
        onChange={setPaymentCycle}
        className="grid grid-cols-2 gap-x-1 rounded-md p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-darkerGray"
      >
        {cycleOptions.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ checked }) => classNames(
              checked ? 'bg-primary text-white' : 'text-darkestGray',
              'cursor-pointer rounded-md py-1 px-2.5',
            )}
          >
            <span>{t(option.label)}</span>
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
}

export default PaymentCycle;
