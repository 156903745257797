import { XIcon } from '@heroicons/react/outline';
import { ManagerType } from '../../../../../__generated__/graphql';

interface Props {
  item: ManagerType,
  handleRemove: (value: string) => void,
  isOwner: boolean,
}

function ManagerCard(props: Props) {
  const { item, handleRemove, isOwner } = props;

  return (
    <div
      className="relative rounded sm:rounded-lg border bg-white px-6 py-5 shadow-sm flex items-center space-x-3"
    >
      <div className="flex-1 flex justify-between min-w-0">
        <p className="text-sm font-medium ">{item?.profile?.fullName}</p>
        <p className="text-sm  truncate">
          {item?.profile?.user?.email}
        </p>
      </div>
      <div>
        {!isOwner && (
          <button onClick={() => handleRemove(item.id)}>
            <XIcon
              className="cursor-pointer flex-1  absolute top-4 right-4 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full"
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default ManagerCard;
