import { gql } from '@apollo/client';

export const THREAD_QUERY = gql`
  query thread(
    $applicant: ID!
    $offer: ID!
    ) {
    thread(
      applicant: $applicant
      offer: $offer
      ){
        id
      }
  }
`;

export const MANAGER_THREADS_QUERY = gql`
query managerThreads{
  managerThreads{
    id
    applicant{
      id
      profile{
        fullName
        imageSrcset
      }
    }
    offer{
      id
      title
      qualification
    }
    isViewed
    hasMessages
  }
}
`;

export const APPLICANT_THREADS_QUERY = gql`
query applicantThreads{
  applicantThreads{
    id
    offer{
      id
      title
      qualification
      company{
        id
        name
        logoImageSrcset
      }
    }
    isViewed
    hasMessages
  }
}
`;

export const MESSAGES_QUERY = gql`
  query messages(
    $thread: ID!
    ) {
      messages(
        thread: $thread
      ){
        hasArchive
        objects{
          id
          createdAt
          body
          isViewed
          applicant{
            id
            profile{
              id
              fullName
              imageSrcset
            }
          }
          manager{
            id
            profile{
              id
              fullName
              imageSrcset
            }
          }
          sender
        }
      }
  }
`;

export const ARCHIVED_MESSAGES_QUERY = gql`
  query archivedMessages(
    $page: Int
    $thread: ID!
    ) {
      archivedMessages(
        page: $page
        thread: $thread
      ){
        page
        pages
        hasNext
        hasPrev
        totalObjectsQuantity
        elidedPageRange
        objects{
          id
          createdAt
          body
          isViewed
          applicant{
            id
            profile{
              id
              fullName
              imageSrcset
            }
          }
          manager{
            id
            profile{
              id
              fullName
              imageSrcset
            }
          }
          sender
        }
      }
  }
`;

export const UNVIEWED_MESSAGES_QUANTITY_QUERY = gql`
query unviewedMessagesQuantity{
  unviewedMessagesQuantity
}
`;
