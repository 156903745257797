const en = {
  translation: {
    Email: 'Email',
    Password: 'Password',
    'Confirm Password': 'Confirm Password',
    'Already registered?': 'Already registered?',
    'Forgot password?': 'Forgot password?',
    "Don't have an account?": "Don't have an account?",
    Register: 'Register',
    'Log in': 'Log in',
    "I am Manager: I'm looking for applicants.": "I am Manager: I'm looking for applicants.",
    "I am an Applicant: I'm looking for a job.": "I am an Applicant: I'm looking for a job.",
    Or: 'Or',
    'Continue with Google': 'Continue with Google',
    'Continue with Facebook': 'Continue with Facebook',
    'Email is Required': 'Email is Required',
    'Password is Required': 'Password is Required',
    'Password Confirmation is Required': 'Password Confirmation is Required',
    'User Type is Required': 'User Type is Required',
    'Fetching data error. Please try again later.': 'Fetching data error. Please try again later.',

    'Craftsman Registration': 'Craftsman Registration',
    'Register quickly and easily with Springboard Crafts so that you can find the job that really suits you. What are you waiting for?': 'Register quickly and easily with Springboard Crafts so that you can find the job that really suits you. What are you waiting for?',
    'Register a Craft Business': 'Register a Craft Business',

    'Register Business': 'Register Business',
    'Register your craft business quickly and easily now, so you can get started right away, create job advertisements and find the right employees for your team.': 'Register your craft business quickly and easily now, so you can get started right away, create job advertisements and find the right employees for your team.',
    'Register as a Craftsman instead': 'Register as a Craftsman instead',

    'Reset Your Password': 'Reset Your Password',
    'Your new password was applied.': 'Your new password was applied.',
    'Please use it to log in the app.': 'Please use it to log in the app.',
    'Back to Login page': 'Back to Login page',
    Reset: 'Reset',

    'Welcome to Sprungbrett Handwerk!': 'Welcome to Sprungbrett Handwerk!',
    'We have sent the confirmation email letter to your email box, used in registration process.': 'We have sent the confirmation email letter to your email box, used in registration process.',
    'Please confirm your email by following the link in the confirmation letter.': 'Please confirm your email by following the link in the confirmation letter.',

    'Your E-mail was successfully confirmed. You can use an application now.': 'Your E-mail was successfully confirmed. You can use an application now.',

    Error: 'Error',
    'Bad Request': 'Bad Request',
    Unauthorized: 'Unauthorized',
    Forbidden: 'Forbidden',
    'Not Found': 'Not Found',
    'Internal Server Error': 'Internal Server Error',
    'Bad Gateway': 'Bad Gateway',

    'Please drop your CV here or click this area to select': 'Please drop your CV here or click this area to select',
    'Please drop your Certificates here or click this area to select': 'Please drop your Certificates here or click this area to select',

    Selected: 'Selected',
    Select: 'Select',
    Confirm: 'Confirm',
    Remove: 'Remove',

    'Error(s) in your submission': 'Error(s) in your submission',

    'Please confirm this action.': 'Please confirm this action.',
    'Do you want to proceed?': 'Do you want to proceed?',
    Cancel: 'Cancel',

    'Email Confirmation': 'Email Confirmation',
    Onboarding: 'Onboarding',
    Dashboard: 'Dashboard',
    Applicants: 'Applicants',
    Matched: 'Matched',
    Offers: 'Offers',
    Settings: 'Settings',
    'Log out': 'Log out',

    'Error(s) occured': 'Error(s) occured',

    Previous: 'Previous',
    Next: 'Next',

    Account: 'Account',
    Profile: 'Profile',
    Applicant: 'Applicant',
    'Preferred Working Location': 'Preferred Working Location',
    Companies: 'Companies',

    Company: 'Company',
    Title: 'Title',
    City: 'City',
    'Salary (EUR)': 'Salary (EUR)',
    Actions: 'Actions',

    'Company Address': 'Company Address',

    Requried: 'Requried',
    'Changes were applied.': 'Changes were applied.',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    Save: 'Save',
    Back: 'Back',

    'Your CV': 'Your CV',
    Qualification: 'Qualification',
    'Salary Expectations': 'Salary Expectations',
    Birthdate: 'Birthdate',
    Languages: 'Languages',
    Skills: 'Skills',
    '(Newly created Skills will appear once they pass moderation)': '(Newly created Skills will appear once they pass moderation)',
    'Select or Add New...': 'Select or Add New...',
    'Driver Licence': 'Driver Licence',
    Certificates: 'Certificates',
    Goals: 'Goals',
    Miscellaneous: 'Miscellaneous',
    'I am open to travel/assembly': 'I am open to travel/assembly',

    Country: 'Country',

    'You like this Company!': 'You like this Company!',
    'Unlike?': 'Unlike?',
    Like: 'Like',
    Unlike: 'Unlike',

    'You like this Offer!': 'You like this Offer!',
    Offer: 'Offer',

    Likes: 'Likes',
    Views: 'Views',

    'Company Name': 'Company Name',

    'Additinal Company was created.': 'Additinal Company was created.',
    'Create Additional Company': 'Create Additional Company',

    Phone: 'Phone',
    'Company Size': 'Company Size',
    Branche: 'Branche',
    Website: 'Website',
    Description: 'Description',
    Highlights: 'Highlights',
    'Area Code': 'Area Code',
    'Please select Area Code': 'Please select Area Code',
    Street: 'Street',
    'Logo Image': 'Logo Image',
    'Profile Image': 'Profile Image',

    'Applicants Activity': 'Applicants Activity',
    'View all activity': 'View all activity',

    Liked: 'Liked',

    'New Offer': 'New Offer',
    Active: 'Active',
    Closed: 'Closed',

    'Filter by': 'Filter by',
    None: 'None',

    'Sort by': 'Sort by',

    Default: 'Default',

    'Ready to meet the next member of your team?': 'Ready to meet the next member of your team?',
    'You have not created any job offers yet? Use the': 'You have not created any job offers yet? Use the',
    'New job offer': 'New job offer',
    'button to easily post a job.': 'button to easily post a job.',
    "Occupation, desired qualification and your salary expectations - that's all you need to get started.": "Occupation, desired qualification and your salary expectations - that's all you need to get started.",

    Created: 'Created',

    'Offer was permanently deleted.': 'Offer was permanently deleted.',
    Delete: 'Delete',
    'Delete Offer': 'Delete Offer',

    'Is Active': 'Is Active',

    'Use Company Address': 'Use Company Address',
    'Travel / Assembly': 'Travel / Assembly',

    'Invitation Code was successfully applied. You will be notified on invitation review.': 'Invitation Code was successfully applied. You will be notified on invitation review.',
    'Invitation Code': 'Invitation Code',
    Apply: 'Apply',

    'Join Company Using Code': 'Join Company Using Code',
    'Create New Company': 'Create New Company',

    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    Owner: 'Owner',

    'Congratulations! You are ready to use an application now.': 'Herzliche Glückwünsche! Sie können jetzt eine Anwendung verwenden.',

    Filters: 'Filters',

    'You like this Applicant!': 'You like this Applicant!',

    'Change Email': 'Change Email',
    'Here you can change your contact Email.': 'Here you can change your contact Email.',
    'You will be forced to login again on success.': 'You will be forced to login again on success.',
    'New Email': 'New Email',

    'Your Authentication Credentials have been changed. Please login again.': 'Your Authentication Credentials have been changed. Please login again.',
    'Change Password': 'Change Password',
    'Here you can change your account Password.': 'Here you can change your account Password.',
    'New Password': 'New Password',

    'Your Account was permanently deleted.': 'Your Account was permanently deleted.',
    'Onboarding is not finished': 'Onboarding is not finished',
    'Please finish the onboarding process in order to get full settings panel access.': 'Please finish the onboarding process in order to get full settings panel access.',
    'Finish Onboarding': 'Finish Onboarding',
    'Delete Account': 'Delete Account',
    'By clicking the button below you will permanently delete your account.': 'By clicking the button below you will permanently delete your account.',

    'Notification Channels': 'Notification Channels',
    'Application Interface': 'Application Interface',

    'Return to Chat': 'Return to Chat',
    'There are no messages in this conversation just yet.': 'There are no messages in this conversation just yet.',

    'Search for older messages in Archive': 'Search for older messages in Archive',

    'Open options': 'Open options',
    'Delete Message': 'Delete Message',

    Contacts: 'Contacts',

    'Close menu': 'Close menu',

    Chat: 'Chat',
    'Chat Archive': 'Chat Archive',
    'Here you can browse your chatting history.': 'Here you can browse your chatting history.',
    'Please pick the conversation on the left in order to start.': 'Please pick the conversation on the left in order to start.',

    'Like job offer or company': 'Like job offer or company',
    'Now like a job offer or a company in the "Dashboard" or under "Job offers". The company will be notified immediately and can also like your profile.': 'Now like a job offer or a company in the "Dashboard" or under "Job offers". The company will be notified immediately and can also like your profile.',

    Match: 'Match',
    'You will be notified when your profile receives a "Like" from a company. If a job offer or a company is interesting for you, you return the Like and you can chat with each other here.': 'You will be notified when your profile receives a "Like" from a company. If a job offer or a company is interesting for you, you return the Like and you can chat with each other here.',
    'You will be notified when your company or one of your job advertisements receives a "Like" from a craftsman. If the craftsman is interesting for your company, you return a Like and you can chat with each other here.': 'You will be notified when your company or one of your job advertisements receives a "Like" from a craftsman. If the craftsman is interesting for your company, you return a Like and you can chat with each other here.',

    'New job ads?': 'New job ads?',
    'As soon as a company you have liked posts a new job, it will be displayed here immediately.': 'As soon as a company you have liked posts a new job, it will be displayed here immediately.',

    'New job offer?': 'New job offer?',
    'If you have more jobs posted and you are connected to a craftsman via a Like, the craftsman will automatically see all of those jobs from you.': 'If you have more jobs posted and you are connected to a craftsman via a Like, the craftsman will automatically see all of those jobs from you.',

    Tip: 'Tip',
    'Your profile': 'Your profile',
    'Your company profile': 'Your company profile',
    'You should have created a meaningful profile of yourself. This increases your chances significantly.': 'You should have created a meaningful profile of yourself. This increases your chances significantly.',
    'You should have created a meaningful company profile. This significantly increases your chances of finding a new member for your team.': 'You should have created a meaningful company profile. This significantly increases your chances of finding a new member for your team.',

    'Like, match and get to know each other': 'Like, match and get to know each other',

    'In two simple steps you can quickly benefit from Sprungbrett Handwerk.': 'In two simple steps you can quickly benefit from Sprungbrett Handwerk.',
    'The Company that owns these offers likes you and you like these offers!': 'The Company that owns these offers likes you and you like these offers!',
    'Here you can get in touch with company managers.': 'Here you can get in touch with company managers.',
    'You have no matches yet.': 'You have no matches yet.',

    'Available Offers': 'Available Offers',
    'You can use filter to get more relevant results.': 'You can use filter to get more relevant results.',

    'No records yet in': 'No records yet in',

    'Available Applicants': 'Available Applicants',

    'Here you can profit very quickly from Sprungbrett Handwerk.': 'Here you can profit very quickly from Sprungbrett Handwerk.',

    'You liked these applicants and they liked offers you propose!': 'You liked these applicants and they liked offers you propose!',
    'Here You can get in touch with them.': 'Here You can get in touch with them.',

    'Create Offer': 'Create Offer',
    'Update Offer': 'Update Offer',

    Notifications: 'Notifications',

    'Current company is': 'Current company is',
    'Change?': 'Change?',
    Invites: 'Invites',
    'Pending Invites': 'Pending Invites',
    'Join Company via Code': 'Join Company via Code',
    'Leave Company': 'Leave Company',

    'Join Company': 'Join Company',

    'Switch Company': 'Switch Company',

    'General Data': 'General Data',
    Benefits: 'Benefits',
    Address: 'Address',
    Managers: 'Managers',
    Ownership: 'Ownership',

    'Add Benefit': 'Add Benefit',
    'Benefit Type': 'Benefit Type',
    'Delete Benefit': 'Delete Benefit',

    Vacation: 'Vacation',
    'Company Car': 'Company Car',
    'Workwear/PPE': 'Workwear/PPE',
    'Modern It Equipment': 'Modern It Equipment',
    'Capital Accumulation Benefits': 'Capital Accumulation Benefits',
    'Further Education': 'Further Education',
    'Career Development': 'Career Development',
    'Bonus System': 'Bonus System',
    'Company Activities': 'Company Activities',
    'Working Hours': 'Working Hours',
    'Leasing Options': 'Leasing Options',
    'Flat Hierarchy': 'Flat Hierarchy',

    'Remove Manager': 'Remove Manager',

    'Transfer Ownership to': 'Transfer Ownership to',
    'You have no one to transfer your Company Ownership.': 'You have no one to transfer your Company Ownership.',

    'Travelling Attitude': 'Travelling Attitude',
    'Is open for it.': 'Is open for it.',
    "Doesn't consider as an option.": "Doesn't consider as an option.",
    CV: 'CV',

    Location: 'Location',

    Loading: 'Loading',
    'Please fill the form below in order to get started.': 'Please fill the form below in order to get started.',

    'Write Something': 'Write Something',

    'Possible Matches': 'Possible Matches',
    'These companies are interested in you. Like them to show that you can be a good match!': 'These companies are interested in you. Like them to show that you can be a good match!',

    'Salary Range (EUR)': 'Salary Range (EUR)',
    'Area Radius': 'Area Radius',

    'All these companies are interested in you. Like them to show that you can be a good match!': 'All these companies are interested in you. Like them to show that you can be a good match!',
    Done: 'Done',
    'Show matching options?': 'Show matching options?',

    'Recommeded Offers': 'Recommeded Offers',
    'These offers are located around you. Look closer and pick!': 'These offers are located around you. Look closer and pick!',

    'Nothing yet': 'Nothing yet',
    "Our algorithms haven't found any jobs near you yet - be patient.": "Our algorithms haven't found any jobs near you yet - be patient.",
    'Alternatively, you can already look at jobs from other regions.': 'Alternatively, you can already look at jobs from other regions.',
    'Search for Offers?': 'Search for Offers?',
    'Search for Applicants?': 'Search for Applicants?',

    Required: 'Required',

    'Company Branche': 'Company Branche',
    'Head Office': 'Head Office',

    'Not Required': 'Not Required',

    Contribution: 'Contribution',
    'Travel activity/assembly': 'Travel activity/assembly',
    Yes: 'Yes',
    No: 'No',
    Performance: 'Performance',
    Salary: 'Salary',
    Month: 'Month',
    'Preferred Location': 'Preferred Location',

    Afrikaans: 'Afrikaans',
    Arabic: 'Arabic',
    Asturian: 'Asturian',
    Azerbaijani: 'Azerbaijani',
    Bulgarian: 'Bulgarian',
    Belarusian: 'Belarusian',
    Bengali: 'Bengali',
    Breton: 'Breton',
    Bosnian: 'Bosnian',
    Catalan: 'Catalan',
    Czech: 'Czech',
    Welsh: 'Welsh',
    Danish: 'Danish',
    German: 'German',
    'Lower Sorbian': 'Lower Sorbian',
    Greek: 'Greek',
    English: 'English',
    Esperanto: 'Esperanto',
    Spanish: 'Spanish',
    Estonian: 'Estonian',
    Basque: 'Basque',
    Persian: 'Persian',
    Finnish: 'Finnish',
    French: 'French',
    Frisian: 'Frisian',
    Irish: 'Irish',
    'Scottish Gaelic': 'Scottish Gaelic',
    Galician: 'Galician',
    Hebrew: 'Hebrew',
    Hindi: 'Hindi',
    Croatian: 'Croatian',
    'Upper Sorbian': 'Upper Sorbian',
    Hungarian: 'Hungarian',
    Armenian: 'Armenian',
    Interlingua: 'Interlingua',
    Indonesian: 'Indonesian',
    Igbo: 'Igbo',
    Ido: 'Ido',
    Icelandic: 'Icelandic',
    Italian: 'Italian',
    Japanese: 'Japanese',
    Georgian: 'Georgian',
    Kabyle: 'Kabyle',
    Kazakh: 'Kazakh',
    Khmer: 'Khmer',
    Kannada: 'Kannada',
    Korean: 'Korean',
    Kyrgyz: 'Kyrgyz',
    Luxembourgish: 'Luxembourgish',
    Lithuanian: 'Lithuanian',
    Latvian: 'Latvian',
    Macedonian: 'Macedonian',
    Malayalam: 'Malayalam',
    Mongolian: 'Mongolian',
    Marathi: 'Marathi',
    Burmese: 'Burmese',
    'Norwegian Bokmål': 'Norwegian Bokmål',
    Nepali: 'Nepali',
    Dutch: 'Dutch',
    'Norwegian Nynorsk': 'Norwegian Nynorsk',
    Ossetic: 'Ossetic',
    Punjabi: 'Punjabi',
    Polish: 'Polish',
    Portuguese: 'Portuguese',
    Romanian: 'Romanian',
    Russian: 'Russian',
    Slovak: 'Slovak',
    Slovenian: 'Slovenian',
    Albanian: 'Albanian',
    Serbian: 'Serbian',
    Swedish: 'Swedish',
    Swahili: 'Swahili',
    Tamil: 'Tamil',
    Telugu: 'Telugu',
    Tajik: 'Tajik',
    Thai: 'Thai',
    Turkmen: 'Turkmen',
    Turkish: 'Turkish',
    Tatar: 'Tatar',
    Udmurt: 'Udmurt',
    Ukrainian: 'Ukrainian',
    Urdu: 'Urdu',
    Uzbek: 'Uzbek',
    Vietnamese: 'Vietnamese',

    'Willingness to travel is required': 'Willingness to travel is required',
    'Willingness to travel is not required': 'Willingness to travel is not required',

    'What Defines You': 'What Defines You',
    'Your Advantages': 'Your Advantages',
    'Show Profile': 'Show Profile',
    Published: 'Published',
    Filter: 'Filter',

    'I like it': 'I like it',

    'No records found': 'No records found',

    Imprint: 'Imprint',
    'Privacy Policy': 'Privacy Policy',
    'Terms of Service': 'Terms of Service',
    'Price list': 'Price list',

    'No activities have taken place so far': 'No activities have taken place so far',

    Matches: 'Matches',

    'Company Benefits': 'Company Benefits',
    'Company Offers': 'Company Offers',
    'About me': 'About me',

    'Passwords must match': 'Passwords must match',

    'Create User and verify credentials.': 'Create User and verify credentials.',
    'Enter your user profile data.': 'Enter your user profile data.',
    'User Profile': 'User Profile',
    'Fill in the applicant form to get the best results for your requirements.': 'Fill in the applicant form to get the best results for your requirements.',
    Craftworker: 'Craftworker',
    'The place where you want to find an offer.': 'The place where you want to find an offer.',
    'Preferred Place Of Work': 'Preferred Place Of Work',
    'Create or join the Company you want to manage.': 'Create or join the Company you want to manage.',

    'Confirm Manager Approval': 'Confirm Manager Approval',
    'Confirm Manager Rejection': 'Confirm Manager Rejection',

    'No options': 'No options',

    'Invalid Date': 'Invalid Date',

    'Multiple Files Not Allowed': 'Multiple Files Not Allowed',
    'Uploaded File type is not supported': 'Uploaded File type is not supported',
    'Maximum File size:': 'Maximum File size:',
    'Maximum multiple Files size:': 'Maximum multiple Files size:',

    'Next Step': 'Next Step',

    'Filter by Offer': 'Filter by Offer',

    'We have sent the new password to your E-mail.': 'We have sent the new password to your E-mail.',

    Unread: 'Unread',
    New: 'New',

    Sorry: 'Sorry',
    'Your Company is deactivated.': 'Your Company is deactivated.',
    'Please contact us to reactivate it.': 'Please contact us to reactivate it.',

    Package: 'Package',
    'Membership for 1 year with unlimited access': 'Membership for 1 year with unlimited access',
    'Annual fee': 'Annual fee',
    'Monthly fee': 'Monthly fee',
    'Company Package': 'Company Package',
    'Services Package to your Company.': 'Services Package to your Company.',
    'Buy package now': 'Buy package now',
    Checkout: 'Checkout',
    'Please choose the payment option.': 'Please choose the payment option.',
    'Apply the payment for the Company Package.': 'Apply the payment for the Company Package.',
    'Pay with': 'Pay with',
    'Please provide correct IBAN': 'Please provide correct IBAN',
    'Please enter your IBAN and we will send you the SEPA direct debit mandate by e-mail': 'Please enter your IBAN and we will send you the SEPA direct debit mandate by e-mail',

    'In order to delete your account please contact us via email:': 'In order to delete your account please contact us via email:',

    'Code of Conduct': 'Code of Conduct',

    'Problems with signing in?': 'Problems with signing in?',
    'If you have problems with the registration, then contact us and we will help you.': 'If you have problems with the registration, then contact us and we will help you.',
    "If you can't find an email in your inbox, it may be in your spam folder.": "If you can't find an email in your inbox, it may be in your spam folder.",

    'multiple selection possible': 'multiple selection possible',

    'Reset password': 'Reset password',

    'To add another user of your company, send your invitation code, which you can find under:': 'To add another user of your company, send your invitation code, which you can find under:',
    'User menu': 'User menu',
    'General data': 'General data',

    'corresponds to': 'corresponds to',
    Annual: 'Annual',
    Monthly: 'Monthly',
    '+ VAT': '+ VAT',
    'per month': 'per month',

    'I have read and accepted the': 'I have read and accepted the',
    'terms and conditions': 'terms and conditions',
    'as well as the': 'as well as the',
    'price list': 'price list',
    'read and accepted and the': 'read and accepted and the',
    'privacy policy': 'privacy policy',
    'is noted': 'is noted',
    'Furthermore, I confirm the recorded company size of': 'Furthermore, I confirm the recorded company size of',
    employees: 'employees',

    'Craftsman on Sprungbrett Handwerk': 'Craftsman on Sprungbrett Handwerk',
    'Here you will find various craftsmen looking for jobs in your region. The whole thing is as simple as it looks. You see the profession and the qualification of the craftsman, the place of residence and the desired monthly salary.': 'Here you will find various craftsmen looking for jobs in your region. The whole thing is as simple as it looks. You see the profession and the qualification of the craftsman, the place of residence and the desired monthly salary.',
    'Next to it you will see a thumb. You click on this thumb if you like a craftsman. A craftsman you already like gets a green thumb. You get details about the craftsman when you click on it. Once both have given the "green thumb", you have a match. Then you see more details, the full name, a profile picture and you can chat with each other. Have fun browsing and good luck with your employee search.': 'Next to it you will see a thumb. You click on this thumb if you like a craftsman. A craftsman you already like gets a green thumb. You get details about the craftsman when you click on it. Once both have given the "green thumb", you have a match. Then you see more details, the full name, a profile picture and you can chat with each other. Have fun browsing and good luck with your employee search.',

    'Salary must be an integer.': 'Salary must be an integer.',
    '2024 is completely free and you will not be charged anything. Only from 2025 will we calculate the annual usage fee according to our price structure for your company size.': '2024 is completely free and you will not be charged anything. Only from 2025 will we calculate the annual usage fee according to our price structure for your company size.',
  },
};

export default en;
