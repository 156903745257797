import { useTranslation } from 'react-i18next';
import { FILTER_TYPE } from '../../../../constants';
import { getInlineOptionsFromEnumDict } from '../../../../helpers/utils';
import { Select } from '../../../../layout/fields';

const filterOptions = getInlineOptionsFromEnumDict(FILTER_TYPE);

interface Props {
  filterBy: string,
  setFilterBy: (value: string) => void,
}

function OffersFilter(props: Props) {
  const { filterBy, setFilterBy } = props;
  const { t } = useTranslation();

  const handleClick = (e: SelectOption) => {
    if (e) {
      setFilterBy(e.value as string);
    } else {
      setFilterBy(FILTER_TYPE.NONE);
    }
  };

  const translatedFilterOptions = filterOptions.map(
    (item) => ({ value: item.value, label: t(item.label) }),
  );

  return (
    <div className="relative mr-4 w-40">
      <Select
        value={filterBy !== FILTER_TYPE.NONE
          ? translatedFilterOptions.find((item) => item.value === filterBy) : undefined}
        isClearable
        options={translatedFilterOptions}
        onChange={handleClick}
        placeholder={`${t('Filter by')}...`}
      />
    </div>
  );
}

export default OffersFilter;
