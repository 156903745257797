import { useQuery } from '@apollo/client';
import { MailIcon, PhoneIcon, GlobeAltIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BenefitType } from '../__generated__/graphql';
import { PUBLIC_COMPANY_QUERY } from '../api/queries/companies';
import { COMPANY_OFFERS_QUERY } from '../api/queries/offers';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { LikeCompanySection } from '../components/applicant/likes';
import { AddressMap, AttributeCard, BenefitPlateCard } from '../components/shared';
import { OffersWidget } from '../components/shared/offersWidget';
import {
  COMPANY_SIZE, USER_TYPE, BRANCHE_TYPE, IMAGE_SIZE,
} from '../constants';
import { classNames } from '../helpers/utils';
import { CompanyProfileImage, CompanyLogoImage } from '../layout';
import { DashboardLayout } from '../layout/layouts';

function Company() {
  const [offersPage, setOffersPage] = useState(1);
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    data: companyData,
  } = useQuery(PUBLIC_COMPANY_QUERY, {
    variables: { company: id },
  });

  const {
    data: offersData,
    fetchMore: fetchMoreOffers,
  } = useQuery(COMPANY_OFFERS_QUERY, {
    variables: {
      company: id,
      page: 1,
    },
  });

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  useEffect(() => {
    fetchMoreOffers({
      variables: {
        page: offersPage,
      },
    });
  }, [fetchMoreOffers, offersPage]);

  let viewerIsApplicant = false;

  if (userData && userData.currentUser && userData.currentUser.userType === USER_TYPE.APPLICANT) {
    viewerIsApplicant = true;
  }

  const offersAvaliable = offersData
    && offersData.companyOffers && !!offersData.companyOffers.totalObjectsQuantity;

  return (
    <DashboardLayout>
      {companyData && companyData.publicCompany && (
        <div className="relative bg-white py-8 sm:rounded-md">
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-5 md:col-span-3 mx-0 max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8">
              <CompanyProfileImage
                className="w-full aspect-[16/8] object-center object-cover rounded-lg text-7xl"
                company={companyData.publicCompany}
                imageSize={IMAGE_SIZE.S768}
              />
              <div className="flex mt-5">
                <CompanyLogoImage
                  className="w-24 h-24 rounded-lg text-2xl border"
                  company={companyData.publicCompany}
                  imageSize={IMAGE_SIZE.S128}
                />
                <div className="ml-4 grid">
                  <p className={classNames(viewerIsApplicant ? 'md:place-self-auto' : '', 'text-2xl font-bold text-left place-self-center ')}>
                    {companyData.publicCompany.name}
                  </p>
                  {viewerIsApplicant
                    && (
                      <div className="hidden md:flex">
                        <div className="self-end justify-start"><LikeCompanySection companyId={companyData.publicCompany.id} /></div>
                      </div>
                    )}
                </div>
              </div>

              <div className="md:hidden mt-5">
                {viewerIsApplicant
                  && (
                    <div className="pb-4"><LikeCompanySection companyId={companyData.publicCompany.id} /></div>
                  )}
              </div>

              {companyData.publicCompany.description && (
                <div className="mt-10 text-left mx-auto ">
                  <div className="text-xl font-bold  text-left">
                    {t('Description')}
                  </div>
                  <div className="grid gap-2">
                    {companyData.publicCompany.description.split('\n').map((str: string, index: number) => <p key={index}>{str}</p>)}
                  </div>
                </div>
              )}
              {companyData.publicCompany.highlights && (
                <div className="mt-10 text-left mx-auto ">
                  <div className="text-xl font-bold  text-left">
                    {t('Highlights')}
                  </div>
                  <div className="grid gap-2">
                    {companyData.publicCompany.highlights.split('\n').map((str: string, index: number) => <p key={index}>{str}</p>)}
                  </div>
                </div>
              )}

              {!!companyData.publicCompany.benefits.length && (
                <div className="font-semibold text-lg col-span-2 mt-10">
                  {t('Company Benefits')}
                  <div className="grid md:grid-cols-3 gap-4 md:gap-12 pt-2">
                    {companyData.publicCompany.benefits.map((benefit: BenefitType) => (
                      <BenefitPlateCard
                        key={benefit.id}
                        benefitType={benefit.benefitType}
                        description={benefit.description}
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className="md:hidden mt-10">
                <dl className="space-y-8">
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Company Size')}
                      value={COMPANY_SIZE[companyData.publicCompany.companySize as keyof typeof COMPANY_SIZE]}
                    />
                  </dd>
                  {companyData.publicCompany.branche && (
                    <dd className="rounded-lg bg-darkerGray">
                      <AttributeCard
                        label={t('Company Branche')}
                        value={BRANCHE_TYPE[companyData.publicCompany.branche as keyof typeof BRANCHE_TYPE]}
                      />
                    </dd>
                  )}
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Head Office')}
                      value={companyData.publicCompany.address.city.name}
                    />
                  </dd>
                </dl>
              </div>

              <div className="md:hidden mt-10">
                <div>
                  <div className="text-xl font-bold  text-left">
                    {t('Contacts')}
                  </div>
                  <dl className="space-y-2">
                    <a target="_blank" href={companyData.publicCompany.website} rel="noopener noreferrer">
                      <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                        <GlobeAltIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                        <span className="ml-3">{companyData.publicCompany.website}</span>
                      </dd>
                    </a>
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <PhoneIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{companyData.publicCompany.phone}</span>
                    </dd>
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <MailIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{companyData.publicCompany.email}</span>
                    </dd>
                  </dl>
                </div>
              </div>

            </div>
            <div className="hidden md:block col-span-2 max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <dl className="space-y-8">
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Company Size')}
                    value={COMPANY_SIZE[companyData.publicCompany.companySize as keyof typeof COMPANY_SIZE]}
                  />
                </dd>
                {companyData.publicCompany.branche && (
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Company Branche')}
                      value={BRANCHE_TYPE[companyData.publicCompany.branche as keyof typeof BRANCHE_TYPE]}
                    />
                  </dd>
                )}
                <dd className="rounded-lg bg-darkerGray">
                  <AttributeCard
                    label={t('Head Office')}
                    value={companyData.publicCompany.address.city.name}
                  />
                </dd>
              </dl>
              <div className="hidden md:block my-8">
                <AddressMap
                  fullAddress={companyData.publicCompany.address.fullAddress}
                  coordinates={companyData.publicCompany.address.coordinates}
                  height="20vh"
                />
              </div>
              <div>
                <div>
                  <div className="text-xl font-bold text-left">
                    {t('Contacts')}
                  </div>
                  <dl className="space-y-2">
                    {companyData.publicCompany.website && (
                      <a target="_blank" href={companyData.publicCompany.website} rel="noopener noreferrer">
                        <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                          <GlobeAltIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                          <span className="ml-3">{companyData.publicCompany.website}</span>
                        </dd>
                      </a>
                    )}
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <PhoneIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{companyData.publicCompany.phone}</span>
                    </dd>
                    <dd className="p-2 flex text-base text-primary bg-darkerGray rounded-lg">
                      <MailIcon className="flex-shrink-0 w-6 h-6 text-primary" aria-hidden="true" />
                      <span className="ml-3">{companyData.publicCompany.email}</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

          </div>
          {offersAvaliable && (
            <>
              <div className="font-semibold text-lg mt-10 px-4 sm:px-6 lg:px-8">
                {t('Company Offers')}
              </div>
              <div className="px-2">
                <OffersWidget
                  items={offersData.companyOffers}
                  setPage={setOffersPage}
                />
              </div>
            </>
          )}
        </div>
      )}
    </DashboardLayout>
  );
}

export default Company;
