import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { PROFILE_MUTATION } from '../../api/mutations/profiles';
import { CURRENT_PROFILE_QUERY } from '../../api/queries/profiles';
import { IMAGE_ASPECT_RATIO } from '../../constants';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Input, Image } from '../../layout/fields';
import { ErrorsList } from '../../layout/forms';
import LoadingIndicator from '../../layout/LoadingIndicator';
import { setSuccessAlert } from '../../redux/alertSlice';

function ProfileForm(props) {
  const {
    userId, refetchQueries, currentProfile,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [
    profileMutation,
    {
      data,
      loading,
    }] = useMutation(PROFILE_MUTATION, {
    refetchQueries,
    update: (cache, { data: profile }) => {
      if (currentProfile && profile.profile.response) {
        cache.writeQuery({
          query: CURRENT_PROFILE_QUERY,
          data: { currentProfile: profile.profile.response },
        });
      }
    },
  });

  const schema = yup.object({
    firstName: yup.string().required(t('Requried')),
    lastName: yup.string().required(t('Requried')),
    image: yup.string().nullable(),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
    touched,
    values: formValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      image: '',
      user: userId,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      if (currentProfile) {
        variables.id = currentProfile.id;
      }
      profileMutation(
        {
          variables,
        },
      );
    },
  });

  const handleImageSelection = (imageData) => {
    setFieldValue('image', imageData);
  };

  useEffect(() => {
    if (currentProfile) {
      setFieldValue('firstName', currentProfile.firstName);
      setFieldValue('lastName', currentProfile.lastName);
      setFieldValue('image', currentProfile.imageSrcset && currentProfile.imageSrcset.s128);
    }
  }, [currentProfile, setFieldValue]);

  useEffect(() => {
    if (currentProfile && data && data.profile && data.profile.response) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [currentProfile, data, dispatch, t]);

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden rounded sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={formValues.firstName}
                    errorMessage={touched.firstName && formikErrors.firstName}
                    label={t('First Name')}
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={formValues.lastName}
                    errorMessage={touched.lastName && formikErrors.lastName}
                    label={t('Last Name')}
                    required
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <Image
                    image={formValues.image}
                    aspectRatio={IMAGE_ASPECT_RATIO.SQUARE}
                    handleSuccess={handleImageSelection}
                    handleError={(e) => setErrors({ image: e })}
                  />
                </div>

              </div>
              {!!formikErrors.image && (
              <ErrorsList
                errors={[formikErrors.image]}
                className="mt-2"
              />
              )}
            </div>
            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              {loading
                ? (
                  <LoadingIndicator className="flex justify-end py-4 mr-2" />

                )
                : (
                  <button
                    diasbled={loading.toString()}
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    {currentProfile ? t('Save') : t('Next Step')}
                  </button>
                )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

ProfileForm.propTypes = {
  userId: PropTypes.string,
  currentProfile: PropTypes.objectOf(PropTypes.any),
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
};

export default ProfileForm;
