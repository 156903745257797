import { useQuery, useMutation } from '@apollo/client';
import { ThumbUpIcon as ThumbUpIconOutline } from '@heroicons/react/outline';
import { ThumbUpIcon as ThumbUpIconSolid } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY_APPLICANT_LIKE_MUTATION } from '../../../api/mutations/likes';
import { MANAGER_THREADS_QUERY } from '../../../api/queries/chats';
import { DOES_COMPANY_LIKE_APPLICANT_QUERY } from '../../../api/queries/likes';
import { classNames } from '../../../helpers/utils';

interface Props {
  applicantId: string,
}

function LikeApplicantSection(props: Props) {
  const { applicantId } = props;
  const [applicantLiked, setApplicantLiked] = useState(undefined);
  const { t } = useTranslation();

  const { data: likeData } = useQuery(
    DOES_COMPANY_LIKE_APPLICANT_QUERY,
    { variables: { applicant: applicantId } },
  );

  const [
    likeMutation,
    {
      loading: mutationLoading,
    }] = useMutation(COMPANY_APPLICANT_LIKE_MUTATION, {
    refetchQueries: [
      { query: MANAGER_THREADS_QUERY },
      { query: DOES_COMPANY_LIKE_APPLICANT_QUERY, variables: { applicant: applicantId } }],
  });

  const handleButtonClick = () => {
    likeMutation({
      variables: {
        applicant: applicantId,
      },
    });
  };

  useEffect(() => {
    if (likeData) {
      setApplicantLiked(likeData.doesCompanyLikeApplicant);
    }
  }, [likeData]);

  if (applicantLiked === undefined) {
    return '';
  }
  return (
    <div
      tabIndex={0}
      role="button"
      data-disabled={mutationLoading}
      onClick={() => handleButtonClick()}
      className={classNames(applicantLiked ? 'bg-darkerGray hover:bg-darkerGray' : 'bg-primary hover:bg-primary', 'py-2 items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md')}
    >
      {applicantLiked
        ? (
          <div className="flex justify-center text-secondary">
            <ThumbUpIconSolid className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span className="flex items-center">
              {t('I like it')}
            </span>
          </div>
        )
        : (
          <div className="flex justify-center text-white">
            <ThumbUpIconOutline className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span className="flex items-center">
              {t('Like')}
            </span>
          </div>
        )}
    </div>
  );
}

export default LikeApplicantSection;
