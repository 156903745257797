import { useMutation } from '@apollo/client';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInvitationType } from '../../../../../__generated__/graphql';
import { COMPANY_INVITATION_MUTATION } from '../../../../../api/mutations/companyInvitations';
import { COMPANY_QUERY } from '../../../../../api/queries/companies';
import { MODERATION_STATUS } from '../../../../../constants';
import { Warning } from '../../../../../layout/modals';

interface Props {
  invitation: CompanyInvitationType,
  companyId: string,
}

function PendingManagerCard(props: Props) {
  const { invitation, companyId } = props;
  const [warningTitle, setWarningTitle] = useState('');
  const [moderationStatus, setModerationStatus] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();

  const [
    executeMutation,
    {
      data: executeMutationData,
      loading: executeMutationLoading,
    },
  ] = useMutation(
    COMPANY_INVITATION_MUTATION,
    {
      refetchQueries: [
        {
          query: COMPANY_QUERY,
          variables: { company: companyId },
        },
      ],
    },
  );

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [showConfirmationModal]);

  function requireConfirmation(inputModerationStatus: string) {
    if (!executeMutationLoading) {
      switch (inputModerationStatus) {
        case MODERATION_STATUS.APPROVED:
          setWarningTitle(t('Confirm Manager Approval'));
          break;
        case MODERATION_STATUS.REJECTED:
          setWarningTitle(t('Confirm Manager Rejection'));
          break;
        default:
          break;
      }
      setModerationStatus(inputModerationStatus);
      toggleConfirmationModal();
    }
  }

  const handleExecute = () => {
    executeMutation({
      variables: {
        id: invitation.id,
        company: companyId,
        manager: invitation.manager.id,
        status: moderationStatus,
      },
    });
  };

  useEffect(() => {
    if (executeMutationData && executeMutationData.companyInvitation.response) {
      setShowConfirmationModal(false);
    }
  }, [executeMutationData, setShowConfirmationModal]);

  return (
    <div
      className="border rounded sm:rounded-lg shadow-sm bg-white px-6 py-5 hover:bg-lightGray hover:border-lightGray focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
    >
      <div className="flex-1 flex justify-between min-w-0">
        <div>
          <p className="text-sm font-medium ">{invitation?.manager?.profile?.fullName}</p>
          <p className="text-sm  truncate">
            {invitation?.manager?.profile?.user?.email}
          </p>
        </div>
        <div>
          <CheckIcon
            className="cursor-pointer block h-6 w-6 text-green-500"
            aria-hidden="true"
            onClick={() => requireConfirmation(MODERATION_STATUS.APPROVED)}
          />
          <XIcon
            className="cursor-pointer block h-6 w-6 text-red-500"
            aria-hidden="true"
            onClick={() => requireConfirmation(MODERATION_STATUS.REJECTED)}
          />
        </div>
      </div>
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleExecute}
        title={warningTitle}
      />
    </div>
  );
}

export default PendingManagerCard;
