import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { RESET_PASSWORD_MUTATION } from '../../api/mutations/users';
import { Input } from '../../layout/fields';
import { AuthLayout } from '../../layout/layouts';

function ResetPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();
  const [reset,
    {
      data,
      loading,
    }] = useMutation(RESET_PASSWORD_MUTATION);

  const schema = yup.object({
    email: yup.string().required(t('Email is Required')),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      reset({ variables });
    },
  });

  useEffect(() => {
    if (data) {
      setEmailSent(true);
    }
  }, [data]);

  return (
    <AuthLayout>
      <h2 className="my-6 text-2xl font-extrabold ">{t('Reset Your Password')}</h2>
      {emailSent
        ? (
          <div>
            <p>{t('We have sent the new password to your E-mail.')}</p>
            <p>{t('Please use it to log in the app.')}</p>
            <Link
              to="/authentication/login"
              className="font-medium text-primary hover:text-primary transition duration-150"
            >
              {t('Back to Login page')}
              .
            </Link>
          </div>
        )
        : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <Input
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                //@ts-ignore
                errorMessage={touched.email && formikErrors.email}
                label={t('Email')}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-1 sm:space-y-0 sm:flex sm:items-center sm:justify-between">
              <div className="text-sm">
                <Link
                  to="/authentication/login"
                  className="font-medium text-primary hover:text-primary transition duration-150"
                >
                  {t('Back to Login page')}
                  ?
                </Link>
              </div>
              <div className="text-sm">
                <Link
                  to="/authentication/register/applicant/"
                  className="font-medium text-primary hover:text-primary transition duration-150"
                >
                  {t("Don't have an account?")}
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading && true}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary transition duration-150 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t('Reset')}
              </button>
            </div>
          </form>
        )}
    </AuthLayout>
  );
}

export default ResetPassword;
