import { useTranslation } from 'react-i18next';
import {
  CURRENT_COMPANY_DASHBOARD_QUERY,
} from '../../../api/queries/companies';
import { DASHBOARD_OFFERS_QUERY } from '../../../api/queries/offers';
import { CompleteCompanyForm } from '../../../components/manager/company';
import {
  SORT_TYPE, FILTER_TYPE,
} from '../../../constants';
import { ManagerDashboardLayout } from '../../../layout/layouts/dashboard';

function CreateAdditionalCompany() {
  const { t } = useTranslation();

  return (
    <ManagerDashboardLayout>
      <div className="max-w-4xl mx-auto grid grid-cols-1 gap-4 lg:col-span-2">
        <div className="mt-5 md:mt-0 md:col-span-6">
          <div className="shadow overflow-hidden rounded sm:rounded-md">
            <div className="px-6 bg-white pt-10">
              <div className="text-xl font-bold ">{t('Create Additional Company')}</div>
            </div>
            <CompleteCompanyForm
              refetchQueries={[{
                query: DASHBOARD_OFFERS_QUERY,
                variables: {
                  page: 1,
                  filterBy: FILTER_TYPE.NONE,
                  orderBy: SORT_TYPE.DEFAULT,
                },
              },
              { query: CURRENT_COMPANY_DASHBOARD_QUERY }]}
            />
          </div>
        </div>
      </div>
    </ManagerDashboardLayout>
  );
}

export default CreateAdditionalCompany;
