import { useQuery, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { UserType } from '../../../__generated__/graphql';
import { MINIFIED_CURRENT_COMPANY_QUERY } from '../../../api/queries/companies';
import {
  MANAGER_ONBOARDING_STATUS_QUERY,
  CURRENT_USER_QUERY,
} from '../../../api/queries/users';
import { USER_TYPE } from '../../../constants';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setSuccessAlert } from '../../../redux/alertSlice';
import { CompanyPackages } from '../../settings/manager/company/companyPackages';
import { PaymentOptions } from '../../settings/manager/company/paymentOptions';
import { ProfileForm } from '../../shared';
import { Sidebar } from '../../shared/onboarding';
import CompanyWrapper from './CompanyWrapper';

interface Props {
  user: UserType,
}

function ManagerOnboarding(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: managerData } = useQuery(MANAGER_ONBOARDING_STATUS_QUERY);
  const [
    getCompany,
    {
      data: companyData,
    }] = useLazyQuery(MINIFIED_CURRENT_COMPANY_QUERY);

  useEffect(() => {
    if (user && user.isOnboarded) {
      dispatch(setSuccessAlert([t('Congratulations! You are ready to use an application now.')]));
    }
  }, [user, dispatch, t]);

  useEffect(
    () => {
      if (managerData && managerData.managerOnboardingStatus
        && managerData.managerOnboardingStatus.hasProfile
        && managerData.managerOnboardingStatus.hasCompany) {
        getCompany();
      }
    },
    [getCompany, managerData],
  );

  if (user.isOnboarded) {
    return <Navigate to="/" />;
  }

  return (
    managerData && managerData.managerOnboardingStatus ? (
      <div className=" grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* Left column */}
        <Sidebar
          onboardingStatus={managerData.managerOnboardingStatus}
          userType={USER_TYPE.MANAGER}
        />
        {/* Right column */}
        {managerData.managerOnboardingStatus
          && !managerData.managerOnboardingStatus.hasProfile
          && (
            <ProfileForm
              userId={user.id}
              refetchQueries={[{
                query: MANAGER_ONBOARDING_STATUS_QUERY,
              },
              { query: CURRENT_USER_QUERY }]}
            />
          )
        }
        {managerData.managerOnboardingStatus
          && managerData.managerOnboardingStatus.hasProfile
          && !managerData.managerOnboardingStatus.hasCompany
          && (
            <CompanyWrapper
              refetchQueries={[{
                query: MANAGER_ONBOARDING_STATUS_QUERY,
              },
              { query: CURRENT_USER_QUERY }]}
            />
          )
        }
        {managerData.managerOnboardingStatus
          && managerData.managerOnboardingStatus.hasProfile
          && managerData.managerOnboardingStatus.hasCompany
          && !managerData.managerOnboardingStatus.hasCompanyPackage
          && companyData
          && (
            <CompanyPackages
              companyId={companyData.currentCompany.id}
              refetchQueries={[
                { query: CURRENT_USER_QUERY },
                { query: MANAGER_ONBOARDING_STATUS_QUERY },
              ]}
            />
          )
        }
        {managerData.managerOnboardingStatus
          && managerData.managerOnboardingStatus.hasProfile
          && managerData.managerOnboardingStatus.hasCompany
          && managerData.managerOnboardingStatus.hasCompanyPackage
          && companyData
          && (
            <PaymentOptions
              companyId={companyData.currentCompany.id}
              refetchQueries={[
                { query: CURRENT_USER_QUERY },
                { query: MANAGER_ONBOARDING_STATUS_QUERY },
              ]}
            />
          )
        }
      </div>
    ) : '');
}

export default ManagerOnboarding;
