import { gql } from '@apollo/client';

export const APPLICANT_MUTATION = gql`
  mutation applicant(
    $id: ID,
    $birthdate: Date!,
    $files: [FileInputType]!,
    $driverLicence: String,
    $goals: String,
    $isOpenForTravelling: Boolean!,
    $languages: String!,
    $miscellaneous: String,
    $user: ID!,
    $salary: Decimal!,
    $skills: [String]
    $title: String!
    $qualification: String!,
    ){
      applicant(input:{
      id: $id
      birthdate: $birthdate
      files: $files
      driverLicence: $driverLicence
      goals: $goals
      isOpenForTravelling: $isOpenForTravelling
      languages: $languages
      miscellaneous: $miscellaneous
      user: $user
      salary: $salary
      skills: $skills
      title: $title
      qualification: $qualification
    }){
      response{
        id
        birthdate
        cv{
          id
          name
          file
        }
        driverLicence
        goals
        languages
        miscellaneous
        skills{
          id
          name
        }
        certificates{
          id
          name
          file
        }
        isOpenForTravelling
        salaryDetails{
          id
          exactValue
        }
        title
        qualification
        user{
          id
        }
      }
      errors{
        field
        messages
      }
    }
  }
`;
