import { PaginatedRecommendedOffersType, PublicOfferType } from '../../../__generated__/graphql';
import {
  Pagination,
} from '../../../layout';
import OffersWidgetCard from './OffersWidgetCard';

interface Props {
  items: PaginatedRecommendedOffersType,
  setPage: (page: number) => void,
}

function OffersWidget(props: Props) {
  const { items, setPage } = props;

  return (
    <div className="mx-auto pt-2 px-4 sm:px-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {items && items.objects && items.objects.map((item) => (
          <OffersWidgetCard key={item?.id} item={item as PublicOfferType} />
        ))}
      </div>
      <div className="h-14 mx-4">
        <Pagination
          page={items.page as number}
          hasNext={items.hasNext as boolean}
          hasPrev={items.hasPrev as boolean}
          elidedPageRange={items.elidedPageRange as string[]}
          onPageChange={setPage}
          omitBorder
          omitNumbers
        />
      </div>
    </div>
  );
}

export default OffersWidget;
