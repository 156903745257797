import { gql } from '@apollo/client';

export const MINIFIED_CURRENT_PROFILE_QUERY = gql`
  query minifiedCurrentProfile{
    currentProfile{
      id
    }
  }
`;

export const CURRENT_PROFILE_QUERY = gql`
  query currentProfile{
    currentProfile{
      id
      firstName
      lastName
      imageSrcset
    }
  }
`;
