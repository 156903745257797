import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ObtainJsonWebToken } from '../../../__generated__/graphql';
import { REGISTER_MUTATION, SOCIAL_REGISTER_MUTATION } from '../../../api/mutations/users';
import { SocialAuth } from '../../../components/auth/social';
import { SOCIAL_AUTH_TYPE, USER_TYPE } from '../../../constants';
import { setAuthCookies } from '../../../helpers/auth';
import { classNames } from '../../../helpers/utils';
import { Input } from '../../../layout/fields';
import { ErrorsList } from '../../../layout/forms';
import { AuthLayout } from '../../../layout/layouts';

function onMutationSuccess(response: ObtainJsonWebToken, isSocial: boolean) {
  let path = '/authentication/email-confirmation';
  if (isSocial) {
    setAuthCookies(response.token, response.refreshToken);
    path = '/';
  }
  window.location.href = path;
}

function Register() {
  const [acceptsLegals, setAcceptsLegals] = useState(false);
  const { t } = useTranslation();
  const googleButtonLabel = t('Continue with Google');
  const facebookButtonLabel = t('Continue with Facebook');

  const [
    register,
    {
      data: registerData,
      loading: registerLoading,
    }] = useMutation(REGISTER_MUTATION);
  const [
    socialRegisterMutation,
    {
      data: socialData,
      loading: socialLoading,
    },
  ] = useMutation(SOCIAL_REGISTER_MUTATION);

  useEffect(() => {
    if (registerData || socialData) {
      const data = registerData || socialData;
      const innerData = data[Object.keys(data)[0]];
      if (innerData.response) {
        const isSocial = socialData || false;
        onMutationSuccess(innerData.response, isSocial);
      }
    }
  }, [registerData, socialData]);

  const schema = yup.object({
    email: yup.string().required(t('Email is Required')),
    password1: yup.string().required(t('Password is Required')),
    password2: yup.string().required(t('Password Confirmation is Required')),
    userType: yup.string().required(t('User Type is Required')),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
    touched,
    errors: formikErrors,
    validateField,
  } = useFormik({
    initialValues: {
      email: '',
      password1: '',
      password2: '',
      userType: USER_TYPE.APPLICANT,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      register({ variables });
    },
  });
  return (
    <AuthLayout>
      <div className="h2 mt-6 text-2xl font-extrabold ">{t('Craftsman Registration')}</div>
      <div className="justify-between mb-6">
        <p className="my-2 text-sm ">{t('Register quickly and easily with Springboard Crafts so that you can find the job that really suits you. What are you waiting for?')}</p>
        <div className="text-sm">
          <Link
            to="/authentication/register/manager/"
            className="font-medium text-primary hover:text-primary transition duration-150"
          >
            {t('Register a Craft Business')}
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            //@ts-ignore
            errorMessage={touched.email && formikErrors.email}
            label={t('Email')}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <Input
            id="password1"
            name="password1"
            type="password"
            autoComplete="password"
            //@ts-ignore
            errorMessage={touched.password1 && formikErrors.password1}
            label={t('Password')}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <Input
            id="password2"
            name="password2"
            type="password"
            autoComplete="password"
            //@ts-ignore
            errorMessage={touched.password2 && formikErrors.password2}
            label={t('Confirm Password')}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="legals"
                name="legals"
                onChange={() => setAcceptsLegals(!acceptsLegals)}
                checked={acceptsLegals}
                type="checkbox"
                className="border-secondaryGray focus:ring-primary h-4 w-4 text-primary rounded cursor-pointer"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="legals" className="font-medium ">
                Ich habe die
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sprungbrett-handwerk.de/allgemeine-geschaeftsbedingungen.html"
                  className="underline"
                >
                  AGB
                </a>
                {' '}
                gelesen und akzeptiert und die
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sprungbrett-handwerk.de/datenschutz.html"
                  className="underline"
                >
                  Datenschutzerklärung
                </a>
                {' '}
                zur Kenntnis genommen.
              </label>
            </div>
          </div>
        </div>

        {formikErrors.userType && (
          <ErrorsList
            errors={[formikErrors.userType]}
          />
        )}

        <div className="flex justify-between">
          <div className="text-sm">
            <Link
              to="/authentication/login"
              className="font-medium text-primary transition duration-150"
            >
              {t('Already registered?')}
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={(!acceptsLegals || registerLoading || socialLoading) && true}
            className={classNames(
              (!acceptsLegals || registerLoading || socialLoading)
                ? 'bg-secondaryGray'
                : 'bg-primary',
              'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
            )}
          >
            {t('Register')}
          </button>
        </div>
      </form>

      <div className="my-6 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-lightGray" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white ">{t('Or')}</span>
        </div>
      </div>

      <div role="button" tabIndex={0} onClick={() => validateField('userType')}>
        <SocialAuth
          isDisabled={!acceptsLegals}
          executeMutation={socialRegisterMutation}
          userType={formikValues.userType}
          googleButtonLabel={googleButtonLabel}
          facebookButtonLabel={facebookButtonLabel}
          authType={SOCIAL_AUTH_TYPE.REGISTER}
        />
      </div>
    </AuthLayout>
  );
}

export default Register;
