/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';

function PlaceholderCard() {
  const { t } = useTranslation();
  return (
    <div className="grid gap-4">
      <div className="text-2xl font-extrabold tracking-tight ">{t('Like, match and get to know each other')}</div>
      <div className="text-base tracking-tight">{t('Here you can profit very quickly from Sprungbrett Handwerk.')}</div>

      <p className="text-base">
        <span className="font-extrabold">{t('Match')}</span>
        {' - '}
        <span>{t('You will be notified when your company or one of your job advertisements receives a "Like" from a craftsman. If the craftsman is interesting for your company, you return a Like and you can chat with each other here.')}</span>
      </p>

      <p className="text-base">
        <span className="font-extrabold">{t('New job offer?')}</span>
        <br />
        <span>{t('If you have more jobs posted and you are connected to a craftsman via a Like, the craftsman will automatically see all of those jobs from you.')}</span>
      </p>

      <p className="text-base">
        <span>{t('Tip')}</span>
        {': '}
        <span className="font-extrabold">{t('Your company profile')}</span>
        {' - '}
        <span>{t('You should have created a meaningful company profile. This significantly increases your chances of finding a new member for your team.')}</span>
      </p>
    </div>
  );
}

export default PlaceholderCard;
