import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { PreferredLocationForm } from '..';
import { UserType } from '../../../__generated__/graphql';
import {
  APPLICANT_ONBOARDING_STATUS_QUERY,
  CURRENT_USER_QUERY,
} from '../../../api/queries/users';
import { USER_TYPE } from '../../../constants';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setSuccessAlert } from '../../../redux/alertSlice';
import { ApplicantForm, ProfileForm } from '../../shared';
import { Sidebar } from '../../shared/onboarding';

interface Props {
  user: UserType,
}

function ApplicantOnboarding(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data } = useQuery(APPLICANT_ONBOARDING_STATUS_QUERY);

  useEffect(() => {
    if (user && user.isOnboarded) {
      dispatch(setSuccessAlert([t('Congratulations! You are ready to use an application now.')]));
    }
  }, [user, dispatch, t]);

  if (user.isOnboarded) {
    return <Navigate to="/" />;
  }

  return (
    data && data.applicantOnboardingStatus ? (
      <div className=" grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* Left column */}
        <Sidebar
          onboardingStatus={data.applicantOnboardingStatus}
          userType={USER_TYPE.APPLICANT}
        />
        {/* Right column */}
        {data.applicantOnboardingStatus && !data.applicantOnboardingStatus.hasProfile && (
          <ProfileForm
            userId={user.id}
            refetchQueries={[{
              query: APPLICANT_ONBOARDING_STATUS_QUERY,
            },
            { query: CURRENT_USER_QUERY }]}
          />
        )}
        {data.applicantOnboardingStatus
          && data.applicantOnboardingStatus.hasProfile
          && !data.applicantOnboardingStatus.hasApplicant
          && (
            <ApplicantForm
              userId={user.id}
            />
          )
        }
        {data.applicantOnboardingStatus
          && data.applicantOnboardingStatus.hasProfile
          && data.applicantOnboardingStatus.hasApplicant
          && (
            <PreferredLocationForm
              refetchQueries={[{
                query: CURRENT_USER_QUERY,
              }]}
            />
          )
        }
      </div>
    ) : '');
}

export default ApplicantOnboarding;
