import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useRef } from 'react';
import { LimitedApplicantType, OfferType } from '../../__generated__/graphql';
import { SmallScreenDetailCard as OfferSmallScreenDetailCard } from '../applicant/offers';
import { SmallScreenSearchCard as ApplicantSmallScreenSearchCard } from '../manager/applicants';

interface Props {
  modalState: boolean,
  setModalState: (value: boolean) => void,
  item: (OfferType | null) | (LimitedApplicantType | null),
  cardIsOffer?: boolean,
  cardIsApplicant?: boolean,
  viewerIsApplicant: boolean,
}

export default function DetailSlideOver(props: Props) {
  // Is showed only on small screens.
  const {
    modalState, setModalState, item, cardIsOffer, cardIsApplicant, viewerIsApplicant,
  } = props;

  const refDiv = useRef(null);

  return (
    <Transition.Root show={modalState} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        className="sm:hidden fixed inset-0 overflow-hidden"
        onClose={setModalState}
      >
        <div ref={refDiv} className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-darkerGray bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-9 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-primary text-white"
                      onClick={() => setModalState(false)}
                    >
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {cardIsOffer && (
                  <OfferSmallScreenDetailCard
                    item={item as OfferType}
                    viewerIsApplicant={viewerIsApplicant}
                  />
                )}
                {cardIsApplicant && (
                  <ApplicantSmallScreenSearchCard
                    item={item as LimitedApplicantType}
                    viewerIsApplicant={viewerIsApplicant}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
