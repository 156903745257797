/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
// eslint-disable-next-line import/named
import { CSSObjectWithLabel, ControlProps, GroupBase, Theme } from 'react-select';
import { EcommerceCompanyPackageCurrencyCodeChoices } from '../__generated__/graphql';
import {
  TARGET_FLEID,
  DRIVER_LICENSE_A_TYPE,
  DRIVER_LICENSE_B_TYPE,
  DRIVER_LICENSE_C_TYPE,
  DRIVER_LICENSE_D_TYPE,
  DRIVER_LICENSE_Z_TYPE,
  JOB_TITLE_HOLZHANDWERK_TYPE,
  JOB_TITLE_METALLHANDWERK_TYPE,
  JOB_TITLE_GLAS_UND_KERAMIK_TYPE,
  JOB_TITLE_BAUHANDWERK_TYPE,
  JOB_TITLE_ELEKTROTECHNIK_TYPE,
  JOB_TITLE_SANITAR_UND_VERSORGUNGSTECHNIK_TYPE,
  JOB_TITLE_FAHRZEUG_UND_MASCHINENTECHNIK_TYPE,
  JOB_TITLE_FARBTECHNIK_TYPE,
  JOB_TITLE_STEIN_UND_PLATTENLEGEHANDWERK_TYPE,
  JOB_TITLE_INSTRUMENTENBAU_TYPE,
  JOB_TITLE_BEKLEIDUNGS_TEXTIL_UND_LEDERHANDWERK_TYPE,
  JOB_TITLE_LEBENSMITTELHANDWERK_TYPE,
  JOB_TITLE_HANDWERK_IN_DER_GESUNDHEITSBRANCHE_TYPE,
  JOB_TITLE_HANDWERK_IN_DER_SCHONHEITSBRANCHE_TYPE,
  JOB_TITLE_SONSTIGE_GEWERKE_TYPE,
} from '../constants';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function fixStr(str: string) {
  let out = str.replace(/^\s*/, ''); // strip leading spaces
  out = out.replace(/^[a-z]|[^\s][A-Z]/g, (innerStr, offset) => {
    if (offset === 0) {
      return (innerStr.toUpperCase());
    }
    return (`${innerStr.substring(0, 2)} ${innerStr.substring(1).toUpperCase()}`);
  });
  return (out);
}

export function getInlineOptionsFromQuery(queryData: any, labelField = 'name') {
  const innerData = queryData[Object.keys(queryData)[0]];
  return innerData.map(
    (item: any) => (
      {
        label: item[labelField],
        value: item.id,
      }
    ),
  );
}

// Update css theme for react-select
export function updateSelectTheme(theme: Theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#054787',
    },
  };
}

export const zIndexTweakedSelectStyles = {
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
};

// Update css theme for react-select when form validation fails
export const updateSelectStylesOnError = {
  control: (styles: CSSObjectWithLabel, state: ControlProps<unknown, boolean, GroupBase<unknown>>) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(252, 75, 108, 0.25)' : 0,
    borderColor: '#f6aaab',
    '&:hover': {
      borderColor: '#ef4444',
    },
  }),
};

export const zIndexTweakedSelectStylesOnError = {
  ...zIndexTweakedSelectStyles,
  ...updateSelectStylesOnError,
};

export const convertArrayToString = (inputArray: string[]) => {
  if (!!inputArray.length === false) return '';
  return inputArray.map((item) => item).join();
};

export function convertInlineOptionsToString(inputArray: SelectOption[], targetField: string) {
  if (![TARGET_FLEID.LABEL, TARGET_FLEID.VALUE].includes(targetField.toUpperCase())) return '';
  if (!!inputArray.length === false) return '';
  return inputArray.map((item) => item && item[targetField.toLowerCase() as keyof typeof item]).join();
}

export function isEmpty(str: string) {
  return (!str || str.length === 0);
}

export function objIsEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function getLabelFromEnumValue(value: string) {
  const tmp = value.toLowerCase().replaceAll('_', ' ');
  return toTitleCase(tmp);
}

type EnumDictionaryType = {
  [key: string]: string;
};

export function getInlineOptionsFromEnumDict(enumDict: EnumDictionaryType, prettifyLabel = true) {
  // "EnumDict" means dictionary that is used as enumerator
  return Object.entries(enumDict).map(
    (item) => {
      const [key, value] = item;
      const label: string = prettifyLabel ? getLabelFromEnumValue(value as string) : value;
      return { value: key, label };
    },
  );
}

export function getInitials(name: string) {
  return name.match(/(^\S\S?|\b\S)?/g)!.join('').match(/(^\S|\S$)?/g)!.join('')
    .toUpperCase();
}

export function getCommaSeparatedStringFromArray(inputArray: string[]) {
  if (!!inputArray.length === false) return '';
  return inputArray.map((item, index) => {
    if (index === inputArray.length - 1) { return item; }
    return `${item}, `;
  });
}

export function getLabeledDriverLicenceOptions() {
  return [
    {
      label: 'Kraftrad, Trike und Quad',
      options: getInlineOptionsFromEnumDict(DRIVER_LICENSE_A_TYPE, false),
    },
    {
      label: 'Pkw',
      options: getInlineOptionsFromEnumDict(DRIVER_LICENSE_B_TYPE, false),
    },
    {
      label: 'Lkw',
      options: getInlineOptionsFromEnumDict(DRIVER_LICENSE_C_TYPE, false),
    },
    {
      label: 'Bus',
      options: getInlineOptionsFromEnumDict(DRIVER_LICENSE_D_TYPE, false),
    },
    {
      label: 'Zugfahrzeuge',
      options: getInlineOptionsFromEnumDict(DRIVER_LICENSE_Z_TYPE, false),
    },
  ];
}

export function getLabeledJobTitleOptions() {
  return [
    {
      label: 'Holzhandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_HOLZHANDWERK_TYPE, false),
    },
    {
      label: 'Metallhandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_METALLHANDWERK_TYPE, false),
    },
    {
      label: 'Glas- und Keramikhandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_GLAS_UND_KERAMIK_TYPE, false),
    },
    {
      label: 'Bau- und Ausbauhandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_BAUHANDWERK_TYPE, false),
    },
    {
      label: 'Elektrotechnik',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_ELEKTROTECHNIK_TYPE, false),
    },
    {
      label: 'Sanitär- und Versorgungstechnik',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_SANITAR_UND_VERSORGUNGSTECHNIK_TYPE, false),
    },
    {
      label: 'Fahrzeug- und Maschinentechnik',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_FAHRZEUG_UND_MASCHINENTECHNIK_TYPE, false),
    },
    {
      label: 'Farbtechnik',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_FARBTECHNIK_TYPE, false),
    },
    {
      label: 'Stein- und Plattenlegehandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_STEIN_UND_PLATTENLEGEHANDWERK_TYPE, false),
    },
    {
      label: 'Instrumentenbau',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_INSTRUMENTENBAU_TYPE, false),
    },
    {
      label: 'Bekleidungs-, Textil- und Lederhandwerk',
      options: getInlineOptionsFromEnumDict(
        JOB_TITLE_BEKLEIDUNGS_TEXTIL_UND_LEDERHANDWERK_TYPE,
        false,
      ),
    },
    {
      label: 'Lebensmittelhandwerk',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_LEBENSMITTELHANDWERK_TYPE, false),
    },
    {
      label: 'Handwerk in der Gesundheitsbranche',
      options: getInlineOptionsFromEnumDict(
        JOB_TITLE_HANDWERK_IN_DER_GESUNDHEITSBRANCHE_TYPE,
        false,
      ),
    },
    {
      label: 'Handwerk in der Schönheitsbranche',
      options: getInlineOptionsFromEnumDict(
        JOB_TITLE_HANDWERK_IN_DER_SCHONHEITSBRANCHE_TYPE,
        false,
      ),
    },
    {
      label: 'Sonstige Gewerke',
      options: getInlineOptionsFromEnumDict(JOB_TITLE_SONSTIGE_GEWERKE_TYPE, false),
    },
  ];
}

export function getFormattedDate(inputDate: Date, inputFormat?: string) {
  const format = inputFormat || 'DD.MM.YYYY';
  return moment(inputDate).format(format);
}

export function getUniqueObjects(objects: any) {
  const result = [];
  const map = new Map();

  // eslint-disable-next-line no-restricted-syntax
  for (const item of objects) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      result.push(item);
    }
  }

  return result;
}

export const getFilesErrorLabel = (t: any, errorType: any, maxFileSize: any, multipleFilesMaxSize?: any) => {
  switch (errorType) {
    case 'multipleNotAllowed':
      return t('Multiple Files Not Allowed');
    case 'unsupportedFileType':
      return t('Uploaded File type is not supported');
    case 'maxSizeExceeded':
      return `${t('Maximum File size:')} ${maxFileSize}`;
    case 'multipleMaxSizeExceeded':
      return `${t('Maximum multiple Files size:')} ${multipleFilesMaxSize}`;
    default:
      return 'Error';
  }
};

export const getCurrencySymbol = (currency: EcommerceCompanyPackageCurrencyCodeChoices) => { const symbol = new Intl.NumberFormat('de', { style: 'currency', currency }).formatToParts(0).find((x) => x.type === 'currency'); return symbol && symbol.value; };

export const parsePayPalError = (inputError: any) => {
  const errorsList = inputError.message.match('{(.*)}');
  let errorMessage = inputError.message;
  if (errorsList) {
    const errorDict = JSON.parse(inputError.message.match('{(.*)}')[0]);
    const errorDetails = errorDict.details[0];
    errorMessage = `${errorDetails.issue}: ${errorDetails.description}`;
  }
  return `PayPal ${errorMessage}`;
};
