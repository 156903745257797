import { useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInvitationType } from '../../../__generated__/graphql';
import { COMPANY_INVITATIONS_QUERY } from '../../../api/queries/companyInvitations';
import { classNames } from '../../../helpers/utils';
import { CompanyCard } from '../../settings/manager/companies';
import CompanyInvitationForm from './CompanyInvitationForm';
import OnboardingCompanyForm from './OnboardingCompanyForm';

export interface OnboardingFormStateType {
  companySize: string,
  description: string,
  email: string,
  highlights: string,
  logoImage: string,
  name: string,
  phone: string,
  profileImage: string,
  website: string,
  areaCode: SelectOption | null,
  city: SelectOption | null,
  street: string,
  country: null,
  branche?: string,
}

interface Props {
  refetchQueries: { query: DocumentNode }[],
}

function CompanyWrapper(props: Props) {
  const { data: queryData } = useQuery(COMPANY_INVITATIONS_QUERY);
  const { refetchQueries } = props;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('create');
  const [invitationFormState, setInvitationFormState] = useState({ code: '' });
  const [onboardingFormState, setOnboardingFormState] = useState<OnboardingFormStateType>({
    companySize: '',
    description: '',
    email: '',
    highlights: '',
    logoImage: '',
    name: '',
    phone: '',
    profileImage: '',
    website: '',
    areaCode: null,
    city: null,
    street: '',
    country: null,
    branche: '',
  });

  const joinNameLabel = t('Join Company Using Code');
  const joinName = queryData && queryData.companyInvitations.length ? `${joinNameLabel}(${queryData.companyInvitations.length})` : joinNameLabel;

  const tabs = [
    { name: t('Create New Company'), value: 'create' },
    { name: joinName, value: 'join' },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="overflow-hidden sm:rounded-md">
          <nav className="relative z-0 rounded-lg shadow flex divide-x divide-lightGray" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <div
                key={tab.name}
                role="button"
                tabIndex={0}
                onClick={() => { setCurrentTab(tab.value); }}
                className={classNames(
                  tab.value === currentTab ? '' : ' hover:',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-lightGray focus:z-10',
                )}
                aria-current={tab.value === currentTab ? 'page' : undefined}
              >
                <span>
                  {tab.name}
                </span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.value === currentTab ? 'bg-primary' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5',
                  )}
                />
              </div>
            ))}
          </nav>
        </div>
        <div className="mt-4">
          {currentTab === 'create'
            && (
              <div className="shadow overflow-hidden rounded sm:rounded-md">
                <OnboardingCompanyForm
                  refetchQueries={refetchQueries}
                  onboardingFormState={onboardingFormState}
                  setOnboardingFormState={setOnboardingFormState}
                />
              </div>
            )}
          {currentTab === 'join' && (
            <>
              {!!queryData.companyInvitations.length && (
                <div className="mb-5">
                  <div className="text-sm leading-6 font-medium ">{t('Invites')}</div>
                  <div className="grid grid-cols-6 gap-6">
                    {queryData.companyInvitations.map((item: CompanyInvitationType) => (
                      <div key={item.id} className="col-span-6 sm:col-span-3">
                        <CompanyCard
                          status={item.status}
                          name={item.company.name}
                          owner={item?.company?.owner?.profile?.fullName as string}
                          isReadonly
                        />
                      </div>

                    ))}
                  </div>
                </div>
              )}
              <CompanyInvitationForm
                refetchQueries={[{ query: COMPANY_INVITATIONS_QUERY }]}
                invitationFormState={invitationFormState}
                setInvitationFormState={setInvitationFormState}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyWrapper;
