import { gql } from '@apollo/client';

export const PROFILE_MUTATION = gql`
  mutation profile(
    $id: ID,
    $firstName: String!,
    $lastName: String!,
    $image: String,
    $user: ID!,
    ){
      profile(input:{
      id: $id
      firstName: $firstName
      lastName: $lastName
      image: $image
      user: $user
    }){
      response{
        id
        firstName
        lastName
        imageSrcset
      }
      errors{
        field
        messages
      }
    }
  }
`;
