import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from '../../helpers/utils';

interface Props {
  navigationItems: { name: string, href: string, icon: (props: SVGProps<SVGSVGElement>) => JSX.Element }[],
}

function Sidebar(props:Props) {
  const { t } = useTranslation();
  const { navigationItems } = props;
  const location = useLocation();

  return (
    <aside className=" bg-grey-100 py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
      <nav className="space-y-1">
        {navigationItems.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={classNames(
              location.pathname.startsWith(item.href)
                ? 'bg-lightGray text-primary hover:text-primary hover:bg-white'
                : ' hover:bg-lightGray',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
            )}
          >
            <item.icon
              className={classNames(
                location.pathname.startsWith(item.href)
                  ? 'text-primary group-hover:text-primary'
                  : ' group-hover:',
                'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
              )}
              aria-hidden="true"
            />
            <span className="truncate">{t(item.name)}</span>
          </Link>
        ))}
      </nav>
    </aside>
  );
}

export default Sidebar;
