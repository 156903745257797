import ActivitiesList from '../../components/manager/dashboard/activity/ActivitesList';
import CompanyCard from '../../components/manager/dashboard/company/CompanyCard';
import { ManagerDashboardLayout } from '../../layout/layouts/dashboard';

function ManagerDashboard() {
  return (
    <ManagerDashboardLayout>
      <div className="relative min-h-screen flex flex-col">
        <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
          <div className="flex-1 min-w-0">
            <CompanyCard />
          </div>
          {/* Activity feed */}
          <div className="bg-lightGray pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-lightGray">
            <div className="pl-6 lg:w-80">
              <ActivitiesList />
            </div>
          </div>
        </div>
      </div>
    </ManagerDashboardLayout>
  );
}

export default ManagerDashboard;
