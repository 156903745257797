import { gql } from '@apollo/client';

export const MINIFIED_CURRENT_APPLICANT_QUERY = gql`
  query minifiedCurrentApplicant {
    currentApplicant{
      id
    }
  }
`;

export const CURRENT_APPLICANT_LOCATION_QUERY = gql`
  query currentApplicantLocation {
    currentApplicant{
      id
      preferredLocation{
        id
        city{
          id
          name
        }
      }
    }
  }
`;

export const CURRENT_APPLICANT_QUERY = gql`
  query currentApplicant{
    currentApplicant{
      id
      birthdate
      cv{
        id
        name
        file
      }
      driverLicence
      goals
      languages
      miscellaneous
      skills{
        id
        name
      }
      certificates{
        id
        name
        file
      }
      isOpenForTravelling
      salaryDetails{
        id
        exactValue
      }
      title
      qualification
      user{
        id
      }
    }
  }
`;

export const PUBLIC_APPLICANT_QUERY = gql`
  query publicApplicant($applicant: ID!) {
    publicApplicant(applicant: $applicant,){
      __typename
      ... on DetailsApplicantType{
        id
        certificates{
          id
          name
          file
        }
        cv{
          id
          name
          file
        }
        createdAt
        birthdate
        driverLicence
        fullTitle
        goals
        isOpenForTravelling
        languages
        preferredLocation{
          id
          city{
            id
            name
            geojson
            latitude
            longitude
          }
          country{
            id
            name
          }
          calculatedLength
        }
        miscellaneous
        skills{
          id
          name
        }
        title
        qualification
        profile{
          id
          fullName
          imageSrcset 
        }
        salaryDetails{
          id
          exactValue
          currencyCode
        }
      }
      ... on LimitedApplicantType{
          id
          createdAt
          driverLicence
          fullTitle
          goals
          isOpenForTravelling
          languages
          preferredLocation{
            id
            city{
              id
              name
              geojson
              latitude
              longitude
            }
            country{
              id
              name
            }
            calculatedLength
          }
          miscellaneous
          skills{
            id
            name
          }
          title
          qualification
          salaryDetails{
            id
            exactValue
            currencyCode
          }
      }
    }
  }
`;

export const APPLICANTS_QUERY = gql`
query applicants(
  $offset: Int
  $title: String
  $qualification: String
  $city: ID
  $salaryMin: Int
  $salaryMax: Int
  $areaRadius: Int
){
  applicants(
    offset: $offset
    title: $title
    qualification: $qualification
    city: $city
    salaryMin: $salaryMin
    salaryMax: $salaryMax
    areaRadius: $areaRadius
  ){
    hasNext
    salaryMin
    salaryMax
    objects{
      id
      driverLicence
      goals
      languages
      miscellaneous
      title
      qualification
      preferredLocation{
        id
        calculatedLength
        city{
          id
          name
          geojson
          latitude
          longitude
        }
      }
      salaryDetails{
        id
        exactValue
        currencyCode
      }
      skills{
        id
        name
      }
      user{
        id
        email
      }
    }
  }
}
`;
