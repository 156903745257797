import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { MINIFIED_CURRENT_COMPANY_QUERY, CURRENT_COMPANY_DASHBOARD_QUERY } from '../../../api/queries/companies';
import { DASHBOARD_OFFERS_QUERY } from '../../../api/queries/offers';

import { OfferForm } from '../../../components/manager/offer';
import {
  SORT_TYPE, FILTER_TYPE,
} from '../../../constants';
import { ManagerDashboardLayout } from '../../../layout/layouts/dashboard';

function CreateOffer() {
  const { t } = useTranslation();
  const {
    data: currentCompanyData,
  } = useQuery(MINIFIED_CURRENT_COMPANY_QUERY);

  return (
    <ManagerDashboardLayout>
      <div className="max-w-4xl mx-auto grid grid-cols-1 gap-4 lg:col-span-2">
        <div className="mt-5 md:mt-0 md:col-span-6">
          {currentCompanyData && currentCompanyData.currentCompany && (
            <OfferForm
              title={t('Create Offer')}
              companyId={currentCompanyData.currentCompany.id}
              refetchQueries={[{
                query: DASHBOARD_OFFERS_QUERY,
                variables: {
                  page: 1,
                  filterBy: FILTER_TYPE.NONE,
                  orderBy: SORT_TYPE.DEFAULT,
                },
              },
              { query: CURRENT_COMPANY_DASHBOARD_QUERY }]}
            />
          )}
        </div>
      </div>
    </ManagerDashboardLayout>
  );
}

export default CreateOffer;
