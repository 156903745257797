import { gql } from '@apollo/client';

export const MESSAGE_MUTATION = gql`
  mutation message(
    $body: String!,
    $sender: String!,
    $thread: ID!,
    ){
      message(input:{
        body: $body,
        sender: $sender,
        thread: $thread,
      }){
          response{
            id
          }
          errors{
            field
            messages
          }
        }
  }
`;

export const READ_THREAD_MUTATION = gql`
  mutation readThread(
    $thread: ID!
    ){
      readThread(input:{
        thread: $thread
      }){
          response
          errors{
            field
            messages
          }
        }
  }
`;

export const DELETE_MESSAGE_MUTATION = gql`
mutation deleteMessage($message: ID!){
  deleteMessage(input:{message: $message})
  { response
    errors{
      field
      messages
    }
  }
}
`;
