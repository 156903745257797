import Cookies from 'js-cookie';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Footer } from '..';
import logo from '../../assets/images/logo.svg';
import managers from '../../assets/images/managers.jpg';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { discardAlert } from '../../redux/alertSlice';
import Alert from '../Alert';

interface Props {
  children: ReactNode,
}

export default function ManagerAuthLayout({ children }: Props) {
  const alertsState = useAppSelector((state) => state.alert);
  const dispatch = useAppDispatch();
  if (Cookies.get('jwt_token')) {
    return <Navigate to="/" />;
  }

  // We duplicate alert areas here to cover both layouts
  // (for large screens and other)
  return (
    <div className="min-h-screen bg-white flex justify-center">
      {alertsState.alert
        && (
          <Alert
            key={alertsState.alert.id}
            alertType={alertsState.alert.alertType}
            isOpen={alertsState.alert.show}
            messageList={alertsState.alert.messages}
            isAuthLg
            toggle={() => { dispatch(discardAlert()); }}
          />
        )}
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={managers}
          alt="Manager Register Screen"
        />
      </div>
      <div className="grid px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96 pt-6 pb-2">
          <div>
            <img
              className="h-12 w-auto"
              src={logo}
              alt="Sprungbrett Handwerk Logo"
            />
            <div className="mt-6">
              {children}
            </div>
          </div>
        </div>
        <div className="self-end">
          <Footer />
        </div>
      </div>
    </div>
  );
}
