import { gql } from '@apollo/client';

export const DASHBOARD_OFFERS_QUERY = gql`
  query dashboardOffers(
    $page: Int
    $orderBy: OrderBy
    $filterBy: FilterBy
    ) {
      dashboardOffers(
      page: $page
      orderBy: $orderBy
      filterBy: $filterBy
      ){
        page
        pages
        hasNext
        hasPrev
        elidedPageRange
        totalObjectsQuantity
        objects{
          id
          createdAt
          descriptionPreview
          isActive
          likesQuantity
          fullTitle
          totalViewsQuantity
          salaryDetails{
            id
            valueLabel
          }
      }
    }
  }
`;

export const OFFER_QUERY = gql`
query offer(
  $offer: ID!
  ) {
  offer(
    offer: $offer
    ){
      id
      address{
        id
        street
        areaCode{
          id
        }
        city{
          id
          name
        }
        country{
          id
          name
        }
      }
      salaryDetails{
        id
        currencyCode
        exactValue
      }
      description
      driverLicence
      company{
        id
        name
        address{
          id
          street
          areaCode{
            id
          }
          city{
            id
            name
          }
          country{
            id
            name
          }
        }
      }
      isActive
      languages
      skills{
        id
        name
        nameLower
      }
      title
      fullTitle
      travellingRequired
      qualification
      useCompanyAddress
  }
}
`;

export const PUBLIC_OFFER_QUERY = gql`
  query publicOffer($offer: ID!) {
    publicOffer(offer: $offer,){
      id
      createdAt
      fullTitle
      address{
        id
        fullAddress
        coordinates
        city{
          name
        }
      }
      salaryDetails{
        id
        currencyCode
        exactValue
      }
      description
      driverLicence
      company{
        id
        email
        name
        phone
        website
        logoImageSrcset
        profileImageSrcset
        address{
          id
          fullAddress
          coordinates
          city{
            id
            name
          }
        }
        name
        description
        benefits{
          id
          benefitType
          description
        }
      }
      languages
      skills{
        id
        name
        nameLower
      }
      title
      travellingRequired
      qualification
      useCompanyAddress
    }
  }
`;

export const OFFERS_QUERY = gql`
query offers(
  $offset: Int
  $title: String
  $qualification: String
  $city: ID
  $salaryMin: Int
  $salaryMax: Int
  $areaRadius: Int
){
  offers(
    offset: $offset
    title: $title
    qualification: $qualification
    city: $city
    salaryMin: $salaryMin
    salaryMax: $salaryMax
    areaRadius: $areaRadius
  ){
    hasNext
    salaryMin
    salaryMax
    objects{
      id
      description
      driverLicence
      createdAt
      title
      qualification
      travellingRequired
      languages
        skills{
          id
          name
          nameLower
        }
      address{
        id
        city{
          id
          name
        }
      }
      company{
        id
        description
        name
        email
        logoImageSrcset
        profileImageSrcset
        phone
        website
        address{
          id
          city{
            id
            name
          }
        }
        benefits{
          id
          benefitType
          description
        }
      }
      salaryDetails{
        id
        exactValue
        currencyCode
      }
      useCompanyAddress
    }
  }
}
`;

export const MATCHED_APPLICANT_OFFERS_QUERY = gql`
query matchedApplicantOffers(
  $page: Int
){
  matchedApplicantOffers(
    page: $page
  ){
    page
    pages
    hasNext
    hasPrev
    elidedPageRange
    totalObjectsQuantity
    objects{
      id
      title
      qualification
      address{
        id
        city{
          id
          name
        }
      }
      company{
        id
        name
        email
        logoImageSrcset
        address{
          id
          city{
            id
            name
          }
        }
      }
      salaryDetails{
        id
        exactValue
        currencyCode
      }
      useCompanyAddress
    }
  }
}
`;

export const RECOMMENDED_OFFERS_QUERY = gql`
query recommendedOffers(
  $page: Int
){
  recommendedOffers(
    page: $page
  ){
    page
    pages
    hasNext
    hasPrev
    elidedPageRange
    totalObjectsQuantity
    objects{
      id
      fullTitle
      qualification
      description
      useCompanyAddress
      company{
        id
        name
        logoImageSrcset
        address{
          id
          fullAddress
          coordinates
          city{
            name
          }
        }
      }
      address{
        id
        fullAddress
        coordinates
        city{
          name
        }
      }
    }
  }
}
`;

export const COMPANY_OFFERS_QUERY = gql`
query companyOffers(
  $company: ID!
  $page: Int
){
  companyOffers(
    company: $company
    page: $page
  ){
    page
    pages
    hasNext
    hasPrev
    elidedPageRange
    totalObjectsQuantity
    objects{
      id
      fullTitle
      qualification
      description
      useCompanyAddress
      company{
        id
        name
        logoImageSrcset
        address{
          id
          fullAddress
          coordinates
          city{
            name
          }
        }
      }
      address{
        id
        fullAddress
        coordinates
        city{
          name
        }
      }
    }
  }
}
`;
