import { ApplicantLikeType, ApplicantOfferViewType } from '../../../__generated__/graphql';
import ActivityCard from './ActivityCard';

interface Props {
  items: ApplicantLikeType[] | ApplicantOfferViewType[],
}

function ActivitiesList(props: Props) {
  const { items } = props;
  return (
    <ul className="mt-5 border-t border-lightGray divide-y divide-lightGray sm:mt-0 sm:border-t-0">
      {items.map((item) => (
        <li key={item.id}>
          <ActivityCard item={item} />
        </li>
      ))}
    </ul>
  );
}

export default ActivitiesList;
