import { useApolloClient } from '@apollo/client';
import DateTime from 'react-datetime';
import { Link } from 'react-router-dom';
import { ApplicantLikeType, ApplicantOfferViewType, ApplicantType, CompanyType, DetailsApplicantType, OfferType, SimplifiedOfferType } from '../../../__generated__/graphql';
import { PUBLIC_APPLICANT_QUERY } from '../../../api/queries/applicants';
import { PUBLIC_COMPANY_QUERY } from '../../../api/queries/companies';
import { PUBLIC_OFFER_QUERY } from '../../../api/queries/offers';
import { TimeAgo } from '../../../layout';

// Hybrid type to combine ApplicantOfferViewType & ApplicantLikeType,
// necessary for functionality of getTitle function
interface ActivityCardType {
  applicant: DetailsApplicantType | ApplicantType,
  company?: CompanyType,
  createdAt: DateTime | Date,
  id: string,
  offer: OfferType | SimplifiedOfferType,
  target?: string,
  updatedAt?: DateTime,
}

interface Props {
  item: ApplicantLikeType | ApplicantOfferViewType,
}

function ActivityCard(props: Props) {
  const { item } = props;
  const client = useApolloClient();
  const getTitle = (data: ActivityCardType) => {
    if (data.offer) {
      return (
        <Link
          to={`/offer/${data.offer.id}/`}
          onMouseEnter={() => client.query({
            query: PUBLIC_OFFER_QUERY,
            variables: { offer: data?.offer?.id },
          })}
        >
          {data.offer.fullTitle}
        </Link>
      );
    } if (data.company) {
      return (
        <Link
          to={`/company/${data.company.id}/`}
          onMouseEnter={() => client.query({
            query: PUBLIC_COMPANY_QUERY,
            variables: { company: data?.company?.id },
          })}
        >
          {data.company.name}
        </Link>
      );
    }
    return '';
  };

  return (
    <div className="group block">
      <div className="flex items-center py-4 px-4 sm:py-4 sm:px-0">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4">
            <div className="col-span-2">
              <Link
                to={`/applicant/${item?.applicant?.id}/`}
                onMouseEnter={() => client.query({
                  query: PUBLIC_APPLICANT_QUERY,
                  variables: { applicant: item?.applicant?.id },
                })}
              >
                <p className="font-medium text-primary truncate">
                  {item?.applicant?.fullTitle}
                </p>
              </Link>
            </div>
            <div className="col-span-2">
              <span className="font-semibold ">{getTitle(item as ActivityCardType)}</span>
            </div>
            <div>
              <p className="text-sm ">
                <TimeAgo date={item.createdAt} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityCard;
