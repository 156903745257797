import { CompanyType, PossibleCompanyMatchType, PublicCompanyType } from '../__generated__/graphql';
import { getInitials, classNames } from '../helpers/utils';

function getCompanyImage(company: PublicCompanyType | CompanyType | PossibleCompanyMatchType, imageSize: string) {
  if (company && company.logoImageSrcset) {
    return company.logoImageSrcset[imageSize];
  }
  return null;
}

interface Props {
  company: PublicCompanyType | CompanyType | PossibleCompanyMatchType,
  className: string,
  imageSize: string,
}

function CompanyLogoImage(props: Props) {
  const { company, className, imageSize } = props;
  const image = getCompanyImage(company, imageSize);

  if (image) {
    return (
      <img
        className={className}
        src={image}
        alt=""
      />
    );
  }
  return (
    <span className={classNames(className && className, 'inline-flex items-center justify-center bg-primary')}>
      <span className="font-medium leading-none text-white">
        {getInitials(company.name as string)}
      </span>
    </span>
  );
}

export default CompanyLogoImage;
