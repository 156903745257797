import { useTranslation } from 'react-i18next';

const navigation = {
  main: [
    { name: 'Imprint', href: 'https://sprungbrett-handwerk.de/impressum.html' },
    { name: 'Privacy Policy', href: 'https://sprungbrett-handwerk.de/datenschutz.html' },
    { name: 'Terms of Service', href: 'https://sprungbrett-handwerk.de/allgemeine-geschaeftsbedingungen.html' },
    { name: 'Code of Conduct', href: 'https://sprungbrett-handwerk.de/code-of-conduct.html' },
    { name: 'Price list', href: 'https://sprungbrett-handwerk.de/sprungbrett-handwerk-preisliste.html' },
  ],
};

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="text-sm">
      <div className="max-w-7xl mx-auto pt-8 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a target="_blank" href={item.href} rel="noopener noreferrer" className="text-secondaryGray hover:text-darkestGray">
                {t(item.name)}
              </a>
            </div>
          ))}
        </nav>
        <p className="my-4 text-center text-secondaryGray">
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Sprungbrett Handwerk
        </p>
      </div>
    </footer>
  );
}
