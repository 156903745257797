/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import handwerkApplicantExample from '../../../assets/images/handwerk-applicant-example.png';
import logo from '../../../assets/images/logo.svg';

function PlaceholderCard() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid gap-4">
        <span className="text-2xl font-bold">
          {t('Craftsman on Sprungbrett Handwerk')}
        </span>
        <p>
          {t('Here you will find various craftsmen looking for jobs in your region. The whole thing is as simple as it looks. You see the profession and the qualification of the craftsman, the place of residence and the desired monthly salary.') }
        </p>
        <div className="my-3">
          <img
            className="h-36 w-auto"
            src={handwerkApplicantExample}
            alt="Handwerk Bewerberbeispiel"
          />
        </div>
        <p>
          {t('Next to it you will see a thumb. You click on this thumb if you like a craftsman. A craftsman you already like gets a green thumb. You get details about the craftsman when you click on it. Once both have given the "green thumb", you have a match. Then you see more details, the full name, a profile picture and you can chat with each other. Have fun browsing and good luck with your employee search.') }
        </p>
      </div>
      <div className="mt-6">
        <img
          className="h-12 w-auto"
          src={logo}
          alt="Sprungbrett Handwerk Logo"
        />
      </div>
    </div>
  );
}

export default PlaceholderCard;
