/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentTextIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

interface Props {
  items: any,
  handleClear: (value: string) => void,
}

function FilesList(props: Props) {
  const { items, handleClear } = props;
  const { t } = useTranslation();
  if (items && items.length) {
    return (
      <div className="pt-4">
        <span>{t('Selected')}</span>
        <div className="mt-1 grid grid-cols-2 gap-2">
          {items.map((item: any) => (
            <div key={item.innerId} className="py-4 pr-4 bg-darkerGray rounded-lg mr-2">
              <span className="flex relative items-center">
                <DocumentTextIcon className="h-8 w-8  flex-shrink-0 mx-1" aria-hidden="true" />
                <span className="flex-initial">{item.name}</span>
                <XIcon
                  className="cursor-pointer flex-1 absolute top-0 right-0 block h-5 w-5 transform -translate-y-1/2 translate-x-1/2 rounded-full"
                  onClick={() => handleClear(item.innerId)}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return '';
}

export default FilesList;
