import { UsersIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClick: () => void,
}

function ContactsButton(props: Props) {
  const { handleClick } = props;
  const { t } = useTranslation();

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => handleClick()}
      className="cursor-pointer flex border border-t-lightGray border-l-lightGray shadow-md hover:shadow-xl items-center justify-center h-12"
    >
      <div className="text-lg font-semibold">{t('Matches')}</div>
      <UsersIcon className="h-5 w-5 ml-2" />
    </div>
  );
}

export default ContactsButton;
