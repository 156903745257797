/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormikErrors } from 'formik';
import { useState } from 'react';
//@ts-ignore
import Files from 'react-butterfiles';
import { useTranslation } from 'react-i18next';
import defaultUserImage from '../../assets/images/default-user.png';
import placeholderImage from '../../assets/images/placeholder.png';
import { IMAGE_ASPECT_RATIO } from '../../constants';
//@ts-ignore
import ImageCropper from '../../helpers/ImageCropper';
import { getFilesErrorLabel } from '../../helpers/utils';

interface Props {
  handleSuccess: (imageData: string | null) => void,
  handleError: (errors: FormikErrors<string>) => void,
  image: string,
  isCompany?: boolean,
  aspectRatio: number,
}

const maxFileSize = '10Mb';

function Image(props: Props) {
  const { t } = useTranslation();
  const [cropper, setCropper] = useState(false);
  const {
    handleSuccess, handleError, image, isCompany, aspectRatio,
  } = props;

  const handleInnerSuccess = (files: any) => {
    handleSuccess(files[0].src.base64);
    setCropper(true);
  };

  let divStyle = {};
  let imgStyle = {};

  switch (aspectRatio) {
    case IMAGE_ASPECT_RATIO.SQUARE:
      divStyle = {
        width: 150,
        height: 150,
      };

      imgStyle = {
        maxWidth: '150px',
        maxHeight: '150px',
      };
      break;

    case IMAGE_ASPECT_RATIO.DVD:
      divStyle = {
        width: 267,
        height: 150,
      };

      imgStyle = {
        maxWidth: '267px',
        maxHeight: '150px',
      };
      break;

    default:
      divStyle = {
        width: 150,
        height: 150,
      };

      imgStyle = {
        maxWidth: '150px',
        maxHeight: '150px',
      };
      break;
  }

  if (!image) {
    divStyle = {
      ...divStyle,
      backgroundImage: `url(${isCompany ? placeholderImage : defaultUserImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      border: '2px lightgray dashed',
    };
  }
  return (
    <Files
      accept={['image/jpg', 'image/jpeg', 'image/png']}
      convertToBase64
      maxSize={maxFileSize}
      onSuccess={handleInnerSuccess}
      onError={(e: any) => {
        const [firstError] = e;
        handleError(getFilesErrorLabel(t, firstError.type, maxFileSize));
      }}
    >
      {({ browseFiles, getDropZoneProps }: any) => (
        <div className="inline-flex items-center pt-1">
          {cropper ? (
            <div style={divStyle}>
              {/* @ts-ignore */}
              <ImageCropper aspectRatio={aspectRatio}>
                {({ getImgProps, getDataURL }: any) => (
                  <div>
                    <img
                      alt=""
                      {...getImgProps({
                        src: image,
                        style: imgStyle,
                      })}
                    />
                    <button
                      className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                      onClick={() => {
                        handleSuccess(getDataURL());
                        setCropper(false);
                      }}
                    >
                      {t('Confirm')}
                    </button>
                  </div>
                )}
              </ImageCropper>
            </div>
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                onClick={browseFiles}
                {...getDropZoneProps({
                  style: { ...divStyle, cursor: 'pointer' },
                })}
              >
                {image && (
                  <img
                    alt=""
                    style={{ width: '100%' }}
                    src={image}
                  />
                )}
              </div>
              {image && (
                <button
                  onClick={() => handleSuccess(null)}
                  type="button"
                  className="flex-auto ml-2 sm:ml-5 bg-white py-2 px-3 border-2 border-darkerGray rounded-md shadow-sm text-sm leading-4 font-medium  hover:bg-lightGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  {t('Remove')}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </Files>
  );
}

export default Image;
