import { useQuery, useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { CURRENT_APPLICANT_LOCATION_QUERY } from '../../api/queries/applicants';
import { CURRENT_USER_QUERY } from '../../api/queries/users';
import { PreferredLocationForm } from '../../components/applicant';
import { USER_TYPE } from '../../constants';
import { SettingsLayout } from '../../layout/layouts/dashboard';

function PreferredLocation() {
  const [entity, setEntity] = useState(null);
  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const [
    getApplicantAddress,
    {
      data: applicantData,
    }] = useLazyQuery(CURRENT_APPLICANT_LOCATION_QUERY);

  useEffect(() => {
    if (userData) {
      switch (userData.currentUser.userType) {
        case USER_TYPE.APPLICANT:
          getApplicantAddress();
          break;
        default:
          break;
      }
    }
  }, [userData, getApplicantAddress]);

  useEffect(() => {
    if (applicantData) {
      setEntity({
        id: applicantData.currentApplicant.preferredLocation.id,
        ...applicantData.currentApplicant.preferredLocation,
      });
    }
  }, [applicantData]);

  return (
    <SettingsLayout>
      {entity && (
        <PreferredLocationForm
          currentLocation={entity}
        />
      )}
    </SettingsLayout>
  );
}

export default PreferredLocation;
