import { useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { LimitedApplicantType, PaginatedApplicantSearchType } from '../../../__generated__/graphql';
import { APPLICANTS_QUERY } from '../../../api/queries/applicants';
import {
  PlaceholderCard, DetailCard,
} from '../../../components/manager/applicants';
import { DetailSlideOver } from '../../../components/shared';
import {
  MatchingFilterSlideOver, MatchingPreviewCardList, MatchingFilterButton,
} from '../../../components/shared/matching';
import { LoadingIndicator } from '../../../layout';
import { ManagerDashboardLayout } from '../../../layout/layouts/dashboard';

interface FormikInitialValuesType {
  title: SelectOption[] | undefined,
  qualification: SelectOption[] | undefined,
  city: SelectOption | null,
  areaRadius: SelectOption | null,
  salaryRange: number[] | undefined,
}

function Applicants() {
  const [currentItem, setCurrentItem] = useState<LimitedApplicantType | null>(null);
  const [filterOverlay, setFilterOverlay] = useState(false);
  const [overlayModal, setOverlayModal] = useState(false);
  const [initialSalaryRange, setInitialSalaryRange] = useState<number[]>([]);
  const [filterIsActive, setFilterIsActive] = useState(false);

  const {
    data: applicantsData,
    loading: applicantsLoading,
    fetchMore: applicantsFetchMore,
    refetch: applicantsRefetch,
  } = useQuery(
    APPLICANTS_QUERY,
    {
      variables: {
        offset: 0,
      } as PaginatedApplicantSearchType,
      fetchPolicy: 'network-only',
    },
  );

  const {
    handleSubmit,
    setFieldValue,
    values: formikValues,
    initialValues: formikInitialValues,
    resetForm: resetFormikValues,
  } = useFormik({
    initialValues: {
      title: [],
      qualification: [],
      city: null,
      areaRadius: null,
      salaryRange: [],
    } as FormikInitialValuesType,
    onSubmit: (values) => {
      applicantsRefetch({
        title: values?.title?.map((item) => item.value).join(),
        qualification: values?.qualification?.map((item) => item.value).join(),
        city: values.city && values.city.value,
        areaRadius: values.areaRadius && values.areaRadius.value,
        salaryMin: values.salaryRange && values.salaryRange[0],
        salaryMax: values.salaryRange && values.salaryRange[1],
      });
      if (
        JSON.stringify(formikValues.title) !== JSON.stringify(formikInitialValues.title)
        || JSON.stringify(formikValues.qualification) !== JSON.stringify(
          formikInitialValues.qualification,
        )
        || formikValues.city !== formikInitialValues.city
        || formikValues.areaRadius !== formikInitialValues.areaRadius
        || JSON.stringify(formikValues.salaryRange) !== JSON.stringify(initialSalaryRange)
      ) {
        setFilterIsActive(true);
      } else {
        setFilterIsActive(false);
      }
    },
  });

  const handlePreviewClick = (item: LimitedApplicantType) => {
    setCurrentItem(item);
    setOverlayModal(true);
  };

  const ClearFilter = () => {
    setFilterOverlay(false);
    resetFormikValues();
    setFilterIsActive(false);
    setFieldValue('salaryRange', initialSalaryRange);
    applicantsRefetch({
      title: formikInitialValues?.title?.join(),
      qualification: formikInitialValues?.qualification?.join(),
      city: formikInitialValues.city,
      areaRadius: formikInitialValues.areaRadius,
      salaryMin: initialSalaryRange[0],
      salaryMax: initialSalaryRange[1],
    });
  };

  useEffect(() => {
    // Setting initial salary range data
    if (!initialSalaryRange.length && applicantsData && applicantsData.applicants) {
      const values: number[] = [applicantsData.applicants.salaryMin, applicantsData.applicants.salaryMax];
      setFieldValue('salaryRange', values);
      setInitialSalaryRange(values);
    }
  }, [initialSalaryRange, applicantsData, setFieldValue, setInitialSalaryRange]);

  return (
    <ManagerDashboardLayout>
      <div className="sm:rounded-lg bg-white">
        <main className="mx-auto px-4 pt-8 max-w-7xl">
          <div className="md:grid md:grid-cols-11 md:gap-x-8">
            <div className="col-span-4 grid gap-4 content-start">
              <MatchingFilterButton
                filterIsActive={filterIsActive}
                setFilterOverlay={setFilterOverlay}
              />
              <div>
                {applicantsLoading && (!applicantsData)
                  ? (<LoadingIndicator className="flex h-[75vh] justify-center items-center" />)
                  : (
                    <MatchingPreviewCardList
                      cardIsApplicant
                      items={applicantsData ? applicantsData.applicants.objects : []}
                      currentItem={currentItem}
                      handlePreviewClick={handlePreviewClick}
                      onLoadMore={() => {
                        if (applicantsData && applicantsData.applicants.hasNext) {
                          const currentLength = applicantsData
                            && applicantsData.applicants.objects.length;
                          applicantsFetchMore({
                            variables: {
                              offset: currentLength,
                            },
                          });
                        }
                      }}
                    />
                  )}
              </div>
            </div>
            <div
              id="styled-scroll"
              className="hidden md:block col-span-7 h-screen overflow-y-auto pr-4"
            >
              {currentItem
                ? <DetailCard key={currentItem.id} item={currentItem} />
                : <PlaceholderCard />}
            </div>
          </div>
        </main>
        {applicantsData && (
          <MatchingFilterSlideOver
            overlayState={filterOverlay}
            setOverlayState={setFilterOverlay}
            values={formikValues}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            initialSalaryRange={initialSalaryRange}
            ClearFilter={ClearFilter}
          />
        )}
        <DetailSlideOver
          modalState={overlayModal}
          setModalState={setOverlayModal}
          item={currentItem}
          cardIsApplicant
          viewerIsApplicant={false}
        />
      </div>
    </ManagerDashboardLayout>
  );
}

export default Applicants;
