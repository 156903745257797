/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';

function SupportContactSection() {
  const { t } = useTranslation();
  return (
    <div className="mt-8">
      <a
        href="https://sprungbrett-handwerk.de/Kontakt.html"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full flex justify-center bg-darkerGray px-1 border border-transparent rounded-md py-2 text-sm font-medium"
      >
        {t('Problems with signing in?')}
      </a>
      <div className="mt-4 text-sm text-secondaryGray">{t('If you have problems with the registration, then contact us and we will help you.')}</div>
    </div>
  );
}

export default SupportContactSection;
