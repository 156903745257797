import { useTranslation } from 'react-i18next';
import { ReactComponent as IconClothing } from '../../assets/images/benefits/sprungbrett-icons-01.svg';
import { ReactComponent as IconCar } from '../../assets/images/benefits/sprungbrett-icons-02.svg';
import { ReactComponent as IconMoneyBag } from '../../assets/images/benefits/sprungbrett-icons-04.svg';
import { ReactComponent as IconWorkerAtom } from '../../assets/images/benefits/sprungbrett-icons-05.svg';
import { ReactComponent as IconCapitalStack } from '../../assets/images/benefits/sprungbrett-icons-06.svg';
import { ReactComponent as IconImac } from '../../assets/images/benefits/sprungbrett-icons-08.svg';
import { ReactComponent as IconTeamDesk } from '../../assets/images/benefits/sprungbrett-icons-10.svg';
import { ReactComponent as IconPeopleMoveUp } from '../../assets/images/benefits/sprungbrett-icons-11.svg';
import { ReactComponent as IconHandGrowMoney } from '../../assets/images/benefits/sprungbrett-icons-13.svg';
import { ReactComponent as IconPlanet } from '../../assets/images/benefits/sprungbrett-icons-15.svg';
import { ReactComponent as IconSandTimer } from '../../assets/images/benefits/sprungbrett-icons-16.svg';
import { ReactComponent as IconBeer } from '../../assets/images/benefits/sprungbrett-icons-17.svg';
import { BENEFIT_TYPE } from '../../constants';

function getBenefitIcon(benefitType: string, width: string) {
  const benefitTypeValue = BENEFIT_TYPE[benefitType as keyof typeof BENEFIT_TYPE];
  let IconComponent;
  switch (benefitTypeValue) {
    case BENEFIT_TYPE.VACATION:
      IconComponent = IconPlanet;
      break;
    case BENEFIT_TYPE.COMPANY_CAR:
      IconComponent = IconCar;
      break;
    case BENEFIT_TYPE.WORKWEAR_PPE:
      IconComponent = IconClothing;
      break;
    case BENEFIT_TYPE.MODERN_IT_EQUIPMENT:
      IconComponent = IconImac;
      break;
    case BENEFIT_TYPE.CAPITAL_ACCUMULATION_BENEFITS:
      IconComponent = IconMoneyBag;
      break;
    case BENEFIT_TYPE.FURTHER_EDUCATION:
      IconComponent = IconPeopleMoveUp;
      break;
    case BENEFIT_TYPE.CAREER_DEVELOPMENT:
      IconComponent = IconWorkerAtom;
      break;
    case BENEFIT_TYPE.BONUS_SYSTEM:
      IconComponent = IconCapitalStack;
      break;
    case BENEFIT_TYPE.COMPANY_ACTIVITIES:
      IconComponent = IconBeer;
      break;
    case BENEFIT_TYPE.WORKING_HOURS:
      IconComponent = IconSandTimer;
      break;
    case BENEFIT_TYPE.LEASING_OPTIONS:
      IconComponent = IconHandGrowMoney;
      break;
    case BENEFIT_TYPE.FLAT_HIERARCHY:
      IconComponent = IconTeamDesk;
      break;
    default:
      break;
  }
  return <IconComponent width={width} />;
}

interface Props {
  benefitType: string,
  description: string,
}

function BenefitPlateCard(props: Props) {
  const { benefitType, description } = props;
  const { t } = useTranslation();

  return (
    <div className="rounded-lg grid bg-gradient-to-b from-grayGradientTop to-grayGradientBottom shadow-xl h-32">
      <div className="text-center  place-self-center">
        {getBenefitIcon(benefitType, '70px')}
      </div>
      <div className="text-center  text-sm">
        {t(BENEFIT_TYPE[benefitType as keyof typeof BENEFIT_TYPE])}
        {description && `: ${description}`}
      </div>
    </div>
  );
}

export default BenefitPlateCard;
