import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { USE_COMPANY_INVITATION_MUTATION } from '../../../api/mutations/companyInvitations';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Input } from '../../../layout/fields';
import { setSuccessAlert } from '../../../redux/alertSlice';

function CompanyInvitationForm(props) {
  const { refetchQueries, invitationFormState, setInvitationFormState } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [
    codeMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(USE_COMPANY_INVITATION_MUTATION, {
    refetchQueries,
  });

  const schema = yup.object({
    code: yup.string().required(t('Requried')),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: invitationFormState,
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      const variables = {
        ...values,
      };
      codeMutation(
        {
          variables,
        },
      );
      resetForm();
    },
  });

  useEffect(() => {
    if (mutationData && mutationData.useCompanyInvitation.response) {
      dispatch(setSuccessAlert(
        [t('Invitation Code was successfully applied. You will be notified on invitation review.')],
      ));
    }
  }, [mutationData, dispatch, t]);

  useEffect(() => {
    setInvitationFormState(formikValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikValues]);

  return (
    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <Input
                    id="code"
                    name="code"
                    type="text"
                    value={formikValues.code}
                    errorMessage={touched.code && formikErrors.code}
                    label={t('Invitation Code')}
                    required
                    onChange={handleChange}
                    autoFocus
                  />
                </div>
              </div>
            </div>

            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              <button
                diasbled={mutationLoading ? 'true' : undefined}
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t('Apply')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

CompanyInvitationForm.propTypes = {
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
  invitationFormState: PropTypes.objectOf(PropTypes.any),
  setInvitationFormState: PropTypes.func,
};

export default CompanyInvitationForm;
