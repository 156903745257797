import { useTranslation } from 'react-i18next';

import { EmailConfirmationLayout } from '../../layout/layouts';

function EmailConfirmation() {
  const { t } = useTranslation();
  return (
    <EmailConfirmationLayout>
      <div className="mb-5">
        <div className="px-1">
          <h3 className="text-lg font-medium ">{t('Welcome to Sprungbrett Handwerk!')}</h3>
          <div className="mt-2 text-sm ">
            <p>{t('We have sent the confirmation email letter to your email box, used in registration process.')}</p>
            <p className="mt-2">{t('Please confirm your email by following the link in the confirmation letter.')}</p>
            <p className="mt-2">{t("If you can't find an email in your inbox, it may be in your spam folder.")}</p>
          </div>
        </div>
      </div>

    </EmailConfirmationLayout>
  );
}

export default EmailConfirmation;
