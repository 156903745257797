import { gql } from '@apollo/client';

export const APPLICANT_LIKES_QUERY = gql`
  query applicantLikes(
    $page: Int
    $likeTarget: ApplicantLikeTargetType
    $offer: ID
    ) {
    applicantLikes(
      page: $page
      likeTarget: $likeTarget
      offer: $offer
      ){
      page
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        createdAt
        offer{
          id
          fullTitle
        }
        company{
          id
          name
        }
        applicant{
          id
          fullTitle
          user{
            email
          }
        }
      }
    }
  }
`;

export const APPLICANT_OFFER_VIEWS_QUERY = gql`
  query applicantOfferViews(
    $page: Int
    $offer: ID
    ) {
      applicantOfferViews(
      page: $page
      offer: $offer
      ){
      page
      pages
      hasNext
      hasPrev
      elidedPageRange
      totalObjectsQuantity
      objects{
        id
        createdAt
        target
        offer{
          id
          fullTitle
        }
        applicant{
          id
          user{
            email
          }
          fullTitle
        }
      }
    }
  }
`;
