import { useTranslation } from 'react-i18next';
import { SearchForOffersButton } from '../../shared/buttons';

function EmptyDashboard() {
  const { t } = useTranslation();

  return (
    <div className="sm:rounded-lg bg-white">
      <main className="mx-auto px-4 pt-8 max-w-7xl">
        <div className="pb-4">
          <h1 className="text-2xl font-extrabold tracking-tight ">{t('Nothing yet')}</h1>
          <div className="my-2 text-base ">
            {t("Our algorithms haven't found any jobs near you yet - be patient.")}
            {' '}
            {t('Alternatively, you can already look at jobs from other regions.')}
          </div>
          <div className="mt-4 mb-2">
            <SearchForOffersButton />
          </div>
        </div>
      </main>
    </div>
  );
}

export default EmptyDashboard;
