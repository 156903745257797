import { useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';
import { PACKAGE_PRICE_GRID_ITEM_QUERY } from '../../../../../api/queries/ecommerce';
import { PAYPAL_CLIENT_ID } from '../../../../../config';
import { PAYMENT_CYCLE } from '../../../../../constants';
import { LoadingIndicator } from '../../../../../layout';
import InfoBanner from './InfoBanner';
import PayPal from './PayPal';
import { SEPA } from './SEPA';

interface Props {
  companyId: string,
  refetchQueries: { query: DocumentNode }[],
}

export default function PaymentOptions(props: Props) {
  const { companyId, refetchQueries } = props;
  const { t } = useTranslation();
  // Paypal Payment is temporarily disabled as Business Requirement.
  const payPalDisabled = true;

  const {
    data: companyPackageData,
    loading: companyPackageLoading,
  } = useQuery(PACKAGE_PRICE_GRID_ITEM_QUERY, { variables: { company: companyId } });

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="rounded-md px-4 py-5 bg-white sm:p-6">
        <div className="sm:align-center sm:flex sm:flex-col">
          <p className="mt-2 text-lg">
            {t('Please choose the payment option.')}
          </p>
        </div>
        <div className='mt-6'>
          <InfoBanner />
        </div>
        <div className="mt-6 space-y-4 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none">
          {companyPackageLoading && !companyPackageData
            ? (<LoadingIndicator className="flex justify-center items-center" />)
            : (
              <>
                {companyPackageData.packagePriceGridItem.paymentCycle === PAYMENT_CYCLE.ANNUAL && !payPalDisabled && (
                  <PayPalScriptProvider options={{
                    'client-id': PAYPAL_CLIENT_ID,
                    currency: companyPackageData.packagePriceGridItem.package.currencyCode,
                    intent: 'capture',
                  }}
                  >
                    <PayPal
                      companyId={companyId}
                      packagePriceGridItem={companyPackageData.packagePriceGridItem}
                      refetchQueries={refetchQueries}
                    />
                  </PayPalScriptProvider>
                )}
                <SEPA
                  companyId={companyId}
                  refetchQueries={refetchQueries}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
}
