import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MODERATION_STATUS } from '../../../../constants';
import { classNames } from '../../../../helpers/utils';

interface Props {
  id?: string,
  name: string,
  owner: string,
  isReadonly?: boolean,
  status?: string,
  handleLeave?: (value: string) => void,
}

export default function CompanyCard(props: Props) {
  const {
    id, name, owner, isReadonly, status, handleLeave,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasErrorStatus = status && status === MODERATION_STATUS.REJECTED;

  const handleClick = (companyId: string) => {
    if (!isReadonly) {
      navigate(`/settings/companies/${companyId}/general/`);
    }
  };
  return (
    //@ts-ignore
    <div className={classNames(!isReadonly && ' hover:bg-lightGray hover:border-lightGray focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary',
      'relative rounded sm:rounded-lg border bg-white px-6 py-5 shadow-sm flex items-center space-x-3')}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => id && handleClick(id)}
        className={classNames(isReadonly ? 'cursor-default' : 'cursor-pointer', 'flex-1 min-w-0')}
      >
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-medium ">{name}</p>
        {status && (
          <div className="py-1">
            <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium text-white ${hasErrorStatus ? 'bg-red-800' : 'bg-primary'}`}>
              {t(status)}
            </span>
          </div>
        )}
        <p className="text-sm  truncate">
          {t('Owner')}
          :
          {' '}
          {owner}
        </p>
      </div>
      <div>
        {!isReadonly && (
        <button onClick={() => handleLeave && id && handleLeave(id)}>
          <XIcon
            className="cursor-pointer flex-1  absolute top-4 right-4 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full"
          />
        </button>
        )}
      </div>
    </div>
  );
}
