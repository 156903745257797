import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error404() {
  const { t } = useTranslation();
  const label = `${t('Error')}: ${t('Not Found')}.`;
  return (
    <ErrorPageTempate
      label={label}
      code={404}
    />
  );
}

export default Error404;
