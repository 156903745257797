import { useQuery, useMutation } from '@apollo/client';
import { RefreshIcon } from '@heroicons/react/outline';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { COMPANY_MUTATION } from '../../api/mutations/companies';
import { MINIFIED_CURRENT_MANAGER_QUERY } from '../../api/queries/managers';
import {
  COMPANY_SIZE,
  BRANCHE_TYPE,
  IMAGE_ASPECT_RATIO,
  IMAGE_SIZE,
} from '../../constants';
import {
  getInlineOptionsFromEnumDict,
  updateSelectStylesOnError,
} from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Input, Image, Select } from '../../layout/fields';
import { ErrorsList } from '../../layout/forms';
import LoadingIndicator from '../../layout/LoadingIndicator';
import { setSuccessAlert } from '../../redux/alertSlice';

const companySizeOptions = getInlineOptionsFromEnumDict(COMPANY_SIZE);

const brancheOptions = getInlineOptionsFromEnumDict(BRANCHE_TYPE, false);

// Temporarily disable company size change.
const companySizeDisabled = true;

function CompanyForm(props) {
  const { refetchQueries, generateInvitationCode, currentCompany } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: managerData } = useQuery(MINIFIED_CURRENT_MANAGER_QUERY);
  const [
    companyMutation,
    {
      data: companyData,
      loading,
    },
  ] = useMutation(COMPANY_MUTATION, {
    refetchQueries,
  });

  const schema = yup.object({
    companySize: yup.string(),
    description: yup.string(),
    email: yup.string().required(t('Requried')),
    highlights: yup.string(),
    logoImage: yup.string().nullable(),
    invitationCode: yup.string().required(t('Requried')),
    name: yup.string().required(t('Requried')),
    phone: yup.string().required(t('Requried')),
    profileImage: yup.string().nullable(),
    website: yup.string(),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      companySize: '',
      description: '',
      email: '',
      highlights: '',
      logoImage: '',
      invitationCode: '',
      name: '',
      phone: '',
      profileImage: '',
      website: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      if (currentCompany) {
        variables.isActive = currentCompany.isActive;
        variables.owner = currentCompany.owner.id;
      } else {
        variables.owner = managerData.currentManager.id;
      }
      delete variables.benefits;
      delete variables.address;
      delete variables.managers;
      delete variables.pendingInvitations;

      companyMutation(
        {
          variables,
        },
      );
    },
  });

  const handleLogoImageSelection = (imageData) => {
    setFieldValue('logoImage', imageData);
  };

  const handleProfileImageSelection = (imageData) => {
    setFieldValue('profileImage', imageData);
  };

  useEffect(() => {
    if (generateInvitationCode) {
      setFieldValue('invitationCode', uuidv4());
    }
  }, [generateInvitationCode, setFieldValue]);

  useEffect(() => {
    if (currentCompany) {
      setValues(currentCompany);
      if (currentCompany && currentCompany.logoImageSrcset) {
        setFieldValue('logoImage', currentCompany.logoImageSrcset[IMAGE_SIZE.S128]);
      }
      if (currentCompany && currentCompany.profileImageSrcset) {
        setFieldValue('profileImage', currentCompany.profileImageSrcset[IMAGE_SIZE.S128]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany, setValues]);

  useEffect(() => {
    if (companyData && companyData.company.response && currentCompany) {
      dispatch(setSuccessAlert(
        [t('Changes were applied.')],
      ));
    }
  }, [companyData, currentCompany, dispatch, t]);

  return (
    <div className="px-2 sm:px-0 grid grid-cols-1 gap-4 lg:col-span-2">
      <div className="sm:mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="rounded sm:rounded-none px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={formikValues.name}
                    errorMessage={touched.name && formikErrors.name}
                    label={t('Company Name')}
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    value={formikValues.email}
                    errorMessage={touched.email && formikErrors.email}
                    label={t('Email')}
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    value={formikValues.phone}
                    errorMessage={touched.phone && formikErrors.phone}
                    label={t('Phone')}
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm font-bold "
                  >
                    {t('Company Size')}
                    <span className="text-red-600"> *</span>
                  </label>
                  <Select
                    className="mt-1"
                    value={companySizeOptions.find(
                      (item) => item.value === formikValues.companySize,
                    )}
                    isDisabled={currentCompany && companySizeDisabled}
                    options={companySizeOptions}
                    onChange={(e) => setFieldValue('companySize', e.value)}
                    styles={formikErrors.companySize && updateSelectStylesOnError}
                  />
                  {touched.companySize && !!formikErrors.companySize && (<div className="mt-2 text-sm text-red-600">{formikErrors.companySize}</div>)}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    className="block text-sm "
                  >
                    {t('Branche')}
                  </label>
                  <Select
                    className="mt-1"
                    value={brancheOptions.find(
                      (item) => item.value === formikValues.branche,
                    )}
                    options={brancheOptions}
                    onChange={(e) => setFieldValue('branche', e.value)}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <Input
                    id="website"
                    name="website"
                    type="text"
                    value={formikValues.website}
                    errorMessage={touched.website && formikErrors.website}
                    label={t('Website')}
                    onChange={handleChange}
                  />
                </div>

                {!generateInvitationCode && (
                  <>
                    <div className="col-span-4 sm:col-span-4">
                      <Input
                        id="invitationCode"
                        name="invitationCode"
                        type="text"
                        value={formikValues.invitationCode}
                        errorMessage={touched.invitationCode && formikErrors.invitationCode}
                        label={t('Invitation Code')}
                        disabled
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1 mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center p-2 border-2 border-darkerGray shadow-sm text-sm leading-4 font-medium rounded-md  bg-white hover:bg-lightGray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={() => setFieldValue('invitationCode', uuidv4())}
                      >
                        <RefreshIcon className="h-5 w-5" aria-hidden="true" />

                      </button>
                    </div>
                  </>
                )}

                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Description')}
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                    value={formikValues.description}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Highlights')}
                  </label>
                  <textarea
                    id="highlights"
                    name="highlights"
                    rows={4}
                    className="mt-1 block w-full border-2 border-darkerGray rounded-md shadow-sm sm:text-sm focus:ring-primary focus:border-primary"
                    value={formikValues.highlights}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Logo Image')}
                  </label>
                  <Image
                    image={formikValues.logoImage}
                    handleSuccess={handleLogoImageSelection}
                    handleError={(e) => setErrors({ logoImage: e })}
                    isCompany
                    aspectRatio={IMAGE_ASPECT_RATIO.SQUARE}
                  />
                  {!!formikErrors.logoImage && (
                    <ErrorsList
                      errors={[formikErrors.logoImage]}
                      className="mt-2"
                    />
                  )}
                </div>

                <div className="col-span-6 sm:col-span-6">
                  <label
                    className="block text-sm font-medium "
                  >
                    {t('Profile Image')}
                  </label>
                  <Image
                    image={formikValues.profileImage}
                    handleSuccess={handleProfileImageSelection}
                    handleError={(e) => setErrors({ profileImage: e })}
                    isCompany
                    aspectRatio={IMAGE_ASPECT_RATIO.DVD}
                  />
                  {!!formikErrors.profileImage && (
                    <ErrorsList
                      errors={[formikErrors.profileImage]}
                      className="mt-2"
                    />
                  )}
                </div>

              </div>
            </div>
            <div className="px-4 py-3 bg-lightGray text-right sm:px-6">
              {loading
                ? (
                  <LoadingIndicator className="flex justify-end py-4 mr-2" />

                )
                : (
                  <button
                    diasbled={loading.toString()}
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    {currentCompany ? t('Save') : t('Next Step')}
                  </button>
                )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

CompanyForm.propTypes = {
  refetchQueries: PropTypes.arrayOf(PropTypes.any),
  generateInvitationCode: PropTypes.bool,
  currentCompany: PropTypes.objectOf(PropTypes.any),
};

export default CompanyForm;
