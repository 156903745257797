import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { RESET_PASSWORD_CONFIRMATION_MUTATION } from '../../api/mutations/users';
import { Input } from '../../layout/fields';
import { AuthLayout } from '../../layout/layouts';

function ResetPasswordConfirmation() {
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const { t } = useTranslation();
  const { uid, token } = useParams();
  const [confirmReset,
    {
      data,
      loading,
    }] = useMutation(RESET_PASSWORD_CONFIRMATION_MUTATION);

  const schema = yup.object({
    password1: yup.string().required(t('Password is Required')),
    password2: yup.string().oneOf([yup.ref('password1'), null], t('Passwords must match')),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        password: values.password1,
        uid,
        token,
      };
      confirmReset({ variables });
    },
  });

  useEffect(() => {
    if (data && data.resetPasswordConfirmation && data.resetPasswordConfirmation.response) {
      setPasswordConfirmed(true);
    }
  }, [data]);

  return (
    <AuthLayout>
      <h2 className="my-6 text-2xl font-extrabold ">{t('Reset Your Password')}</h2>
      {passwordConfirmed
        ? (
          <div>
            <p>{t('Your new password was applied.')}</p>
            <p>{t('Please use it to log in the app.')}</p>
            <Link
              to="/authentication/login"
              className="font-medium text-primary hover:text-primary transition duration-150"
            >
              {t('Back to Login page')}
              .
            </Link>
          </div>
        )
        : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-1">
              <Input
                id="password1"
                name="password1"
                type="password"
                autoComplete="password"
                //@ts-ignore
                errorMessage={touched.password1 && formikErrors.password1}
                label={t('New Password')}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-1">
              <Input
                id="password2"
                name="password2"
                type="password"
                autoComplete="password"
                //@ts-ignore
                errorMessage={touched.password2 && formikErrors.password2}
                label={t('Confirm Password')}
                onChange={handleChange}
              />
            </div>

            <div>
              <button
                type="submit"
                disabled={loading && true}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary transition duration-150 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t('Reset password')}
              </button>
            </div>

          </form>
        )}
    </AuthLayout>
  );
}

export default ResetPasswordConfirmation;
