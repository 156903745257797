import { useTranslation } from 'react-i18next';
import { LimitedApplicantType } from '../../../__generated__/graphql';
import {
  LANGUAGE, DRIVER_LICENSE_TYPE, JOB_TITLE, JOB_QUALIFICATION,
} from '../../../constants';
import { classNames } from '../../../helpers/utils';
import { AttributeCard } from '../../shared';
import { LikeApplicantSection } from '../likes';

interface Props {
  item: LimitedApplicantType,
  viewerIsApplicant: boolean,
}

export default function SmallScreenSearchCard(props: Props) {
  // Used for Searching Candidates.
  // Is showed only on small screens.
  const {
    item, viewerIsApplicant,
  } = props;
  const { t } = useTranslation();

  return (
    <div id="styled-scroll" className="p-4 h-full flex flex-col bg-darkerGray overflow-y-auto">
      <div className="flex-1">
        <div className="h-full bg-white rounded-xl pb-2">
          <div>
            <div className="w-full px-4">
              <div className={classNames(!viewerIsApplicant ? '' : 'mt-10', 'grid')}>
                <div>
                  <div>
                    {!viewerIsApplicant && <div className="py-6"><LikeApplicantSection applicantId={item.id} /></div>}
                  </div>
                  <div>
                    <div className="break-words">
                      <div className="font-semibold">{JOB_QUALIFICATION[item.qualification]}</div>
                      <div className="text-xl font-normal">{JOB_TITLE[item.title as keyof typeof JOB_TITLE]}</div>
                    </div>
                  </div>
                </div>
              </div>

              {item.goals && (
                <div className="mt-10 text-left mx-auto ">
                  <div className="text-xl font-bold  text-left">
                    {t('Goals')}
                  </div>
                  <div className="grid gap-2">
                    {item.goals.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                  </div>
                </div>
              )}

              {item.miscellaneous && (
                <div className="mt-10 text-left mx-auto ">
                  <div className="text-xl font-bold  text-left">
                    {t('Miscellaneous')}
                  </div>
                  <div className="grid gap-2">
                    {item.miscellaneous.split('\n').map((str, index) => <p key={index}>{str}</p>)}
                  </div>
                </div>
              )}

              <div className="my-10">
                <div className="my-4 font-semibold text-lg">
                  {t('What Defines You')}
                </div>
                <dl className="space-y-8">
                  {!!item?.driverLicence?.length && (
                    <dd className="rounded-lg bg-darkerGray">
                      <AttributeCard
                        label={t('Driver Licence')}
                        value={item.driverLicence.map((licence) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE]).join(', ')}
                      />
                    </dd>
                  )}
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Travel activity/assembly')}
                      value={item.isOpenForTravelling ? t('Yes') : t('No')}
                    />
                  </dd>
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={t('Languages')}
                      value={item.languages.map((language) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
                    />
                  </dd>
                  {!!item.skills.length && (
                    <dd className="rounded-lg bg-darkerGray">
                      <AttributeCard
                        label={t('Skills')}
                        value={item.skills.map((skill) => skill.name).join(', ')}
                      />
                    </dd>
                  )}
                </dl>
              </div>
              <div className="pb-10 ">
                <div className="text-lg font-semibold">
                  {t('Performance')}
                </div>
                <dl className="space-y-8 pt-2">
                  <dd className="rounded-lg bg-darkerGray">
                    <AttributeCard
                      label={`${t('Salary')}`}
                      value={`${item?.salaryDetails?.exactValue} ${item?.salaryDetails?.currencyCode}`}
                    />
                  </dd>
                </dl>
              </div>
              <div>
                <div>
                  <span className="text-xl font-bold ">
                    {t('Preferred Location')}
                    {' '}
                    :
                    {' '}
                    <span className="text-secondaryGray">
                      {item?.preferredLocation?.city.name}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
