/* eslint-disable max-len */
export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const SIGNATURE_EXPIRED_ERROR = 'Signature has expired';
export const REFRESH_TOKEN_EXPIRED_ERROR = 'Refresh token is expired';

export const PAYMENT_CYCLE = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
};

export const PUBLIC_APPLICANT_TYPE = {
  DETAILS: 'DetailsApplicantType',
  LIMITED: 'LimitedApplicantType',
};

export const USER_TYPE = {
  APPLICANT: 'APPLICANT',
  MANAGER: 'MANAGER',
};

export const SOCIAL_AUTH_TYPE = {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
};

export const SOCIAL_AUTH_PROVIDER = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
};

export const ONBOARDING_STEP_STATUS = {
  UPCOMING: 'UPCOMING',
  CURRENT: 'CURRENT',
  COMPLETE: 'COMPLETE',
};

export const DRIVER_LICENSE_A_TYPE = {
  A: 'A',
  A1: 'A1',
  A2: 'A2',
  AM: 'AM',
};

export const DRIVER_LICENSE_B_TYPE = {
  B: 'B',
  BE_B96: 'BE/B96',
  BF17: 'BF17',
};

export const DRIVER_LICENSE_C_TYPE = {
  C: 'C',
  C1: 'C1',
  C1E: 'C1E',
  CE: 'CE',
};

export const DRIVER_LICENSE_D_TYPE = {
  D: 'D',
  D1: 'D1',
  D1E: 'D1E',
  DE: 'DE',
};

export const DRIVER_LICENSE_Z_TYPE = {
  L: 'L',
  T: 'T',
};

export const DRIVER_LICENSE_TYPE = {
  ...DRIVER_LICENSE_A_TYPE,
  ...DRIVER_LICENSE_B_TYPE,
  ...DRIVER_LICENSE_C_TYPE,
  ...DRIVER_LICENSE_D_TYPE,
  ...DRIVER_LICENSE_Z_TYPE,
};

export const FILE_TYPE = {
  CV: 'CV',
  CERTIFICATE: 'CERTIFICATE',
};

export const TARGET_FLEID = {
  LABEL: 'LABEL',
  VALUE: 'VALUE',
};

export const COMPANY_SIZE = {
  ONE_TO_TEN: '1-10',
  ELEVEN_TO_THIRTY: '11-30',
  THIRTY_ONE_TO_FIFTY: '31-50',
  FIFTY_ONE_TO_HUNDRED: '51-100',
  OVER_HUNDRED: '100+',
};

export const NOTIFICATION_TYPE = {
  PENDING_MANAGER: 'PENDING_MANAGER',
  PENDING_MANAGER_APPROVED: 'PENDING_MANAGER_APPROVED',
  PENDING_MANAGER_REJECTED: 'PENDING_MANAGER_REJECTED',
};

export const NOTIFICATION_CHANNELS = {
  EMAIL: 'EMAIL',
};

export const MODERATION_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const BENEFIT_TYPE = {
  VACATION: 'Vacation',
  COMPANY_CAR: 'Company Car',
  WORKWEAR_PPE: 'Workwear/PPE',
  MODERN_IT_EQUIPMENT: 'Modern It Equipment',
  CAPITAL_ACCUMULATION_BENEFITS: 'Capital Accumulation Benefits',
  FURTHER_EDUCATION: 'Further Education',
  CAREER_DEVELOPMENT: 'Career Development',
  BONUS_SYSTEM: 'Bonus System',
  COMPANY_ACTIVITIES: 'Company Activities',
  WORKING_HOURS: 'Working Hours',
  LEASING_OPTIONS: 'Leasing Options',
  FLAT_HIERARCHY: 'Flat Hierarchy',
};

export const SORT_TYPE = {
  DEFAULT: 'DEFAULT',
  LIKES: 'LIKES',
  VIEWS: 'VIEWS',
};

export const FILTER_TYPE = {
  NONE: 'NONE',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
};

// DVD aspect ratio is 16:9
export const IMAGE_ASPECT_RATIO = {
  SQUARE: 1,
  DVD: 16 / 9,
};

export const IMAGE_SIZE = {
  S32: 's32',
  S64: 's64',
  S128: 's128',
  S256: 's256',
  S512: 's512',
  S768: 's768',
};

export const JOB_QUALIFICATION = {
  LEHRLING: 'Lehrling',
  GESELLE: 'Geselle',
  MEISTER: 'Meister',
  TECHNIKER: 'Techniker',
  ABITUR: 'Abitur',
  STUDIUM: 'Studium',
  FORTBILDUNG: 'Fortbildung',
  SONSTIGE_QUALIFIKATIONEN: 'Sonstige Qualifikationen',
};

export const JOB_TITLE_HOLZHANDWERK_TYPE = {
  BOOTSBAUER_UND_SCHIFFBAUER: 'Bootsbauer und Schiffbauer/-in',
  BOTTCHER: 'Böttcher/-in',
  BURSTEN_UND_PINSELMACHER: 'Bürsten- und Pinselmacher/-in',
  DRECHSLER_ELFENBEINSCHNITZER: 'Drechsler/-in (Elfenbeinschnitzer/-in)',
  HOLZ_UND_BAUTENSCHUTZER: 'Holz- und Bautenschützer/-in',
  FLECHTWERKGESTALTER: 'Flechtwerkgestalter/-in',
  HOLZBILDHAUER: 'Holzbildhauer/-in',
  HOLZSPIELZEUGMACHER: 'Holzspielzeugmacher/-in',
  PARKETTLEGER: 'Parkettleger/-in',
  ROLLLADEN_UND_SONNENSCHUTZMECHATRONIKER: 'Rollladen- und Sonnenschutzmechatroniker/-in',
  TECHNISCHER_MODELLBAUER: 'Technischer/-r Modellbauer/-in',
  TISCHLER_SCHREINER: 'Tischler/-in (auch Schreiner genannt/-in)',
  TREPPENBAUER: 'Treppenbauer/-in',
  BAUELEMENTEHANDEL: 'Bauelementehandel/in',
};

export const JOB_TITLE_METALLHANDWERK_TYPE = {
  BEHALTER_UND_APPARATEBAUER: 'Behälter- und Apparatebauer/-in',
  BUCHSENMACHER: 'Büchsenmacher/-in',
  FACHKRAFT_FUR_METALLTECHNIK: 'Fachkraft für Metalltechnik',
  FEINWERKMECHANIKER: 'Feinwerkmechaniker/-in',
  GOLDSCHMIED: 'Goldschmied/-in',
  GRAVEUR: 'Graveur/-in',
  KONSTRUKTIONSMECHANIKER: 'Konstruktionsmechaniker/-in',
  METALLBAUER: 'Metallbauer/-in (Schlosser, Schmied/in)',
  METALLBILDNER: 'Metallbildner/-in',
  SILBERSCHMIED: 'Silberschmied/-in',
  PRAZISIONSWERKZEUGMECHANIKER: 'Präzisionswerkzeugmechaniker/-in',
  ZERSPANUNGSMECHANIKER: 'Zerspanungsmechaniker/-in',
};

export const JOB_TITLE_GLAS_UND_KERAMIK_TYPE = {
  FEINOPTIKER: 'Feinoptiker/-in',
  GLASAPPARATEBAUER: 'Glasapparatebauer/-in',
  GLASER: 'Glaser/-in',
  GLASBLASER: 'Glasbläser/-in',
  GLAS_UND_PORZELLANMALER: 'Glas- und Porzellanmaler/-in',
  GLASVEREDLER: 'Glasveredler/-in',
  KERAMIKER: 'Keramiker/-in',
};

export const JOB_TITLE_BAUHANDWERK_TYPE = {
  AUSBAUFACHARBEITER: 'Ausbaufacharbeiter/-in',
  BAUWERKSABDICHTER: 'Bauwerksabdichter/-in',
  BAUGERATEFUHRER: 'Baugeräteführer/-in',
  BAUZEICHNER: 'Bauzeichner/-in',
  BETON_UND_STAHLBETONBAUER: 'Beton- und Stahlbetonbauer/-in',
  BRUNNENBAUER: 'Brunnenbauer/-in',
  DACHDECKER: 'Dachdecker/-in',
  ESTRICHLEGER: 'Estrichleger/-in',
  FEUERUNGS_UND_SCHORNSTEINBAUER: 'Feuerungs- und Schornsteinbauer/-in',
  GERUSTBAUER: 'Gerüstbauer/-in',
  HOCHBAUFACHARBEITER: 'Hochbaufacharbeiter/-in',
  KANALBAUER: 'Kanalbauer/-in',
  MAURER: 'Maurer/-in',
  NATURWERKSTEINMECHANIKER: 'Naturwerksteinmechaniker/-in',
  OFEN_UND_LUFTHEIZUNGSBAUER: 'Ofen- und Luftheizungsbauer/-in',
  SCHORNSTEINFEGER: 'Schornsteinfeger/-in',
  STEINMETZ_UND_STEINBILDHAUER: 'Steinmetz/-in und Steinbildhauer/-in',
  STRASSENBAUER: 'Straßenbauer/-in',
  STUCKATEUR: 'Stuckateur/-in (Gipser/in)',
  TIEFBAUFACHARBEITER: 'Tiefbaufacharbeiter/-in',
  TROCKENBAUMONTEUR: 'Trockenbaumonteur/-in',
  WARME_KALTE_UND_SCHALLSCHUTZISOLIERER: 'Wärme-, Kälte- und Schallschutzisolierer/-in',
  WERKSTEINHERSTELLER: 'Werksteinhersteller/-in',
  ZIMMERER: 'Zimmerer/-in (Bauhauptgewerbe/in)',
};

export const JOB_TITLE_ELEKTROTECHNIK_TYPE = {
  ELEKTRONIKER_FUR_MASCHINEN_UND_ANTRIEBSTECHNIK: 'Elektroniker/-in für Maschinen und Antriebstechnik',
  ELEKTRONIKER_FUR_GEBAUDESYSTEMINTEGRATION: 'Elektroniker/-in für Gebäudesystemintegration',
  INFORMATIONSELEKTRONIKER: 'Informationselektroniker/-in',
  SYSTEMELEKTRONIKER: 'Systemelektroniker/-in',
};

export const JOB_TITLE_SANITAR_UND_VERSORGUNGSTECHNIK_TYPE = {
  ANLAGENMECHANIKER: 'Anlagenmechaniker/-in für Sanitär-, Heizungs- und Klimatechnik (ugs. Installateur/in)',
  BEHALTER_UND_APPARATEBAUER: 'Behälter- und Apparatebauer/-in',
  KLEMPNER: 'Klempner/-in (ugs. auch Spengler und (Bau-)Flaschner/in)',
  MECHATRONIKER_FUR_KALTETECHNIK: 'Mechatroniker/-in für Kältetechnik',
  MECHATRONIKER: 'Mechatroniker/-in',
  ROHRLEITUNGSBAUER: 'Rohrleitungsbauer/-in',
  SCHWIMMBAD_UND_SAUNABAUER: 'Schwimmbad und Saunabauer/-in',
  SOLAR_UND_PHOTOVOLTAIKANLAGENBAUER: 'Solar- und Photovoltaikanlagenbauer/in',
  TANKSCHUTZ_UND_TANKBAU: 'Tankschutz und Tankbau/in',
};

export const JOB_TITLE_FAHRZEUG_UND_MASCHINENTECHNIK_TYPE = {
  FAHRRADMONTEUR: 'Fahrradmonteur/-in',
  KRAFTFAHRZEUGMECHATRONIKER: 'Kraftfahrzeugmechatroniker/-in (ugs. Kfz-Schlosser/in)',
  KAROSSERIE_UND_FAHRZEUGBAUMECHANIKER: 'Karosserie- und Fahrzeugbaumechaniker/-in',
  LAND_UND_BAUMASCHINENMECHATRONIKER: 'Land- und Baumaschinenmechatroniker/-in',
  MASCHINEN_UND_ANLAGENFUHRER: 'Maschinen- und Anlagenführer/-in',
  STELLMACHER: 'Stellmacher/-in',
  ZWEIRADMECHATRONIKER: 'Zweiradmechatroniker/-in',
};

export const JOB_TITLE_FARBTECHNIK_TYPE = {
  BAUTEN_UND_OBJEKTBESCHICHTER: 'Bauten- und Objektbeschichter/-in',
  BUHNENMALER: 'Bühnenmaler',
  FAHRZEUGLACKIERER: 'Fahrzeuglackierer/-in',
  KIRCHENMALER: 'Kirchenmaler/-in',
  AUTOLACKIERER: 'Autolackierer/in',
  MALER_UND_LACKIERER: 'Maler/-in und Lackierer/-in',
};

export const JOB_TITLE_STEIN_UND_PLATTENLEGEHANDWERK_TYPE = {
  FLIESEN_PLATTEN_UND_MOSAIKLEGER: 'Fliesen-, Platten- und Mosaikleger/-in',
};

export const JOB_TITLE_INSTRUMENTENBAU_TYPE = {
  BOGENMACHER: 'Bogenmacher/-in',
  GEIGENBAUER: 'Geigenbauer/-in',
  HANDZUGINSTRUMENTENMACHER: 'Handzuginstrumentenmacher/-in',
  HOLZBLASINSTRUMENTENMACHER: 'Holzblasinstrumentenmacher/-in',
  KLAVIER_UND_CEMBALOBAUER: 'Klavier- und Cembalobauer/-in',
  METALLBLASINSTRUMENTENMACHER: 'Metallblasinstrumentenmacher/-in',
  ORGELBAUER: 'Orgelbauer/-in',
  ZUPFINSTRUMENTENMACHER: 'Zupfinstrumentenmacher/-in',
};

export const JOB_TITLE_BEKLEIDUNGS_TEXTIL_UND_LEDERHANDWERK_TYPE = {
  ANDERUNGSSCHNEIDER: 'Änderungsschneider/-in (ugs. Schneider/in)',
  BUCHBINDER: 'Buchbinder/-in',
  FACHKRAFT_FUR_LEDERHERSTELLUNG_UND_GERBEREITECHNIK: 'Fachkraft für Lederherstellung und Gerbereitechnik',
  HANDSCHUHMACHER: 'Handschuhmacher/-in',
  KORSETTMACHER: 'Korsettmacher',
  KURSCHNER: 'Kürschner/-in',
  MASSSCHUHMACHER: 'Maßschuhmacher/-in (ugs. Schuhmacher/-in)',
  MASSSCHNEIDER: 'Maßschneider/-in',
  MODIST: 'Modist/-in (ugs. Hutmacher/-in)',
  POLSTER_UND_DEKORATIONSNAHER: 'Polster- und Dekorationsnäher/-in',
  RAUMAUSSTATTER: 'Raumausstatter/-in',
  SATTLER: 'Sattler/-in',
  SEGELMACHER: 'Segelmacher/-in',
  SEILER: 'Seiler/-in',
  TEXTILGESTALTER_IM_HANDWERK: 'Textilgestalter/-in im Handwerk',
  TEXTILREINIGER: 'Textilreiniger/-in',
};

export const JOB_TITLE_LEBENSMITTELHANDWERK_TYPE = {
  BACKER: 'Bäcker/-in',
  BRAUER_UND_MALZER: 'Brauer und Mälzer/-in',
  FACHVERKAUFER_IM_LEBENSMITTELHANDWERK: 'Fachverkäufer/-in im Lebensmittelhandwerk',
  FLEISCHER: 'Fleischer/-in',
  KONDITOR: 'Konditor/-in',
  VERFAHRENSTECHNOLOGE_IN_MUHLEN_UND_GETREIDEWIRTSCHAFT: 'Verfahrenstechnologe/in in Mühlen und Getreidewirtschaft (ugs. Müller/in)',
  WEINTECHNOLOGE: 'Weintechnologe/-in',
};

export const JOB_TITLE_HANDWERK_IN_DER_GESUNDHEITSBRANCHE_TYPE = {
  AUGENOPTIKER: 'Augenoptiker/-in',
  CHIRURGIEMECHANIKER: 'Chirurgiemechaniker/-in',
  GEBAUDEREINIGER: 'Gebäudereiniger/-in',
  HORAKUSTIKER: 'Hörakustiker/-in',
  ORTHOPADIETECHNIK_MECHANIKER: 'Orthopädietechnik-Mechaniker/-in',
  ORTHOPADIESCHUHMACHER: 'Orthopädieschuhmacher/-in',
  ZAHNTECHNIKER: 'Zahntechniker/-in',
};

export const JOB_TITLE_HANDWERK_IN_DER_SCHONHEITSBRANCHE_TYPE = {
  FRISEUR: 'Friseur/-in',
  KOSMETIKER: 'Kosmetiker/-in',
  TATTOWIERER: 'Tattowierer/in',
  MASKENBILDNER: 'Maskenbildner/-in',
  NAGELDESIGNER: 'Nageldesigner',
};

export const JOB_TITLE_SONSTIGE_GEWERKE_TYPE = {
  AUTOMOBILKAUFMANN: 'Automobilkaufmann/frau',
  BESTATTUNGSFACHKRAFT: 'Bestattungsfachkraft',
  EDELSTEINSCHLEIFER: 'Edelsteinschleifer/-in',
  KERZENHERSTELLER_UND_WACHSBILDNER: 'Kerzenhersteller/-in und Wachsbildner/-in',
  FACHKRAFT_FUR_LAGERLOGISTIK: 'Fachkraft für Lagerlogistik',
  FACHLAGERIST: 'Fachlagerist/in',
  FOTOGRAF: 'Fotograf/-in',
  FOTOMEDIENFACHMANN: 'Fotomedienfachmann/-frau',
  KAUFMANN_FUR_BUROMANAGEMENT: 'Kaufmann/-frau für Büromanagement',
  KAUFMANN_IM_EINZELHANDEL: 'Kaufmann/-frau im Einzelhandel',
  MECHANIKER_FUR_REIFEN_UND_VULKANISATIONSTECHNIK: 'Mechaniker/-in für Reifen- und Vulkanisationstechnik',
  MEDIENGESTALTER_DIGITAL_UND_PRINT: 'Mediengestalter/-in Digital und Print',
  MEDIENTECHNOLOGE_DRUCK: 'Medientechnologe/-in Druck',
  MEDIENTECHNOLOGE_SIEBDRUCK: 'Medientechnologe/-in Siebdruck',
  METALL_UND_GLOCKENGIESSER: 'Metall- und Glockengießer/-in',
  OBERFLACHENBESCHICHTER: 'Oberflächenbeschichter/-in',
  SCHILDER_UND_LICHTREKLAMEHERSTELLER: 'Schilder- und Lichtreklamehersteller/-in',
  TECHNISCHER_PRODUKTDESIGNER: 'Technische/-r Produktdesigner/-in',
  TECHNISCHER_SYSTEMPLANER: 'Technische/-r Systemplaner/-in',
  THERMOMETERMACHER: 'Thermometermacher/-in',
  UHRMACHER: 'Uhrmacher/-in',
  VERFAHRENSMECHANIKER_FUR_KUNSTSTOFF_UND_KAUTSCHUKTECHNIK: 'Verfahrensmechaniker/in für Kunststoff- und Kautschuktechnik',
  VERGOLDER: 'Vergolder/-in',
  FEUERWERK_UND_PYROTECHNIKER: 'Feuerwerk und Pyrotechniker/-in',
  ARCHITEKTEN_UND_INGENIEURE: 'Architekten und Ingenieure',
};

export const JOB_TITLE = {
  ...JOB_TITLE_HOLZHANDWERK_TYPE,
  ...JOB_TITLE_METALLHANDWERK_TYPE,
  ...JOB_TITLE_GLAS_UND_KERAMIK_TYPE,
  ...JOB_TITLE_BAUHANDWERK_TYPE,
  ...JOB_TITLE_ELEKTROTECHNIK_TYPE,
  ...JOB_TITLE_SANITAR_UND_VERSORGUNGSTECHNIK_TYPE,
  ...JOB_TITLE_FAHRZEUG_UND_MASCHINENTECHNIK_TYPE,
  ...JOB_TITLE_FARBTECHNIK_TYPE,
  ...JOB_TITLE_STEIN_UND_PLATTENLEGEHANDWERK_TYPE,
  ...JOB_TITLE_INSTRUMENTENBAU_TYPE,
  ...JOB_TITLE_BEKLEIDUNGS_TEXTIL_UND_LEDERHANDWERK_TYPE,
  ...JOB_TITLE_LEBENSMITTELHANDWERK_TYPE,
  ...JOB_TITLE_HANDWERK_IN_DER_GESUNDHEITSBRANCHE_TYPE,
  ...JOB_TITLE_HANDWERK_IN_DER_SCHONHEITSBRANCHE_TYPE,
  ...JOB_TITLE_SONSTIGE_GEWERKE_TYPE,
};

export const BRANCHE_TYPE = {
  HOLZHANDWERK: 'Holzhandwerk',
  METALLHANDWERK: 'Metallhandwerk',
  GLAS_UND_KERAMIK: 'Glas und Keramik',
  BAUHANDWERK: 'Bauhandwerk',
  ELEKTROTECHNIK: 'Elektrotechnik',
  SANITAR_UND_VERSORGUNGSTECHNIK: 'Sanitär- und Versorgungstechnik',
  FAHRZEUG_UND_MASCHINENTECHNIK: 'Fahrzeug- und Maschinentechnik',
  FARBTECHNIK: 'Farbtechnik',
  STEIN_UND_PLATTENLEGEHANDWERK: 'Stein- und Plattenlegehandwerk',
  INSTRUMENTENBAU: 'Instrumentenbau',
  BEKLEIDUNGS_TEXTIL_UND_LEDERHANDWERK: 'Bekleidungs-, Textil- und Lederhandwerk',
  LEBENSMITTELHANDWERK: 'Lebensmittelhandwerk',
  HANDWERK_IN_DER_GESUNDHEITSBRANCHE: 'Handwerk in der Gesundheitsbranche',
  HANDWERK_IN_DER_SCHONHEITSBRANCHE: 'Handwerk in der Schönheitsbranche',
  SONSTIGE_GEWERKE: 'Sonstige Gewerke',
};

export const LANGUAGE = {
  af: 'Afrikaans',
  ar: 'Arabic',
  ast: 'Asturian',
  az: 'Azerbaijani',
  bg: 'Bulgarian',
  be: 'Belarusian',
  bn: 'Bengali',
  br: 'Breton',
  bs: 'Bosnian',
  ca: 'Catalan',
  cs: 'Czech',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dsb: 'Lower Sorbian',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  fi: 'Finnish',
  fr: 'French',
  fy: 'Frisian',
  ga: 'Irish',
  gd: 'Scottish Gaelic',
  gl: 'Galician',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hsb: 'Upper Sorbian',
  hu: 'Hungarian',
  hy: 'Armenian',
  ia: 'Interlingua',
  id: 'Indonesian',
  ig: 'Igbo',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  ja: 'Japanese',
  ka: 'Georgian',
  kab: 'Kabyle',
  kk: 'Kazakh',
  km: 'Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  ky: 'Kyrgyz',
  lb: 'Luxembourgish',
  lt: 'Lithuanian',
  lv: 'Latvian',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mr: 'Marathi',
  my: 'Burmese',
  nb: 'Norwegian Bokmål',
  ne: 'Nepali',
  nl: 'Dutch',
  nn: 'Norwegian Nynorsk',
  os: 'Ossetic',
  pa: 'Punjabi',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sq: 'Albanian',
  sr: 'Serbian',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  tk: 'Turkmen',
  tr: 'Turkish',
  tt: 'Tatar',
  udm: 'Udmurt',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
};
