import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon, TrashIcon } from '@heroicons/react/outline';
import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandedMessageType } from '../../../__generated__/graphql';
import { DELETE_MESSAGE_MUTATION } from '../../../api/mutations/chats';
import { MESSAGES_QUERY } from '../../../api/queries/chats';
import { classNames } from '../../../helpers/utils';
import { Warning } from '../../../layout/modals';


interface CachedMessagesType {
  messages: ExpandedMessageType,
}

interface Props {
  isSender: boolean,
  messageId: string,
  threadId: string,
}

export default function MessageOptions(props: Props) {
  const { isSender, messageId, threadId } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const [
    deleteMutation,
    {
      loading: deleteLoading,
    },
  ] = useMutation(DELETE_MESSAGE_MUTATION, {
    update: (cache, { data: { deleteMessage } }) => {
      if (deleteMessage.response) {
        const data: CachedMessagesType | null = cache.readQuery({
          query: MESSAGES_QUERY,
          variables: { thread: threadId },
        });
        const filteredList = data?.messages?.objects?.filter((item) => item?.id !== messageId);
        const newData = {
          messages: {
            hasArchive: data?.messages.hasArchive,
            objects: filteredList,
          },
        };
        cache.writeQuery({
          query: MESSAGES_QUERY,
          variables: { thread: threadId },
          data: newData,
        });
      }
    },
  });

  const handleDelete = () => {
    if (!deleteLoading) {
      deleteMutation({ variables: { message: messageId } });
    }
    setShowConfirmationModal(false);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const requireConfirmation = () => {
    if (!deleteLoading) {
      toggleConfirmationModal();
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left p-1">
      <div>
        <Menu.Button className="flex items-center">
          <DotsVerticalIcon className="text-secondaryGray h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(isSender ? 'top-2 -right-5 md:top-0 md:right-5' : 'left-0', 'absolute mt-2 rounded-md shadow-lg bg-white')}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => requireConfirmation()}
            className="cursor-pointer py-2 px-3 text-sm leading-4 font-medium rounded-md  hover:bg-lightGray"
          >
            <Menu.Item>
              <div
                className="inline-flex items-center"
              >
                <TrashIcon className="h-5 w-5  mr-1" />
                {t('Delete')}
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
      <Warning
        viewModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        actionTrigger={handleDelete}
        title={t('Delete Message')}
      />
    </Menu>
  );
}
