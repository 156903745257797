import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { ThumbUpIcon } from '@heroicons/react/outline';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APPLICANT_COMPANY_LIKE_MUTATION } from '../../../api/mutations/likes';
import { DOES_APPLICANT_LIKE_COMPANY_QUERY } from '../../../api/queries/likes';
import { MATCHED_APPLICANT_OFFERS_QUERY } from '../../../api/queries/offers';

interface Props {
  companyId: string,
}

function LikePossibleMatchSection(props: Props) {
  const { companyId } = props;
  const [companyLiked, setCompanyLiked] = useState(undefined);
  const { t } = useTranslation();
  const client = useApolloClient();

  const { data: likeData } = useQuery(
    DOES_APPLICANT_LIKE_COMPANY_QUERY,
    { variables: { company: companyId } },
  );

  const [
    likeMutation,
    {
      loading: mutationLoading,
    }] = useMutation(APPLICANT_COMPANY_LIKE_MUTATION, {
    refetchQueries: [
      { query: DOES_APPLICANT_LIKE_COMPANY_QUERY, variables: { company: companyId } },
      { query: MATCHED_APPLICANT_OFFERS_QUERY, variables: { page: 1 } }],
  });

  const handleButtonClick = () => {
    likeMutation({
      variables: {
        company: companyId,
      },
    });
  };

  useEffect(() => {
    if (likeData) {
      setCompanyLiked(likeData.doesApplicantLikeCompany);
    }
  }, [likeData]);

  if (companyLiked === undefined) {
    // Simulate button space
    return (
      <div
        className="py-2"
      >
        <div className="h-5" />
      </div>
    );
  }
  return (
    companyLiked
      ? (
        <div
          className="px-3 relative flex bg-darkerGray border border-transparent rounded-md py-2 items-center justify-center text-sm font-medium  hover:bg-lightGray"
        >
          <Link
            to="/applicant/matches/"
            onMouseEnter={() => client.query({
              query: MATCHED_APPLICANT_OFFERS_QUERY,
              fetchPolicy: 'network-only',
            })}
          >
            {`${t('Done')}!`}
            <span>&nbsp;&nbsp;</span>

            {t('Show matching options?')}
          </Link>
        </div>
      )
      : (
        <div
          tabIndex={0}
          role="button"
          data-disabled={mutationLoading}
          onClick={() => handleButtonClick()}
          className="py-2 items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <div className="flex justify-center">
            <ThumbUpIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span className="flex items-center">
              {t('Like')}
              {' '}
              {t('Company')}
            </span>
          </div>
        </div>
      )

  );
}

export default LikePossibleMatchSection;
