import { FilterIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/utils';

interface Props {
  filterIsActive: boolean,
  setFilterOverlay: (value: boolean) => void,
}

function MatchingFilterButton(props: Props) {
  const { filterIsActive, setFilterOverlay } = props;
  const { t } = useTranslation();
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => setFilterOverlay(true)}
      className={classNames(filterIsActive ? 'text-secondary' : '', 'cursor-pointer flex border border-t-lightGray border-l-lightGray shadow-md hover:shadow-xl items-center justify-center h-12')}
    >
      <div className="text-lg font-semibold">{t('Filter')}</div>
      <FilterIcon className="h-5 w-5 ml-2" />
    </div>
  );
}

export default MatchingFilterButton;
