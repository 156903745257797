import { XCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface Props {
  errors: string[],
}

export default function AlertErrorsList(props: Props) {
  const { t } = useTranslation();
  const { errors } = props;
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {errors.length}
            {' '}
            {t('Error(s) in your submission')}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              {errors.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
