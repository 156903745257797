import { useQuery } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ManagerType } from '../../__generated__/graphql';
import { COMPANY_ADDRESS_MUTATION } from '../../api/mutations/address';
import { COMPANY_QUERY, CURRENT_COMPANY_DASHBOARD_QUERY } from '../../api/queries/companies';
import { MINIFIED_CURRENT_MANAGER_QUERY } from '../../api/queries/managers';
import BenefitsList from '../../components/settings/manager/company/benefits/BenefitsList';
import ManagersList from '../../components/settings/manager/company/managers/ManagersList';
import OwnershipForm from '../../components/settings/manager/company/OwnershipForm';
import { CompanyForm, AddressForm } from '../../components/shared';
import { classNames } from '../../helpers/utils';
import { Select } from '../../layout/fields';
import { SettingsLayout } from '../../layout/layouts/dashboard';

const initialTabs = [
  { label: 'General Data', value: 'general' },
  { label: 'Benefits', value: 'benefits' },
  { label: 'Address', value: 'address' },
  { label: 'Managers', value: 'managers' },
];

function Company() {
  const [tabs, setTabs] = useState(initialTabs);
  const [isOwner, setIsOwner] = useState(false);
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: companyData,
  } = useQuery(COMPANY_QUERY, {
    variables: { company: id },
  });

  const {
    data: managerData,
  } = useQuery(MINIFIED_CURRENT_MANAGER_QUERY);

  const unsetOwner = useCallback(() => {
    setIsOwner(false);
    setTabs(initialTabs);
  }, [setIsOwner]);

  useEffect(() => {
    if (managerData && managerData.currentManager && companyData && companyData.company) {
      if (companyData.company.owner.id === managerData.currentManager.id) {
        const updatedTabs = [
          ...initialTabs,
          { label: 'Ownership', value: 'ownership' },
        ];
        setIsOwner(true);
        setTabs(updatedTabs);
      }
    }
  }, [managerData, companyData]);

  const translatedTabs = tabs.map((item) => (
    {
      ...item,
      label: t(item.label),
    }
  ));

  return (
    <SettingsLayout>
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="sm:hidden px-2">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            {/* @ts-ignore */}
            <Select
              value={translatedTabs.find((item) => item.value === tab)}
              onChange={(e) => navigate(`/settings/companies/${id}/${e.value}/`)}
              options={translatedTabs}
            >
              {translatedTabs.map((item) => (
                <option key={item.value}>{item.label}</option>
              ))}
            </Select>
          </div>
          <div className="hidden sm:overflow-hidden sm:block sm:rounded-md">
            <nav className="relative z-0 rounded-lg shadow flex divide-x divide-lightGray" aria-label="Tabs">
              {translatedTabs.map((item, tabIdx) => (
                <Link
                  key={item.label}
                  to={`/settings/companies/${id}/${item.value}/`}
                  className={classNames(
                    item.value === tab ? '' : ' hover:',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === translatedTabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-lightGray focus:z-10',
                  )}
                  aria-current={item.value === tab ? 'page' : undefined}
                >
                  <span>
                    {item.label}
                  </span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      item.value === tab ? 'bg-primary' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    )}
                  />
                </Link>
              ))}
            </nav>
          </div>
          {companyData && (
            <div className="mt-4">
              {tab === 'general'
                && (
                  <CompanyForm
                    currentCompany={companyData.company}
                    refetchQueries={[
                      { query: COMPANY_QUERY, variables: { company: companyData.company.id } },
                      { query: CURRENT_COMPANY_DASHBOARD_QUERY },
                    ]}
                  />
                )}
              {tab === 'benefits' && (
                <BenefitsList
                  companyId={companyData.company.id}
                  benefits={companyData.company.benefits}
                />
              )}
              {tab === 'address'
                && (
                  <AddressForm
                    currentAddress={companyData.company.address}
                    mutationSchema={COMPANY_ADDRESS_MUTATION}
                    companyId={companyData.company.id}
                    refetchQueries={[
                      { query: COMPANY_QUERY, variables: { company: companyData.company.id } },
                    ]}
                  />
                )}
              {tab === 'managers' && (
                <ManagersList
                  companyId={companyData.company.id}
                  pendingInvitations={companyData.company.pendingInvitations}
                  managers={companyData.company.managers}
                />
              )}
              {isOwner && tab === 'ownership' && (
                <OwnershipForm
                  companyId={companyData.company.id}
                  ownerCandidates={
                    companyData.company.managers.filter(
                      (item: ManagerType) => item.id !== managerData.currentManager.id,
                    )
                  }
                  unsetOwner={unsetOwner}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  );
}

export default Company;
