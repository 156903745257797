import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import { PossibleCompanyMatchType } from '../../../__generated__/graphql';
import { PUBLIC_COMPANY_QUERY } from '../../../api/queries/companies';
import { IMAGE_SIZE } from '../../../constants';
import {
  CompanyLogoImage,
  CompanyProfileImage,
  TimeAgo,
} from '../../../layout';
import { LikePossibleMatchSection } from '../likes';

interface Props {
  item: PossibleCompanyMatchType,
}

function PossibleMatchCompanyCard(props: Props) {
  const { item } = props;
  const client = useApolloClient();
  return (
    <div
      className="group relative bg-white border-2 border-darkerGray flex flex-col"
    >
      <Link
        to={`/company/${item.id}`}
        onMouseEnter={() => client.query({
          query: PUBLIC_COMPANY_QUERY,
          variables: { company: item.id },
          fetchPolicy: 'network-only',
        })}
      >
        <div className="group-hover:opacity-80">
          <CompanyProfileImage
            className="w-full aspect-video object-center object-cover text-5xl"
            company={item}
            imageSize={IMAGE_SIZE.S512}
          />
        </div>
      </Link>
      <div className="flex-1 p-4 space-y-2 flex flex-col">
        <Link
          to={`/company/${item.id}`}
          onMouseEnter={() => client.query({
            query: PUBLIC_COMPANY_QUERY,
            variables: { company: item.id },
            fetchPolicy: 'network-only',
          })}
        >
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0 h-12 w-12">
              <CompanyLogoImage
                className="h-12 w-12 rounded-lg text-2xl"
                company={item}
                imageSize={IMAGE_SIZE.S64}
              />
            </div>
            <span className="ml-2">{item.name}</span>
          </div>
        </Link>
        <p className="text-sm  h-4 truncate">{item.description}</p>
        <div className="flex-1 flex flex-col justify-end">
          <p className="text-sm italic "><TimeAgo date={item.likedAt} /></p>
          <div className="mt-5"><LikePossibleMatchSection companyId={item.id as string} /></div>
        </div>
      </div>
    </div>
  );
}

export default PossibleMatchCompanyCard;
