import { ReactNode } from 'react';
import { Footer } from '..';
import handwerker from '../../assets/images/handwerker.jpg';
import logo from '../../assets/images/logo.svg';

interface Props {
  children: ReactNode,
}

export default function EmailConfirmationLayout({ children }: Props) {
  return (
    <div className="min-h-screen bg-white flex text-darkestGray justify-center">
      <div className="grid px-4 sm:px-6 lg:px-20 xl:px-24 items-center">
        <div className="mx-auto w-full max-w-sm lg:w-96 py-12 self-end">
          <div>
            <img
              className="h-12 w-auto"
              src={logo}
              alt="Sprungbrett Handwerk Logo"
            />
            <div className="mt-6">
              {children}
            </div>
          </div>
        </div>
        <div className="self-end">
          <Footer />
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={handwerker}
          alt=""
        />
      </div>
    </div>
  );
}
