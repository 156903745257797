/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/named
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_APP_CLIENT_ID } from '../../../config';
import { SOCIAL_AUTH_TYPE } from '../../../constants';
import FacebookAuthButton from './FacebookAuthButton';
import GoogleAuthButton from './GoogleAuthButton';

interface AuthVarsType {
  token: string,
  provider: string,
  userType?: string,
}

interface Props {
  userType?: string,
  googleButtonLabel: string,
  facebookButtonLabel: string,
  executeMutation: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>,
  authType: string,
  isDisabled?: boolean,
}

function SocialAuth(props: Props) {
  const {
    userType, googleButtonLabel, facebookButtonLabel, executeMutation, authType,
    isDisabled,
  } = props;
  const disabled = (isDisabled || userType === '');
  const facebookButtonIsEnabled = false;
  const googleButtonIsEnabled = true;

  const handleAuth = ({ token, provider }: { token: string, provider: string }) => {
    if (token && provider) {
      const variables: AuthVarsType = {
        token,
        provider,
      };
      if (authType === SOCIAL_AUTH_TYPE.REGISTER) {
        variables.userType = userType;
      }
      executeMutation({ variables });
    }
  };

  return (
    <>
      {googleButtonIsEnabled && (
        <GoogleOAuthProvider clientId={GOOGLE_APP_CLIENT_ID}>
          <GoogleAuthButton
            disabled={disabled}
            label={googleButtonLabel}
            handleAuth={handleAuth}
          />
        </GoogleOAuthProvider>
      )}
      {facebookButtonIsEnabled && (
        <FacebookAuthButton
          className="mt-4"
          disabled={disabled}
          label={facebookButtonLabel}
          handleAuth={handleAuth}
        />
      )}
    </>
  );
}

export default SocialAuth;
