import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { BenefitType } from '../../../../../__generated__/graphql';
import { BENEFIT_TYPE } from '../../../../../constants';

interface Props {
  item: BenefitType,
  handleDelete: (value: string) => void,
}

function BenefitCard(props: Props) {
  const { item, handleDelete } = props;
  const { t } = useTranslation();
  return (
    <div
      className="relative rounded sm:rounded-lg border bg-white px-6 py-5 shadow-sm flex items-center space-x-3"
    >
      <XIcon
        onClick={() => handleDelete(item.id)}
        className="cursor-pointer flex-1  absolute top-4 right-4 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full"
      />
      <div className="flex-1 justify-between min-w-0">
        <p className="text-sm font-medium ">{t(BENEFIT_TYPE[item.benefitType])}</p>
        <p className="text-sm  truncate">
          {item.description}
        </p>
      </div>
    </div>
  );
}

export default BenefitCard;
