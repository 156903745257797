import { PlusCircleIcon } from '@heroicons/react/outline';

interface Props {
  title: string,
  onClick: () => void,
}

export default function ActionCompanyCard(props: Props) {
  const { title, onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <div
        className="hover:bg-lightGray cursor-pointer hover:border-lightGray relative rounded sm:rounded-lg border bg-white px-6 py-5 shadow-sm flex items-center space-x-3"
      >
        <div className="flex place-items-center">
          <PlusCircleIcon className="w-10 h-10 " />
          <div className="text-sm  ml-2">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}
