import { useMutation } from '@apollo/client';
import { NotificationType } from '../../../__generated__/graphql';
import { DISMISS_NOTIFICATION_MUTATION } from '../../../api/mutations/notifications';
import { CURRENT_NOTIFICATIONS_QUERY } from '../../../api/queries/notifications';
import Notification from './Notification';

interface Props {
  items: NotificationType[],
  hideNotifications: () => void,
}

export default function NotificationsList(props: Props) {
  const { items, hideNotifications } = props;
  const [
    notificationMutation,
    {
      loading,
    },
  ] = useMutation(DISMISS_NOTIFICATION_MUTATION, {
    refetchQueries: [{ query: CURRENT_NOTIFICATIONS_QUERY }],
  });

  const handleDismiss = (itemId: string) => {
    if (!loading) {
      notificationMutation({ variables: { notification: itemId } });
    }
  };

  return (
    <ul
      id="styled-scroll"
      className="h-screen overflow-y-auto space-y-2 pr-2 rounded-md divide-y divide-lightGray pb-4"
    >
      {items.map((item) => (
        <li
          key={item.id}
          className="border rounded-md bg-white relative py-5 px-4 shadow-md hover:shadow-xl hover:bg-lightGray"
        >
          <Notification
            id={item.id}
            timestamp={item.createdAt}
            targetPath={item.targetPath}
            subject={item.subject}
            body={item.body}
            handleDismiss={handleDismiss}
            hideNotifications={hideNotifications}
          />
        </li>
      ))}
    </ul>
  );
}
