export default function InfoBanner() {
  return (
    <div className="bg-gradient-to-r from-yellow-100 to-green-100 sm:rounded-lg border">
      <div className="px-4 py-5 sm:p-6">
        <div className="mt-2 max-w-xl text-sm text-darkestGray">
          <p>
          Kostenfrei in 2024: Der Vollständigkeit halber benötigen wir Deine Kontodaten. Wir werden im vierten Quartal 2024 noch einmal auf dich zukommen, um Daten abzugleichen, dein Feedback einzuholen und Du kannst dich entscheiden, ob du Sprungbrett Handwerk weiter nutzen möchtest.
          </p>
        </div>
      </div>
    </div>
  );
}
