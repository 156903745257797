import { useApolloClient } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { CityType } from '../../__generated__/graphql';
import {
  zIndexTweakedSelectStyles,
  updateSelectTheme,
} from '../../helpers/utils';

interface Props {
  getInlineOptions: (cities: CityType[]) => void,
  value: SelectOption | null,
  variables: { name: string, skipSmall: boolean },
  query: DocumentNode,
  handleInputChange: (newValue: string) => string,
  onChange: (value: SelectOption) => void,
  isClearable: boolean,
  isDisabled?: boolean,
  queryName: string,
  placeholder?: string,
}

const INLINE_RECORDS_SIZE = 100;

function AsyncSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    query, variables, handleInputChange, value, onChange, isClearable, queryName,
    getInlineOptions, placeholder, isDisabled,
  } = props;
  
  const client = useApolloClient();
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadOptions(search, loadedOptions, { skip }: any) {
    const updatedVariables = {
      ...variables,
      skip,
    };
    const { data } = await client.query({
      query,
      variables: updatedVariables,
      context: {
        debounceKey: queryName,
      },
    });
    const innerData = data[Object.keys(data)[0]];
    return {
      options: data ? getInlineOptions(innerData.objects) : [],
      hasMore: innerData.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  return (
    <AsyncPaginate
      placeholder={placeholder || `${t('Select')}...`}
      defaultOptions
      value={value}
      //@ts-ignore
      loadOptions={loadOptions}
      //@ts-ignore
      onChange={onChange}
      additional={{
        skip: 0,
      }}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onInputChange={handleInputChange}
      styles={zIndexTweakedSelectStyles}
      theme={(theme) => updateSelectTheme(theme)}
      menuPortalTarget={document.body}
      noOptionsMessage={() => (t('No options'))}
    />
  );
}

export default AsyncSelectInput;
