import { useQuery, useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApplicantLikeType, ApplicantOfferViewType, PaginatedApplicantLikeType, PaginatedApplicantOfferViewType } from '../../../__generated__/graphql';
import { APPLICANT_LIKES_QUERY, APPLICANT_OFFER_VIEWS_QUERY } from '../../../api/queries/activities';
import { CURRENT_COMPANY_ACTIVITY_TABS_QUERY } from '../../../api/queries/companies';
import ActivitiesList from '../../../components/manager/activity/ActivitiesList';
import Tabs from '../../../components/manager/activity/Tabs';
import { Pagination } from '../../../layout';
import { ManagerDashboardLayout } from '../../../layout/layouts/dashboard';

function ApplicantsActivity() {
  const [page, setPage] = useState(1);
  const [innerData, setInnerData] = useState<PaginatedApplicantLikeType | PaginatedApplicantOfferViewType | undefined>(undefined);
  const [likesQuantity, setLikesQuantity] = useState(undefined);
  const [viewsQuantity, setViewsQuantity] = useState(undefined);
  const { t } = useTranslation();
  const { tab, offer } = useParams();
  const {
    data: companyOffersData,
  } = useQuery(CURRENT_COMPANY_ACTIVITY_TABS_QUERY);

  const [
    getLikes,
    {
      data: likesData,
    }] = useLazyQuery(APPLICANT_LIKES_QUERY);

  const [
    getViews,
    {
      data: viewsData,
    }] = useLazyQuery(APPLICANT_OFFER_VIEWS_QUERY);

  useEffect(() => {
    if (companyOffersData && companyOffersData.currentCompany) {
      setLikesQuantity(companyOffersData.currentCompany.totalOffersLikesQuantity);
      setViewsQuantity(companyOffersData.currentCompany.totalApplicantOfferViewsQuantity);
    }
  }, [companyOffersData]);

  useEffect(() => {
    if (likesData && likesData.applicantLikes) {
      if (tab === 'likes') {
        setInnerData(likesData.applicantLikes);
      }
      setLikesQuantity(likesData.applicantLikes.totalObjectsQuantity);
    }
  }, [likesData, tab]);

  useEffect(() => {
    if (viewsData && viewsData.applicantOfferViews) {
      if (tab === 'views') {
        setInnerData(viewsData.applicantOfferViews);
      }
      setViewsQuantity(viewsData.applicantOfferViews.totalObjectsQuantity);
    }
  }, [viewsData, tab]);

  useEffect(() => {
    const initialPage = 1;
    setPage(initialPage);
    // This triggers data fetch only for the first time the tab loads.
    // Then the first page relies on Apollo cache
    getLikes({ variables: { page: initialPage, offer } });
    getViews({ variables: { page: initialPage, offer } });
  }, [tab, offer, getLikes, getViews]);

  useEffect(() => {
    if (tab === 'likes') {
      getLikes({ variables: { page, offer } });
    } else if (tab === 'views') {
      getViews({ variables: { page, offer } });
    }
  }, [tab, page, offer, getLikes, getViews]);

  return (
    <ManagerDashboardLayout>
      <div className="sm:rounded-lg relative min-h-screen bg-white">
        <main className="pt-8 pb-16">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <h2 className="text-lg font-medium ">{t('Applicants Activity')}</h2>
              {/* Tabs */}
              <Tabs
                tab={tab as string}
                currentOffer={offer || ''}
                likesQuantity={likesQuantity}
                viewsQuantity={viewsQuantity}
                offers={companyOffersData
                  && companyOffersData.currentCompany
                  && companyOffersData.currentCompany.offers}
              />
              {/* Tabs End */}
            </div>

            {innerData && (
              <>
                {innerData?.objects?.length
                  ? (
                    <ActivitiesList
                      items={innerData.objects as ApplicantLikeType[] | ApplicantOfferViewType[]}
                    />
                  )
                  : (
                    <div className="py-8">
                      <span className="px-4 sm:px-0">
                        {t('No records yet in')}
                        {' '}
                        {tab}
                      </span>
                    </div>
                  )}

                <Pagination
                  page={innerData.page as number}
                  hasNext={innerData.hasNext as boolean}
                  hasPrev={innerData.hasPrev as boolean}
                  elidedPageRange={innerData.elidedPageRange as string[]}
                  onPageChange={setPage}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </ManagerDashboardLayout>
  );
}

export default ApplicantsActivity;
