import {
  CurrencyEuroIcon, LocationMarkerIcon, MapIcon, CalendarIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../../../__generated__/graphql';
import {
  JOB_TITLE, LANGUAGE, DRIVER_LICENSE_TYPE, JOB_QUALIFICATION, IMAGE_SIZE,
} from '../../../constants';
import { getFormattedDate } from '../../../helpers/utils';
import { CompanyLogoImage } from '../../../layout';
import { ApplicantOfferViewTrigger } from '../../offer';
import { AttributeCard, BenefitPlateCard } from '../../shared';
import { ShowProfileButton } from '../../shared/buttons';
import { LikeOfferSection, LikeCompanySection } from '../likes';

interface Props {
  item: OfferType,
}

function DetailCard(props: Props) {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <div>
      <ApplicantOfferViewTrigger offerId={item.id} />
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1 space-y-2">
          <CompanyLogoImage
            company={item.company}
            className="aspect-square w-full border rounded-lg border-lightGray text-7xl"
            imageSize={IMAGE_SIZE.S256}
          />
          <LikeOfferSection offerId={item.id} />
        </div>
        <div className="pl-2 col-span-2">
          <div className="text-2xl font-bold  ">
            {JOB_TITLE[item.title as keyof typeof JOB_TITLE]}
          </div>
          <div className="flex text-primary py-2 font-semibold">
            {item.company.name}
          </div>
          <dl className="space-y-1">
            <dd className="flex items-center">
              <CurrencyEuroIcon className="h-5 w-5 mr-1" />
              {item?.salaryDetails?.exactValue}
              {' '}
              {item?.salaryDetails?.currencyCode}
            </dd>
            <dd className="flex items-center">
              <LocationMarkerIcon className="h-5 w-5 mr-1" />
              {item.useCompanyAddress
                ? item?.company?.address?.city.name
                : item?.address?.city.name}
            </dd>
            <dd className="flex items-center">
              <MapIcon className="h-5 w-5 mr-1" />
              {item.travellingRequired
                ? t('Willingness to travel is required')
                : t('Willingness to travel is not required')}
            </dd>
            <dd className="flex items-center">
              <CalendarIcon className="h-5 w-5 mr-1" />
              {t('Published')}
              :
              {' '}
              {getFormattedDate(item.createdAt)}
            </dd>
          </dl>
        </div>
      </div>
      <div className="py-8">
        {item.description.split('\n').map((str, index) => <p className="pb-2" key={index}>{str}</p>)}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="font-semibold text-lg">
          {t('What Defines You')}
          <dl className="space-y-4 pt-2">
            {!!item?.driverLicence?.length && (
              <dd className="rounded-lg bg-darkerGray shadow-md">
                <AttributeCard
                  label={t('Driver Licence')}
                  value={item?.driverLicence
                    .map((licence) => DRIVER_LICENSE_TYPE[licence as keyof typeof DRIVER_LICENSE_TYPE])
                    .join(', ')}
                />
              </dd>
            )}
            <dd className="rounded-lg bg-darkerGray shadow-md">
              <AttributeCard
                label={t('Qualification')}
                value={JOB_QUALIFICATION[item.qualification]}
              />
            </dd>
            <dd className="rounded-lg bg-darkerGray shadow-md">
              <AttributeCard
                label={t('Languages')}
                value={item.languages.map((language) => t(LANGUAGE[language as keyof typeof LANGUAGE])).join(', ')}
              />
            </dd>
            {!!item.skills.length && (
              <dd className="rounded-lg bg-darkerGray shadow-md">
                <AttributeCard
                  label={t('Skills')}
                  value={item.skills.map((skill) => skill.name).join(', ')}
                />
              </dd>
            )}
          </dl>
        </div>
        {!!item?.company?.benefits?.length && (
          <div className="font-semibold text-lg">
            {t('Company Benefits')}
            <div className="grid grid-cols-2 gap-6 pt-2">
              {item?.company?.benefits?.map((benefit) => (
                <BenefitPlateCard
                  key={benefit?.id}
                  benefitType={benefit?.benefitType as string}
                  description={benefit?.description as string}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="pt-8">
        <div className="font-semibold text-lg py-4">
          {item.company.name}
        </div>
        <div className="grid gap-2">
          {item.company.description.split('\n').map((str, index) => <p key={index}>{str}</p>)}
        </div>
      </div>
      <div className="grid grid-cols-2 pt-4">
        <div className="grid grid-cols-2 gap-3">
          <div>
            <LikeCompanySection companyId={item.company.id} />
          </div>
          <div>
            <ShowProfileButton link={`/company/${item.company.id}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailCard;
