import { gql } from '@apollo/client';

export const APPLICANT_PREFERRED_LOCATION_MUTATION = gql`
mutation applicantPreferredLocation(
  $id: ID,
  $applicant: ID!,
  $city: ID!,
  $country: ID!
  ){
    applicantPreferredLocation(
      input:{
        id: $id
        applicant: $applicant
        city: $city
        country: $country
      }
    )
  { response{
    id
  }
    errors{
      field
      messages
    }
  }
}
`;
