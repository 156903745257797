import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { fixStr } from '../../helpers/utils';

interface Props {
  errors: string[],
  className?: string,
  hideIcon?: boolean,
}

function ErrorsList(props: Props) {
  const {
    errors, className, hideIcon,
  } = props;
  return (
    <ul className={className || ''}>
      {errors.map((error, index) => (
        <li key={`${index}-${error}`} className="flex items-center py-1">
          {!hideIcon && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span
            className={`text-red-600 font-medium text-sm ${hideIcon ? '' : 'ml-2'}`}
          >
            {fixStr(error)}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default ErrorsList;
