import { CompanyType, PossibleCompanyMatchType } from '../__generated__/graphql';
import { getInitials, classNames } from '../helpers/utils';

function getCompanyImage(company: CompanyType | PossibleCompanyMatchType, imageSize: string) {
  if (company && company.profileImageSrcset) {
    return company.profileImageSrcset[imageSize];
  }
  return null;
}

interface Props {
  company: PossibleCompanyMatchType | CompanyType,
  className: string,
  imageSize: string,
}

function CompanyProfileImage(props: Props) {
  const { company, className, imageSize } = props;
  const image = getCompanyImage(company, imageSize);

  if (image) {
    return (
      <img
        className={className}
        src={image}
        alt=""
      />
    );
  }
  return (
    <span className={classNames(className && className, 'inline-flex items-center justify-center bg-primary')}>
      <span className="font-medium leading-none text-white">
        {getInitials(company.name as string)}
      </span>
    </span>
  );
}

export default CompanyProfileImage;
