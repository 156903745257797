import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { POSSIBLE_MATCHES_QUERY } from '../../api/queries/companies';
import { RECOMMENDED_OFFERS_QUERY } from '../../api/queries/offers';
import { PossibleMatches, RecommendedOffers, EmptyDashboard } from '../../components/applicant/dashboard';
import { ApplicantDashboardLayout } from '../../layout/layouts/dashboard';

function ApplicantDashboard() {
  const [matchedPage, setMatchedPage] = useState(1);
  const [recommendedPage, setRecommendedPage] = useState(1);

  const {
    data: matchesData,
    loading: matchesLoading,
    fetchMore: fetchMoreMatches,
  } = useQuery(POSSIBLE_MATCHES_QUERY, {
    variables: {
      page: 1,
    },
  });

  const {
    data: offersData,
    loading: offersLoading,
    fetchMore: fetchMoreOffers,
  } = useQuery(RECOMMENDED_OFFERS_QUERY, {
    variables: {
      page: 1,
    },
  });

  useEffect(() => {
    fetchMoreOffers({
      variables: {
        page: matchedPage,
      },
    });
  }, [fetchMoreOffers, matchedPage]);

  useEffect(() => {
    fetchMoreOffers({
      variables: {
        page: recommendedPage,
      },
    });
  }, [fetchMoreMatches, fetchMoreOffers, recommendedPage]);

  const matchesAvaliable = matchesData
    && matchesData.possibleMatches && !!matchesData.possibleMatches.totalObjectsQuantity;

  const offersAvaliable = offersData
    && offersData.recommendedOffers && !!offersData.recommendedOffers.totalObjectsQuantity;

  return (
    <ApplicantDashboardLayout>
      <div className="grid gap-y-6">
        {matchesAvaliable && (
          <PossibleMatches
            items={matchesData.possibleMatches}
            setPage={setMatchedPage}
          />
        )}
        {offersAvaliable && (
          <RecommendedOffers
            items={offersData.recommendedOffers}
            setPage={setRecommendedPage}
          />
        )}
        {!matchesAvaliable && !matchesLoading && !offersAvaliable && !offersLoading && (
          <EmptyDashboard />
        )}
      </div>
    </ApplicantDashboardLayout>
  );
}

export default ApplicantDashboard;
