import { useQuery, useMutation } from '@apollo/client';
import { ThumbUpIcon as ThumbUpIconOutline } from '@heroicons/react/outline';
import { ThumbUpIcon as ThumbUpIconSolid } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { COMPANY_APPLICANT_LIKE_MUTATION } from '../../../api/mutations/likes';
import { MANAGER_THREADS_QUERY } from '../../../api/queries/chats';
import { DOES_COMPANY_LIKE_APPLICANT_QUERY } from '../../../api/queries/likes';

interface Props {
  applicantId: string,
  className: string,
}

function LikeApplicantIconButton(props: Props) {
  const { applicantId, className } = props;
  const [applicantLiked, setApplicantLiked] = useState(undefined);

  const {
    data: likeData,
  } = useQuery(DOES_COMPANY_LIKE_APPLICANT_QUERY, { variables: { applicant: applicantId } });

  const [
    likeMutation,
    {
      loading: mutationLoading,
    }] = useMutation(COMPANY_APPLICANT_LIKE_MUTATION, {
    refetchQueries: [
      { query: MANAGER_THREADS_QUERY },
      { query: DOES_COMPANY_LIKE_APPLICANT_QUERY, variables: { applicant: applicantId } }],
  });

  const handleButtonClick = () => {
    likeMutation({
      variables: {
        applicant: applicantId,
      },
    });
  };

  useEffect(() => {
    if (likeData) {
      setApplicantLiked(likeData.doesCompanyLikeApplicant);
    }
  }, [likeData]);

  if (applicantLiked === undefined) {
    return '';
  }
  return (
    applicantLiked ? (
      <div
        tabIndex={0}
        role="button"
        data-disabled={mutationLoading}
        onClick={() => handleButtonClick()}
      >
        <div className="flex justify-center text-secondary">
          <ThumbUpIconSolid className={className} />
        </div>
      </div>
    ) : (
      <div
        tabIndex={0}
        role="button"
        data-disabled={mutationLoading}
        onClick={() => handleButtonClick()}
      >
        <div className="flex justify-center">
          <ThumbUpIconOutline className={className} />
        </div>
      </div>
    )
  );
}

export default LikeApplicantIconButton;
