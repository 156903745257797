import { gql } from '@apollo/client';

export const MINIFIED_CURRENT_MANAGER_QUERY = gql`
  query minifiedCurrentManager{
    currentManager{
      id
    }
  }
`;

export const CURRENT_MANAGER_QUERY = gql`
query currentManager{
  currentManager{
    id
    profile{
      id
      fullName
    }
  }
}
`;

export const MANAGER_COMPANIES_QUERY = gql`
  query managerCompanies{
    currentManager{
      id
      currentCompany{
        id
        name
      }
      companies{
        id
        name
        owner{
          id
          profile{
            id
            fullName
          }
        }
      }
      invitations{
        id
        status
        company{
          id
          name
          owner{
            profile{
              fullName
            }
          }
        }
      }
    }
  }
`;
