import { Switch as HeadlessSwitch } from '@headlessui/react';
import { classNames } from '../../helpers/utils';

interface Props {
  label: string,
  value: boolean,
  onChange: () => void,
  className?: string,
}

export default function Switch(props: Props) {
  const {
    label, value, onChange, className,
  } = props;

  return (
    <HeadlessSwitch.Group as="div" className={className}>
      <HeadlessSwitch
        checked={value}
        onChange={onChange}
        className={classNames(
          value ? 'bg-primary' : 'bg-lightGray',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </HeadlessSwitch>
      <HeadlessSwitch.Label as="span" className="ml-3">
        <span className="text-sm font-medium ">{label}</span>
      </HeadlessSwitch.Label>
    </HeadlessSwitch.Group>
  );
}
