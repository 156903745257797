import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import { ApplicantOnboarding } from '../components/applicant/onboarding';
import { ManagerOnboarding } from '../components/manager/onboarding';
import { USER_TYPE } from '../constants';
import { DashboardLayout } from '../layout/layouts';

function Onboarding() {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY);
  const user = data ? data.currentUser : null;
  return (
    <DashboardLayout>
      {loading && (
      <p>
        {t('Loading')}
        ...
      </p>
      )}
      {error && (<p>{error.message}</p>)}
      <main>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="mb-5">
            <div className="px-1">
              <h3 className="text-lg leading-6 font-medium ">{t('Welcome to Sprungbrett Handwerk!')}</h3>
              <div className="mt-2 max-w-xl text-sm ">
                <p>{t('Please fill the form below in order to get started.')}</p>
              </div>
            </div>
          </div>
          {user && user.userType === USER_TYPE.APPLICANT && (
          <ApplicantOnboarding
            user={user}
          />
          )}
          {user && user.userType === USER_TYPE.MANAGER && (
          <ManagerOnboarding
            user={user}
          />
          )}
        </div>
      </main>

    </DashboardLayout>
  );
}

export default Onboarding;
