import { useQuery } from '@apollo/client';
import { CURRENT_PROFILE_QUERY } from '../../api/queries/profiles';
import { CURRENT_USER_QUERY } from '../../api/queries/users';
import { ProfileForm } from '../../components/shared';
import { SettingsLayout } from '../../layout/layouts/dashboard';

function Profile() {
  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const { data: profileData } = useQuery(CURRENT_PROFILE_QUERY);

  return (
    <SettingsLayout>
      {userData && profileData && (
        <ProfileForm
          userId={userData.currentUser.id}
          currentProfile={profileData.currentProfile}
          refetchQueries={[{
            query: CURRENT_USER_QUERY,
          }]}
        />
      )}
    </SettingsLayout>
  );
}

export default Profile;
